/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-floating-promises */
import _ from 'utils/lodash';
import { observable, action } from 'mobx';
import type Api from 'types/next-api';
import DeliveryStore from './deliveryStore';
import TemplateStore from './templateStore';

export type Concept = Api.Components.Schemas.Concept;
export type ConceptSearchPayload = Api.Paths.SearchConcepts.RequestBody;
export type ConceptParticipatePayload = Api.Paths.StoreParticipateAutomatically.RequestBody;

export default class ConceptStore {
  public client: Api.Client;
  public stores: {
    deliveryStore: DeliveryStore;
    templateStore: TemplateStore;
  };

  /**
   * Current entity
   */
  @observable current: Concept;
  @action setCurrent(concept: Concept) {
    this.current = concept;
  }
  @action unsetCurrent() {
    this.current = undefined;
  }

  /**
   * Entity collection
   */
  @observable collection: Concept[] = [];
  get concepts() {
    return this.collection;
  }

  /**
   * IDs of cached concepts
   */
  private conceptIds: string[] = [];

  @action
  public updateCollection(items: Concept[]) {
    for (const item of items) {
      if (!item) {
        continue;
      }
      const index = _.findIndex(this.collection, { id: item.id });
      if (index !== -1) {
        // update
        if (!_.isEqual(this.collection[index], item)) {
          this.collection[index] = item;
        } else {
          // no need to update
        }
      } else {
        // add to collection
        this.collection = [...this.collection, item];
      }
    }
    return this.collection;
  }

  @action
  public resetCollection() {
    this.collection = [];
    this.conceptIds = [];
    return this.collection;
  }

  /**
   * API methods
   */
  @action async search(payload: ConceptSearchPayload = {}) {
    try {
      const result = await this.client.searchConcepts(null, payload);

      // Update collection with cached concepts if they exist. Otherwise use search results.
      const concepts = _.map(result.data.result, (concept) =>
        this.conceptIds.includes(concept.id) ? _.find(this.collection, { id: concept.id }) : concept,
      );
      this.updateCollection(concepts);
      return result.data.result;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async getConcept(id: string, fetchRelations = true) {
    try {
      if (fetchRelations) {
        // eslint-disable-next-line
        (async () => {
          this.stores.deliveryStore.search({ concept: [id] });
          this.stores.templateStore.search({ concept: [id] });
        })();
      }

      // If we have cached copy, return it, otherwise fetch new
      let concept = this.conceptIds.includes(id) ? _.find(this.collection, { id }) : null;
      if (!concept) {
        const result = await this.client.getConcept(id);
        concept = result.data;
        this.updateCollection([concept]);
        this.conceptIds.push(concept.id);
      }
      return concept;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async storeParticipateAutomatically(conceptId: string, participate: ConceptParticipatePayload = {}) {
    try {
      const result = await this.client.storeParticipateAutomatically(conceptId, participate);
      this.updateCollection([result.data]);
      return result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async storeParticipateAutomaticallyExcludeTemplate(conceptId: string, templateId: string, exclude: boolean) {
    try {
      const result = await this.client.storeParticipateAutomaticallyExcludeTemplate(conceptId, { templateId, exclude });
      this.updateCollection([result.data]);
      return result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  handleError(error: Error) {
    console.error(error);
  }
}
