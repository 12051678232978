/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-misused-promises, 	@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires, 	@typescript-eslint/no-unsafe-member-access  */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import page from 'components/next/pages/page/page';
import { inject, observer } from 'mobx-react';
import StoreStore from 'stores/next/stores';
import type { Store } from 'types/next';
import UserStore from 'stores/userStore';
import DeliveriesStore from 'stores/next/deliveries';
import { getLink } from 'components/retailer/next/routes';
import { getTokenForStore } from 'utils/api/user';
import './stores.scss';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import SearchBox from 'components/next/components/sidebar/searchBox';
import ChainSelector from 'components/next/components/chainSelector';
import { getExcludedChains, useDebouncedEffect } from 'utils/helpers';
import { ChainAbbreviations } from 'constants/common';
import Spinner from 'components/common/next/spinner';

interface InjectedProps {
  storeStore?: StoreStore;
  deliveriesStore?: DeliveriesStore;
}

interface Props extends InjectedProps {
  chainIds: string[];
}

const Stores = ({ storeStore, deliveriesStore, chainIds: chainIdsProps }: Props) => {
  const [query, setQuery] = useState('');
  const [chainIds, setChainIds] = useState<string[]>(chainIdsProps);
  const [searchResult, setSearchResults] = useState<Store[]>([]);
  const [deliveryCounts, setDeliveryCounts] = useState<{ [storeId: string]: number }>({});
  const [isLoading, setIsLoading] = useState(true);

  const excludedChains = getExcludedChains(chainIdsProps);

  const searchStores = async () => {
    setIsLoading(true);
    const searchResult: Store[] = await storeStore.searchStores({
      query,
      chainIds,
      limit: 100,
      includeTestStores: true,
    });

    const deliveries = _.get(await deliveriesStore.search({ storeId: _.map(searchResult, 'storeId') }), [
      'data',
      'result',
    ]);

    const deliveryCounts = _.countBy(deliveries, 'storeId');

    setSearchResults(searchResult);
    setDeliveryCounts(deliveryCounts);
    setIsLoading(false);
  };

  const viewAsStore = (storeId: string) => async (e) => {
    await getTokenForStore(storeId);
    window.location.href = getLink('front');
  };

  const renderStoreRows = () => {
    return (
      <tbody>
        {searchResult
          .filter((store) => !store.removed)
          .map((store) => (
            <tr key={store.storeId}>
              <td>{store.storeId}</td>
              <td>{store.name}</td>
              <td>
                <span className={`chain-name ${ChainAbbreviations[store.chainId]}`}>
                  {ChainAbbreviations[store.chainId]}
                </span>
              </td>
              <td>{_.get(deliveryCounts, [store.storeId], 0)}</td>
              <td className="actions">
                <button onClick={viewAsStore(store.storeId)}>
                  <img src={require('images/eye-color.svg').default} alt="view-as-store" />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'query') {
      setQuery(value);
    }
  };

  const handleChainChange = (chain: string) => {
    const newChainIds = [...chainIds];
    if (chainIds.includes(chain)) {
      _.pullAt(newChainIds, chainIds.indexOf(chain));
    } else {
      newChainIds.push(chain);
    }
    setChainIds(newChainIds);
  };

  useDebouncedEffect(searchStores, [query, chainIds], 500);

  const renderSidebar = () => (
    <div className="stores-sidebar">
      <div className="store-filters">
        <div className="sidebar__title-wrapper">
          <h3 className="sidebar__title">Filters</h3>
          {isLoading && <Spinner addClassName="spinner--unset" />}
        </div>
        <SearchBox value={query} name="query" onChange={handleChange} placeholder="Search stores..." />
        <label />
        <ChainSelector chainSelection={chainIds} excludeChains={excludedChains} handleChainChange={handleChainChange} />
      </div>
    </div>
  );

  return (
    <SidebarWrapper renderSidebar={renderSidebar}>
      <div className="stores">
        <header>
          <h2>Stores</h2>
        </header>
        <table className="styled">
          <thead>
            <tr>
              <th>Business Unit</th>
              <th>Name</th>
              <th>Chain</th>
              <th>Deliveries</th>
              <th>Actions</th>
            </tr>
          </thead>
          {renderStoreRows()}
        </table>
        {isLoading && <Spinner />}
      </div>
    </SidebarWrapper>
  );
};

export default inject('storeStore', 'deliveriesStore')(observer(page(Stores)));
