/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/unbound-method */
import _ from 'utils/lodash';
import type Api from 'types/next-api';
import { castDate, isKRautaChain, isOnninenChain, replaceDotInPrice } from 'utils/helpers';
import * as date from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { observable, action, computed, toJS } from 'mobx';
import type {
  SelectionCounts,
  SelectionAreas,
  ContentTemplate,
  EmailContentTemplate,
  PrintContentTemplate,
  DeliveryOffer,
  OfferOption,
  PreviewParams,
  DeliveryLanguage,
  MobileContentTemplate,
  DeliveryField,
} from 'types/next';
import TemplateStore, { DeliveryTemplate, DeliveryTemplateRelations } from './templateStore';
import AlertStore from 'stores/next/alerts';
import type { User } from 'types/user';
import ConceptStore from './conceptStore';
import { getClient } from 'utils/next-api';
import { AxiosRequestConfig } from 'axios';
import { todoRegexp } from '../../types/delivery';
import { getPrintRecipientCount } from 'components/retailer/next/utils/helpers';
import { BusinessType, DeliveryChannelName, ConceptType, PreviewFormat } from 'enums/common';

export interface DeliveryMeta {
  selectionCounts: SelectionCounts;
  selectionCountsLoading: boolean;
  maxSelectionCounts: SelectionCounts;
  maxSelectionCountsLoading: boolean;
  maxSelectionAreas: SelectionAreas;
  maxSelectionAreasLoading: boolean;

  printPreviewLarge: {
    fi: string;
    sv?: string;
  };
  printPreviewLargeLoading: boolean;
  printPreviewThumb: {
    fi: string;
    sv?: string;
  };
  printPreviewThumbLoading: boolean;
  printPreviewPdf: {
    fi: string;
    sv?: string;
  };
  printPreviewPdfLoading: boolean;

  emailPreviewLarge: {
    fi: string;
    sv?: string;
  };
  emailPreviewLargeLoading: boolean;
  emailPreviewThumb: {
    fi: string;
    sv?: string;
  };
  emailPreviewThumbLoading: boolean;
  emailPreviewHtml: {
    fi: string;
    sv?: string;
  };
  emailPreviewHtmlLoading: boolean;
  emailPreviewUrl: {
    fi: string;
    sv?: string;
  };
  meta?: {
    titles: {
      deliveryTemplate: {
        fi: string;
        sv?: string;
      };
      concept: {
        fi: string;
        sv?: string;
      };
    };
    type?: ConceptType;
  };
}

export type DeliverySearchPayload = Api.Paths.SearchDeliveries.RequestBody;
export type Delivery = Api.Components.Schemas.Delivery;
export type DeliveryRelations = Api.Components.Schemas.DeliveryRelations;
export type DeliveryWithMeta = Delivery & Partial<DeliveryRelations & DeliveryMeta>;
export type DeliveryResult = any;

// which method to use when updating the entity
export interface DeliveryEntityRef {
  current?: boolean;
  deliveryId?: string;
  defaultDeliveryTemplateId?: string;
}

export interface DeliveryPreviewsOpts {
  print?: {
    image?: boolean;
    pdf?: boolean;
  };
  email?: {
    image?: boolean;
    html?: boolean;
  };
  languages?: DeliveryLanguage[];
}

// start of action
export default class DeliveryStore {
  public client: Api.Client;
  public stores: {
    // add stores here
    templateStore: TemplateStore;
    conceptStore: ConceptStore;
    alertStore: AlertStore;
  };

  @observable current: DeliveryWithMeta;
  @observable collection: DeliveryWithMeta[] = [];

  @observable previewLanguage: DeliveryLanguage = 'fi';

  // alias for current
  @computed
  get delivery(): DeliveryWithMeta {
    return this.current;
  }

  // alias for collection
  @computed
  get deliveries(): DeliveryWithMeta[] {
    return this.collection;
  }

  @computed
  get numberOfValidationErrors(): number {
    if (this.current) {
      const regex = new RegExp(todoRegexp, 'g');
      const currentLangs = this.current.targetGroup.languages;
      // if current languages includes finnish, check the finnish language deliveryfields
      const fiFields = [];
      const svFields = [];
      if (currentLangs.includes('fi')) {
        const fiDeliveryFields = this.current.deliveryFields.map((field) => _.get(field, 'value.fi'));
        fiFields.push(fiDeliveryFields);
      }
      // if current languages includes swedish, check the swedish language deliveryfields
      if (currentLangs.includes('sv')) {
        const svDeliveryFields = this.current.deliveryFields.map((field) => _.get(field, 'value.sv'));
        svFields.push(svDeliveryFields);
      }
      const fieldsToValidate = [...fiFields, ...svFields];
      const validationErrors = JSON.stringify(fieldsToValidate).match(regex);
      return validationErrors ? validationErrors.length : 0;
    }
    return 0;
  }

  /**
   * Set preview language
   */
  @action
  public setPreviewLanguage(language: DeliveryLanguage) {
    this.previewLanguage = language;
  }

  /**
   * Current entity methods
   */
  @action
  public setCurrent(delivery: DeliveryWithMeta) {
    this.current = delivery;
  }
  @action
  public unsetCurrent() {
    this.current = undefined;
  }

  /**
   * Entity collection methods
   */
  public getCollectionItem(id: string) {
    return _.find(this.collection, { id });
  }

  @action
  public replaceCollectionItems = (items: DeliveryWithMeta[]) => {
    for (const item of items) {
      const index = _.findIndex(this.collection, { id: item.id });
      if (index !== -1) {
        // update
        if (!_.isEqual(this.collection[index], item)) {
          this.collection[index] = item;
        } else {
          // no need to update
        }
      } else {
        // add to collection
        this.collection = [...this.collection, item];
      }
    }
    return this.collection;
  };

  @action
  public updateCollection = (items: DeliveryWithMeta[]) => {
    const newItems = items.map((item) => {
      const existing = this.getCollectionItem(item.id);
      if (existing) {
        return { ...existing, ...item, deliveryTemplate: item.deliveryTemplate || '' };
      } else {
        return { ...item, deliveryTemplate: item.deliveryTemplate || '' };
      }
    });
    return this.replaceCollectionItems(newItems);
  };

  @action
  public deleteFromCollection(ids: string[]) {
    const items = this.collection.filter((item) => !ids.includes(item.id));
    this.collection = items;
    return this.collection;
  }

  @action
  public resetCollection() {
    this.collection = [];
    return this.collection;
  }

  /**
   * Updating entities in store with reference
   */
  @action
  public createRefForEntity(entity: DeliveryWithMeta): DeliveryEntityRef {
    if (entity === this.current) {
      return { current: true };
    }
    if (entity.id) {
      return { deliveryId: entity.id };
    }
  }

  public getEntityForRef(ref: DeliveryEntityRef) {
    if (!ref) {
      return undefined;
    }
    if (ref.current) {
      return this.current;
    }
    if (ref.deliveryId) {
      // update in collection
      return this.getCollectionItem(ref.deliveryId);
    }
    if (ref.defaultDeliveryTemplateId) {
      // update template.defaultDelivery
      const template = this.stores.templateStore.getCollectionItem(ref.defaultDeliveryTemplateId);
      return template.defaultDelivery;
    }
  }

  @action
  public updateEntityWithRef = (ref: DeliveryEntityRef, update: Partial<DeliveryWithMeta>) => {
    if (!ref) {
      // do nothing
      return null;
    }
    if (ref.current) {
      // update current
      return this.setCurrent({ ...this.current, ...update });
    }
    if (ref.deliveryId) {
      // update in collection
      const item = this.getCollectionItem(ref.deliveryId);
      return this.replaceCollectionItems([{ ...item, ...update }]);
    }
    if (ref.defaultDeliveryTemplateId) {
      // update template.defaultDelivery
      const template = this.stores.templateStore.getCollectionItem(ref.defaultDeliveryTemplateId);
      const defaultDelivery = template.defaultDelivery
        ? { ...template.defaultDelivery, ...update }
        : { ...(update as DeliveryWithMeta) };
      return this.stores.templateStore.replaceCollectionItems([{ ...template, defaultDelivery }]);
    }
  };

  /**
   * API methods
   */
  @action async search(payload: DeliverySearchPayload = {}) {
    try {
      const parameters = { light: true };
      const result = await this.client.searchDeliveries(parameters, payload);
      this.updateCollection(result.data.result);
      return result.data.result;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async getDelivery(id: string, fetchTemplateAndConcept = true) {
    try {
      const result = await this.client.getDelivery(id);
      this.updateCollection([result.data]);

      // fetch relations
      (async () => {
        const delivery = this.getCollectionItem(id);
        this.getSelectionCountsForDelivery(delivery);
        if (fetchTemplateAndConcept) {
          const template = await this.stores.templateStore.getDeliveryTemplate(delivery.deliveryTemplate);
          await this.stores.conceptStore.getConcept(template.concept);
        }
      })();

      return result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async saveDelivery(
    delivery: Delivery & DeliveryRelations,
    isHalvePrintRecipientCount: boolean,
    mobileOffers?: boolean,
  ) {
    try {
      const payload = getDeliveryPayload(delivery, isHalvePrintRecipientCount);
      const result = delivery.id
        ? await this.client.replaceDelivery(delivery.id, mobileOffers ? { ...payload, mobileOffers: true } : payload)
        : await this.client.createDelivery(null, mobileOffers ? { ...payload, mobileOffers: true } : payload);
      this.updateCollection([result.data]);
      return result.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  @action async deleteDelivery(id: string) {
    try {
      const result = await this.client.deleteDelivery(id);
      this.deleteFromCollection([id]);
      return result.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  @action async previewDelivery(
    delivery: Delivery & DeliveryRelations,
    params: PreviewParams,
    ref?: DeliveryEntityRef,
  ) {
    // apply defaults
    params = {
      format: PreviewFormat.Image,
      channel: DeliveryChannelName.Email,
      language: this.previewLanguage,
      ...params,
    };

    const previewById = ref?.deliveryId && !delivery;

    if (!ref) {
      ref = this.createRefForEntity(delivery);
    }

    if (params.channel === DeliveryChannelName.Email && params.format === PreviewFormat.Image) {
      this.updateEntityWithRef(ref, { emailPreviewLargeLoading: true, emailPreviewThumbLoading: true });
    }
    if (params.channel === DeliveryChannelName.Email && params.format === PreviewFormat.Html) {
      this.updateEntityWithRef(ref, { emailPreviewHtmlLoading: true });
    }
    if (params.channel === DeliveryChannelName.Print && params.format === PreviewFormat.Image) {
      this.updateEntityWithRef(ref, { printPreviewLargeLoading: true, printPreviewThumbLoading: true });
    }
    if (params.channel === DeliveryChannelName.Print && params.format === PreviewFormat.Pdf) {
      this.updateEntityWithRef(ref, { printPreviewPdfLoading: true });
    }

    const responseType = params.returnUrl ? 'json' : 'blob';
    const config: AxiosRequestConfig = { responseType };

    const payload = previewById ? null : getDeliveryPayload(delivery, false); // can be always false, because recipientEstimates is not used here

    const result = previewById
      ? await this.client.previewDeliveryById(
          {
            ...params,
            chainDelivery: 'false',
            deliveryId: ref.deliveryId,
          },
          null,
          config,
        )
      : await this.client.previewDelivery(params, payload, config);

    const entity = this.getEntityForRef(ref);

    if (params.returnUrl) {
      const updateLanguageURL = params.language == 'fi' ? { fi: result.data as string } : { sv: result.data as string };
      this.updateEntityWithRef(ref, {
        emailPreviewLargeLoading: false,
        emailPreviewThumbLoading: false,
        emailPreviewUrl: { ...entity.emailPreviewUrl, ...updateLanguageURL },
      });
      return result.data as string;
    }

    const blobURL = URL.createObjectURL(result.data as Blob);
    const updateLanguageURL = params.language == 'fi' ? { fi: blobURL } : { sv: blobURL };

    if (params.channel === DeliveryChannelName.Email && params.format === PreviewFormat.Image) {
      this.updateEntityWithRef(ref, {
        emailPreviewLarge: { ...entity.emailPreviewLarge, ...updateLanguageURL },
        emailPreviewThumb: { ...entity.emailPreviewThumb, ...updateLanguageURL },
        emailPreviewLargeLoading: false,
        emailPreviewThumbLoading: false,
      });
    }
    if (params.channel === DeliveryChannelName.Email && params.format === PreviewFormat.Html) {
      this.updateEntityWithRef(ref, {
        emailPreviewHtml: { ...entity.emailPreviewHtml, ...updateLanguageURL },
        emailPreviewHtmlLoading: false,
      });
    }
    if (params.channel === DeliveryChannelName.Print && params.format === PreviewFormat.Image) {
      this.updateEntityWithRef(ref, {
        printPreviewLarge: { ...entity.printPreviewLarge, ...updateLanguageURL },
        printPreviewThumb: { ...entity.printPreviewThumb, ...updateLanguageURL },
        printPreviewLargeLoading: false,
        printPreviewThumbLoading: false,
      });
    }
    if (params.channel === DeliveryChannelName.Print && params.format === PreviewFormat.Pdf) {
      this.updateEntityWithRef(ref, {
        printPreviewPdf: { ...entity.printPreviewPdf, ...updateLanguageURL },
        printPreviewPdfLoading: false,
      });
    }
    return blobURL;
  }

  @action async previewDeliveryById(deliveryId: string, params: PreviewParams) {
    const ref: DeliveryEntityRef = { deliveryId };
    return this.previewDelivery(null, params, ref);
  }

  updateDeliveryPreviews = ({
    delivery,
    ref,
    opts,
  }: {
    delivery: Delivery & DeliveryRelations;
    ref?: DeliveryEntityRef;
    opts?: DeliveryPreviewsOpts;
  }) => {
    const defaultOpts: DeliveryPreviewsOpts = {
      print: { image: true, pdf: true },
      email: { image: true, html: true },
      languages: ['fi', 'sv'],
    };
    const previewOpts: DeliveryPreviewsOpts = _.merge({}, defaultOpts, opts);

    const template = this.stores.templateStore.getCollectionItem(delivery.deliveryTemplate);

    previewOpts.languages.forEach((language) => {
      if (_.find(template.channels, { name: 'email' })) {
        if (previewOpts.email.html) {
          this.previewDelivery(
            delivery,
            { channel: DeliveryChannelName.Email, format: PreviewFormat.Html, language },
            ref,
          );
        }

        if (previewOpts.email.image) {
          this.previewDelivery(
            delivery,
            { channel: DeliveryChannelName.Email, format: PreviewFormat.Image, language },
            ref,
          );
        }
      }

      if (_.find(template.channels, { name: 'print' })) {
        if (previewOpts.print.pdf) {
          this.previewDelivery(
            delivery,
            { channel: DeliveryChannelName.Print, format: PreviewFormat.Pdf, language },
            ref,
          );
        }

        if (previewOpts.print.image) {
          this.previewDelivery(
            delivery,
            { channel: DeliveryChannelName.Print, format: PreviewFormat.Image, language },
            ref,
          );
        }
      }
    });
  };

  @action async getSelectionCountsForDelivery(delivery: DeliveryWithMeta, ref?: DeliveryEntityRef) {
    if (!ref) {
      ref = this.createRefForEntity(delivery);
    }

    this.updateEntityWithRef(ref, { selectionCountsLoading: true });

    const targetGroup = toJS(delivery.targetGroup);
    const result = await this.client.selectionCounts(null, targetGroup);

    if (result.status !== 200) {
      this.stores.alertStore.error({
        message: 'Kohderyhmien haku epäonnistui',
      });
    }

    const selectionCounts = result.data;

    const updated = this.getEntityForRef(ref);
    if (updated && _.isEqual(targetGroup, toJS(updated.targetGroup))) {
      // only update counts if the target group is still the same as when we started
      this.updateEntityWithRef(ref, {
        selectionCounts,
        selectionCountsLoading: false,
      });
    }

    this.updateEntityWithRef(ref, {
      selectionCounts,
      selectionCountsLoading: false,
    });

    return selectionCounts;
  }

  @action async getMaxSelectionCountsForDelivery(delivery: DeliveryWithMeta, ref?: DeliveryEntityRef) {
    if (!ref) {
      ref = this.createRefForEntity(delivery);
    }

    this.updateEntityWithRef(ref, { maxSelectionCountsLoading: true });

    const targetGroup = toJS(delivery.targetGroup);
    const template = this.stores.templateStore.getCollectionItem(delivery.deliveryTemplate) as DeliveryTemplate &
      DeliveryTemplateRelations;
    const channels = getMaxChannelSelectionsForTemplate(template);

    let maxSelectionCounts: SelectionCounts = {
      kRuokaApp: 0,
      channels: {
        email: 0,
        print: 0,
        mobile: 0,
      },
      total: 0,
    };
    const maxSelection = this.client.selectionCounts(null, {
      ...targetGroup,
      channels,
    });

    if (
      (targetGroup.channels.email !== undefined && targetGroup.channels.email !== -1) ||
      (targetGroup.channels.print !== undefined && targetGroup.channels.print !== -1)
    ) {
      try {
        // email selection affects available print recipients
        const [res1, res2] = await Promise.all([
          maxSelection,
          this.client.selectionCounts(null, {
            ...targetGroup,
            channels: {
              ...channels,
              email: targetGroup.channels.email,
              print: targetGroup.channels.print,
            },
          }),
        ]);

        const emailMax = res1.data.channels.email;
        const printMax = res1.data.channels.print;
        const mobileMax = res1.data.channels.mobile;
        const kRuokaApp = res2.data.kRuokaApp;

        maxSelectionCounts = {
          channels: {
            email: emailMax,
            print:
              res1.data.channels.print > res2.data.channels.print ? res1.data.channels.print : res2.data.channels.print,
            mobile: mobileMax,
          },
          kRuokaApp,
          language: {
            ...res1.data.language,
            // does this matter for max?
          },
          total: emailMax + printMax + mobileMax,
        };
      } catch (error) {
        this.stores.alertStore.error({ message: 'Kohderyhmien haku epäonnistui' });
      }
    } else {
      try {
        const res = await maxSelection;
        maxSelectionCounts = res.data;
      } catch (error) {
        this.stores.alertStore.error({ message: 'Kohderyhmien haku epäonnistui' });
      }
    }

    const updated = this.getEntityForRef(ref);
    if (updated && _.isEqual(targetGroup, toJS(updated.targetGroup))) {
      // refresh channel selections according to counts
      if (targetGroup.channels.email) {
        targetGroup.channels.email = _.min([maxSelectionCounts.channels.email, targetGroup.channels.email]);
      }
      if (targetGroup.channels.print) {
        targetGroup.channels.print = _.min([maxSelectionCounts.channels.print, targetGroup.channels.print]);
      }
      if (targetGroup.channels.mobile) {
        targetGroup.channels.mobile = _.min([maxSelectionCounts.channels.mobile, targetGroup.channels.mobile]);
      }

      this.updateEntityWithRef(ref, {
        maxSelectionCounts,
        maxSelectionCountsLoading: false,
        targetGroup,
      });
    }
    return maxSelectionCounts;
  }

  @action async getMaxSelectionAreasForDelivery(delivery: DeliveryWithMeta, ref?: DeliveryEntityRef) {
    if (!ref) {
      ref = this.createRefForEntity(delivery);
    }
    this.updateEntityWithRef(ref, { maxSelectionAreasLoading: true });

    const targetGroup = toJS(delivery.targetGroup);
    const template = this.stores.templateStore.getCollectionItem(delivery.deliveryTemplate) as DeliveryTemplate &
      DeliveryTemplateRelations;
    const channels = getMaxChannelSelectionsForTemplate(template);
    // Postal areas areas are not in use in B2B delivery
    const result =
      targetGroup.targetGroupType === BusinessType.B2b
        ? { data: [] }
        : await this.client.selectionAreas(null, {
            ..._.omit(targetGroup, 'areas'),
            channels,
          });
    const maxSelectionAreas = result.data;
    const updated = this.getEntityForRef(ref);
    if (updated && _.isEqual(targetGroup, toJS(updated.targetGroup))) {
      // only update counts if the target group is still the same as when we started
      this.updateEntityWithRef(ref, {
        maxSelectionAreas,
        maxSelectionAreasLoading: false,
      });
    }

    return maxSelectionAreas;
  }

  @action async getOfferOptionUseCount(offerOptionIds: string[]) {
    const client = await getClient();
    const res = await client.getOfferOptionUseCount(null, offerOptionIds);
    const result = res.data;
    return result;
  }

  handleError(error: any) {
    console.error(error);
    if (error.response) {
      console.error(error.response.code, error.response.data);
    }
    throw error;
  }
}

export const getDeliveryPayload = (delivery: Delivery & DeliveryRelations, isHalvePrintRecipientCount: boolean) => {
  // apply dates to each offer
  const deliveryOffers = delivery.deliveryOffers.map((o) => {
    return {
      ...o,
      startDate: delivery.offerStartDate,
      endDate: delivery.offerEndDate,
    };
  });
  const omittedProps = [
    'emailHtml', // generated by backend
    'printPdf', // generated by backend
    'status',
    'deadline',
    'createdAt',
    'updatedAt',
  ];

  const maxSelectionCounts = (delivery as DeliveryWithMeta).maxSelectionCounts;
  const emailMax = _.get(maxSelectionCounts, ['channels', 'email'], 0);
  const printMax = _.get(maxSelectionCounts, ['channels', 'print'], 0);
  const mobileMax = _.get(maxSelectionCounts, ['channels', 'mobile'], 0);
  const emailSelection = _.get(delivery, ['targetGroup', 'channels', 'email'], 0);
  const printSelection = _.get(delivery, ['targetGroup', 'channels', 'print'], 0);
  const mobileSelection = _.get(delivery, ['targetGroup', 'channels', 'mobile'], 0);

  const recipientEstimates = {
    email:
      emailSelection === -1
        ? delivery.targetGroup.targetGroupType === BusinessType.B2b && delivery.emailRecipients
          ? emailMax - delivery.emailRecipients.bannedEmails.length + delivery.emailRecipients.additionalEmails.length
          : emailMax
        : emailSelection,
    print: getPrintRecipientCount({
      restrictedCount: printSelection,
      maxCount: printMax,
      isHalvePrintRecipientCount,
    }),
    mobile: mobileSelection === -1 ? mobileMax : mobileSelection,
  };

  const updatedFields = replaceDotInPrice(delivery.deliveryFields);
  delivery.deliveryFields = updatedFields as DeliveryField[];

  delivery.recipientEstimates = recipientEstimates;

  return {
    ...(_.omit(delivery, omittedProps) as Delivery & DeliveryRelations),
    deliveryOffers,
  };
};

export const getMaxChannelSelectionsForTemplate = (template: DeliveryTemplate & DeliveryTemplateRelations) => {
  const channelNames = template.channels.map((c) => c.name);
  const hasEmailChannel = _.includes(channelNames, 'email') && _.find(template.contentTemplates, { channel: 'email' });
  const hasPrintChannel = _.includes(channelNames, 'print') && _.find(template.contentTemplates, { channel: 'print' });
  const hasMobileChannel =
    _.includes(channelNames, 'mobile') && _.find(template.contentTemplates, { channel: 'mobile' });
  return {
    email: hasEmailChannel ? -1 : 0,
    print: hasPrintChannel ? -1 : 0,
    mobile: hasMobileChannel ? -1 : 0,
  };
};

export const checkDeliveryOffer = async (deliveryOffer: DeliveryOffer) => {
  const client = await getClient();
  const offer = toJS(deliveryOffer);
  const res = await client.checkOffer(null, offer);
  const result = res.data;
  if (result && result.tosOffer) {
    // set image from offer
    offer.image = result.tosOffer.image;
  }
  return result;
};

/**
 * Figure out the earliest possible start date for a delivery
 */
export const getFirstPossibleStartDateForDelivery = (
  delivery: Partial<Delivery>,
  template: DeliveryTemplate,
  conceptType: ConceptType,
) => {
  const printSelection = delivery.targetGroup.channels.print;
  const printSelected = printSelection !== 0;
  const onlyMobileSelected =
    !printSelected && delivery.targetGroup.channels.email === 0 && delivery.targetGroup.channels.mobile !== 0;

  // Make sure template has first and last start dates set.
  if (_.isNil(template.firstStartDate) || _.isNil(template.lastStartDate)) {
    return null;
  }
  /*
   * Timeline for important dates regarding to deliveries:
   *
   * With only email selected:
   *  startDate: when delivery is considered started, eg. 2019-09-16
   *  offerStartDate: when delivery offers start, eg. 2019-09-16
   *  deadline: when delivery is not editable anymore. with only email, this is 3 business days before delivery start
   *            so 2019-09-11 in this case
   *  scheduled task to run delivery offers and customers to AC: always the day after deadline at 01:00 so 2019-09-12
   *                                                             in this case
   *  processDateTime: when AC processes this delivery. with email this is 2 days before offerStartDate so
   *                   2019-09-14 in this case
   *  deliveryDateTime: when AC sends the email. with only email this is the same as startDate so 2019-09-16
   *
   * With email + print selected:
   *  email:
   *    startDate: when delivery is considered started, eg. 2019-09-16
   *    offerStartDate: when delivery offers start, eg. 2019-09-16
   *    deadline: when delivery is not editable anymore. with email + print, this is 7 business days before delivery
   *              start so 2019-09-05 in this case
   *    scheduled task to run delivery offers and customers to AC: always the day after deadline at 01:00 so 2019-09-06
   *                                                               in this case
   *    processDateTime: when AC processes this delivery. with email this is 2 days before offerStartDate so
   *                     2019-09-14 in this case
   *    deliveryDateTime: when AC sends the email. with email + print this is 2 days after startDate so 2019-09-18
   *
   *  print:
   *    startDate: when delivery is considered started, eg. 2019-09-16
   *    offerStartDate: when delivery offers start, eg. 2019-09-16
   *    deadline: when delivery is not editable anymore. with email + print, this is 14 business days before delivery
   *              start so 2019-09-05 in this case
   *    scheduled task to run delivery offers and customers to AC: always the day after deadline at 01:00 so 2019-09-06
   *                                                               in this case
   *    processDateTime: when AC processes this delivery. with print this is always 7 days before offerStartDate so
   *                     2019-09-09 in this case
   *    deliveryDateTime: not sure if this is used or prints but this is always the same as startDate so 2019-09-16
   */

  const isKRautaOrOnninen = isKRautaChain(delivery.chainId) || isOnninenChain(delivery.chainId);
  const daysBuffer =
    printSelected && conceptType === ConceptType.Season ? 14 : isKRautaOrOnninen ? 1 : onlyMobileSelected ? 3 : 3;
  const today = new Date();
  let firstPossibleStartDate = _.max([castDate(template.firstStartDate), date.addBusinessDays(today, daysBuffer)]);
  if (template.deliveryWeekdays && template.deliveryWeekdays.length > 0) {
    const getWeekday = (d: Date) => date.getISODay(d) - 1; // 0-6, mon-sun
    while (!template.deliveryWeekdays.includes(getWeekday(firstPossibleStartDate))) {
      firstPossibleStartDate = date.addDays(firstPossibleStartDate, 1);
    }
  }
  // Sanity check: template's last start date can't be earlier than delivery's first possible start date
  if (firstPossibleStartDate > castDate(template.lastStartDate)) {
    return castDate(template.lastStartDate);
  }
  return firstPossibleStartDate;
};

/*
 * Glue logic for generating default deliveries from templates
 */
export const getDefaultDeliveryFromTemplate = async (
  template: DeliveryTemplate & DeliveryTemplateRelations,
  conceptType: ConceptType,
  me: User,
) => {
  const delivery: Partial<Delivery & DeliveryRelations> = {
    deliveryTemplate: template.id,
    storeId: me.store,
    chainId: me.chainId.toString(),
    state: 'draft',
  };

  // content template + slots + fields
  const emailContentTemplates = _.filter(template.contentTemplates, { channel: 'email' }) as EmailContentTemplate[];
  const printContentTemplates = _.filter(template.contentTemplates, { channel: 'print' }) as PrintContentTemplate[];
  const mobileContentTemplates = _.filter(template.contentTemplates, { channel: 'mobile' }) as MobileContentTemplate[];
  const emailContentTemplate = _.find(emailContentTemplates, 'isDefault') || emailContentTemplates[0];
  const printContentTemplate = _.find(printContentTemplates, 'isDefault') || printContentTemplates[0];
  const mobileContentTemplate = _.find(mobileContentTemplates, 'isDefault') || mobileContentTemplates[0];

  // channels
  const channelNames = template.channels.map((c) => c.name);
  const hasEmailChannel = _.includes(channelNames, 'email') && emailContentTemplate;
  const hasPrintChannel = _.includes(channelNames, 'print') && printContentTemplate;
  const hasMobileChannel = _.includes(channelNames, 'mobile') && mobileContentTemplate;

  // target group
  const targetGroupChannels = {
    email: hasEmailChannel ? -1 : 0,
    print: hasPrintChannel ? -1 : 0,
    mobile: hasMobileChannel ? -1 : 0,
  };
  delivery.targetGroup = {
    channels: targetGroupChannels,
    dimensions: [],
    languages: ['fi'],
    targetGroupType: conceptType === ConceptType.B2b ? BusinessType.B2b : BusinessType.B2c,
  };
  if (template.targetGroupOpts.dimensions) {
    for (const dimension of template.targetGroupOpts.dimensions) {
      const selection = _.uniq(dimension.default);
      delivery.targetGroup.dimensions.push({
        name: dimension.name,
        selection,
      });
    }
  }

  // dates
  const startDate = getFirstPossibleStartDateForDelivery(delivery, template, conceptType);
  const endDate = date.addDays(castDate(startDate), template.deliveryOpts.minimumLength);

  const offerStartDate = startDate;
  const offerDays = _.get(template, ['offerOpts', 'defaultLength']) || 7;
  const offerEnd = _.get(template, ['offerOpts', 'endDate']);
  const offerEndDate = offerEnd
    ? date.min([date.addDays(castDate(offerStartDate), offerDays - 1), castDate(offerEnd)])
    : date.addDays(castDate(offerStartDate), offerDays - 1);

  delivery.startDate = date.format(castDate(startDate), 'yyyy-MM-dd');
  delivery.endDate = date.format(castDate(endDate), 'yyyy-MM-dd');
  delivery.offerStartDate = date.format(castDate(offerStartDate), 'yyyy-MM-dd');
  delivery.offerEndDate = date.format(castDate(offerEndDate), 'yyyy-MM-dd');
  delivery.lastEditDate = template.lastEditDate ? date.format(castDate(template.lastEditDate), 'yyyy-MM-dd') : null;

  delivery.deliverySlots = [];
  delivery.deliveryFields = [];

  // set default delivery fields
  for (const contentField of template.contentFields) {
    if (contentField.defaultValue) {
      delivery.deliveryFields.push({
        contentField: contentField.id,
        value: contentField.defaultValue,
      });
    }
  }

  // set default delivery slots
  for (const contentTemplate of template.contentTemplates) {
    for (const contentSlot of contentTemplate.contentSlots) {
      if (contentSlot.defaultContentBlock) {
        delivery.deliverySlots.push({
          contentSlot: contentSlot.id,
          contentBlock: contentSlot.defaultContentBlock,
        });
      }
    }
  }

  delivery.deliverySlots = _.uniqBy(delivery.deliverySlots, 'contentSlot');
  delivery.deliveryFields = _.uniqBy(delivery.deliveryFields, 'contentField');

  // set default content templates
  if (hasEmailChannel && emailContentTemplate) {
    delivery.emailContentTemplate = emailContentTemplate.id;
    delivery.emailSubject = emailContentTemplate.emailDefaultSubject;
  }
  if (hasPrintChannel && printContentTemplate) {
    delivery.printContentTemplate = printContentTemplate.id;
  }

  if (template.offerOptions) {
    const filteredOfferOptions = template.offerOptions;

    const offerPromises = _.map(
      _.orderBy(filteredOfferOptions, 'order').filter((o) => o.default),
      async (o: OfferOption) => {
        const prices = _.filter(_.map(_.get(o, ['products']), 'price'), _.isNumber);
        const minPrice = _.min(prices) || o.regularPriceMin;
        const maxPrice = _.max(prices) || o.regularPriceMax;
        const discountPrice = _.isNil(minPrice) ? null : o.discountPrice || Number((0.75 * minPrice).toFixed(1));

        const offer: DeliveryOffer = {
          ...toJS(o),
          id: uuidv4(),
          offerOptionId: o.id,
          // Set default discount price for products
          ...(o.type === 'product' && o.productOfferType === 'price' && discountPrice && { discountPrice }),
          regularPriceMin: minPrice || null,
          regularPriceMax: maxPrice || null,
          startDate: date.format(castDate(offerStartDate), 'yyyy-MM-dd'),
          endDate: date.format(castDate(offerEndDate), 'yyyy-MM-dd'),
        };
        const { overrides } = await checkDeliveryOffer(offer);
        return { ...offer, ...overrides };
      },
    );
    delivery.deliveryOffers = await Promise.all(offerPromises);
  }

  return delivery as DeliveryWithMeta;
};

export const getDefaultSlotsAndFields = (
  template: DeliveryTemplate & DeliveryTemplateRelations,
  contentTemplate: ContentTemplate,
) => {
  const deliverySlots = [];
  const deliveryFields = [];

  // loop fields for content template
  for (const identifier of contentTemplate.contentFieldIdentifiers) {
    const contentField = _.find(template.contentFields, { identifier });
    if (contentField.defaultValue) {
      deliveryFields.push({
        contentField: contentField.id,
        value: contentField.defaultValue,
      });
    }
  }

  for (const contentSlot of contentTemplate.contentSlots) {
    if (contentSlot.defaultContentBlock) {
      const defaultContentBlockId = contentSlot.defaultContentBlock;
      deliverySlots.push({
        contentSlot: contentSlot.id,
        contentBlock: defaultContentBlockId,
      });

      // loop fields for content block
      const contentBlock = _.find(template.contentBlocks, { id: defaultContentBlockId });
      for (const identifier of contentBlock.contentFieldIdentifiers) {
        const contentField = _.find(template.contentFields, { identifier });
        if (contentField.defaultValue) {
          deliveryFields.push({
            contentField: contentField.id,
            value: contentField.defaultValue,
          });
        }
      }
    }
  }

  return { deliverySlots, deliveryFields };
};
