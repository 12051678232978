/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-member-access  */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { getAllowedConceptTypes, getByType, getChain, isKRautaChain, isOnninenChain } from 'utils/helpers';
import { inject, observer } from 'mobx-react';
import AnalyticsStore from 'stores/next-retailer/analyticsStore';
import Button from '../../../../common/next/form/button';
import { Link, NavLink } from 'react-router-dom';
import * as date from 'date-fns';
import { dateFormat } from 'components/next/utils';
import Spinner from 'components/common/next/spinner';
import './frontPage.scss';
import { getLink } from '../../routes';
import { NumericFormat } from 'react-number-format';
import InfoPopover from '../common/infoPopover';
import ContentStore from 'stores/next-retailer/contentStore';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import TemplateStore, { DeliveryTemplate } from 'stores/next-retailer/templateStore';
import UserStore from 'stores/userStore';
import Timeline, { TimelineElement } from 'components/common/next/timeline';

import IconHeart from '@kesko/icons/misc/icon-heart.svg';
import IconSend from '@kesko/icons/action/icon-msg-send.svg';
import IconB2B from '@kesko/icons/people/icon_userB2B.svg';
import ConceptStore, { Concept } from 'stores/next-retailer/conceptStore';
import AppStore from 'stores/next/app';
import { ConceptType } from 'enums/common';

const MILLION = 1000000;
interface Props {
  analyticsStore?: AnalyticsStore;
  contentStore?: ContentStore;
  templateStore?: TemplateStore;
  deliveryStore?: DeliveryStore;
  userStore?: UserStore;
  conceptStore?: ConceptStore;
  appStore?: AppStore;
}
const FrontPage = ({
  analyticsStore,
  contentStore,
  templateStore,
  deliveryStore,
  userStore,
  conceptStore,
  appStore,
}: Props) => {
  const [timelineData, setTimelineData] = useState([]);
  const [content, setContent] = useState([]);

  const deliveryIdList = deliveryStore.deliveries.map((d) => d.deliveryTemplate);

  const getTooltipContent = (deliveryTemplate: DeliveryTemplate, element: TimelineElement) => {
    const { deliveries } = deliveryStore;
    const matchingDelivery = deliveries.find((d) => d.deliveryTemplate === deliveryTemplate.id) || null;
    let showParticipateButton = false;
    let showEditButton = false;
    const now = new Date();
    showParticipateButton =
      !matchingDelivery &&
      date.isBefore(now, date.endOfDay(new Date(deliveryTemplate.deadline))) &&
      date.isAfter(now, date.startOfDay(new Date(deliveryTemplate.publishDate)));
    showEditButton = matchingDelivery && date.isBefore(now, date.endOfDay(new Date(matchingDelivery.deadline)));
    const deliveryWindow =
      deliveryTemplate.firstStartDate === deliveryTemplate.lastStartDate
        ? date.format(new Date(deliveryTemplate.firstStartDate), dateFormat)
        : `${date.format(new Date(deliveryTemplate.firstStartDate), dateFormat)} - ${date.format(
            new Date(deliveryTemplate.lastStartDate),
            dateFormat,
          )}`;
    return (
      <div className="tooltip__content">
        <header>
          <h4>{element.title}</h4>
        </header>
        <p>
          <b>
            Osallistumisaika: {date.format(new Date(deliveryTemplate.publishDate), dateFormat)} -{' '}
            {date.format(new Date(deliveryTemplate.deadline), dateFormat)}
          </b>
          <b>Lähetysten toimitus: {deliveryWindow} </b>
          <span>{element.introduction}</span>
        </p>
        <footer>
          {showEditButton && (
            <Button link={getLink('editDelivery', matchingDelivery.id, deliveryTemplate.id)}>Muokkaa</Button>
          )}
          {showParticipateButton && (
            <Button link={getLink('editDelivery', 'new', deliveryTemplate.id)}>
              {deliveryTemplate.maxDeliveries === 1 ? 'Osallistu' : 'Tee lähetys'}
            </Button>
          )}
        </footer>
      </div>
    );
  };

  const mapTemplatesToTimelineElements = (templates: DeliveryTemplate[], concepts: Concept[]) => {
    const now = new Date();

    const getConcept = (conceptId: string) => {
      return concepts.find((concept) => concept.id === conceptId);
    };

    const timeline = templates
      .filter(
        (templa) =>
          templa.published &&
          date.isAfter(new Date(templa.deadline), date.startOfDay(now)) &&
          date.isBefore(new Date(templa.publishDate), date.addMonths(now, 2)),
      )
      .map((templa): TimelineElement => {
        const result: TimelineElement = {
          signUpStarts: new Date(templa.publishDate),
          signUpEnds: new Date(templa.deadline),
          firstDelivery: new Date(templa.firstStartDate),
          title: templa.title.fi,
          introduction: templa.description.fi,
          invertColors: !deliveryIdList.includes(templa.id),
          icon: getByType(getConcept(templa.concept).type as ConceptType, IconHeart, IconSend, IconB2B),
          deliveryLine: Boolean(templa.targetGroupLockDate),
        };
        result.tooltipContent = getTooltipContent(templa, result);
        return result;
      });
    return _.chain(timeline).orderBy('signUpEnds').groupBy('concept').values().value();
  };

  useEffect(() => {
    const init = async () => {
      await deliveryStore.search();
      const content = await contentStore.search({ showOnFrontPage: true, type: 'news' });
      if (_.isEmpty(analyticsStore.analytics)) {
        analyticsStore.getAnalytics();
      }
      const templas = await templateStore.search();
      const concepts = await conceptStore.search();

      if (process.env.REACT_APP_TIMELINE_ON === 'true') {
        const timelineData = mapTemplatesToTimelineElements(templas, concepts);
        setTimelineData(timelineData);
        setContent(content);
      }
    };
    init();
  }, []);

  const renderTotalRevenue = (revenue?: number) => (
    <NumericFormat
      value={revenue > MILLION ? revenue / MILLION : revenue / 1000}
      displayType="text"
      decimalSeparator=","
      decimalScale={1}
      suffix={revenue > MILLION ? 'M€' : 'K€'}
    />
  );

  const renderAnalytics = () => {
    const { analytics } = analyticsStore;
    if (!analytics) {
      return <Spinner addClassName="spinner--margin-top spinner--margin-bottom" />;
    }
    return (
      <div className="analytics">
        <div className="box">
          <div className="box-section">
            <div className="big-number-display">
              <div className="big-number">
                <NumericFormat value={analytics.totalPersons} displayType="text" thousandSeparator="&nbsp;" />
              </div>
              <label>{isOnninen ? 'Asiakasta' : 'Plussa-asiakasta'} tavoitettavissa K&nbsp;Markkinoinnilla</label>
            </div>
            <InfoPopover>
              <div
                className="help-content"
                dangerouslySetInnerHTML={{
                  __html: contentStore.getContentBySlug('info-tavoitettavissa'),
                }}
              />
            </InfoPopover>
          </div>
          {!isOnninen && (
            <div className="box-section">
              <div className="big-number-display">
                <div className="big-number">{renderTotalRevenue(analytics.totalRevenue)}</div>
                <label>Tavoitettavissa olevien asiakkaiden tunnistettu Plussa-myynti</label>
              </div>
              <InfoPopover>
                <div
                  className="help-content"
                  dangerouslySetInnerHTML={{
                    __html: contentStore.getContentBySlug('info-plussa-myynti'),
                  }}
                />
              </InfoPopover>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderFrontPageNews = () => {
    return (
      <React.Fragment>
        <h3>Uutiset</h3>
        <div className="news-wrapper">
          {content.length > 0 ? (
            content.map((contentItem) => (
              <div className="news-item" key={`front-page-news-${contentItem.id}`}>
                <h3 className="news-item__title">{contentItem.title.fi}</h3>
                <NavLink className="news-item__read-more" to={getLink('newsItem', contentItem.id)}>
                  Lue lisää
                </NavLink>
              </div>
            ))
          ) : (
            <Spinner />
          )}
        </div>
      </React.Fragment>
    );
  };

  const shouldRenderTimeline = process.env.REACT_APP_TIMELINE_ON === 'true' ? timelineData.length > 0 : false;
  const isKRauta = isKRautaChain(userStore.me.chainId);
  const isOnninen = isOnninenChain(userStore.me.chainId);
  const box_program_header = isKRauta ? 'Osallistu ketjun lähetyksiin' : 'Liity asiakasohjelmiin';
  const box_program_content = isKRauta
    ? 'Ketjulähetysten avulla voit osallistua ketjun kampanjoihin ja ohjelmiin.'
    : 'Asiakasohjelmien avulla voit pitkäjänteisesti osallistua K-Ryhmän kampanjoihin ja ohjelmiin.';
  const box_season_header = isKRauta ? 'Tee omia lähetyksiä' : 'Osallistu lähetyksiin';
  const box_season_content = isKRauta
    ? 'Omilla lähetyksillä tavoitat asiakkaasi oikealla hetkellä.'
    : 'Yksittäisillä lähetyksillä tavoitat asiakkaasi juuri oikealla hetkellä.';
  const box_b2b_header = isKRauta ? 'Tee B2B lähetyksiä' : 'Tee myymälän lähetyksiä';
  const box_b2b_content = isKRauta
    ? 'B2B lähetyksillä tavoitat asiakkaasi oikealla hetkellä.'
    : 'Myymälän lähetyksillä tavoitat asiakkaasi oikealla hetkellä.';

  const allowedConceptTypes = getAllowedConceptTypes(appStore.chain);
  const showSeasonConcepts = allowedConceptTypes.includes(ConceptType.Season);
  const showProgramConcepts = allowedConceptTypes.includes(ConceptType.Program);
  const showB2BConcepts = allowedConceptTypes.includes(ConceptType.B2b);

  return (
    <div className="front-page">
      <div className="main-content">
        <div className="content">
          {renderFrontPageNews()}
          {renderAnalytics()}
          <div className="welcome">
            {shouldRenderTimeline && (
              <Timeline
                data={timelineData}
                retailerMode={true}
                chain={getChain([userStore.me.chainId])}
                title={'Mitä seuraavaksi?'}
                infoText={contentStore.getContentBySlug('info-timeline')}
              />
            )}
            {(showProgramConcepts || showSeasonConcepts) && (
              <div className="box">
                {showProgramConcepts && (
                  <div className="box-section">
                    <Link to={getLink('concepts', 'program')}>
                      <h3>{box_program_header}</h3>
                    </Link>
                    <p>{box_program_content}</p>
                  </div>
                )}
                {showSeasonConcepts && (
                  <div className="box-section">
                    <Link to={getLink('concepts', 'season')}>
                      <h3>{box_season_header}</h3>
                    </Link>
                    <p>{box_season_content}</p>
                  </div>
                )}
              </div>
            )}
            {showB2BConcepts && (
              <div className="box">
                <div className="box-section">
                  <Link to={getLink('concepts', 'b2b')}>
                    <h3>{box_b2b_header}</h3>
                  </Link>
                  <p>{box_b2b_content}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'analyticsStore',
  'contentStore',
  'templateStore',
  'deliveryStore',
  'userStore',
  'conceptStore',
  'appStore',
)(observer(FrontPage));
