/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { observable, action } from 'mobx';
import type Api from 'types/next-api';
import _ from 'utils/lodash';

export type Setting = Api.Components.Schemas.Setting;

export default class SettingsStore {
  client: Api.Client;
  @observable collection: Setting[] = [];

  get settings() {
    return this.collection;
  }

  @action async getSetting(name: Api.Components.Schemas.SettingName) {
    const index = _.findIndex(this.collection, { name });
    if (index !== -1) {
      return this.collection[index];
    }
    const result = await this.client.getSetting({ settingName: name });
    this.setSetting(result.data);
    return result.data;
  }

  @action setSetting(item: Setting) {
    const index = _.findIndex(this.collection, { name: item.name });
    if (index !== -1) {
      this.collection[index] = item;
    } else {
      this.collection.push(item);
    }
  }

  @action async modifySetting(setting: Setting) {
    try {
      const result = await this.client.modifySetting({ settingName: setting.name }, setting);
      this.setSetting(result.data);
      return result;
    } catch (err) {
      this.handleError(err);
    }
  }

  handleError(error: Error) {
    console.error(error);
    throw error;
  }

  @action
  public reset() {
    this.collection = undefined;
  }
}
