/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-floating-promises  */
import React from 'react';
import { observer } from 'mobx-react';
import _ from 'utils/lodash';
import InputSelect from 'components/common/next/form/inputSelect';
import { FormInput } from 'components/next/components/form/input';
import Button from 'components/common/next/form/button';
import { parseNumber, StampCardEditorMode } from '../stampCardEditor';
import { getLink } from 'components/retailer/next/routes';
import { discountImageUrl } from 'utils/api/campaign';
import isBefore from 'date-fns/isBefore';
import { PRODUCT_IMAGE_PLACEHOLDER, BASKET_DISCOUNT_PERCENTAGES } from 'constants/stampCard';
import { ProductPricing } from 'enums/product';
import {
  CollectionStampCardRewardDiscountMethod,
  CollectionStampCardRewardType,
  CollectionStampCardRewardDiscountType,
  CollectionStampCardEditorRewardType,
} from 'enums/stampCard';
import type { StampCardEditorTabProps } from '../stampCardEditor';
import InputImage from 'components/common/next/form/inputImage';

interface State {
  uploadedRewardImageSelected: boolean;
}

@observer
export default class StampCardRewardTab extends React.Component<StampCardEditorTabProps, State> {
  constructor(props: StampCardEditorTabProps) {
    super(props);
    this.state = {
      uploadedRewardImageSelected: false,
    };
  }

  componentDidMount(): void {
    const rewardImages = this.getRewardImages();
    const {
      reward: { image },
    } = this.currentStampCard;
    // image is not rewardImage or default
    if (!rewardImages.find((i) => i.image === image) && image !== PRODUCT_IMAGE_PLACEHOLDER) {
      this.setState({ uploadedRewardImageSelected: true });
    }
  }

  get currentStampCard() {
    return this.props.stampCardStoreNew.current;
  }

  get originalStampCard() {
    return this.props.stampCardStoreNew.original;
  }

  get validationError() {
    return this.props.stampCardStoreNew.validationError;
  }

  get invalidField() {
    return this.props.stampCardStoreNew.invalidField;
  }

  get isStampCardEditable() {
    const { mode } = this.props;
    return (
      (this.originalStampCard && !isBefore(new Date(this.originalStampCard.activeFrom), new Date())) ||
      mode !== StampCardEditorMode.Edit
    );
  }

  copyRewardsFromProducts = () => {
    const { stampCardStoreNew } = this.props;
    const rewards = _.get(this.currentStampCard, ['rewardProducts']);
    if (rewards.length > 0) {
      const confirmed = window.confirm(
        'Haluatko varmasti kopioida leimatuotteet palkintotuotteiksi ja korvata nykyiset palkintotuotteet?',
      );
      if (!confirmed) {
        return false;
      }
    }

    const products = _.get(this.currentStampCard, ['products']);
    stampCardStoreNew.updateValue(['rewardProducts'], products);
    if (
      products.some(
        (product) => !product.pricingUnit || (product.pricingUnit as ProductPricing) === ProductPricing.Weight,
      )
    ) {
      stampCardStoreNew.updateValue(['reward', 'pricingUnit'], ProductPricing.Weight);
    }
  };

  renderProductPicker = () => {
    const products = _.get(this.currentStampCard, ['rewardProducts'], []);
    const discount = _.get(this.currentStampCard, ['reward', 'discount']);
    const { mode, id, currentTab } = this.props;

    return (
      <div className="product-picker form-control">
        <label>Palkintotuotteet</label>
        {products.length === 0
          ? 'Ei tuotteita'
          : _.map(products, (product) => (
              <div key={`product-${_.get(product, ['ean'])}`} className="product-item">
                <div className="image-container">
                  <img
                    src={
                      _.get(product, ['image']) ? `${_.get(product, ['image'])}?h=50&fm=png` : PRODUCT_IMAGE_PLACEHOLDER
                    }
                    alt={_.get(product, ['title', 'fi'])}
                  />
                </div>
                <div className="item-details">
                  <div className="title">{_.get(product, ['title', 'fi'])}</div>
                  <div className="label">
                    <span className="emph">EAN </span>
                    {_.get(product, ['ean'])}
                  </div>
                </div>
                <div className="price-container">
                  <div className="title">{parseFloat(String(discount)).toFixed(2)}</div>
                  <div className="label">{product?.pricingUnit ? `€ / ${product.pricingUnit}` : ''}</div>
                </div>
              </div>
            ))}
        <div className="button-container">
          <Button color="default" link="picker/reward">
            Muokkaa tuotteita
          </Button>
          {_.get(this.currentStampCard, ['products'], []).length > 0 && (
            <Button color="bordered" onClick={this.copyRewardsFromProducts}>
              Kopioi leimatuotteista
            </Button>
          )}
        </div>
      </div>
    );
  };

  changeRewardType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const rewardTypeUiValue = e.target.value as CollectionStampCardRewardType | 'productFree';
    const { stampCardStoreNew } = this.props;

    let editorRewardType;
    let type;
    let discountType;
    let discountMethod;
    let discount;
    let minimumPurchase;
    let title;

    // clear reward products and selected reward image only if discount type changes away from products
    let rewardProducts = _.get(this.currentStampCard, ['rewardProducts'], []);
    let rewardImage = _.get(this.currentStampCard, ['reward', 'image'], PRODUCT_IMAGE_PLACEHOLDER);

    switch (rewardTypeUiValue) {
      default:
      case 'productFree': {
        editorRewardType = 'productFree';
        type = CollectionStampCardRewardType.ProductEur;
        discountType = CollectionStampCardRewardDiscountType.Product;
        discountMethod = CollectionStampCardRewardDiscountMethod.ProductEuro;
        discount = 0;
        minimumPurchase = 0;
        title = 'Tuote veloituksetta';
        // Reset image if there was discount image earlier
        if (_.get(this.currentStampCard, ['reward', 'type'], '') !== CollectionStampCardRewardType.ProductEur) {
          rewardImage = PRODUCT_IMAGE_PLACEHOLDER;
        }
        break;
      }
      case CollectionStampCardRewardType.ProductEur: {
        editorRewardType = CollectionStampCardRewardType.ProductEur;
        type = CollectionStampCardRewardType.ProductEur;
        discountType = CollectionStampCardRewardDiscountType.Product;
        discountMethod = CollectionStampCardRewardDiscountMethod.ProductEuro;
        discount = 0.1;
        minimumPurchase = 0;
        title = 'Tuote alennettuun hintaan';
        // Reset image if there was discount image earlier
        if (_.get(this.currentStampCard, ['reward', 'type'], '') !== CollectionStampCardRewardType.ProductEur) {
          rewardImage = PRODUCT_IMAGE_PLACEHOLDER;
        }
        break;
      }
      case CollectionStampCardRewardType.BasketEur: {
        editorRewardType = CollectionStampCardRewardType.BasketEur;
        type = CollectionStampCardRewardType.BasketEur;
        discountType = CollectionStampCardRewardDiscountType.BasketEuro;
        discountMethod = CollectionStampCardRewardDiscountMethod.BasketEuro;
        discount = 10;
        minimumPurchase = 50;
        title = `${discount}€ alennus vähintään ${minimumPurchase}€ kertaostoksesta`;
        rewardProducts = [];
        rewardImage = discountImageUrl(0, discount);
        break;
      }
      case CollectionStampCardRewardType.BasketPercentage: {
        editorRewardType = CollectionStampCardRewardType.BasketPercentage;
        type = CollectionStampCardRewardType.BasketPercentage;
        discountType = CollectionStampCardRewardDiscountType.BasketPercent;
        discountMethod = CollectionStampCardRewardDiscountMethod.BasketPercent;
        discount = 12; // This maps to AC discount group which equals to 5%
        minimumPurchase = 50; // Basket percent discount minimum purchase must be atleast 3 euros.
        const discountPercentage = _.get(BASKET_DISCOUNT_PERCENTAGES, discount);
        title = `${discountPercentage}% alennus vähintään ${minimumPurchase}€ kertaostoksesta`;
        rewardProducts = [];
        rewardImage = discountImageUrl(0, null, discountPercentage);
        break;
      }
    }

    stampCardStoreNew.updateRewardType(editorRewardType);
    stampCardStoreNew.updateValue(['rewardProducts'], rewardProducts);
    stampCardStoreNew.updateValue(['reward', 'type'], type);
    stampCardStoreNew.updateValue(['reward', 'discountType'], discountType);
    stampCardStoreNew.updateValue(['reward', 'discountMethod'], discountMethod);
    stampCardStoreNew.updateValue(['reward', 'discount'], discount);
    stampCardStoreNew.updateValue(['reward', 'minimumPurchase'], minimumPurchase);
    stampCardStoreNew.updateValue(['reward', 'title', 'fi'], title);
    stampCardStoreNew.updateValue(['reward', 'image'], rewardImage);
  };

  getRewardImages = () => {
    const {
      rewardProducts,
      reward: { image },
    } = this.currentStampCard;
    let rewardImageText = '';
    return _.map(
      _.filter(rewardProducts, (product) => !_.isNil(product.image) && product.image !== PRODUCT_IMAGE_PLACEHOLDER),
      (product) => {
        if (image === product.image) {
          rewardImageText = product.title.fi;
        }
        return { title: product.title.fi, image: product.image };
      },
    );
  };

  renderRewardConfiguration = () => {
    const { stampCardStoreNew } = this.props;
    const { editorRewardType } = stampCardStoreNew;
    const {
      reward: {
        discount,
        minimumPurchase,
        image,
        title: { fi: title },
      },
    } = this.currentStampCard;

    if (
      editorRewardType === CollectionStampCardEditorRewardType.ProductFree ||
      editorRewardType === CollectionStampCardEditorRewardType.ProductEur
    ) {
      const discountEuros = Math.floor(discount);
      const discountCents = Math.round((discount - discountEuros) * 100);
      const rewardImages = this.getRewardImages();
      const titleDetail =
        editorRewardType === CollectionStampCardEditorRewardType.ProductFree
          ? 'Mainitse, jos useita variantteja. Tuotenimen lisäksi näytetään "Plussa-kortilla veloituksetta" -maininta.'
          : 'Mainitse, jos useita variantteja. Tuotenimen lisäksi näytetään alennettu hinta ja "Plussa-kortilla" -maininta.';
      return (
        <div className="form-control">
          {editorRewardType === CollectionStampCardEditorRewardType.ProductEur && (
            <>
              <label>Alennettu hinta</label>
              <div className="reward-type form-control">
                <FormInput
                  additionalClasses="discount-amount"
                  type="number"
                  min={0.1}
                  handleChange={(e) =>
                    stampCardStoreNew.updateValue(['reward', 'discount'], parseNumber(e.target.value))
                  }
                  value={discount}
                  isInvalid={this.invalidField === 'reward.discount'}
                  validationErrorText={this.validationError}
                />
              </div>
            </>
          )}
          {this.renderProductPicker()}
          {this.invalidField === 'productPicker' && (
            <div className="input-validation-error">{this.validationError}</div>
          )}
          <div className="reward-details form-control">
            <FormInput
              id="reward-title"
              label="Asiakkaalle näkyvä teksti palkintoeduista"
              detail={titleDetail}
              value={title}
              required
              isInvalid={this.invalidField === 'reward.title.fi'}
              validationErrorText={this.validationError}
              handleChange={(e) => stampCardStoreNew.updateValue(['reward', 'title', 'fi'], e.target.value)}
            />
          </div>
          <div className="reward-details form-control">
            <InputSelect
              label="Valitse asiakkaalle näkyvä kuva palkintoeduista"
              value={
                !rewardImages.find((i) => i.image === image) && image !== PRODUCT_IMAGE_PLACEHOLDER
                  ? 'upload-image'
                  : image
              }
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                stampCardStoreNew.updateValue(['reward', 'image'], e.target.value);
                if (e.target.value === 'upload-image') {
                  this.setState({ uploadedRewardImageSelected: true });
                } else {
                  this.setState({ uploadedRewardImageSelected: false });
                }
              }}
              placeholder="Valitse tuotekuva"
            >
              <option key="reward-no-image" value={PRODUCT_IMAGE_PLACEHOLDER}>
                Ei kuvaa
              </option>
              {this.props.keskoStampCard && (
                <option key="reward-upload-image" value={'upload-image'}>
                  Ladattu kuva
                </option>
              )}
              {rewardImages.map((rewardImage) => (
                <option key={`reward-${rewardImage.image}`} value={rewardImage.image}>
                  {/* {title} */}
                  {rewardImage.title}
                </option>
              ))}
              ;
            </InputSelect>
            <div className="reward-image-preview reward-type-product">
              <div className="reward-image-container">
                {this.props.keskoStampCard && this.state.uploadedRewardImageSelected ? (
                  <InputImage
                    onChange={({ src }) => stampCardStoreNew.updateValue(['reward', 'image'], src)}
                    value={{ src: image === 'upload-image' ? '' : image }}
                    styles={{ maxHeight: 100 }}
                    readOnly={false}
                    uploadDirectory="stamp-card-rewards"
                    disableDelete={true}
                  />
                ) : (
                  <img src={image} alt="reward" />
                )}
              </div>
              <div className="reward-name-container">{title}</div>
              <div className="reward-discount-container">
                <div className="reward-discount-text-top">Plussa-kortilla</div>
                {editorRewardType === CollectionStampCardEditorRewardType.ProductFree && (
                  <div className="reward-discount-text-free">Veloituksetta</div>
                )}
                {editorRewardType === CollectionStampCardEditorRewardType.ProductEur && (
                  <div className="reward-discount-value-container">
                    <div className="reward-discount-value-euros">{discountEuros}</div>
                    <div className="reward-discount-cents-container">
                      <div className="reward-discount-value-cents">{discountCents}</div>
                      <div className="reward-discount-value-unit">/kpl</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (editorRewardType === CollectionStampCardEditorRewardType.BasketEur) {
      return (
        <React.Fragment>
          <div className="reward-type form-control">
            <FormInput
              label="Euromääräinen alennus"
              type="number"
              min={1}
              step={1}
              isInvalid={this.invalidField === 'reward.discount'}
              validationErrorText={this.validationError}
              handleChange={(e) => {
                const value = parseNumber(e.target.value);
                stampCardStoreNew.updateValue(['reward', 'discount'], value);
                stampCardStoreNew.updateValue(
                  ['reward', 'title', 'fi'],
                  `${value}€ alennus vähintään ${minimumPurchase}€ kertaostoksesta`,
                );

                if (this.invalidField !== 'reward.discount') {
                  stampCardStoreNew.updateValue(['reward', 'image'], discountImageUrl(0, value as number));
                }

                // Minimum purchase must be at least the same as discount.
                if (value && minimumPurchase < value) {
                  stampCardStoreNew.updateValue(['reward', 'minimumPurchase'], value);
                }
              }}
              value={discount}
            />
          </div>
          <div className="reward-type form-control">
            <FormInput
              label="Kertaostoksen suuruus euroissa"
              type="number"
              min={discount}
              isInvalid={this.invalidField === 'reward.minimumPurchase'}
              validationErrorText={this.validationError}
              handleChange={(e) => {
                const value = parseNumber(e.target.value);
                stampCardStoreNew.updateValue(['reward', 'minimumPurchase'], value);
                stampCardStoreNew.updateValue(
                  ['reward', 'title', 'fi'],
                  `${discount}€ alennus vähintään ${value}€ kertaostoksesta`,
                );
                if (this.invalidField !== 'reward.discount') {
                  stampCardStoreNew.updateValue(['reward', 'image'], discountImageUrl(0, discount));
                }
              }}
              value={minimumPurchase}
            />
            <div className="reward-image-preview reward-type-basket">
              <div className="reward-image-container">
                <img src={image} alt="reward" />
              </div>
              <div className="reward-name-container">{title}</div>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (editorRewardType === CollectionStampCardEditorRewardType.BasketPercentage) {
      return (
        <React.Fragment>
          <div className="reward-type form-control">
            <InputSelect
              label="Prosenttialennus"
              value={discount}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                const discountGroup = _.get(BASKET_DISCOUNT_PERCENTAGES, [value]);
                stampCardStoreNew.updateValue(['reward', 'discount'], value);
                stampCardStoreNew.updateValue(
                  ['reward', 'title', 'fi'],
                  `${discountGroup}% alennus vähintään ${minimumPurchase}€ kertaostoksesta`,
                );
                stampCardStoreNew.updateValue(['reward', 'image'], discountImageUrl(0, null, discountGroup));
              }}
            >
              <option key={12} value={12}>
                5%
              </option>
              <option key={13} value={13}>
                7%
              </option>
              <option key={14} value={14}>
                10%
              </option>
              <option key={15} value={15}>
                15%
              </option>
              <option key={16} value={16}>
                20%
              </option>
            </InputSelect>
            <FormInput
              label="Kertaostoksen suuruus euroissa"
              type="number"
              min={3}
              isInvalid={this.invalidField === 'reward.minimumPurchase'}
              validationErrorText={this.validationError}
              handleChange={(e) => {
                const value = parseNumber(e.target.value);
                const discountGroup = _.get(BASKET_DISCOUNT_PERCENTAGES, [discount]);
                stampCardStoreNew.updateValue(['reward', 'minimumPurchase'], value);
                stampCardStoreNew.updateValue(
                  ['reward', 'title', 'fi'],
                  `${discountGroup}% alennus vähintään ${value}€ kertaostoksesta`,
                );
                stampCardStoreNew.updateValue(['reward', 'image'], discountImageUrl(0, null, discountGroup));
              }}
              value={minimumPurchase}
            />
            <div className="reward-image-preview reward-type-basket">
              <div className="reward-image-container">
                <img src={image} alt="reward" />
              </div>
              <div className="reward-name-container">{title}</div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  renderRewardTypePicker = () => (
    <React.Fragment>
      <InputSelect
        className={this.invalidField === 'reward.type' ? ' invalid' : ''}
        label="Edun tyyppi"
        value={this.props.stampCardStoreNew.editorRewardType}
        onChange={this.changeRewardType}
      >
        <option key="productFree" value="productFree">
          Tuote veloituksetta
        </option>
        <option key={CollectionStampCardRewardType.ProductEur} value={CollectionStampCardRewardType.ProductEur}>
          Hinta, jolla asiakas saa palkintoedun
        </option>
        <option key={CollectionStampCardRewardType.BasketEur} value={CollectionStampCardRewardType.BasketEur}>
          Kuitin loppusumma-alennus, euro
        </option>
        <option
          key={CollectionStampCardRewardType.BasketPercentage}
          value={CollectionStampCardRewardType.BasketPercentage}
        >
          Kuitin loppusumma-alennus, prosentti
        </option>
      </InputSelect>
      {this.invalidField === 'reward.type' && <div className="input-validation-error">{this.validationError}</div>}
    </React.Fragment>
  );

  render() {
    const type = _.get(this.currentStampCard, ['reward', 'type']);
    return (
      <div>
        <div className="section-header">
          <h2>Palkintoetu</h2>
        </div>
        <div className="section-split">
          <div className="left">
            <div className="box reward-configuration">
              {this.renderRewardTypePicker()}
              {this.renderRewardConfiguration()}
            </div>
          </div>
          <div className="right">
            <div className="box">
              <h3>Ohjeet</h3>
              <p>
                Voit valita neljästä eri palkintotyypistä:
                <br />- tuote veloituksetta
                <br />- tuote alennetulla hinnalla
                <br />- kuitin loppusumman prosenttialennus
                <br />- kuitin loppusumman euromääräinen alennus
              </p>
              <p>
                Etu tulee voimaan välittömästi asiakkaan Plussa -kortille, kun leimapassi on täyttynyt. Etu on
                talouskohtainen.
              </p>
              {type === CollectionStampCardRewardType.ProductEur && (
                <>
                  <p>
                    Lisää tuote-etua tehdessäsi mukaan kaikki ne variantit EAN-koodeista, jotka eduissa täytyy olla
                    mukana tai kopioi keräilyssä olevat tuotteet palkinnoksi. Palkintoetu voi olla ainoastaan
                    kpl-hintainen ja kaikki tuotteet, jotka palkintoon oikeuttavat, ovat etuina samanarvoisia. Valitse
                    palkinnolle myös kuva. Huom! Asiakas saa leiman aina kyseisestä EAN-koodista, eli jos palkintoetuna
                    on keräiltävä tuote, asiakas saa leiman myös etua lunastaessaan.
                  </p>
                  <p>
                    Huom! Jos lisäät pantillisen tuotteen: Kassajärjestelmä peri asiakkaalta pantin, vaikka tote olisi
                    merkitty veloituksetta tai alennuksella. Eli pantti tule vielä ilmoitetun hinnan päälle. Viesti tämä
                    selkeästi.
                  </p>
                </>
              )}
              {(type === CollectionStampCardRewardType.BasketEur ||
                type === CollectionStampCardRewardType.BasketPercentage) && (
                <>
                  <p>
                    Kuitin loppusumman prosentti- tai euromääräisessä alennuksessa musta tarkistaa antamasi summat edun
                    otsikosta.
                  </p>
                  <p>Kuitin loppusumma-alennuksessa todellinen euromääräinen raja on 2€ valittua alhaisempi.</p>
                  <p>
                    Ostorajan täyttymisessä ei huomioida lahjakortteja, pullopantteja, äidinmaidonkorvikkeita, tupakka-,
                    alkoholi-, nikotiinikorvaus-, Postin-, Veikkauksen- tai muita välitystuotteita. Etu ei koske
                    tarjoustuotteita, eikä sitä voi yhdistää muihin alennuksiin.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
