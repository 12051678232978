/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-misused-promises, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-return */
import React from 'react';
import './timeline.scss';
import * as d3 from 'd3';
import * as date from 'date-fns';
import { shortDateFormat, monthNames } from 'components/next/utils';
import { castDate } from 'utils/helpers';
import InfoPopover from 'components/retailer/next/components/common/infoPopover';
import { Chain } from 'enums/common';

export interface TimelineElement {
  signUpStarts: Date;
  signUpEnds: Date;
  firstDelivery?: Date;
  editUrl?: string;
  invertColors?: boolean; // used to highlight element with white fill/blue text
  tooltipContent?: JSX.Element;
  title: string;
  introduction?: string;
  concept?: string;
  icon: any;
  deliveryLine: boolean;
}

interface Props {
  title?: string;
  data: TimelineElement[][];
  retailerMode?: boolean;
  chain: Chain;
  infoText?: string;
}

interface State {
  graph: any;
  graphComplete: boolean;
  tooltipElement: TimelineElement;
  tooltipHTML: JSX.Element;
}

const colors = {
  kBlue: '#00205b',
  kOrange: '#f86800',
  kRauta: '#330072',
  onninen: '#002855',
};

const MIN_GRAPH_HEIGHT = 250;
const ROW_HEIGHT = 42;
const LEFT_MARGIN = 6;

export default class Timeline extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      graph: undefined,
      graphComplete: false,
      tooltipElement: null,
      tooltipHTML: null,
    };
  }

  rContainer = React.createRef<HTMLDivElement>();
  rTooltip = React.createRef<HTMLDivElement>();

  get width() {
    const graphBox = document.querySelector('.graph-container').getBoundingClientRect();
    return Math.max(date.differenceInWeeks(this.endDate, this.startDate) * 100, graphBox.width);
  }

  get monthNames() {
    return this.props.retailerMode ? monthNames.fi : monthNames.en;
  }

  get height() {
    let rowCount = 0;
    this.props.data.forEach((row: TimelineElement[]) => {
      rowCount++;
      row.forEach((item: TimelineElement) => {
        if (this.isOverLapping(item, row)) {
          rowCount++;
        }
      });
    });
    const countedHeight = rowCount * ROW_HEIGHT + 110;
    return countedHeight > MIN_GRAPH_HEIGHT ? countedHeight : MIN_GRAPH_HEIGHT;
  }

  get startDate() {
    return date.startOfISOWeek(date.addWeeks(new Date(), -1));
  }

  get endDate() {
    return date.endOfMonth(date.addMonths(new Date(), 2));
  }

  get scale() {
    return d3
      .scaleTime()
      .domain([this.startDate, this.endDate])
      .range([0, this.width - 25])
      .nice(); // ...nice
  }

  setStateAsync = async (updater) => new Promise((resolve: any) => this.setState(updater, resolve));

  componentDidMount = async () => {
    await this.initGraph();
    window.addEventListener('click', this.handleClick);
  };

  // eslint-disable-next-line
  componentDidUpdate = async (prevProps: Props) => {
    if (prevProps.data !== this.props.data) {
      this.setState({ graphComplete: false }, () => this.destroyGraph().then(() => this.initGraph()));
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClick);
  };

  handleClick = (e) => {
    if (this.rTooltip.current) {
      if (!this.rContainer.current.contains(e.target)) {
        this.rTooltip.current.style.display = 'none';
      }
    }
  };

  // Checks if the date given fits the shown timeline from left side and returns a marging from the start if not
  scaleWithinViewLeft = (start: Date): number =>
    date.isBefore(start, this.startDate) ? LEFT_MARGIN : this.scale(start);

  // Checks if the date given fits the shown timeline from right returns the scaled end date if not
  scaleWithinViewRight = (end: Date): number =>
    date.isAfter(end, this.endDate) ? this.scale(this.endDate) : this.scale(end);

  initGraph = async () => {
    // eslint-disable-next-line
    await this.renderGraphBackground();
    await this.renderXAxis();
    await this.renderGrid();
    if (this.state.graph && !this.state.graphComplete) {
      await this.renderGraphElements();
    }
    // eslint-disable-next-line
    await this.renderToday();
  };

  destroyGraph = async () => {
    d3.select('#graph svg').remove();
    await this.setStateAsync({ graph: undefined, graphComplete: false });
  };

  renderGraphBackground = () => {
    const graph = d3
      .select('#graph')
      .append('svg')
      .attr('id', 'graph-svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .on('click', () => {
        if (this.rTooltip.current) {
          this.rTooltip.current.style.display = 'none';
        }
      });
    this.setState({ graph });
  };

  // eslint-disable-next-line
  renderXAxis = async () => {
    const { graph } = this.state;
    // Need to take into account the fact that the scale goes until end of month
    // Also: round up to next full ten; D3 treats tick count as a suggestion and may render more
    const weekCount = Math.ceil(date.differenceInCalendarISOWeeks(this.endDate, this.startDate) / 10) * 10;

    const xAxis = d3
      .axisBottom(this.scale)
      .ticks(weekCount)
      .tickFormat((_, i) => {
        return date.getISOWeek(date.addWeeks(this.startDate, i)).toString();
      })
      .tickPadding(8);

    const weekNumbers = d3
      .axisBottom(this.scale)
      .ticks(weekCount)
      .tickSize(0)
      .tickFormat((w, i) => {
        return date.format(castDate(date.startOfISOWeek(date.addWeeks(this.startDate, i))), shortDateFormat);
      });
    graph
      .append('g')
      .attr('transform', `translate(0, ${this.height - 38})`)
      .attr('class', 'grid')
      .call(xAxis);

    graph
      .append('g')
      .attr('transform', `translate(-5, ${this.height - 12})`)
      .attr('class', 'weekends')
      .style('text-anchor', 'start')
      .call(weekNumbers);

    // eslint-disable-next-line
    await this.setState({ graph });
  };

  // eslint-disable-next-line
  renderGrid = async () => {
    const { graph } = this.state;
    const monthCount = date.differenceInMonths(this.endDate, this.startDate);
    const firstMonth = date.endOfMonth(this.startDate);
    const monthEndDates = [firstMonth];

    for (let i = 1; i <= monthCount; i++) {
      monthEndDates.push(date.endOfMonth(date.addMonths(firstMonth, i)));
    }

    const gridLines = d3
      .axisBottom(this.scale)
      .tickValues(monthEndDates)
      .tickFormat((_) => '')
      .tickSize(this.height);
    const monthNames = d3
      .axisTop(this.scale)
      .tickValues(monthEndDates.map((d) => date.startOfMonth(d)))
      .tickFormat((m: Date) => this.monthNames[date.getMonth(m)])
      .tickSize(0);
    // grid lines at the end of every month
    graph.append('g').attr('transform', `translate(0, -38)`).attr('class', 'background-grid').call(gridLines);
    // month name row
    graph
      .append('g')
      .attr('transform', `translate(12, 12)`)
      .attr('class', 'month-names')
      .call(monthNames)
      .selectAll('text')
      .style('text-anchor', 'start');
    // eslint-disable-next-line
    await this.setState({ graph });
  };

  renderToday = () => {
    // Today marker -- only if today falls between start & end dates
    if (date.isWithinInterval(new Date(), { start: this.startDate, end: this.endDate })) {
      const { graph } = this.state;
      const todayMarker = graph.append('g');
      todayMarker
        .append('rect')
        .attr('x', () => this.scale(new Date()))
        .attr('y', -32)
        .attr('width', 1)
        .attr('height', this.height)
        .attr('fill', colors.kOrange);
      todayMarker
        .append('circle')
        .attr('cx', () => this.scale(new Date()))
        .attr('cy', this.height - 38)
        .attr('r', 5)
        .attr('fill', colors.kOrange);
    }
  };

  isOverLapping = (current: TimelineElement, row: TimelineElement[]) => {
    if (row.indexOf(current) === 0) {
      return false;
    }
    const index = row.indexOf(current);
    const prev = row[index - 1];
    // return date.isBefore(current.deadline, prev.endDate);
    return (
      date.isBefore(current.signUpStarts, prev.signUpEnds) || date.isBefore(current.firstDelivery, prev.signUpEnds)
    );
  };

  // eslint-disable-next-line
  toggleToolTip = async (event, d) => {
    event.stopPropagation();

    const graphContainer = document.querySelector('.graph-container');
    const graph = document.querySelector('#graph-svg');

    const targetElementBox = event.currentTarget.getBoundingClientRect();
    const containerBox = graphContainer.getBoundingClientRect();

    const position = {
      x: Math.floor(targetElementBox.x - containerBox.left) + targetElementBox.width + graphContainer.scrollLeft,
      y: Math.floor(targetElementBox.y - containerBox.top) - 15,
    };

    const graphWidth = graph.getBoundingClientRect().width;
    if (graphWidth < position.x + 300) {
      position.x = position.x - (position.x + 340 - graphWidth);
    }

    if (targetElementBox.right + 340 > containerBox.right) {
      graphContainer.scrollLeft += targetElementBox.right + 360 - containerBox.right;
    }

    // eslint-disable-next-line
    await this.setState({ tooltipElement: d, tooltipHTML: d.tooltipContent });
    this.rTooltip.current.style.left = `${position.x}px`;
    this.rTooltip.current.style.top = `${position.y}px`;
    this.rTooltip.current.style.display = 'block';
  };

  // eslint-disable-next-line
  renderGraphElements = async () => {
    const { graph } = this.state;
    const { data, chain } = this.props;
    const color = chain === Chain.kRauta ? colors.kRauta : chain === Chain.onninen ? colors.onninen : colors.kBlue;

    const wrapper = graph.append('g').classed('element-wrapper', true).attr('transform', `translate(0, 12)`);

    let rowNo = 0;
    data.forEach((row: TimelineElement[], index) => {
      // @TODO: is there a cleaner way of avoiding overlapping updates than using generated classes?
      const element = wrapper
        .selectAll(`.timeline-element-${index}`)
        .data(row)
        .enter()
        .append('g')
        .attr('class', `.timeline-element-${index}`)
        .attr('transform', (d) => {
          if (this.isOverLapping(d, row)) {
            rowNo++;
          }
          return `translate(0, ${rowNo * 40 + 20})`;
        })
        .on('click', this.toggleToolTip);
      element // Creates rectangular box for delivery
        .append('rect')
        .attr('class', 'element-box')
        .attr('x', (d) => this.scaleWithinViewLeft(d.signUpStarts))
        .attr('rx', 4)
        .attr('ry', 4)
        // Width is 4 at minimum. This is to show delivery templates that have the same start and end date.
        .attr('width', (d) =>
          Math.max(this.scaleWithinViewRight(d.signUpEnds) - this.scaleWithinViewLeft(d.signUpStarts), 4),
        )
        .attr('height', 30)
        .attr('fill', (d) => (d.invertColors ? 'white' : color))
        .attr('stroke', color);
      element // Creates icon in the delivery rectangle
        .append('svg:image')
        .attr('x', (d) => this.scaleWithinViewLeft(d.signUpStarts) + 3)
        .attr('y', 3)
        .attr('width', 24)
        .attr('height', 24)
        .attr('xlink:href', (d) => d.icon);
      element // Creates tittle text in the delivery rectangle
        .append('text')
        .attr('x', (d) => this.scaleWithinViewLeft(d.signUpStarts) + 24)
        .attr('y', 13)
        .attr('transform', 'translate(10, 5)')
        .attr('stroke', 'none')
        .attr('fill', (d) => (d.invertColors ? color : 'white'))
        .attr('font-size', '12px')
        .style('text-anchor', 'start')
        .text((d: TimelineElement) =>
          date.differenceInDays(d.signUpEnds, d.signUpStarts) < 8 ? d.title.substr(0, 10) : d.title,
        );
      element // Creates the narrow horiontal line to first delivery date (program type only)
        .append('rect')
        .filter(
          (d) =>
            d.deliveryLine &&
            date.isAfter(d.firstDelivery, this.startDate) &&
            date.isBefore(d.firstDelivery, this.endDate) &&
            date.isAfter(d.firstDelivery, d.signUpEnds),
        ) // should be true
        .attr('x', (d) => this.scale(d.signUpEnds))
        .attr('y', 14)
        .attr('width', (d) => this.scaleWithinViewRight(d.firstDelivery) - this.scale(d.signUpEnds))
        .attr('height', 2)
        .attr('fill', colors.kBlue);
      element // Creates the vertical line at first delivery date (program type only)
        .append('rect')
        .filter(
          (d) =>
            d.deliveryLine &&
            date.isAfter(d.firstDelivery, this.startDate) &&
            date.isBefore(d.firstDelivery, this.endDate),
        )
        .attr('x', (d) => this.scale(d.firstDelivery))
        .attr('y', 5)
        .attr('width', 2)
        .attr('height', 20)
        .attr('fill', colors.kBlue);

      rowNo++;
    });
    // eslint-disable-next-line
    await this.setState({ graphComplete: true });
  };

  infoButton = (text: string) => {
    return (
      <InfoPopover>
        <div
          className="help-content"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </InfoPopover>
    );
  };

  render() {
    const { title, infoText } = this.props;
    const { tooltipHTML } = this.state;
    return (
      <React.Fragment>
        <div className="title">
          <h3>{title}</h3>
          {infoText && this.infoButton(infoText)}
        </div>
        <div className="timeline" ref={this.rContainer}>
          <div className="graph-container">
            <div id="graph" />
            <div className="tooltip" ref={this.rTooltip}>
              {tooltipHTML}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
