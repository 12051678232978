/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, 	@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, 	@typescript-eslint/no-unsafe-member-access */
import axios from 'axios';
import type { ContentItem, ContentData } from 'types/content';

const API_URL = process.env.REACT_APP_API_URL;

const contentUrl = `${API_URL}/meta`;
const uploadUrl = `${API_URL}/upload`;
const fileEndpoint = `${API_URL}/file`;

export async function getContent(): Promise<ContentItem[]> {
  try {
    const { data } = await axios.get(contentUrl, {
      withCredentials: true,
    });
    const { result } = data;
    return result;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    // no content
    return;
  }
}

export async function postContent(contentData: ContentData): Promise<any> {
  try {
    const { data } = await axios.post(contentUrl, contentData, {
      withCredentials: true,
    });
    const { result } = data;
    return result;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return;
  }
}

export async function upload(file: string | Blob, directory?: string): Promise<string> {
  try {
    const formData = new FormData();
    formData.append('file', file);

    if (directory) {
      formData.append('directory', directory);
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      withCredentials: true,
    };
    const { data } = await axios.post(uploadUrl, formData, config);
    const { result } = data;
    const { url } = result;
    return url;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return;
  }
}

export const deleteFileFromS3 = async (fileName: string, directory?: string): Promise<void> => {
  try {
    const config = {
      headers: {
        'content-type': 'application/json',
      },
      withCredentials: true,
      data: {
        opts: {
          directory,
        },
      },
    };

    const { data } = await axios.delete(`${fileEndpoint}/${fileName}`, config);
    const { result } = data;
    return result;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return;
  }
};

export async function replaceFileInS3(file: string | Blob, fileName: string, directory?: string): Promise<string> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    if (directory) {
      formData.append('directory', directory);
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      withCredentials: true,
    };
    const { data } = await axios.put(`${fileEndpoint}/${fileName}`, formData, config);
    const { result } = data;
    const { url } = result;
    return url;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return;
  }
}
