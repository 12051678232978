/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { AxiosRequestConfig } from 'axios';
import { action, observable, toJS } from 'mobx';
import { OpenAPIClient } from 'openapi-client-axios';
import DeliveriesStore from './deliveries';
import { PreviewFormat, StoreState } from 'enums/common';
import type {
  ContentField,
  Delivery,
  DeliveryField,
  DeliveryTemplate,
  DeliveryTemplateChangeOrderPayload,
  PreviewParams,
  TemplateSearchPayload,
} from 'components/next/types';
import { Components } from 'types/next-api';
import { replaceDotInPrice } from 'utils/helpers';

export default class DeliveryTemplateStore {
  client: OpenAPIClient;
  stores: {
    deliveriesStore: DeliveriesStore;
  };

  @observable collection: DeliveryTemplate[] = [];
  @observable template = undefined;
  @observable error = undefined;
  @observable deleted = false;
  @observable status: StoreState = StoreState.ready;
  @observable previewPayload: DeliveryTemplate | Delivery;
  @observable emailPreviewUrl: string;

  // alias for this.collection
  get templates() {
    return this.collection;
  }

  // collection of templates
  @action
  public updateTemplatesInCollection(templates: DeliveryTemplate[]) {
    for (const template of templates) {
      const index = _.findIndex(this.collection, { id: template.id });
      if (index !== -1) {
        // update
        if (!_.isEqual(this.collection[index], template)) {
          this.collection[index] = template;
        } else {
          // no need to update
        }
      } else {
        // add to collection
        this.collection = [...this.collection, template];
      }
    }
    this.status = StoreState.ready;
    return this.collection;
  }

  @action
  public deleteTemplateFromCollection(id: string | number) {
    const reportIndex = _.findIndex(this.collection, { id: String(id) });
    const templates = [...this.collection];
    templates.splice(reportIndex, 1);
    this.collection = templates;
    return this.collection;
  }

  // set the current working template
  @action setTemplate(template) {
    this.template = template;
  }
  // unset the current working template
  @action unsetTemplate() {
    this.template = null;
    this.emailPreviewUrl = null;
  }
  // alias
  @action clearDeliveryTemplate() {
    this.unsetTemplate();
  }

  @action async search(payload: TemplateSearchPayload = {}, parameters = {}) {
    this.status = StoreState.loading;
    const result = await this.client.searchDeliveryTemplates(parameters, payload);
    this.updateTemplatesInCollection(result.data.result);
    return result.data;
  }

  // set error
  @action setError(error) {
    this.error = error;
  }

  @action async getTemplateById(id: string) {
    const result = await this.client.getDeliveryTemplate(id);
    if (result.status === 200) {
      this.updateTemplatesInCollection([result.data]);
      return result.data;
    } else {
      this.setError(result);
    }
  }

  @action async deleteDeliveryTemplate(id: string) {
    const result = await this.client.deleteDeliveryTemplate(parseInt(id, 10));
    if (result.status === 200) {
      this.deleteTemplateFromCollection(id);
      return result.data;
    } else {
      this.setError(result);
    }
  }

  @action async updateDeliveryTemplate(template: DeliveryTemplate) {
    this.setError(null);
    const result = await this.client.replaceDeliveryTemplate(template.id, template);
    if (result.status === 200) {
      this.updateTemplatesInCollection([result.data]);
    } else {
      this.setError(result);
    }
    return result;
  }

  @action async changeDeliveryTemplateOrder(id: string, payload: DeliveryTemplateChangeOrderPayload) {
    await this.client.changeDeliveryTemplateOrder(parseInt(id, 10), payload);
  }

  @action async batchSaveDeliveryTemplates(templates: DeliveryTemplate[]) {
    if (templates.length === 0) {
      return templates;
    }
    try {
      const promises = _.map(templates, async (t: DeliveryTemplate) => {
        if (!t.id) {
          return this.createDeliveryTemplate(t);
        } else {
          return this.updateDeliveryTemplate(t);
        }
      });
      const responses = await Promise.all(promises);
      this.status = StoreState.ready;
      return responses.map((response) => response.data as DeliveryTemplate);
    } catch (error) {
      // should be handled with error ??
      this.status = StoreState.error;
      console.error(error);
    }
  }

  @action async createDeliveryTemplate(template: DeliveryTemplate) {
    this.setError(null);
    const result = await this.client.createDeliveryTemplate(null, template);
    if (result.status === 201) {
      this.updateTemplatesInCollection([result.data]);
    } else {
      this.setError(result);
    }
    return result;
  }

  @action async previewDeliveryTemplateById(id: number | string, params: PreviewParams) {
    const p: PreviewParams = {
      format: PreviewFormat.Image,
      ...params,
    };
    if (!p.format) {
      p.format = PreviewFormat.Image;
    }
    const result = await this.client.previewDeliveryTemplateById(id, params, {
      responseType: 'blob',
    });
    return result;
  }

  @action async previewDeliveryTemplate(params: PreviewParams) {
    const p: PreviewParams = {
      format: PreviewFormat.Image,
      ...params,
    };
    if (!p.format) {
      p.format = PreviewFormat.Image;
    }

    const responseType = params.returnUrl ? 'json' : 'blob';

    const config: AxiosRequestConfig = {
      params,
      responseType,
    };
    const result = await this.client.previewDeliveryTemplate(p as any, this.previewPayload, config);
    if (params.returnUrl) {
      this.emailPreviewUrl = result.data as string;
      return;
    }
    return result;
  }

  @action setPreviewPayload(template: DeliveryTemplate | Delivery | undefined) {
    const fields: ContentField[] | DeliveryField[] =
      'contentFields' in template ? template.contentFields : template.deliveryFields;
    const updatedFields = replaceDotInPrice(fields);

    // Reassign the modified array back
    if ('contentFields' in template) {
      template.contentFields = updatedFields as ContentField[];
    } else if ('deliveryFields' in template) {
      template.deliveryFields = updatedFields as DeliveryField[];
    }

    this.previewPayload = template;
  }

  // Get unique offers used in deliveries by array of delivery template ids
  @action async getOffersInDeliveriesByIds(ids: string[]): Promise<Components.Schemas.DeliveryTemplateOffer[]> {
    const payload = {
      deliveryTemplateIds: ids,
    };
    const { data } = await this.client.getDeliveryTemplateOffers(null, payload);
    return data as Components.Schemas.DeliveryTemplateOffer[];
  }
}
