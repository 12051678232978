/* eslint-disable 	@typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-floating-promises  */
import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { format, isBefore, isAfter } from 'date-fns';
import _ from 'utils/lodash';
import { ReactComponent as IconStampCard } from '@kesko/icons/shopping/icon-payment_stampCard.svg';
import { ReactComponent as IconChevronRight } from '@kesko/icons/nav/icon-chevron-right.svg';
import { getLink } from '../../routes';
import UserStore from 'stores/userStore';
import StampCardStore from 'stores/next/stampCard';
import './stampCardList.scss';
import Spinner from 'components/common/next/spinner';

interface Props {}

interface InjectedProps extends Props {
  userStore: UserStore;
  stampCardStore: StampCardStore;
}

interface State {
  status: 'passed' | 'active_or_upcoming';
}

@inject('userStore', 'stampCardStore')
@observer
class StampCardList extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      status: 'active_or_upcoming',
    };
  }

  get injected() {
    return this.props as InjectedProps;
  }

  get userStore() {
    return this.injected.userStore;
  }

  get stampCardStore() {
    return this.injected.stampCardStore;
  }

  componentDidMount() {
    const { status } = this.state;
    this.stampCardStore.searchCollectionStampCards({ payload: { status } });
    this.stampCardStore.getCollectionStampCardStatistics({ status });
    this.stampCardStore.searchCollectionStampCardTemplates({ payload: {} });
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.state;
    if (prevState.status !== status) {
      this.stampCardStore.searchCollectionStampCards({ payload: { status } });
      this.stampCardStore.getCollectionStampCardStatistics({ status });
    }
  }

  renderStampCardHeader = () => {
    return (
      <div className="stampcard-list-header">
        <h2>Keräilypassit</h2>
        <p>
          Keräilypassit ovat kaupan omia tiettyihin tuotteisiin tai tuoteperheisiin kohdistettuja ostokannustimia.
          Keräilypassit ovat digitaalisia ja toimivat K-Ruoka sovelluksessa. Täällä voit luoda ja hallinnoida
          varsinaisia passeja sekä seurata niiden tehokkuutta myynnin edistämisessä. Keräilypassien luvut päivittyvät
          kerran vuorokaudessa. Huomioithan, että kaupalla voi olla maksimissaan viisi keräilypassia kerrallaan
          voimassa.
        </p>
      </div>
    );
  };

  renderStampCardStatus = (activeFrom: string, activeTo: string) => {
    const isActive = !isBefore(new Date(), new Date(activeFrom)) && !isAfter(new Date(), new Date(activeTo));

    return <span className="stampcard-status">{isActive ? 'Aktiivinen' : 'Tulossa'}</span>;
  };

  public renderStampCardList = () => {
    const { status } = this.state;
    const { isLoadingStampCards, isLoadingStampCardStatistics, stampCards, stampCardStatistics } = this.stampCardStore;

    if (isLoadingStampCards) {
      return <Spinner />;
    }

    if (stampCards.length === 0) {
      return <div>Ei omia passeja</div>;
    }

    return _.map(stampCards, (stampCard) => {
      const stats = _.get(stampCardStatistics, [stampCard.id], {});
      const stampcardRegistrationsQty = _.get(stats, ['stampcardRegistrationsQty'], '-');
      const stampQty = _.get(stats, ['stampQty'], '-');
      const fullStampcardQty = _.get(stats, ['fullStampcardQty'], '-');

      const activeFrom = _.get(stampCard, ['activeFrom']);
      const activeTo = _.get(stampCard, ['activeTo']);

      return (
        <div key={_.get(stampCard, ['id'])} className="stampcard-box box">
          <IconStampCard />
          <div className="stampcard-box-content">
            <div className="stampcard-title">
              <h3>{_.get(stampCard, ['uiData', 'uiName', 'fi'])}</h3>
              {status === 'active_or_upcoming' && this.renderStampCardStatus(activeFrom, activeTo)}
            </div>
            <div className="description">
              Voimassa {format(new Date(activeFrom), 'd.M.yyyy')} - {format(new Date(activeTo), 'd.M.yyyy')}
              <br />
              Lunastus viimeistään {format(new Date(_.get(stampCard, ['redeemableTo'])), 'd.M.yyyy')}
            </div>
          </div>
          <div className={`stampcard-box-statistics${isLoadingStampCardStatistics ? ' loading' : ''}`}>
            {isLoadingStampCardStatistics ? (
              'Ladataan tietoja...'
            ) : (
              <React.Fragment>
                <div className="stampcard-box-statistics-active">
                  <p>{stampcardRegistrationsQty}</p>aktiivista passia
                </div>
                <div className="stampcard-box-statistics-stamps">
                  <p>{stampQty}</p>leimaa yhteensä
                </div>
                <div className="stampcard-box-statistics-full">
                  <p>{fullStampcardQty}</p>täyttä passia
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="stampcard-box-actions">
            <Link className="btn btn-inverse" to={getLink('viewStampCard', _.get(stampCard, ['id']))}>
              Tiedot
              <IconChevronRight />
            </Link>
          </div>
        </div>
      );
    });
  };

  renderNewStampCardOptions = () => {
    const { stampCardTemplates, isLoadingStampCardTemplates } = this.stampCardStore;

    if (isLoadingStampCardTemplates) {
      return null;
    }

    const templates = _.map(stampCardTemplates, (template) => {
      // TODO: Remove old create stamp card code when new is released
      const stampCardEditorRoute = getLink('stampCardEditor', 'create', _.get(template, ['id']), 'type');
      return (
        <div key={_.get(template, ['id'])} className="stampcard-box box">
          <div className="icon">
            <IconStampCard />
          </div>
          <div className="stampcard-box-content">
            <h3>{_.get(template, ['uiData', 'uiName', 'fi'])}</h3>
            <div className="description">{_.get(template, ['templateDescription', 'fi'])}</div>
          </div>
          <div className="stampcard-box-actions">
            <Link className="btn btn-default" to={stampCardEditorRoute}>
              Luo uusi
            </Link>
          </div>
        </div>
      );
    });

    return (
      <div className="stampcard-list-create">
        {templates.length > 0 && <h2>Luo uusi passi</h2>}
        {templates}
      </div>
    );
  };

  public render() {
    const { status } = this.state;
    return (
      <div className="stampcard-list">
        <div className="main-content">
          <div className="content">
            {this.renderStampCardHeader()}
            <div className="stampcard-list-own">
              <div className="stampcard-list-header-actions">
                <h2>Omat passit</h2>
                <div className="filters">
                  <div>Näytä:</div>
                  <nav>
                    <Link
                      to="#"
                      className={status === 'passed' ? 'active' : ''}
                      onClick={() => this.setState({ status: 'passed' })}
                    >
                      Menneet
                    </Link>
                    <Link
                      to="#"
                      className={status === 'active_or_upcoming' ? 'active' : ''}
                      onClick={() => this.setState({ status: 'active_or_upcoming' })}
                    >
                      Aktiiviset ja tulevat
                    </Link>
                  </nav>
                </div>
              </div>
              {this.renderStampCardList()}
            </div>
            {this.renderNewStampCardOptions()}
          </div>
        </div>
      </div>
    );
  }
}

export default StampCardList;
