/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-member-access  */
import React, { useEffect } from 'react';
import page from 'components/next/pages/page/page';
import { inject, observer } from 'mobx-react';
import type { Route } from 'types/next';
import './settings.scss';
import DimensionsStore from '../../../../stores/next/dimensions';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as IconArrowRight } from '@kesko/icons/nav/icon-arrow-right.svg';
import { NavLink } from 'react-router-dom';
import { kRuoka } from '../../routes';
import SiteOptions from '../../components/siteOptions';
import Alerts from 'components/common/next/alert/alerts';
import InvoicesStore from '../../../../stores/next/invoices';
import AppStore from '../../../../stores/next/app';
import { ChainAbbreviations } from 'constants/common';
import { BusinessType, ConceptType } from 'enums/common';
import Spinner from 'components/common/next/spinner';
import { getAllowedConceptTypes } from 'utils/helpers';

interface SettingsProps {
  chainIds: string[];
  dimensionsStore?: DimensionsStore;
  invoicesStore?: InvoicesStore;
  appStore?: AppStore;
}

const SettingsPage = ({ chainIds, dimensionsStore, invoicesStore, appStore }: SettingsProps) => {
  useEffect(() => {
    dimensionsStore.getDimensionsByChain(chainIds);
    dimensionsStore.getB2BDimensionsByChain(chainIds);
  }, []);

  const createDimension = (type: BusinessType.B2c | BusinessType.B2b) => {
    return dimensionsStore.createDimension({
      name: 'new' + Math.round(Math.random() * 1000),
      title: 'New dimension',
      order: 0,
      chainIds: chainIds,
      options: [],
      type: type,
    });
  };

  const deleteDimension = (id: number) => {
    return dimensionsStore.deleteDimension(id.toString());
  };

  const renderDimensionRows = () => {
    return (
      <tbody>
        {dimensionsStore.dimensions.map((dimension) => (
          <tr key={dimension.id}>
            <td>{dimension.name}</td>
            <td>{dimension.title}</td>
            <td>{dimension.order}</td>
            <td>
              {dimension.chainIds.map((chainId) =>
                ChainAbbreviations[chainId] ? (
                  <span key={chainId} className={`chain-name ${ChainAbbreviations[chainId]}`}>
                    {ChainAbbreviations[chainId]}
                  </span>
                ) : null,
              )}
            </td>
            <td className="actions">
              {dimension.options.length === 0 && (
                <button onClick={() => deleteDimension(dimension.id)}>
                  <IconDelete />
                </button>
              )}
              <NavLink to={`dimension/${dimension.id}`}>
                <IconArrowRight />
              </NavLink>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const renderB2BDimensionRows = () => {
    return (
      <tbody>
        {dimensionsStore.b2bDimensions.map((dimension) => (
          <tr key={dimension.id}>
            <td>{dimension.name}</td>
            <td>{dimension.title}</td>
            <td>{dimension.order}</td>
            <td>
              {dimension.chainIds.map((chainId) => (
                <span key={chainId} className={`chain-name ${ChainAbbreviations[chainId]}`}>
                  {ChainAbbreviations[chainId]}
                </span>
              ))}
            </td>
            <td className="actions">
              {dimension.options.length === 0 && (
                <button onClick={() => deleteDimension(dimension.id)}>
                  <IconDelete />
                </button>
              )}
              <NavLink to={`dimension/${dimension.id}`}>
                <IconArrowRight />
              </NavLink>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const downloadCsvFile = async (downloadInternal: boolean) => {
    const invoiceCsv = downloadInternal ? await invoicesStore.getInternalInvoices() : await invoicesStore.getInvoices();
    const element = document.createElement('a');
    const file = new Blob([invoiceCsv], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = 'invoices.csv';
    document.body.appendChild(element);
    element.click();
  };

  const showB2cDimensions = getAllowedConceptTypes(appStore.chain).includes(ConceptType.Season);
  const showB2bDimensions = getAllowedConceptTypes(appStore.chain).includes(ConceptType.B2b);

  return (
    <div className="settings">
      {showB2cDimensions && (
        <div className="dimensions">
          <header>
            <h2>{!showB2bDimensions ? 'Dimensions' : 'Dimensions (B2C)'}</h2>
            <button className="add-dimension" onClick={() => createDimension(BusinessType.B2c)}>
              <span>Add dimension</span>
              <img src={require('images/add.svg').default} alt="add" />
            </button>
          </header>
          <table className="styled">
            <thead>
              <tr>
                <th>Name</th>
                <th>Title</th>
                <th>Order</th>
                <th>Chain</th>
                <th>Actions</th>
              </tr>
            </thead>
            {renderDimensionRows()}
          </table>
          {!dimensionsStore.dimensions && <Spinner addClassName="spinner--margin-top" />}
        </div>
      )}
      {showB2bDimensions && (
        <div className="b2b-dimensions">
          <header>
            <h2>{!showB2cDimensions ? 'Dimensions' : 'Dimensions (B2B)'}</h2>
            <button className="add-dimension" onClick={() => createDimension(BusinessType.B2b)}>
              <span>Add dimension</span>
              <img src={require('images/add.svg').default} alt="add" />
            </button>
          </header>
          <table className="styled">
            <thead>
              <tr>
                <th>Name</th>
                <th>Title</th>
                <th>Order</th>
                <th>Chain</th>
                <th>Actions</th>
              </tr>
            </thead>
            {renderB2BDimensionRows()}
          </table>
          {!dimensionsStore.dimensions && <Spinner />}
        </div>
      )}
      {appStore.chain === kRuoka.chain && (
        <div className="siteOptions">
          <h2>Download invoice rows</h2>
          <button onClick={() => downloadCsvFile(false)}>Invoices</button>
          <button onClick={() => downloadCsvFile(true)}>Internal Invoices</button>
        </div>
      )}
      <div className="siteOptions">
        <h2>Disable site</h2>
        <SiteOptions />
      </div>
      <Alerts />
    </div>
  );
};

export default page(inject('dimensionsStore', 'invoicesStore', 'appStore')(observer(SettingsPage)));
