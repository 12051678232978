/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/unbound-method, @typescript-eslint/no-misused-promises  */
import React from 'react';
import { inject, observer } from 'mobx-react';
import SettingsStore from '../../../../stores/next/setting';
import Spinner from 'components/common/next/spinner';
import AlertStore from 'stores/next/alerts';
import './siteOptions.scss';
import { siteDisabled, siteDisabledText } from 'constants/common';

interface Props {}

interface InjectedProps extends Props {
  settingsStore: SettingsStore;
  alertStore: AlertStore;
}

interface State {
  isLoading: boolean;
  siteDisabled: boolean;
  text?: string;
}

@inject('settingsStore', 'alertStore')
@observer
class SiteOptions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      siteDisabled: false,
      text: null,
    };
    this.toggle = this.toggle.bind(this);
    this.changeText = this.changeText.bind(this);
    this.saveText = this.saveText.bind(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const { settingsStore } = this.injected;
    const disabled = await settingsStore.getSetting(siteDisabled);
    const text = await settingsStore.getSetting(siteDisabledText);
    this.setState({ siteDisabled: disabled.value === 'true', text: text.value });
    this.setState({ isLoading: false });
  };

  async toggle(disabled: boolean) {
    const { isLoading } = this.state;
    const { settingsStore, alertStore } = this.injected;
    if (!isLoading) {
      const description = 'site';
      const confirmed = window.confirm(
        `Are you sure you want to ${!disabled ? 'disable' : 'enable'} the ${description} for all retailers?`,
      );
      if (confirmed) {
        this.setState({ isLoading: true });
        const result = await settingsStore.modifySetting({
          name: siteDisabled,
          value: !disabled ? 'true' : 'false',
        });
        this.setState({ isLoading: false, siteDisabled: result.data.value === 'true' });
        if (result.status === 200) {
          alertStore.success({
            message: `Site ${!disabled ? 'disabled' : 'enabled'} succesfully`,
          });
        }
      }
    }
  }

  changeText(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = event.currentTarget;
    this.setState({ text: value });
  }

  async saveText() {
    const { text } = this.state;
    const { settingsStore, alertStore } = this.injected;
    if (text && text.length > 0) {
      const result = await settingsStore.modifySetting({
        name: siteDisabledText,
        value: text,
      });
      if (result.status === 200) {
        alertStore.success({
          message: 'Text saved succesfully',
        });
      } else {
        alertStore.error({
          message: 'Text saving failed',
        });
      }
    }
  }

  render() {
    const { text, isLoading, siteDisabled } = this.state;
    if (isLoading) {
      return <Spinner />;
    }
    const buttonClass = (check) => `btn btn-${check ? 'default' : 'inverse'}`;

    return (
      <div className="actions-page">
        {siteDisabled ? (
          <p>The site is closed for retailers.</p>
        ) : (
          <p>Close the site for retailers. The retailer pages will be unaccessible for all users, including admins.</p>
        )}
        <button
          type="button"
          className={`${buttonClass(siteDisabled)} disable-site`}
          onClick={this.toggle.bind(this, !!siteDisabled)}
        >
          {siteDisabled ? 'Avaa pääsy K Markkinointiin' : 'Lukitse pääsy K Markkinointiin'}
        </button>
        <p>Set a text to display to the retailers when the site is closed.</p>
        <div className="input-container">
          <textarea rows={2} value={text || ''} onChange={this.changeText} />
          <button
            type="button"
            className={`btn btn-${text && text.length > 0 ? 'default' : 'disabled'}`}
            onClick={this.saveText}
          >
            Save text
          </button>
        </div>
      </div>
    );
  }
}

export default SiteOptions;
