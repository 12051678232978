/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument */
import { observable, action } from 'mobx';
import type { Client } from 'types/next-api';
import type { PricingGroup } from 'types/next';

interface SearchProps {
  limit?: number;
  offset?: number;
  name?: string;
}

export default class PricingGroupStore {
  client: Client;
  @observable pricingGroups: PricingGroup[] = [];
  @observable pricingGroup: PricingGroup;

  @action async searchPricingGroups({ limit, offset, name }: SearchProps) {
    try {
      const result = await this.client.searchPricingGroupItems({ limit, offset, name }, null);
      this.pricingGroups = result.data as PricingGroup[];
      return result;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async findById(id: number | string) {
    try {
      const result = await this.client.getPricinGroupItem(id);
      this.pricingGroup = result.data;
      return result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async createPricingGroup(pricingGroup: PricingGroup) {
    try {
      const result = await this.client.createPricingGroup(null, pricingGroup);
      await this.searchPricingGroups({});
      this.pricingGroup = result.data;
      return result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async updatePricingGroup(pricingGroup: PricingGroup) {
    try {
      const result = await this.client.updatePricingGroup(pricingGroup.id, pricingGroup);
      await this.searchPricingGroups({});
      this.pricingGroup = result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async deletePricingGroup(id: string) {
    try {
      await this.client.deletePricingGroup(id);
      await this.searchPricingGroups({});
    } catch (err) {
      this.handleError(err);
    }
  }

  handleError(err: any) {
    console.error(err);
    if (err.response && err.response.data) {
      console.error(err.response.data);
      throw new Error(err.response.data.message);
    }
    throw err;
  }
}
