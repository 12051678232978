/* eslint-disable @typescript-eslint/no-floating-promises, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import Spinner from 'components/common/next/spinner';
import InputText from 'components/common/next/form/inputText';
import { Checkbox } from 'components/retailer/next/components/primitives/checkbox';
import StoreStore from 'stores/next-retailer/storeStore';
import UserStore from 'stores/userStore';
import { getChain, validateNotificationContact } from '../../../../../utils/helpers';
import './profilePage.scss';
import { NotificationType } from 'types/user';
import { Chain } from 'enums/common';
import AlertStore from 'stores/next/alerts';

interface Props {
  storeStore?: StoreStore;
  userStore?: UserStore;
  alertStore?: AlertStore;
}

const ProfilePage = ({ storeStore, userStore, alertStore }: Props) => {
  const [newContact, setNewContact] = useState('');
  const [isNewContactValid, setIsNewContactValid] = useState(false);

  useEffect(() => {
    storeStore.fetchStore();
  }, []);

  const toggleNotificationType = (checked: boolean, notificationType: NotificationType) => {
    const {
      me: { store },
    } = userStore;

    const notificationTypes = checked
      ? [...userStore.notificationTypes, notificationType]
      : _.without(userStore.notificationTypes, notificationType);

    userStore.patchNotificationTypes(store, notificationTypes).catch((err) => {
      alertStore.error({ message: 'Imoitusasetuksen muutos epäonnistui.' });
    });
  };

  const addNotificationContact = () => {
    const { notificationContacts, me } = userStore;
    const { store } = me;

    if (!isNewContactValid) {
      return;
    }
    const { type } = validateNotificationContact(newContact, false);
    const newNotificationContact = { type, value: newContact };

    const updatedNotificationContacts = [...notificationContacts, newNotificationContact];

    userStore
      .patchNotificationContacts(store, updatedNotificationContacts)
      .then(() => {
        setNewContact('');
        setIsNewContactValid(false);
      })
      .catch((err) => {
        alertStore.error({ message: 'Muistutettavan lisääminen epäonnistui.' });
      });
  };

  const removeNotificationContact = (i: number) => {
    const { notificationContacts, me } = userStore;
    const { store } = me;
    const updatedNotificationContacts = notificationContacts.filter((contact, index) => index !== i);

    userStore.patchNotificationContacts(store, updatedNotificationContacts).catch((err) => {
      alertStore.error({ message: 'Muistutettavan poisto epäonnistui.' });
    });
  };

  const onNewContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const { valid } = validateNotificationContact(value, false);
    setNewContact(value);
    setIsNewContactValid(valid);
  };

  const renderNotificationContacts = () => {
    const { store } = storeStore;
    const { retailerEmail } = store;

    if (!userStore) {
      return null;
    }

    const { notificationContacts } = userStore;

    return _.map(notificationContacts, (notificationContact, i) => {
      return (
        <div key={`notification-contact-${i}`} className="notification-contact">
          <span>{notificationContact.value}</span>{' '}
          {notificationContact.value !== retailerEmail && (
            <React.Fragment>
              (<button onClick={() => removeNotificationContact(i)}>poista</button>)
            </React.Fragment>
          )}
        </div>
      );
    });
  };

  if (!storeStore.store) {
    return <Spinner />;
  }

  const { me } = userStore;
  const { name, email } = me;
  const { notificationTypes } = userStore;
  const { phone, retailerImage, name: storeName, address, facebookUrl } = storeStore.store;
  const chain = getChain([me.chainId]);
  const showStampCardNofication = chain === Chain.kRuoka;
  const showAutomaticParticipationNotification = chain === Chain.kRuoka;

  // !TODO: retailer_admin_link for BTT to be added later, now using empty values
  const retailer_admin_text = chain === Chain.kRuoka ? `Omia kauppiastietoja hallitaan Deskin kautta: ` : ``;
  const retailer_admin_link = chain === Chain.kRuoka ? `https://deski.k-ruoka.fi/perustiedot` : null;
  // !TODO: shop_admin_text for BTT to be added later, now using empty values
  const shop_admin_text = chain === Chain.kRuoka ? `Kaupan tietoja hallitaan Deskin kautta: ` : ``;
  const shop_admin_link = chain === Chain.kRuoka ? `https://deski.k-ruoka.fi/` : null;

  return (
    <div className="profile-page">
      <div className="main-content">
        <div className="content">
          <h1 className="main-title">Profiili</h1>
          <section>
            <div>
              <div>Omat tiedot</div>
              <div>
                {retailer_admin_text}
                <a href={retailer_admin_link} target="_blank" rel="noopener noreferrer">
                  {retailer_admin_link}
                </a>
              </div>
            </div>
            <div>
              <div>Nimi</div>
              <div>{name}</div>
            </div>
            <div>
              <div>Sähköposti</div>
              <div>{email}</div>
            </div>
            <div>
              <div>Puhelinnumero</div>
              <div>{phone}</div>
            </div>
            <div>
              <div>Kauppiaskuva</div>
              <div>
                <a href={retailerImage} target="_blank" rel="noopener noreferrer">
                  {retailerImage}
                </a>
              </div>
            </div>
            <div>
              <div>Omat muistutukset</div>
              <div>
                <Checkbox
                  key="notification-type-delivery-confirmation"
                  id="notification-type-delivery-confirmation"
                  label="Vahvistukset luoduista tai muokatuista lähetyksistä"
                  checked={_.includes(notificationTypes, NotificationType.deliveryConfirmation)}
                  onChange={({ target: { checked } }) =>
                    toggleNotificationType(checked, NotificationType.deliveryConfirmation)
                  }
                />
                <Checkbox
                  key="notification-type-draft-delivery-reminder"
                  id="notification-type-draft-delivery-reminder"
                  label="Muistutukset vahvistamattomista lähetyksistä"
                  checked={_.includes(notificationTypes, NotificationType.draftDeliveryReminder)}
                  onChange={({ target: { checked } }) =>
                    toggleNotificationType(checked, NotificationType.draftDeliveryReminder)
                  }
                />
                <Checkbox
                  key="notification-type-new-delivery-template"
                  id="notification-type-new-delivery-template"
                  label="Ilmoitukset uusista lähetyksistä joihin voit osallistua"
                  checked={_.includes(notificationTypes, NotificationType.newDeliveryTemplate)}
                  onChange={({ target: { checked } }) =>
                    toggleNotificationType(checked, NotificationType.newDeliveryTemplate)
                  }
                />
                <Checkbox
                  key="notification-type-delivery-template-reminder"
                  id="notification-type-delivery-template-reminder"
                  label="Muistutukset asiakasohjelmista joihin et ole vielä osallistunut"
                  checked={_.includes(notificationTypes, NotificationType.deliveryTemplateReminder)}
                  onChange={({ target: { checked } }) =>
                    toggleNotificationType(checked, NotificationType.deliveryTemplateReminder)
                  }
                />
                {showStampCardNofication && (
                  <Checkbox
                    key="notification-type-stampcard-delivery-confirmation"
                    id="notification-type-stampcard-delivery-confirmation"
                    label="Vahvistukset luoduista tai muokatuista keräilypasseista"
                    checked={_.includes(notificationTypes, NotificationType.stampcardDeliveryConfirmation)}
                    onChange={({ target: { checked } }) =>
                      toggleNotificationType(checked, NotificationType.stampcardDeliveryConfirmation)
                    }
                  />
                )}
                {showStampCardNofication && (
                  <Checkbox
                    key="notification-type-stampcard-reminder"
                    id="notification-type-stampcard-reminder"
                    label="Muistutus kun keräilypassi on päättymässä"
                    checked={_.includes(notificationTypes, NotificationType.stampcardReminder)}
                    onChange={({ target: { checked } }) =>
                      toggleNotificationType(checked, NotificationType.stampcardReminder)
                    }
                  />
                )}
                {showAutomaticParticipationNotification && (
                  <Checkbox
                    key="notification-type-automatic-participation-reminder"
                    id="notification-type-automatic-participation-reminder"
                    label="Muistutukset automaattisesti tehtävistä lähetyksistä, joita voit vielä muokata"
                    checked={_.includes(notificationTypes, NotificationType.automaticParticipationReminder)}
                    onChange={({ target: { checked } }) =>
                      toggleNotificationType(checked, NotificationType.automaticParticipationReminder)
                    }
                  />
                )}
              </div>
            </div>
            <div>
              <div>Muistutettavat</div>
              <div className="notification-contacts">
                {renderNotificationContacts()}
                <div className="new-notification-contact">
                  <InputText
                    name="newContact"
                    placeholder="Sähköposti"
                    label="Lisää uusi"
                    onChange={onNewContactChange}
                    value={newContact}
                  />
                  <button
                    className={`btn btn-default${isNewContactValid ? '' : ' btn-disabled'}`}
                    onClick={addNotificationContact}
                  >
                    Lisää
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div>
              <div>Kaupan tiedot</div>
              <div>
                {shop_admin_text}
                <a href={shop_admin_link} target="_blank" rel="noopener noreferrer">
                  {shop_admin_link}
                </a>
              </div>
            </div>
            <div>
              <div>Nimi</div>
              <div>{storeName}</div>
            </div>
            <div>
              <div>Osoite</div>
              <div>{address}</div>
            </div>
            <div>
              <div>Facebook</div>
              <div>
                <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
                  {facebookUrl}
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default inject('userStore', 'storeStore', 'alertStore')(observer(ProfilePage));
