/* eslint-disable @typescript-eslint/no-floating-promises, 	react/prop-types, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-misused-promises, 	@typescript-eslint/no-unsafe-member-access  */
import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import DeliveriesStore from 'stores/next/deliveries';
import StoreStore from 'stores/next/stores';
import StatisticsStore from 'stores/statisticsStore';
import page from 'components/next/pages/page/page';
import Spinner from 'components/common/next/spinner';
import DeliveryTemplateStore from 'stores/next/deliveryTemplates';
import ConceptsStore from 'stores/next/programs';
import SearchBox from 'components/next/components/sidebar/searchBox';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import ChainSelector from 'components/next/components/chainSelector';
import { getExcludedChains } from 'utils/helpers';
import type { Components } from 'types/next-api';
import { RadioButton } from 'components/next/components/radioButton/radioButton';
import * as dateFns from 'date-fns';
import { ChainAbbreviations } from 'constants/common';
import { ConceptType } from 'enums/common';

interface AutomaticParticipationStatisticsItem {
  id: string;
  name: string;
  chainIds: string[];
  automaticallyParticipatingStores: number;
  allParticipatingStores: number;
}

export enum AutomaticParticipationStatisticsType {
  Concept = 'concepts',
  Template = 'deliveryTemplates',
}

interface AutomaticParticipationState {
  chainIds: string[];
  excludedChains: string[];
  isLoading: boolean;
  query: string;
  selectedItems: AutomaticParticipationStatisticsItem[];
  storeIds: string[];
  types: { [type: string]: boolean };
  statistics: Components.Responses.AutomaticParticipationTabStatisticsResponse;
  statisticsType: AutomaticParticipationStatisticsType;
}

interface AutomaticParticipationProps {
  deliveriesStore: DeliveriesStore;
  deliveryTemplateStore: DeliveryTemplateStore;
  conceptsStore: ConceptsStore;
  storeStore: StoreStore;
  statisticsStore: StatisticsStore;
  chainIds: string[];
}

@inject('storeStore', 'statisticsStore')
@observer
class AutomaticParticipation extends React.Component<AutomaticParticipationProps, AutomaticParticipationState> {
  debouncedHandleStatisticsMapping: () => void;

  constructor(props) {
    super(props);
    const { chainIds } = this.props;
    this.state = {
      query: '',
      chainIds,
      excludedChains: getExcludedChains(chainIds),
      storeIds: [],
      selectedItems: [],
      isLoading: true,
      types: _.reduce(
        Object.keys(ConceptType),
        (types, key) => {
          const type = ConceptType[key as string];
          types[type] = true;
          return types;
        },
        {},
      ),
      statistics: {
        deliveryTemplates: [],
        concepts: [],
      },
      statisticsType: AutomaticParticipationStatisticsType.Concept,
    };
    this.debouncedHandleStatisticsMapping = _.debounce(() => this.handleStatisticsMapping(), 500);
  }

  get storesParticipatingAutomaticallyThisYear() {
    return this.props.statisticsStore.automaticParticipationsThisYear;
  }

  componentDidMount = async () => {
    const { statisticsStore } = this.props;
    await statisticsStore.getStatisticsAutomaticParticipation();
    this.handleStatisticsMapping();
  };

  handleStatisticsMapping = () => {
    this.setState({ isLoading: true });
    const { statisticsStore } = this.props;
    const conceptStatistics = statisticsStore.automaticParticipationConceptStatistics;
    const deliveryTemplateStatistics = statisticsStore.automaticParticipationDeliveryTemplateStatistics;

    // Filter concept statistics and delivery template statistics by query and chainIds
    const filteredConceptStatistics = _.filter(conceptStatistics, (concept) => {
      return (
        concept.title.toLowerCase().includes(this.state.query.toLowerCase()) &&
        concept.chainIds.some((chainId) => this.state.chainIds.includes(chainId))
      );
    });

    const filteredDeliveryTemplateStatistics = _.filter(deliveryTemplateStatistics, (template) => {
      return (
        template.title.toLowerCase().includes(this.state.query.toLowerCase()) &&
        template.chainIds.some((chainId) => this.state.chainIds.includes(chainId))
      );
    });

    this.setState({
      statistics: {
        concepts: filteredConceptStatistics,
        deliveryTemplates: filteredDeliveryTemplateStatistics,
      },
    });
    this.setState({ isLoading: false });
  };

  handleChainChange = (chain: string) => {
    const chainIds = [...this.state.chainIds];
    if (chainIds.includes(chain)) {
      _.pullAt(chainIds, chainIds.indexOf(chain));
    } else {
      chainIds.push(chain);
    }
    this.setState({ chainIds, isLoading: true });
    this.debouncedHandleStatisticsMapping();
  };

  handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { types } = this.state;
    const type = e.currentTarget.dataset.conceptType;
    types[type] = !types[type];
    this.setState({ types, isLoading: true });
    this.debouncedHandleStatisticsMapping();
  };

  setTypeFilter = (type: AutomaticParticipationStatisticsType) => {
    this.setState({ statisticsType: type });
  };

  // eslint-disable-next-line
  handleQueryChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'query') {
      this.setState({ query: value, isLoading: true });
      this.debouncedHandleStatisticsMapping();
    }
  };

  renderSidebar = () => {
    const { query, chainIds, excludedChains, statisticsType, isLoading } = this.state;
    return (
      <div className="deliveries-sidebar">
        <div className="deliveries-filters">
          <div className="sidebar__title-wrapper">
            <h3 className="sidebar__title">Filters</h3>
            {isLoading && <Spinner addClassName="spinner--unset" />}
          </div>
          <SearchBox
            value={query}
            name="query"
            onChange={this.handleQueryChange}
            placeholder="Filter results"
            detail="Filter by concept or delivery template title"
          />
          <label>Chain</label>
          <ChainSelector
            chainSelection={chainIds}
            excludeChains={excludedChains}
            handleChainChange={this.handleChainChange}
          />
          <div className="filter-group">
            <label>Type</label>
            <RadioButton
              group="statistics-type-filter"
              id="type-concept"
              checked={statisticsType === AutomaticParticipationStatisticsType.Concept}
              handleClick={() => this.setTypeFilter(AutomaticParticipationStatisticsType.Concept)}
              label="Concept"
            />
            <RadioButton
              group="statistics-type-filter"
              id="type-delivery-template"
              checked={statisticsType === AutomaticParticipationStatisticsType.Template}
              handleClick={() => this.setTypeFilter(AutomaticParticipationStatisticsType.Template)}
              label="Delivery template"
            />
          </div>
        </div>
      </div>
    );
  };

  renderAutomaticParticipationRows = () => {
    const selectedStatistics =
      this.state.statisticsType === AutomaticParticipationStatisticsType.Concept
        ? this.state.statistics.concepts
        : this.state.statistics.deliveryTemplates;

    if (!selectedStatistics) {
      return (
        <div className="no-data">
          <p>No data found.</p>
        </div>
      );
    }

    const estimateInfo = (statisticsItem: Components.Schemas.AutomaticParticipationStatisticsResponseItem) => {
      return statisticsItem && dateFns.isAfter(new Date(statisticsItem.deadline), new Date()) ? ' (estimate)' : '';
    };

    return (
      <tbody id="delivery-table-body">
        {selectedStatistics.map((i) => (
          <tr key={i.id} className="delivery-row">
            <td>{i.title}</td>
            <td>
              {i.chainIds.sort().map((c, i) =>
                ChainAbbreviations[c] ? (
                  <span className={`chain-name ${ChainAbbreviations[c]}`} key={`chain-${i}`}>
                    {ChainAbbreviations[c]}
                  </span>
                ) : null,
              )}
            </td>
            <td>
              {i.storesParticipatingAutomatically.length} {estimateInfo(i)}
            </td>
            <td>
              {i.storesParticipatingTotal.length} {estimateInfo(i)}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  render() {
    const { isLoading } = this.state;

    return (
      <SidebarWrapper renderSidebar={this.renderSidebar}>
        <div className="results-view">
          {this.props.children}
          <div className="results-container">
            <div className="result">
              <span className="result__value">{this.storesParticipatingAutomaticallyThisYear || 0}</span>
              <span className="result_detail">stores participating automatically in programs this year</span>
            </div>
          </div>

          <div className="deliveries">
            <div className="table-container">
              <table className="styled">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Chains</th>
                    <th>Automatically participating stores</th>
                    <th>Total participating stores</th>
                  </tr>
                </thead>
                {this.renderAutomaticParticipationRows()}
              </table>
              {isLoading && <Spinner />}
            </div>
          </div>
        </div>
      </SidebarWrapper>
    );
  }
}
export default page(AutomaticParticipation);
