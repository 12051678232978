/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment  */
import React from 'react';
import { observer } from 'mobx-react';
import _ from 'utils/lodash';
import { DatePicker } from '@material-ui/pickers';
import { Checkbox } from 'components/next/components/checkbox';
import { isBefore, addYears, addMonths, startOfDay, endOfDay, addWeeks } from 'date-fns';
import { CollectionStampCardType, CollectionStampCardStrategy } from 'enums/stampCard';
import type { StampCardEditorTabProps } from '../stampCardEditor';
import { ChainFilters, kRuokaChainIds } from 'constants/common';

interface State {}

@observer
export default class StampCardValidityTab extends React.Component<StampCardEditorTabProps, State> {
  constructor(props: StampCardEditorTabProps) {
    super(props);
  }

  get currentStampCard() {
    return this.props.stampCardStoreNew.current;
  }

  get originalStampCard() {
    return this.props.stampCardStoreNew.original;
  }

  get validationError() {
    return this.props.stampCardStoreNew.validationError;
  }

  get invalidField() {
    return this.props.stampCardStoreNew.invalidField;
  }

  get activeToMaxDate() {
    const { userStore: isAdmin } = this.props;
    if (isAdmin) {
      return undefined;
    }
    const maxDate = addYears(new Date(this.currentStampCard.activeFrom), 1); // limit to 1 year from activation for all stamp cards
    return maxDate;
  }

  get redeemableToMaxDate() {
    const { userStore: isAdmin } = this.props;
    if (isAdmin) {
      return undefined;
    }
    return addMonths(new Date(this.currentStampCard.activeTo), 1);
  }

  toggleStore = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { userStore, stampCardStoreNew } = this.props;
    const { stores } = this.currentStampCard;
    const { storesByStoreId } = userStore;
    const { value: storeId, checked } = e.target;
    const store = _.get(storesByStoreId, [storeId]);

    let updatedStores = [...stores];

    if (checked) {
      updatedStores.push({
        name: store.name,
        storeId: store.storeId,
        chainId: store.chainId,
      });
    } else {
      updatedStores = _.filter(updatedStores, (store) => store.storeId !== storeId);
    }

    // Get unique stores
    updatedStores = _.uniqBy(updatedStores, 'storeId');

    // Get unique chains for selected stores.
    const updatedChains = _.uniq(_.map(updatedStores, ({ storeId }) => _.get(storesByStoreId, [storeId, 'chainId'])));

    // Update values.
    stampCardStoreNew.updateValue(['stores'], updatedStores);
    stampCardStoreNew.updateValue(['chains'], updatedChains);
  };
  toggleChain = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { stampCardStoreNew } = this.props;
    const { chains } = this.currentStampCard;
    const { value: chainId, checked } = e.target;

    let updatedChains = [...chains];

    if (checked) {
      updatedChains.push(chainId);
    } else {
      updatedChains = _.filter(updatedChains, (chain) => chainId !== chain);
    }

    stampCardStoreNew.updateValue(['chains'], updatedChains);
  };

  changeActiveFrom = (date: Date) => {
    const { stampCardStoreNew } = this.props;
    const activeFrom = startOfDay(date);
    stampCardStoreNew.updateValue(['activeFrom'], activeFrom.toISOString());
    stampCardStoreNew.updateValue(['visibleFrom'], activeFrom.toISOString());

    // If active from is changed to a value that is after the others, change the others too.
    if (!isBefore(new Date(this.currentStampCard.activeFrom), new Date(this.currentStampCard.activeTo))) {
      stampCardStoreNew.updateValue(['activeTo'], endOfDay(activeFrom).toISOString());
      stampCardStoreNew.updateValue(['visibleTo'], endOfDay(activeFrom).toISOString());
      stampCardStoreNew.updateValue(['redeemableTo'], endOfDay(addWeeks(activeFrom, 2)).toISOString());
    }
  };

  changeActiveTo = (date: Date) => {
    const { stampCardStoreNew } = this.props;
    const activeTo = endOfDay(date);
    stampCardStoreNew.updateValue(['activeTo'], activeTo.toISOString());
    stampCardStoreNew.updateValue(['visibleTo'], activeTo.toISOString());
    stampCardStoreNew.updateValue(['redeemableTo'], endOfDay(addWeeks(activeTo, 2)).toISOString());
  };

  changeRedeemableTo = (date: Date) => {
    const { stampCardStoreNew } = this.props;
    const redeemableTo = endOfDay(date).toISOString();
    stampCardStoreNew.updateValue(['redeemableTo'], redeemableTo);
  };

  changeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { stampCardStoreNew } = this.props;
    const type = e.target.value as CollectionStampCardType;
    const strategy =
      type === CollectionStampCardType.Basket
        ? CollectionStampCardStrategy.OneTimePurchase
        : CollectionStampCardStrategy.SimpleProductCollecting;
    const oneTimePurchase = strategy === CollectionStampCardStrategy.OneTimePurchase ? 50 : '';

    stampCardStoreNew.updateValue(['products'], []);
    stampCardStoreNew.updateValue(['type'], type);
    stampCardStoreNew.updateValue(['strategy'], strategy);
    stampCardStoreNew.updateValue(['oneTimePurchase'], oneTimePurchase);
  };

  renderStorePicker = () => {
    const stores = this.props.userStore.stores;
    const storeIds = _.map(this.currentStampCard.stores, 'storeId');

    return (
      <div className="form-control">
        <label>Osallistuvat kaupat</label>
        {_.map(stores, (store) => {
          return (
            <Checkbox
              key={`store-${store.value}`}
              id={`store-${store.value}`}
              value={store.value}
              handleClick={this.toggleStore}
              checked={_.includes(storeIds, store.value)}
              label={store.label}
            />
          );
        })}
      </div>
    );
  };
  renderChainPicker = () => {
    const chains = ['3', '4', '5'].map((c) => {
      return { id: c, name: ChainFilters[c] };
    });
    const selectedChains = this.currentStampCard.chains || [];
    return (
      <div className="form-control">
        <label>Osallistuvat ketjut</label>
        {_.map(chains, (chain) => {
          return (
            <Checkbox
              key={`chain-${chain.id}`}
              id={`store-${chain.id}`}
              value={chain.id}
              handleClick={this.toggleChain}
              checked={_.includes(selectedChains, chain.id)}
              label={chain.name}
            />
          );
        })}
      </div>
    );
  };

  render() {
    const isStartDateEditable =
      this.originalStampCard && !isBefore(new Date(), new Date(this.originalStampCard.activeFrom));
    return (
      <div>
        <div className="section-header">
          <h2>Keräilypassin voimassaolo</h2>
        </div>
        <div className="section-split">
          <div className="left">
            <div className="box">
              <div className="form-control">
                <label>Leimojen keräysaika</label>
                <div className="card-dates">
                  <DatePicker
                    value={this.currentStampCard.activeFrom}
                    minDate={new Date()}
                    onChange={this.changeActiveFrom}
                    format="d.M.yyyy"
                    disableToolbar
                    disabled={isStartDateEditable}
                  />
                  <span>&mdash;</span>
                  <DatePicker
                    value={this.currentStampCard.activeTo}
                    minDate={this.currentStampCard.activeFrom}
                    maxDate={this.activeToMaxDate}
                    onChange={this.changeActiveTo}
                    format="d.M.yyyy"
                    disableToolbar
                  />
                </div>
                <div className="form-control detail-text">Keräilypassi voi olla voimassa maksimissaan vuoden.</div>
              </div>
              <div className="form-control">
                <label>Palkintoetujen voimassaolo</label>
                <div className="card-dates">
                  <DatePicker
                    value={this.currentStampCard.redeemableTo}
                    minDate={this.currentStampCard.activeTo}
                    maxDate={this.redeemableToMaxDate}
                    onChange={this.changeRedeemableTo}
                    format="d.M.yyyy"
                    disableToolbar
                  />
                </div>
              </div>
              <div className="form-control detail-text">
                Huomioithan, että kaupalla voi olla maksimissaan viisi keräilypassia kerrallaan voimassa.
              </div>
              {this.props.keskoStampCard ? (
                <div className="store-list stampcard-stores">{this.renderChainPicker()}</div>
              ) : (
                <div className="store-list stampcard-stores">{this.renderStorePicker()}</div>
              )}
            </div>
          </div>
          <div className="right">
            <div className="box">
              <h3>Ohjeet</h3>
              <p>
                Huomaathan, että keräilypassi voi olla voimassa maksimissaan vuoden, ja sen päättymisen jälkeen voit
                luoda uuden keräilypassin. Kaupalla voi olla yhtäaikaisesti 5 keräilypassia voimassa. Jos haluat sulkea
                aktiivisen keräilypassin ennenaikaisesti, voit käydä vaihtamassa keräilyn päättymispäivän aikaisemmaksi
                / menneisyyteen.
              </p>
              <p>
                Palkintoedut ovat oletusarvoisesti lunastettavissa vielä 2 viikkoa keräilypassin päättymisen jälkeen ja
                voit halutessasi pidentää lunastusaikaa maksimissaan kuukauteen.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
