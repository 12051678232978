import type {
  Delivery,
  DeliverySearchPayload,
  PreviewParams,
  DeliveryPayload,
  TargetGroupSelection,
} from 'components/next/types';
import { action, observable, toJS } from 'mobx';
import * as _ from 'lodash';
import { AxiosResponse } from 'axios';
import { OpenAPIClient } from 'openapi-client-axios';
import type { Client as NextClient } from 'types/next-api';
import DeliveryTemplateStore from './deliveryTemplates';
import { PreviewFormat } from 'enums/common';
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-floating-promises */
export default class DeliveriesStore {
  client: OpenAPIClient;
  stores: {
    deliveryTemplatesStore: DeliveryTemplateStore;
  };
  @observable collection: Delivery[] = [];
  @observable delivery: Delivery = undefined;
  @observable count = 0;
  // Lookup cache
  public deliveryIndexMap: Map<string | number, number> = new Map<string | number, number>();

  get nextClient() {
    return this.client as any as NextClient;
  }

  // alias for this.collection
  get deliveries() {
    return this.collection;
  }

  // collection of deliveries
  @action
  public updateDeliveriesInCollection(deliveries: Delivery[]) {
    for (const item of deliveries) {
      const index = this.deliveryIndexMap.get(item.id);
      if (index !== undefined) {
        // update
        if (!_.isEqual(this.collection[index], item)) {
          this.collection[index] = item;
        } else {
          // no need to update
        }
      } else {
        // add to collection
        const newLength = this.collection.push(item);
        this.deliveryIndexMap.set(item.id, newLength - 1);
      }
    }
    return this.collection;
  }

  @action
  public deleteDeliveryFromCollection(id: string | number) {
    const index = this.deliveryIndexMap.get(id);
    if (index !== undefined) {
      this.collection.splice(index, 1);
      this.deliveryIndexMap.delete(id);
      // Shift other indexes due to delete. THIS MIGHT BE SLOW IF USED OFTEN, but the routine seems not to be in use.
      const newDeliveryIndexMap: Map<string | number, number> = new Map<string | number, number>();
      this.deliveryIndexMap.forEach((curren, key) => {
        newDeliveryIndexMap.set(key, curren > index ? curren - 1 : curren);
      });
      this.deliveryIndexMap = newDeliveryIndexMap;
    }
    return this.collection;
  }

  @action
  async search(payload: DeliverySearchPayload = {}, parameters = {}) {
    _.set(parameters, 'light', true);
    const result = await this.nextClient.searchDeliveries(parameters, payload);
    this.updateDeliveriesInCollection(result.data.result as Delivery[]);
    return result;
  }

  @action
  async rebuildDeliveries(deliveryIds: string[]) {
    const result = await this.nextClient.rebuildDeliveries(null, {
      deliveryIds: [deliveryIds[0], ...deliveryIds.slice(1)],
    });
    return result;
  }

  @action
  async setArchiveStatusForDeliveries(deliveryIds: string[], archiveStatus: boolean) {
    const result = await this.nextClient.setArchiveStatusForDeliveries(null, {
      deliveryIds: [deliveryIds[0], ...deliveryIds.slice(1)],
      archiveStatus,
    });

    return result;
  }

  @action setDelivery(delivery) {
    this.delivery = delivery;
  }

  @action
  async getDeliveryById(id: number | string) {
    const result = await this.nextClient.getDelivery(id);
    if (result.data) {
      const delivery = result.data as Delivery;
      this.updateDeliveriesInCollection([delivery]);
      if (delivery.targetGroup.channels.mobile !== -1) {
        // Do not try to search if mobile offer delivery, will fail
        this.stores.deliveryTemplatesStore.getTemplateById(delivery.deliveryTemplate);
      }
    }
    return result.data;
  }

  @action
  async getSelectionAreasForDelivery(delivery: Delivery, overrides: Partial<TargetGroupSelection> = {}) {
    const targetGroup = toJS(delivery.targetGroup);
    delivery.selectionAreasLoading = true;
    const { data: selectionAreas } = await this.client.selectionAreas(null, {
      ..._.omit(targetGroup, 'areas'),
      ...overrides,
    });
    if (!_.isEqual(targetGroup, toJS(delivery.targetGroup))) {
      // only update counts if the target group is still the same as when we started
      return;
    }
    delivery.selectionAreas = selectionAreas;
    delivery.selectionAreasLoading = false;
    if (this.delivery && delivery.id === this.delivery.id) {
      // trigger actions
      this.setDelivery(toJS(delivery));
    }
    return delivery;
  }

  @action
  async previewDelivery(delivery: Delivery, params: PreviewParams) {
    const p: PreviewParams = {
      format: PreviewFormat.Image,
      ...params,
    };
    if (!p.format) {
      p.format = PreviewFormat.Image;
    }
    const payload = this.deliveryToDeliveryPayload(delivery);
    const result = await this.nextClient.previewDelivery(params, payload, { responseType: 'blob' });
    return result as AxiosResponse<Blob>;
  }

  @action
  async exportDeliveryReport(payload: DeliverySearchPayload) {
    const { data } = await this.nextClient.exportDeliveryReport({}, payload);
    return data;
  }

  deliveryToDeliveryPayload(delivery: Delivery): DeliveryPayload {
    return {
      ...delivery,
      deliveryFields: delivery.deliveryFields as any,
    };
  }
}
