/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
import { action } from 'mobx';
import type { Client } from 'types/next-api';

export default class InvoicesStore {
  client: Client;
  deliveryIdToInvoiceSum = new Map<string, number>();

  @action async getDeliveryInvoiceSum(deliveryId: string): Promise<void> {
    try {
      const { data } = await this.client.getDeliveryInvoiceSum({ deliveryId });
      this.deliveryIdToInvoiceSum.set(deliveryId, data.sum);
    } catch (err) {
      if (!err.message.includes('404')) {
        // HTTP 404 is expected as not all deliveries have invoices. The error doesn't contain error code,
        // which means string matching is required
        console.error('Failed to fetch invoice sum', err);
        throw err;
      }
    }
  }

  @action async getInvoices(): Promise<string> {
    try {
      const { data } = await this.client.exportInvoices(null, {});
      return data;
    } catch (err) {
      console.error('Failed to load invoices', err);
      throw err;
    }
  }

  @action async getInternalInvoices(): Promise<string> {
    try {
      const { data } = await this.client.exportInternalInvoices(null, {});
      return data;
    } catch (err) {
      console.error('Failed to load internal invoices', err);
      throw err;
    }
  }
}
