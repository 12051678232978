/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-misused-promises, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/unbound-method  */
import React from 'react';
import type { ContentBlock, ContentField, ContentBlockTemplate, DeliveryLanguage } from 'components/next/types';
import { FormInput, FormTextArea } from '../form/input';
import LanguageSelector from 'components/common/next/languageSelector';
import * as _ from 'lodash';
import { ReactComponent as IconUp } from '@kesko/icons/action/icon-push_up.svg';
import { ReactComponent as IconDown } from '@kesko/icons/action/icon-push_down.svg';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { storeData, targetData, mapHTMLContentToFields } from 'components/next/utils';
import './contentBlockEditor.scss';
import HelpButton from 'components/common/next/help/helpButton';
import Dropdown from '../dropdown';

interface ContentBlockEditorProps {
  contentBlock: ContentBlock;
  contentFields: ContentField[];
  contentBlockTemplates: ContentBlockTemplate[];
  index: number;
  languages: DeliveryLanguage[];
  update(c: ContentBlock): void;
  changeOrder(o: number, s: boolean): void;
  delete(id: string): void;
}

interface ContentBlockEditorState {
  fullEdit: boolean;
  contentFields: ContentField[];
  nonMatchingFields: string[];
  language: DeliveryLanguage;
}

export default class ContentBlockEditor extends React.Component<ContentBlockEditorProps, ContentBlockEditorState> {
  constructor(props: ContentBlockEditorProps) {
    super(props);
    this.handleHTMLChange = this.handleHTMLChange.bind(this);
    this.updateField = this.updateField.bind(this);
    this.delete = this.delete.bind(this);
    this.state = { fullEdit: false, contentFields: [], nonMatchingFields: [], language: 'fi' };
  }

  componentDidMount() {
    this.mapHTMLContentToFields(this.compileHTML(), false);
  }

  compileHTML = () => {
    const { contentBlock } = this.props;
    const { language } = this.state;
    return contentBlock.emailHtml[language].concat(contentBlock.printHtml[language]);
  };

  edit(fieldName: string, value: string) {
    const { update } = this.props;
    const { language } = this.state;
    const path = `${fieldName}.${language}`;
    const contentBlock = { ...this.props.contentBlock };

    if (this.getContent(fieldName)) {
      _.set(contentBlock, path, value);
    } else {
      _.set(contentBlock, fieldName, value);
    }

    update(contentBlock);
  }

  // eslint-disable-next-line
  async mapHTMLContentToFields(html: string, shouldCallUpdate: boolean) {
    const { contentFields, update } = this.props;
    const { matchingFields, nonMatchingFields } = mapHTMLContentToFields(html, contentFields);

    if (nonMatchingFields) {
      // eslint-disable-next-line
      await this.setState({ nonMatchingFields });
    }

    if (matchingFields) {
      // eslint-disable-next-line
      await this.setState({ contentFields: matchingFields });

      if (shouldCallUpdate) {
        const contentBlock = { ...this.props.contentBlock };
        update(
          _.set(
            contentBlock,
            'contentFieldIdentifiers',
            matchingFields.map((field) => field.identifier),
          ),
        );
      }
    }
  }

  handleHTMLChange(html: string, channel: string) {
    const contentBlock = { ...this.props.contentBlock };
    const { update } = this.props;
    const { language } = this.state;
    const fieldPath = channel === 'email' ? `emailHtml.${language}` : `printHtml.${language}`;
    update(_.set(contentBlock, fieldPath, html));
    this.mapHTMLContentToFields(html, true);
  }

  updateField(field: ContentField) {
    const { update, contentBlock } = this.props;
    const { contentFields } = this.state;
    const index = _.findIndex(contentFields, (c: ContentField) => c.identifier === field.identifier);
    contentFields[index] = field;
    this.setState({ contentFields }, () => update(_.set(contentBlock, 'contentFields', contentFields)));
  }

  delete() {
    const confirmed = window.confirm('Are you sure you want to delete this content block?');
    const { id } = this.props.contentBlock;
    if (confirmed) {
      this.props.delete(id);
    }
  }

  renderInstructions() {
    return (
      <div>
        <h3>Supported K Markkinointi tags:</h3>
        <p>The following tags are supported in content block HTML and populated by K Markkinointi:</p>
        <pre>{storeData}</pre>
        <h3>Supported Adobe Campaign tags:</h3>
        <p>The following tags are supported in content block HTML and populated by Adobe Campaign:</p>
        <pre>{targetData}</pre>
      </div>
    );
  }

  async overWriteBlockWithTemplate(templateId: string) {
    const confirmed = window.confirm("Using a template will overwrite this content block's data. Continue?");
    if (!confirmed) {
      return;
    }
    const { contentBlockTemplates, update } = this.props;
    const template = contentBlockTemplates.find((template) => template.id === templateId);
    const block = { ...this.props.contentBlock };
    if (template) {
      _.assign(block, _.pick(template, ['title', 'emailHtml', 'printHtml']));
    }
    // eslint-disable-next-line
    await update(block);
    this.mapHTMLContentToFields(this.compileHTML(), true);
  }

  getContent = (propertyName: string) => {
    const { contentBlock } = this.props;
    const { language } = this.state;
    return _.get(contentBlock, `${propertyName}.${language}`, null);
  };

  render() {
    const { contentBlock, index } = this.props;
    const { contentFields, nonMatchingFields, language } = this.state;
    const templates = this.props.contentBlockTemplates.map((template) => ({
      name: template.title.fi,
      value: template.id,
    }));

    return (
      <section className="editor-section content-block-editor">
        <header className="editor-section__header">
          <div className="title-row">
            <h3 className="section-title">{this.getContent('title')}</h3>
            <div className="title-row__buttons">
              <LanguageSelector
                language={language}
                languages={['fi', 'sv']}
                toggleLanguage={(language: DeliveryLanguage) => this.setState({ language })}
              />

              <Dropdown
                data={templates}
                select={(value) => this.overWriteBlockWithTemplate(value as string)}
                notSelectedText="Use template"
              />
              <button onClick={() => this.props.changeOrder(index, false)}>
                <IconDown />
              </button>
              <button onClick={() => this.props.changeOrder(index, true)}>
                <IconUp />
              </button>
              <button className="delete-button" onClick={() => this.delete()}>
                <IconDelete />
              </button>
            </div>
          </div>
        </header>
        <div className="content-block-editor__advanced">
          <FormInput
            label={`Title - ${language}`}
            value={this.getContent('title')}
            handleChange={(e) => this.edit(`title.${language}`, e.target.value)}
          />
          <div className="form-control">
            <HelpButton renderContent={this.renderInstructions} />
            <FormTextArea
              id={`${contentBlock.id}-html-email`}
              label={`Email HTML - ${language}`}
              value={this.getContent('emailHtml') || ''}
              handleChange={(e) => this.handleHTMLChange(e.target.value, 'email')}
            />
          </div>
          <div className="form-control">
            <HelpButton renderContent={this.renderInstructions} />
            <FormTextArea
              id={`${contentBlock.id}-html-print`}
              label={`Print HTML - ${language}`}
              value={this.getContent('printHtml') || ''}
              handleChange={(e) => this.handleHTMLChange(e.target.value, 'print')}
            />
          </div>
          {nonMatchingFields.length > 0 && (
            <div className="missing-fields">
              <span>No content fields found for identifier(s)</span>
              <b>{nonMatchingFields.join(', ')}</b>
            </div>
          )}
        </div>
        {contentFields.length > 0 && (
          <div className="existing-content-fields">
            <h3>Block Content Fields</h3>
            {contentFields.map((c, i) => (
              <div className="block-content-field" key={i}>
                <h4>{c.title[language]}</h4>
                <span>Type: {c.type}</span>
                <span>Identifier: {c.identifier}</span>
                {c.defaultValue && <span>Default value: {JSON.stringify(c.defaultValue[language], null, 2)}</span>}
                {c.required && <span>Required</span>}
                {c.readOnly && <span>Read only</span>}
              </div>
            ))}
          </div>
        )}
      </section>
    );
  }
}
