/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires, 	@typescript-eslint/no-misused-promises    */
import React, { useEffect, useState } from 'react';
import page from 'components/next/pages/page/page';
import { NavLink, Link, Route, Routes, useParams, useBlocker } from 'react-router-dom';
import { FormInput, CustomField } from 'components/next/components/form/input';
import { inject, observer } from 'mobx-react';
import './dimension.scss';
import { settings } from 'components/next/routes';
import ChainSelector from 'components/next/components/chainSelector';
import type { Dimension, DimensionOption } from 'components/next/types';
import * as _ from 'lodash';
import { ReactComponent as SurprisedIcon } from '@kesko/icons/mood/icon-mood_surprise.svg';
import { ReactComponent as HappyIcon } from '@kesko/icons/mood/icon-mood_happy.svg';
import { ReactComponent as UnhappyIcon } from '@kesko/icons/mood/icon-mood_unhappy.svg';
import { ReactComponent as CopyIcon } from '@kesko/icons/action/icon-copy.svg';
import { ReactComponent as IconArrowRight } from '@kesko/icons/nav/icon-arrow-right.svg';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import DimensionsStore from '../../../../stores/next/dimensions';
import DimensionOptionPage from '../dimensionOptions/index';
import { Chain, StoreState } from 'enums/common';
import { getAllowedChains, getExcludedChains } from 'utils/helpers';

const promptMessage =
  'Some changes you made to this concept have not been saved. If you move away from this page, these changes will be lost. Are you sure you want to continue?';

interface DimensionProps {
  dimensionsStore?: DimensionsStore;
}

type DimensionParams = {
  id: string;
  chain: Chain;
};

function onUnload(e) {
  // NOTE: many modern browsers DO NOT support custom refresh messages...
  e.preventDefault();
  e.returnValue = promptMessage;
}

const DimensionPage = ({ dimensionsStore }: DimensionProps) => {
  const { id: idParam, chain: chainParam } = useParams<DimensionParams>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editedDimension, setEditedDimension] = useState<Dimension>(undefined);
  const [editedDimensionOption, setEditedDimensionOption] = useState<DimensionOption>(undefined);

  // Block routing when there's unsaved changes
  const blocker = useBlocker(unsavedChanges);
  React.useEffect(() => {
    // unload function is for page refresh / back button
    // blocker is for routing inside react router
    if (unsavedChanges) {
      window.addEventListener('beforeunload', onUnload);
    } else {
      window.removeEventListener('beforeunload', onUnload);
      if (blocker.state === 'blocked') {
        blocker.reset();
      }
    }
  }, [blocker, unsavedChanges]);

  useEffect(() => {
    const init = async () => {
      const isB2B = chainParam === Chain.kRauta || chainParam === Chain.onninen;
      if (isB2B) {
        // Check for B2B dimension
        if (dimensionsStore.b2bDimensions.length === 0) {
          await dimensionsStore.getB2BDimensionsByChain(getAllowedChains(chainParam));
        }
        const b2bDimension = dimensionsStore.b2bDimensions.find((d) => String(d.id) === idParam);
        if (b2bDimension) {
          await dimensionsStore.getDimensionOptionInUse(b2bDimension);
          setEditedDimension({ ...b2bDimension });
        }
      }

      const isB2C = chainParam === Chain.kRuoka || chainParam === Chain.kRauta;
      if (isB2C) {
        if (dimensionsStore.dimensions.length === 0) {
          // this happens when user refreshes screen while browsing some dimension
          await dimensionsStore.getDimensionsByChain(getAllowedChains(chainParam));
        }
        const dimension = dimensionsStore.dimensions.find((d) => String(d.id) === idParam);

        if (dimension) {
          await dimensionsStore.getDimensionOptionInUse(dimension);
          setEditedDimension({ ...dimension });
        }
      }
    };
    init();

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  const updateDimension = (updatedDimension: Dimension) => {
    setEditedDimension(updatedDimension);
    setUnsavedChanges(true);
  };

  const updateDimensionOption = (updatedDimensionOption: DimensionOption) => {
    if (updatedDimensionOption) {
      setEditedDimensionOption(updatedDimensionOption);
    } else {
      setEditedDimensionOption(undefined);
    }
  };

  const createDimensionOption = async (templateDimensionOption: DimensionOption = undefined) => {
    const newEditedDimension = { ...editedDimension };
    const newOption: DimensionOption = templateDimensionOption
      ? templateDimensionOption
      : {
          name: 'newOption' + Math.round(Math.random() * 1000),
          title: 'New option',
          condition: 'Add condition!',
          exclusive: false,
        };
    newEditedDimension.options = [...newEditedDimension.options, newOption];
    const updatedDimension = await dimensionsStore.updateDimension(newEditedDimension);
    await dimensionsStore.getDimensionOptionInUse(updatedDimension);
    setUnsavedChanges(false);
    setEditedDimension(updatedDimension);
  };

  const copyDimensionOption = (dimensionOption: DimensionOption) => {
    const copyDimensionOption = { ...dimensionOption };
    copyDimensionOption.name = copyDimensionOption.name + 'Copy';
    copyDimensionOption.title = copyDimensionOption.title + ' Copy';
    copyDimensionOption.id = undefined;
    createDimensionOption(copyDimensionOption);
  };

  const deleteDimensionOption = async (dimensionOption: DimensionOption) => {
    const newEditedDimension = { ...editedDimension };
    newEditedDimension.options = newEditedDimension.options.filter((dimOpt) => dimOpt.id !== dimensionOption.id);
    const updatedDimension = await dimensionsStore.updateDimension(newEditedDimension);
    setUnsavedChanges(false);
    setEditedDimension(updatedDimension);
  };

  const handleSave = async () => {
    const dimensionId = editedDimension.id;
    const newEditedDimension = { ...editedDimension };

    if (editedDimensionOption) {
      const dimOpts = newEditedDimension.options.filter((o) => o.id !== editedDimensionOption.id);
      dimOpts.push(editedDimensionOption);
      newEditedDimension.options = dimOpts;
    }
    await dimensionsStore.updateDimension(newEditedDimension);

    // If dimensionId is not found from dimensionsStore.dimensions, try looking for a B2B dimension instead
    const dimension = dimensionsStore.dimensions.find((d) => d.id === dimensionId);
    const refreshedDimension = dimension || dimensionsStore.b2bDimensions.find((d) => d.id === dimensionId);
    setUnsavedChanges(false);
    setEditedDimension(refreshedDimension);
    setEditedDimensionOption(undefined);
  };

  const handleChainChange = (key: string) => {
    const dimension = editedDimension;
    const chainIds = [...dimension.chainIds];
    if (chainIds.includes(key) && chainIds.length === 1) {
      // do nothing, there has to be at least one chain selected
      return;
    } else if (chainIds.includes(key)) {
      _.pullAt(chainIds, chainIds.indexOf(key));
    } else {
      chainIds.push(key);
    }
    updateDimension({ ...dimension, chainIds });
  };

  const isDimensionOptionInUse = (name: string) => {
    const retVal = dimensionsStore.dimensionOptionInUse.some(
      (opt) => opt.dimensionOption.name === name && opt.inUse === true,
    );
    return retVal;
  };

  const renderEditorStatus = () => {
    const unsaved = unsavedChanges || editedDimensionOption;
    let message = 'All changes saved!';
    let icon = <HappyIcon />;
    let statusClass = 'ok';

    if (unsaved) {
      message = 'There are unsaved changes!';
      statusClass = 'warning';
      icon = <SurprisedIcon />;
    }

    if (dimensionsStore.status === StoreState.error) {
      message = 'Error saving changes';
      statusClass = 'error';
      icon = <UnhappyIcon />;
    }

    return (
      <div className={`editor-status ${statusClass}`}>
        <span>
          {message} {icon}
        </span>
      </div>
    );
  };

  const renderHeader = () => {
    if (!editedDimension) {
      return null;
    }
    return (
      <header className="editor-header">
        <div className="editor-header__controls">
          <NavLink className="go-back" to={settings.link.replace(/:chain/, chainParam)}>
            <img src={require('images/arrow-back.svg').default} alt="go-back" />
          </NavLink>
          <div className="editor-header__details">
            <h3>{editedDimension.title}</h3>
            <h4>
              <span>ID: {editedDimension.id}</span>
              <span>Name: {editedDimension.name}</span>
            </h4>
          </div>
          <div className="editor-header__actions">
            {renderEditorStatus()}
            <button className="save" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
        <div className="editor-header__details" />
      </header>
    );
  };

  const renderDimensionOptionTable = () => {
    const dimensionOptions =
      editedDimension.options && dimensionsStore.dimensionOptionInUse.length !== 0 ? editedDimension.options : [];
    return (
      <table className="styled editor-grid">
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>Order</th>
            <th>Exclusive</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {_.orderBy(dimensionOptions, 'order').map((dimOpt, i) => (
            <tr key={i}>
              <td>{dimOpt.name}</td>
              <td>{dimOpt.title}</td>
              <td>{dimOpt.order}</td>
              <td>{dimOpt.exclusive ? 'True' : 'False'}</td>
              <td>
                <div>
                  {!isDimensionOptionInUse(dimOpt.name) && (
                    <button onClick={() => deleteDimensionOption(dimOpt)}>
                      <IconDelete />
                    </button>
                  )}
                  <button onClick={() => copyDimensionOption(dimOpt)} title="Duplicate dimension option">
                    <CopyIcon />
                  </button>
                  <Link to={`dimension-options/${dimOpt.id}`} title="View dimension options">
                    <IconArrowRight />
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderDimensionContent = () => {
    const dimension = editedDimension;
    const chainIds = dimension.chainIds;
    const excludedChains = getExcludedChains(getAllowedChains(chainParam));
    return (
      <div className="content">
        <FormInput
          type="text"
          label="Name"
          value={dimension.name || ''}
          required={true}
          readonly={dimension.options.length > 0}
          handleChange={(e) => updateDimension({ ...dimension, name: e.target.value })}
        />
        <FormInput
          type="text"
          label="Title"
          value={dimension.title || ''}
          required={true}
          handleChange={(e) => updateDimension({ ...dimension, title: e.target.value })}
        />
        <FormInput
          type="number"
          label="Order"
          value={dimension.order || 0}
          min={0}
          handleChange={(e) => updateDimension({ ...dimension, order: Number(e.target.value) })}
        />
        <CustomField>
          <ChainSelector
            chainSelection={chainIds || []}
            excludeChains={excludedChains}
            handleChainChange={handleChainChange}
            label="Chain(s)"
            detail="Pick chains for deliveries"
          />
        </CustomField>
        <div className="dimension-options">
          <button className="add-dimension-option" onClick={() => createDimensionOption()}>
            <span>Add dimension option</span>
            <img src={require('images/add.svg').default} alt="add" />
          </button>
        </div>
        {renderDimensionOptionTable()}
      </div>
    );
  };

  if (!editedDimension) {
    return null;
  }

  const renderConfirmation = () => {
    if (blocker) {
      if (blocker.state === 'blocked') {
        const confirmed = window.confirm(promptMessage);
        if (confirmed) {
          blocker.proceed?.();
        } else {
          blocker.reset?.();
        }
      }
    }
  };

  return (
    <>
      {renderConfirmation()}
      <div className="dimension">
        {renderHeader()}
        <Routes>
          <Route
            path="dimension-options/:oid"
            element={
              <DimensionOptionPage unsavedChanges={!!editedDimensionOption} informParentPage={updateDimensionOption} />
            }
          />
          <Route path="*" element={renderDimensionContent()} />
        </Routes>
      </div>
    </>
  );
};

export default page(inject('dimensionsStore')(observer(DimensionPage)));
