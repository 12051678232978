/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/unbound-method, @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-member-access   */
import React from 'react';
import type { ContentBlock, ContentField, ContentBlockTemplate, DeliveryTemplate } from 'components/next/types';
import ContentBlockEditor from 'components/next/components/contentBlockEditor';
import { getContentBlockDefaults, changeOrder } from 'components/next/utils';
import * as _ from 'lodash';

export interface ContentBlocksProps {
  deliveryTemplate: DeliveryTemplate;
  contentFields: ContentField[];
  contentBlocks: ContentBlock[];
  contentBlockTemplates: ContentBlockTemplate[];
  update(c: ContentBlock[]): void;
}

interface ContentBlocksState {
  contentBlocks: ContentBlock[];
  contentFields: ContentField[];
}

export default class ContentBlocks extends React.Component<ContentBlocksProps, ContentBlocksState> {
  constructor(props: ContentBlocksProps) {
    super(props);
    const { contentBlocks } = this.props;
    this.state = { contentBlocks, contentFields: [] };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.add = this.add.bind(this);
    this.deleteContentBlock = this.deleteContentBlock.bind(this);
    this.changeOrder = this.changeOrder.bind(this);
  }

  add() {
    const newBlock = getContentBlockDefaults(null, this.state.contentBlocks.length);
    const contentBlocks = [...this.state.contentBlocks, newBlock];
    this.setState({ contentBlocks }, () => this.props.update(this.state.contentBlocks));
  }

  handleUpdate(block: ContentBlock) {
    const { update } = this.props;
    const contentBlocks = [...this.state.contentBlocks];
    const existingBlock = contentBlocks.find((b) => b.id === block.id);
    const i = _.findIndex(contentBlocks, existingBlock);
    if (i === -1) {
      contentBlocks.push(block);
    } else {
      contentBlocks[i] = block;
    }
    this.setState({ contentBlocks }, () => update(this.state.contentBlocks));
  }

  deleteContentBlock(id: string) {
    const contentBlock = _.find(this.props.contentBlocks, { id });
    if (contentBlock) {
      const contentBlocks = [...this.state.contentBlocks];
      _.pullAt(contentBlocks, _.findIndex(contentBlocks, contentBlock));
      this.setState({ contentBlocks }, () => this.props.update(this.state.contentBlocks));
    } else {
      console.error(`Content block ${id} not found!`);
    }
  }

  changeOrder(index: number, shiftUp: boolean) {
    const { contentBlocks, update } = this.props;
    const result = changeOrder(contentBlocks, index, shiftUp);
    update(result);
  }

  render() {
    const { contentBlocks } = this.state;
    const { contentFields, contentBlockTemplates, deliveryTemplate } = this.props;
    return (
      <div className="editor-tab content-blocks">
        <button className="add-content-block" onClick={this.add}>
          Add content block
          <img src={require('images/add.svg').default} alt="add" />
        </button>
        {contentBlocks.length > 0 && (
          <div className="content-block-editors">
            {_.orderBy(contentBlocks, ['order']).map((c, i) => (
              <ContentBlockEditor
                key={i}
                contentBlock={c}
                contentFields={contentFields}
                languages={deliveryTemplate.languages}
                index={i}
                changeOrder={this.changeOrder}
                update={(c) => this.handleUpdate(c)}
                delete={this.deleteContentBlock}
                contentBlockTemplates={contentBlockTemplates}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}
