/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import './button.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
  link?: string;
  state?: Record<string, any>;
  onClick?: (e?: React.SyntheticEvent) => any;
  children?: string | React.ReactText[] | JSX.Element;
  color?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  className?: string;
  title?: string;
}

const Button = ({ link, state, onClick, children, color, size, disabled, className, title }: Props) => {
  const navigate = useNavigate();

  const handleClick = (e: React.SyntheticEvent) => {
    if (!disabled) {
      if (link) {
        navigate(link, { state });
      } else {
        onClick(e);
      }
    }
  };

  const classes = ['btn', `btn-${color || 'default'}`, `btn-${size || 'medium'}`];
  if (disabled) {
    classes.push('btn-disabled');
  }
  if (className) {
    classes.push(className);
  }
  return (
    <div className={classes.join(' ')} onClick={handleClick} title={title}>
      {children}
    </div>
  );
};

export default Button;
