/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
import type { Client } from 'types/next-api';
import AlertStore from 'stores/next/alerts';

export enum ReportType {
  OrderAmountEstimates = 'orderAmountEstimates',
  OrderDeliveryReport = 'orderDeliveryReport',
}

export type DeliveryTemplateOffer = {
  title: string;
  deliveryTemplate: string;
};
export default class ReportsStore {
  public client: Client;
  stores: {
    alertStore: AlertStore;
  };

  async exportOrderAmountEstimateReport(
    concepts: string[],
    deliveryTemplates: string[],
    selectedOffers: string[],
    recipientEmail: string,
  ) {
    try {
      await this.client.exportOrderAmountEstimateReport(null, {
        reportType: 'orderAmountEstimates',
        conceptIds: [concepts[0], ...concepts.slice(1)],
        deliveryTemplateIds: [deliveryTemplates[0], ...deliveryTemplates.slice(1)],
        offers: [selectedOffers[0], ...selectedOffers.slice(1)],
        recipientEmail,
      });
      this.stores.alertStore.success({ message: 'The report has been sent to your email.' });
      return true;
    } catch (err) {
      let message = err;
      if (err?.response?.data) {
        message = err.response.data.message;
        if (err.response.data.statusCode === 503) {
          // Request time out, report is still being processed and sent to recipients email in the server
          this.stores.alertStore.success({
            message: 'The report will be sent to your email once it has been processed.',
          });
          return true;
        }
      }
      this.stores.alertStore.error({ message });
      throw err;
    }
  }
}
