/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-floating-promises  */
import React, { useEffect } from 'react';
import Button from '../../../../common/next/form/button';
import { getLink } from '../../routes';
import { inject, observer } from 'mobx-react';
import TemplateStore from 'stores/next-retailer/templateStore';
import * as date from 'date-fns';
import Spinner from 'components/common/next/spinner';
import './deliveryBox.scss';
import DeliveryStore, { DeliveryWithMeta } from 'stores/next-retailer/deliveryStore';
import ResultsStore from 'stores/next-retailer/resultsStore';
import { ReactComponent as IconSend } from '@kesko/icons/action/icon-msg-send.svg';
import { castDate } from 'utils/helpers';
import { Link, useNavigate } from 'react-router-dom';
import { getPrintRecipientCount } from '../../utils/helpers';
import { ConceptType, DeliveryState, DeliveryStatus, DeliveryTemplateStatus } from 'enums/common';

interface Props {
  delivery: DeliveryWithMeta;
  conceptType?: ConceptType;
  isParticipatingAutomatically: boolean;
  isHalvePrintRecipientCount: boolean;
}

interface InjectedProps extends Props {
  templateStore?: TemplateStore;
  deliveryStore?: DeliveryStore;
  resultsStore?: ResultsStore;
}

const DeliveryBox = ({
  templateStore,
  deliveryStore,
  resultsStore,
  delivery,
  conceptType,
  isParticipatingAutomatically,
  isHalvePrintRecipientCount,
}: InjectedProps) => {
  const navigate = useNavigate();

  const getTemplate = () => {
    return templateStore.getCollectionItem(delivery.deliveryTemplate);
  };

  const isDraft = () => {
    return delivery.state === DeliveryState.Draft;
  };

  const isEditable = () => {
    return (
      delivery.status === DeliveryStatus.Editable || (isDraft() && getTemplate().status === DeliveryTemplateStatus.Open)
    );
  };

  const getResults = () => {
    return resultsStore.getDeliveryResults(delivery.id);
  };

  useEffect(() => {
    const results = getResults();
    if (!results) {
      return;
    }
    if (
      (!results.emailDeliveryResultsAvailable || !results.printDeliveryResultsAvailable) &&
      !delivery.selectionCounts &&
      delivery.selectionCountsLoading === undefined
    ) {
      deliveryStore.getSelectionCountsForDelivery(delivery);
    }
    if (!getTemplate()) {
      templateStore.getDeliveryTemplate(delivery.deliveryTemplate);
    }
  }, []);

  const editDelivery = () => {
    navigate(getLink('editDelivery', delivery.id, delivery.deliveryTemplate));
  };

  const viewDelivery = () => {
    navigate(getLink('delivery', delivery.id));
  };

  const render = () => {
    const template = getTemplate();
    if (!template) {
      return <div className="delivery-box box loading" />;
    }
    const title = template.title.fi;
    const channelNames = [];
    if (delivery.targetGroup.channels.email) {
      channelNames.push('Sähköposti');
    }
    if (delivery.targetGroup.channels.print) {
      channelNames.push('Printti');
    }

    // Delivery deadline is until the end of day.
    // If the delivery has a deadline and it is sooner than the template lastEditDate,
    // we show the deadline instead of the lastEditDate
    const daysUntilEditEnd =
      !template.lastEditDate || (delivery.deadline && delivery.deadline < template.lastEditDate)
        ? date.differenceInDays(castDate(delivery.deadline), date.startOfToday()) + 1
        : date.differenceInDays(castDate(template.lastEditDate), date.startOfToday()) + 1;

    const daysUntilEditStart = template.firstEditDate
      ? date.differenceInDays(castDate(template.firstEditDate), date.startOfToday()) + 1
      : date.differenceInDays(castDate(template.firstStartDate), date.startOfToday()) + 1;

    const daysUntilStartDate = date.differenceInDays(castDate(delivery.startDate), date.startOfToday()) + 1;

    const classes = ['delivery-box', 'box'];

    let action = viewDelivery;
    let actionButton = (
      <Button onClick={action} color="bordered">
        Tulokset
      </Button>
    );

    if (delivery.status === DeliveryStatus.Editable) {
      action = editDelivery;
      actionButton = (
        <Button onClick={action} color="bordered">
          {isParticipatingAutomatically ? 'Muokattu' : 'Muokkaa'}
        </Button>
      );
    }
    if (
      delivery.status === DeliveryStatus.Processed ||
      (delivery.state === DeliveryState.Draft && delivery.status !== DeliveryStatus.Editable)
    ) {
      action = viewDelivery;
      actionButton = (
        <Button onClick={action} color="bordered">
          Tarkastele
        </Button>
      );
    }
    if (isDraft() && delivery.status === DeliveryStatus.Editable) {
      action = editDelivery;
      actionButton = (
        <Button onClick={action} color="bordered">
          {isParticipatingAutomatically ? 'Muokattu' : 'Muokkaa'}
        </Button>
      );
    }
    const results = getResults();
    const deliveryDescription = delivery.description ? ': ' + delivery.description : '';
    const deliveryTitle = `${title}${deliveryDescription} ${isDraft() ? '(luonnos)' : ''}`;
    const deliveryOffers = delivery.deliveryOffers ? delivery.deliveryOffers : [];
    return (
      <div className={classes.join(' ')}>
        <div className="delivery-header">
          <div className="icon">
            <IconSend />
          </div>
          <div className="basic-info">
            <h3 onClick={isDraft() ? null : viewDelivery} className={isDraft() ? '' : 'clickable'}>
              {deliveryTitle}
            </h3>
            <ul className="text-small">
              <li>
                Asiakkailla: <strong>{date.format(castDate(castDate(delivery.startDate)), 'd.M.yyyy')}</strong>
              </li>
              <li>{channelNames.sort().join(', ')}</li>
            </ul>
          </div>
        </div>
        {conceptType === ConceptType.Program && deliveryOffers.length > 0 && (
          <div className="delivery-offers">
            <div className="offer-container">
              Valittu etu
              <br />
              <strong>
                <Link to={getLink('editDelivery', delivery.id, template.id)}>
                  {deliveryOffers[0].title.fi}
                  {deliveryOffers.length > 1 && ` ja ${deliveryOffers.length - 1} muuta`}
                </Link>
              </strong>
            </div>
          </div>
        )}
        <React.Fragment>
          <div className="target-group">
            {!(
              delivery.selectionCounts ||
              results.emailDeliveryResultsAvailable ||
              results.printDeliveryResultsAvailable
            ) && (
              <div>
                <Spinner />
              </div>
            )}
            {(delivery.selectionCounts || results.emailDeliveryResultsAvailable) &&
              delivery.targetGroup.channels.email !== 0 && (
                <div className="channel channel-email">
                  <span className="hl">
                    {results.emailDeliveryResultsAvailable && results.emailResults
                      ? results.emailResults.totalRecipients
                      : delivery.selectionCounts.channels.email}
                  </span>
                  <br />
                  sähköpostia
                </div>
              )}
            {(delivery.selectionCounts || results.printDeliveryResultsAvailable) &&
              delivery.targetGroup.channels.print !== 0 && (
                <div className="channel channel-print">
                  <span className="hl">
                    {results.printDeliveryResultsAvailable && results.printResults
                      ? results.printResults.totalRecipients
                      : getPrintRecipientCount({
                          restrictedCount: delivery.targetGroup.channels.print,
                          maxCount: delivery.selectionCounts.channels.print,
                          isHalvePrintRecipientCount: isHalvePrintRecipientCount,
                        })}
                  </span>
                  <br />
                  printtiä
                </div>
              )}
          </div>
          <div className="delivery-status">
            {delivery.status === DeliveryStatus.Editable && daysUntilEditStart > 0 && (
              <div className="">
                <span className="hl">{daysUntilEditStart > 1 ? daysUntilEditStart : '<1'} päivää</span>
                <br />
                muokkauksen avautumiseen
              </div>
            )}
            {delivery.status === DeliveryStatus.Editable && daysUntilEditStart <= 0 && (
              <div className="">
                <span className="hl">{daysUntilEditEnd > 1 ? daysUntilEditEnd : '<1'} päivää</span>
                <br />
                aikaa muokata
              </div>
            )}
            {!isDraft() && delivery.status === DeliveryStatus.Processed && (
              <div className="">
                <span className="hl">{daysUntilStartDate || '<1'} päivää</span>
                <br />
                aikaa lähetykseen
              </div>
            )}
            {!isDraft() && delivery.status === DeliveryStatus.Finished && <div className="">lähetys ohi</div>}
          </div>
          <div className="actions text-right">{actionButton}</div>
        </React.Fragment>
      </div>
    );
  };

  return render();
};

export default inject('templateStore', 'deliveryStore', 'resultsStore')(observer(DeliveryBox));
