/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-floating-promises, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-return, 	@typescript-eslint/no-unsafe-assignment, 	@typescript-eslint/no-var-requires, @typescript-eslint/no-misused-promises, react/no-unescaped-entities  */
import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { CustomField } from 'components/next/components/form/input';
import './pricingGroupSelector.scss';
import { useCombobox } from 'downshift';
import type { DeliveryTemplate, PricingGroup } from 'components/next/types';
import { ChainAbbreviations } from 'constants/common';
import * as dateFns from 'date-fns';
import { inject, observer } from 'mobx-react';
import ConceptsStore from 'stores/next/programs';
import PricingGroupStore from 'stores/next/pricingGroup';
import DeliveryTemplateStore from 'stores/next/deliveryTemplates';

interface Props {
  deliveryTemplate: DeliveryTemplate;
  label?: string;
  placeholder?: string;
  changePricingGroup: (pricingGroupId: string) => Promise<void>;
  conceptsStore?: ConceptsStore;
  pricingGroupStore?: PricingGroupStore;
  deliveryTemplateStore?: DeliveryTemplateStore;
}

const PricingGroupSelector = ({
  label,
  deliveryTemplate,
  changePricingGroup,
  placeholder,
  conceptsStore,
  deliveryTemplateStore,
  pricingGroupStore,
}: Props) => {
  const pricingGroups: PricingGroup[] = pricingGroupStore.pricingGroups;
  const pricingGroup = _.find(pricingGroups, (pricingGroup) => pricingGroup.id === deliveryTemplate.pricingGroup);

  const [filteredPricingGroups, setFilteredPricingGroups] = useState<PricingGroup[]>([]);

  const getFilteredPricingGroups = (pricingGroupFilter) => {
    const possibleGroups = pricingGroups
      .slice()
      // only those pricing groups backend has decided to be selectable
      .filter((pricingGroup) => pricingGroup.selectable)
      // only those pricing groups that are not used yet or are used on templates with same last edit date
      .filter(
        (pricingGroup) =>
          !pricingGroup.templateLastEditDate ||
          dateFns.isEqual(
            dateFns.startOfDay(new Date(pricingGroup.templateLastEditDate)),
            dateFns.startOfDay(new Date(deliveryTemplate.lastEditDate)),
          ),
      );
    return pricingGroupFilter !== ''
      ? possibleGroups.filter((pricingGroup) =>
          pricingGroup.name.toLowerCase().includes(pricingGroupFilter.toLowerCase()),
        )
      : possibleGroups.slice();
  };

  useEffect(() => {
    setFilteredPricingGroups(getFilteredPricingGroups(inputValue));
  }, [pricingGroups]);

  useEffect(() => {
    fetchRelatedDeliveryTemplates();
  }, [pricingGroup]);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    selectItem,
    setInputValue,
  } = useCombobox({
    items: filteredPricingGroups,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        // If an item was selected, empty the input value
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return {
            ...changes,
            inputValue: '',
          };
        default:
          return changes;
      }
    },
    onInputValueChange: ({ inputValue }) => {
      setFilteredPricingGroups(getFilteredPricingGroups(inputValue));
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        changePricingGroup(selectedItem.id);
      }
    },
  });

  const fetchRelatedConcepts = async () => {
    if (pricingGroup) {
      const conceptIds = deliveryTemplateStore.collection
        .filter((x) => pricingGroup.deliveryTemplates.includes(x.id))
        .map((x) => x.concept);
      const missing = _.difference(
        conceptIds,
        conceptsStore.concepts.map((x) => x.id),
      );
      if (missing.length > 0) {
        await conceptsStore.search({ id: [missing[0], ...missing.slice(1)] });
      }
    }
  };

  const fetchRelatedDeliveryTemplates = async () => {
    if (pricingGroup) {
      const missing = _.difference(
        pricingGroup.deliveryTemplates,
        deliveryTemplateStore.collection.map((x) => x.id),
      );
      if (missing.length > 0) {
        await deliveryTemplateStore.search({ id: [missing[0], ...missing.slice(1)] });
      }
      await fetchRelatedConcepts();
    }
  };

  const isNewPricingGroupName =
    inputValue &&
    !pricingGroups.reduce(
      (pricingGroupFound, pricingGroup) =>
        pricingGroupFound || pricingGroup.name.toLowerCase().trim() === inputValue.toLocaleLowerCase().trim(),
      false,
    );

  const handleRemovePricingGroup = () => {
    changePricingGroup(null);
    selectItem(null);
  };

  const handleCreatePricingGroup = async () => {
    const newPricingGroup = {
      name: inputValue,
    };
    const savedPricingGroup = await pricingGroupStore.createPricingGroup(newPricingGroup);
    await changePricingGroup(savedPricingGroup.id);
    fetchRelatedDeliveryTemplates();
  };

  const renderPricingGroupDeliveryTemplates = () => {
    if (pricingGroup) {
      const deliveryTemplateIds = pricingGroup.deliveryTemplates;
      const pricingGroupDeliveryTemplates = deliveryTemplateStore.collection.filter((deliveryTemplate) =>
        deliveryTemplateIds.includes(deliveryTemplate.id),
      );
      const conceptIds = pricingGroupDeliveryTemplates.map((deliveryTemplate) => deliveryTemplate.concept);
      const chainIds = conceptsStore.concepts
        .filter((concept) => conceptIds.includes(concept.id))
        .reduce(
          (acc, val) => {
            return { ...acc, [val.id]: val.chainIds.map((id) => ChainAbbreviations[id]) };
          },
          {} as { [key: string]: string[] },
        );
      return (
        <div className="pricing-group-related">
          Delivery templates in this group:
          {pricingGroupDeliveryTemplates.map((deliveryTemplate) => (
            <div key={deliveryTemplate.id}>
              - [{chainIds[deliveryTemplate.concept]}] {deliveryTemplate.title.fi}
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <CustomField additionalClasses={'pricing-group-selector'}>
      <label {...getLabelProps()}>{label || 'Pricing group'}</label>
      <div
        className={`pricing-group-selector__wrapper${
          deliveryTemplate.pricingGroup ? ' pricing-group-selector__wrapper--hidden' : ''
        }`}
      >
        <div>
          <input {...getInputProps({ placeholder: placeholder || 'Select pricing group' })} />
        </div>
        <ul
          {...getMenuProps({
            className: `pricing-group-selector__list${
              !(isOpen && filteredPricingGroups.length) ? ' pricing-group-selector__list--hidden' : ''
            }`,
          })}
        >
          {isOpen &&
            filteredPricingGroups.map((pricingGroup, index) => (
              <li
                key={`${pricingGroup.id}${index}`}
                {...getItemProps({
                  item: pricingGroup,
                  index,
                  className: `pricing-group-selector__pricing-group${
                    highlightedIndex === index ? ' pricing-group-selector__pricing-group--highlight' : ''
                  }`,
                })}
              >
                {pricingGroup.name}{' '}
                {pricingGroup.templateLastEditDate
                  ? '(' + dateFns.format(new Date(pricingGroup.templateLastEditDate), 'dd-MM-yyyy') + ')'
                  : ''}
              </li>
            ))}
        </ul>
      </div>
      {!deliveryTemplate.pricingGroup && (
        <span className="error-text">This is a required field, it should not be left blank</span>
      )}
      {!!deliveryTemplate.pricingGroup && (
        <div className="pricing-group-selector__selected">
          {pricingGroup.name}
          <button className="pricing-group-selector__delete" onClick={handleRemovePricingGroup}>
            <img src={require('images/cross.svg').default} alt="delete" />
          </button>
        </div>
      )}
      {!!deliveryTemplate.pricingGroup && renderPricingGroupDeliveryTemplates()}
      <span className="detail-text">
        The postage and print costs of deliveries in same pricing group is calculated based on the combined amount of
        recipients belonging to the deliveries in the pricing group
      </span>
      {isNewPricingGroupName && (
        <button className="pricing-group-selector__create" onClick={handleCreatePricingGroup}>
          Create pricing group "{inputValue}"
        </button>
      )}
    </CustomField>
  );
};

export default inject('conceptsStore', 'pricingGroupStore', 'deliveryTemplateStore')(observer(PricingGroupSelector));
