import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import UserStore from 'stores/userStore';
import { useLocation } from 'react-router-dom';
/* eslint-disable @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument */
interface Props {
  userStore?: UserStore;
}

const Lander = ({ userStore }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const login = () => {
    const from = location.state && location.state.from;
    setIsLoading(true);
    userStore.login(from);
  };

  const banner = process.env.REACT_APP_RETAILER_BANNER;

  return (
    <div className="lander app">
      {banner && <div className="alert-banner">{banner}</div>}
      <div className="lander-container">
        <div className="lander-content">
          <div className="lander-box">
            <h1>Tervetuloa K&nbsp;Markkinointiin!</h1>
            <p>K Markkinointi on tarkoitettu kaikkien K-ryhmän ansiokkaiden kauppiaiden käyttöön.</p>
            <button
              onClick={login}
              disabled={isLoading}
              className={`button button-${isLoading ? 'disabled button-loading' : 'default'} login-button`}
            >
              {isLoading ? 'Odota hetkinen...' : 'Kirjaudu sisään'}
            </button>
          </div>
          <div className="disclaimer">
            Verkkopalvelussamme käytetään evästeitä käyttäjäkokemuksen parantamiseen. Käyttämällä palvelua hyväksyt
            evästeiden käytön.
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject('userStore')(observer(Lander));
