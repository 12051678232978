/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-floating-promises   */
import React, { useEffect } from 'react';
import _ from 'utils/lodash';
import page from 'components/next/pages/page/page';
import DeliveriesStore from 'stores/next/deliveries';
import TemplatesStore from 'stores/next/deliveryTemplates';
import { inject, observer } from 'mobx-react';
import type { Delivery, Store } from 'types/next';
import StoreStore from 'stores/next/stores';
import * as YAML from 'js-yaml';
import './delivery.scss';
import { useParams } from 'react-router-dom';

interface DeliveryProps {
  deliveriesStore?: DeliveriesStore;
  deliveryTemplateStore?: TemplatesStore;
  storeStore?: StoreStore;
}

const DeliveryView = ({ deliveriesStore, deliveryTemplateStore, storeStore }: DeliveryProps) => {
  const { id: idParam } = useParams();

  const getDelivery = () => {
    return _.find(deliveriesStore.deliveries, { id: idParam }) as Delivery;
  };

  const getTemplate = () => {
    const delivery = getDelivery();
    return delivery && delivery.targetGroup.channels.mobile === -1
      ? { title: { fi: delivery.description } }
      : _.find(deliveryTemplateStore.templates, { id: delivery.deliveryTemplate });
  };

  const getStore = () => {
    const delivery = getDelivery();
    return delivery && (_.find(storeStore.stores, { storeId: delivery.storeId }) as Store);
  };

  useEffect(() => {
    const init = async () => {
      await deliveriesStore.getDeliveryById(idParam);
      await storeStore.searchStores({ query: getDelivery().storeId });
    };
    init();
  }, []);

  const renderDeliveryData = () => {
    return (
      <div className="delivery">
        <h2>
          {getTemplate().title.fi}, {getStore().name}
        </h2>
        <pre>{YAML.dump(_.omit(getDelivery(), ['emailHtml']))}</pre>
      </div>
    );
  };

  const isReady = Boolean(getDelivery() && getTemplate() && getStore());

  return <div className="content delivery-view">{isReady && renderDeliveryData()}</div>;
};

export default page(inject('deliveriesStore', 'deliveryTemplateStore', 'storeStore')(observer(DeliveryView)));
