/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/unbound-method, @typescript-eslint/no-var-requires, @typescript-eslint/no-unused-vars   */
import React from 'react';
import type {
  ContentTemplate,
  ContentSlot,
  ContentBlock,
  ContentField,
  DeliveryTemplate,
  PricingItem,
  PricingComponent,
  PricingStep,
} from 'components/next/types';
import ContentTemplateEditor from 'components/next/components/contentTemplateEditor';
import Dropdown, { DropdownItem } from 'components/next/components/dropdown';
import { getContentTemplateDefaults, changeOrder } from 'components/next/utils';
import _ from 'utils/lodash';
import './contentTemplates.scss';
import { CustomField } from 'components/next/components/form/input';
import ToggleButton from 'components/common/next/form/toggleButton';
import { toJS } from 'mobx';
import { ConceptType, DeliveryChannelName } from 'enums/common';

interface ContentTemplatesProps {
  conceptType: ConceptType;
  deliveryTemplate: DeliveryTemplate;
  contentTemplates: ContentTemplate[];
  contentBlocks: ContentBlock[];
  contentFields: ContentField[];
  pricingItems: PricingItem[];
  handlePricingChange(value: string, channel: DeliveryChannelName): void;
  handleContentTemplateUpdate(t: ContentTemplate[]): void;
  handleChannelChange(channels): void;
}

interface ContentTemplatesState {
  expandedEditors: number[];
}

export default class ContentTemplates extends React.Component<ContentTemplatesProps, ContentTemplatesState> {
  pricingItemOptions: { [channel: string]: DropdownItem[] } = {
    [DeliveryChannelName.Email]: [{ name: 'Free', value: null }],
    [DeliveryChannelName.Print]: [{ name: 'Free', value: null }],
  };

  currentPricingItems: { [channel: string]: DropdownItem } = {};

  constructor(props: ContentTemplatesProps) {
    super(props);
    this.state = { expandedEditors: [] };
    this.update = this.update.bind(this);
    this.create = this.create.bind(this);
    this.delete = this.delete.bind(this);
    this.onChannelChange = this.onChannelChange.bind(this);
    this.changeOrder = this.changeOrder.bind(this);
    this.renderContentTemplateEditors = this.renderContentTemplateEditors.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);

    const { deliveryTemplate, pricingItems } = props;
    const { pricing } = deliveryTemplate;

    // Get all available pricing item options to dropdown.
    this.pricingItemOptions = _.reduce(
      pricingItems,
      (result, pricingItem: PricingItem) => {
        result[pricingItem.channel].push({ name: pricingItem.title, value: pricingItem.id });
        return result;
      },
      this.pricingItemOptions,
    );

    // Get currently selected pricing items.
    this.currentPricingItems = _.reduce(
      pricing,
      (result, pricingItem: PricingItem) => {
        result[pricingItem.channel] = { name: pricingItem.title, value: pricingItem.id };
        return result;
      },
      {},
    );
  }

  create() {
    const { handleContentTemplateUpdate, contentTemplates } = this.props;
    const order = contentTemplates.length > 0 ? _.max(contentTemplates.map((ct) => ct.order)) + 1 : 0;
    const payload = [...this.props.contentTemplates, getContentTemplateDefaults(undefined, order)];
    handleContentTemplateUpdate(payload);
  }

  update(index: number, field: string, value: string | string[] | ContentSlot[]) {
    const { handleContentTemplateUpdate } = this.props;
    const contentTemplates = [...this.props.contentTemplates];
    const t = contentTemplates[index];
    if (!t) {
      console.error('No content template to edit');
      return;
    }
    _.set(t, field, value);
    contentTemplates[index] = t;
    handleContentTemplateUpdate(contentTemplates);
  }

  delete(index: number) {
    const { handleContentTemplateUpdate, handlePricingChange } = this.props;
    const contentTemplates = [...this.props.contentTemplates];
    const removedTemplate: ContentTemplate = _.first(_.pullAt(contentTemplates, index));
    if (
      _.filter(
        contentTemplates,
        (contentTemplate: ContentTemplate) => contentTemplate.channel === removedTemplate.channel,
      ).length === 0
    ) {
      handlePricingChange(null, removedTemplate.channel as DeliveryChannelName);
    }

    handleContentTemplateUpdate(contentTemplates);
  }

  onChannelChange(channel) {
    const { deliveryTemplate: template, handleChannelChange } = this.props;
    const channels = [...template.channels];
    const index = _.findIndex(channels, (c) => c.name === channel.name);
    channels[index] = channel;
    handleChannelChange(channels);
  }

  changeOrder(index: number, shiftUp: boolean) {
    const { contentTemplates, handleContentTemplateUpdate } = this.props;
    const result = changeOrder(contentTemplates, index, shiftUp);
    handleContentTemplateUpdate(result);
  }

  toggleExpanded(index: number | undefined) {
    if (index === undefined) {
      this.setState({ expandedEditors: [] });
      return;
    }
    this.setState({
      expandedEditors: this.state.expandedEditors.includes(index)
        ? this.state.expandedEditors.filter((i) => i !== index)
        : this.state.expandedEditors.concat(index),
    });
  }

  maybeRenderPricingItem(channel: DeliveryChannelName) {
    const { deliveryTemplate: template } = this.props;
    const selectedPricing: PricingItem = _.find(
      template.pricing,
      (pricingItem: PricingItem) => pricingItem.channel === channel,
    );

    if (!selectedPricing) {
      return false;
    }

    return (
      <div className="pricing-item">
        {_.map(selectedPricing.components, (component: PricingComponent, i: number) => {
          return (
            <div key={`component-${i}`} className="pricing-component">
              {component.title} (Pricing method: {selectedPricing.pricingMethod})
              <div className="pricing-steps">
                <table>
                  <thead>
                    <tr>
                      <th>From (recipients)</th>
                      <th>Price per recipient (€)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(component.steps, (step: PricingStep, i: number) => {
                      return (
                        <tr key={`step-${i}`} className="pricing-step">
                          <td>{step.from}</td>
                          <td>{step.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderContentTemplateEditors() {
    const contentTemplates = _.orderBy(this.props.contentTemplates, 'order');
    const { contentBlocks, contentFields } = this.props;
    return contentTemplates.map((contentTemplate, i) => (
      <ContentTemplateEditor
        key={i}
        index={i}
        template={contentTemplate}
        edit={this.update}
        delete={this.delete}
        contentBlocks={contentBlocks}
        contentFields={contentFields}
        changeOrder={this.changeOrder}
        isExpanded={this.state.expandedEditors.includes(i)}
        toggleExpanded={this.toggleExpanded}
        duplicate={this.duplicate}
        conceptType={this.props.conceptType}
      />
    ));
  }

  duplicate = (id: string) => {
    const { contentTemplates, handleContentTemplateUpdate } = this.props;
    const original = toJS(_.find(contentTemplates, { id }));
    if (original) {
      let clone = _.cloneDeep(original);
      clone = _.chain(clone)
        .set('title.fi', `${original.title.fi} copy`)
        .set('title.sv', `${original.title.sv} copy`)
        .set('order', _.max(contentTemplates.map((ct) => ct.order)) + 1)
        .omit('id')
        .value() as ContentTemplate;
      handleContentTemplateUpdate([...contentTemplates, clone]);
    }
  };

  render() {
    const { deliveryTemplate, handlePricingChange } = this.props;
    const emailChannel = deliveryTemplate.channels.find((c) => c.name === DeliveryChannelName.Email);
    const printChannel = deliveryTemplate.channels.find((c) => c.name === DeliveryChannelName.Print);

    return (
      <div className="editor-tab content-templates">
        <button className="add-content-template" onClick={() => this.create()}>
          <span>Add content template</span>
          <img src={require('images/add.svg').default} alt="add" />
        </button>

        {(emailChannel || printChannel) && (
          <section className="editor-section channels">
            <h3 className="section-title">Channels</h3>
            {emailChannel && (
              <div className="channel-selection">
                <CustomField label="Email channel selected by default" additionalClasses="third-wide">
                  <ToggleButton
                    isSelected={emailChannel.default}
                    value={emailChannel.default}
                    selectedText="yes"
                    notSelectedText="no"
                    toggle={(value) => this.onChannelChange(_.set(emailChannel, 'default', !emailChannel.default))}
                  />
                </CustomField>
                <CustomField label="Email channel required" additionalClasses="third-wide">
                  <ToggleButton
                    isSelected={emailChannel.locked}
                    value={emailChannel.locked}
                    selectedText="yes"
                    notSelectedText="no"
                    toggle={(value) => this.onChannelChange(_.set(emailChannel, 'locked', !emailChannel.locked))}
                  />
                </CustomField>
              </div>
            )}
            {printChannel && (
              <div className="channel-selection">
                <CustomField label="Print channel selected by default" additionalClasses="third-wide">
                  <ToggleButton
                    isSelected={printChannel.default}
                    value={printChannel.default}
                    selectedText="yes"
                    notSelectedText="no"
                    toggle={(value) => this.onChannelChange(_.set(printChannel, 'default', !printChannel.default))}
                  />
                </CustomField>
                <CustomField label="Print channel required" additionalClasses="third-wide">
                  <ToggleButton
                    isSelected={printChannel.locked}
                    value={printChannel.locked}
                    selectedText="yes"
                    notSelectedText="no"
                    toggle={(value) => this.onChannelChange(_.set(printChannel, 'locked', !printChannel.locked))}
                  />
                </CustomField>
              </div>
            )}
          </section>
        )}

        {this.renderContentTemplateEditors()}

        {(emailChannel || printChannel) && (
          <section className="editor-section details">
            <h3 className="section-title">Pricing</h3>
            {emailChannel && (
              <div className="input-field">
                <label>Email</label>
                <Dropdown
                  data={this.pricingItemOptions[DeliveryChannelName.Email]}
                  selectedItem={
                    this.currentPricingItems[DeliveryChannelName.Email] ||
                    this.pricingItemOptions[DeliveryChannelName.Email][0]
                  }
                  notSelectedText="Select email pricing"
                  select={(value: string) => handlePricingChange(value, DeliveryChannelName.Email)}
                />
                {this.maybeRenderPricingItem(DeliveryChannelName.Email)}
              </div>
            )}
            {printChannel && (
              <div className="input-field">
                <label>Print</label>
                <Dropdown
                  data={this.pricingItemOptions[DeliveryChannelName.Print]}
                  selectedItem={
                    this.currentPricingItems[DeliveryChannelName.Print] ||
                    this.pricingItemOptions[DeliveryChannelName.Print][0]
                  }
                  notSelectedText="Select print pricing"
                  select={(value: string) => handlePricingChange(value, DeliveryChannelName.Print)}
                />
                {this.maybeRenderPricingItem(DeliveryChannelName.Print)}
              </div>
            )}
          </section>
        )}
      </div>
    );
  }
}
