/* eslint-disable @typescript-eslint/no-floating-promises */
import DeliveryStore, { DeliveryWithMeta } from 'stores/next-retailer/deliveryStore';
import '../delivery/deliveryBox.scss';
import { ReactComponent as IconSend } from '@kesko/icons/action/icon-msg-send.svg';
import { DeliveryState, DeliveryStatus } from 'enums/common';
import { useNavigate } from 'react-router-dom';
import { getLink } from '../../routes';
import * as date from 'date-fns';
import { castDate } from 'utils/helpers';
import React, { useEffect } from 'react';
import Spinner from 'components/common/next/spinner';
import Button from 'components/common/next/form/button';
import { inject, observer } from 'mobx-react';

interface Props {
  delivery: DeliveryWithMeta;
}
interface InjectedProps extends Props {
  deliveryStore?: DeliveryStore;
}

const MobileDeliveryBox = ({ delivery, deliveryStore }: InjectedProps) => {
  const classes = ['delivery-box', 'box'];
  const navigate = useNavigate();

  useEffect(() => {
    if (!delivery.selectionCounts && delivery.selectionCountsLoading === undefined) {
      deliveryStore.getSelectionCountsForDelivery(delivery);
    }
  }, []);

  const isDraft = () => {
    return delivery.state === DeliveryState.Draft;
  };
  const viewDelivery = () => {
    navigate(getLink('mobileOffer', delivery.id));
  };
  const editDelivery = () => {
    navigate(getLink('editMobileOffers', delivery.id));
  };
  const getAction = () => {
    let action = viewDelivery;
    let actionButton = (
      <Button onClick={action} color="bordered">
        Tulokset
      </Button>
    );

    if (delivery.status === DeliveryStatus.Editable || isDraft()) {
      action = editDelivery;
      actionButton = (
        <Button onClick={action} color="bordered">
          Muokkaa
        </Button>
      );
      return { action, actionButton };
    }
    if (delivery.status === DeliveryStatus.Processed) {
      action = viewDelivery;
      actionButton = (
        <Button onClick={action} color="bordered">
          Tarkastele
        </Button>
      );
    }
    return { action, actionButton };
  };
  // const deliveryOffers = delivery.deliveryOffers ? delivery.deliveryOffers : [];
  const daysUntilDeadline = date.differenceInDays(castDate(delivery.deadline), date.startOfToday()) + 1;
  const action = getAction();
  return (
    <div className={classes.join(' ')}>
      <div className="delivery-header">
        <div className="icon">
          <IconSend />
        </div>
        <div className="basic-info">
          <h3 onClick={action.action} className={'clickable'}>
            {delivery.description}
          </h3>
          <ul className="text-small">
            {!isDraft() && (
              <li>
                Asiakkailla: <strong>{date.format(castDate(castDate(delivery.startDate)), 'd.M.yyyy')}</strong>
              </li>
            )}
            <li>
              Voimassa {date.format(castDate(castDate(delivery.offerStartDate)), 'd.M.yyyy')} -{' '}
              {date.format(castDate(castDate(delivery.offerEndDate)), 'd.M.yyyy')}
            </li>
          </ul>
        </div>
      </div>
      {/*deliveryOffers.length > 0 && (
        <div className="delivery-offers">
          <div className="offer-container">
            Valittu etu
            <br />
            <strong>
              <Link to={getLink('editMobileOffers', delivery.id)}>
                {deliveryOffers[0].title.fi}
                {deliveryOffers.length > 1 && ` ja ${deliveryOffers.length - 1} muuta`}
              </Link>
            </strong>
          </div>
        </div>
      )*/}
      {delivery.selectionCounts && Boolean(delivery.selectionCounts.channels.mobile) ? (
        <div className="target-group">
          <div className="channel channel-email">
            <span className="hl">{delivery.selectionCounts.channels.mobile}</span>
            <br />
            vastaanottajaa
          </div>
        </div>
      ) : (
        !delivery.selectionCounts && (
          <div className="target-group">
            <Spinner />
          </div>
        )
      )}
      {!isDraft() && (
        <div className="template-status">
          {delivery.status === DeliveryStatus.Editable && (
            <div className="">
              <span className="hl">{daysUntilDeadline > 1 ? daysUntilDeadline : '<1'} päivää</span>
              <br />
              aikaa muokata
            </div>
          )}
          {delivery.status === DeliveryStatus.Finished && <div className="">lähetys ohi</div>}
        </div>
      )}
      <div className="actions text-right">{action.actionButton}</div>
    </div>
  );
};

export default inject('deliveryStore')(observer(MobileDeliveryBox));
