/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, react/prop-types, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unused-vars */
import React from 'react';
import { NumberFormatBase, NumberFormatBaseProps, useNumericFormat } from 'react-number-format';

interface Props {
  label?: string;
  value?: number;
  name: string;
  suffix?: string;
  onChange?: (e: React.SyntheticEvent) => any;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowEmptyFormatting?: boolean;
  min?: number;
  max?: number;
  readOnly?: boolean;
  disabled?: boolean;
  allowNegative?: boolean;
}

// Custom format to support allowEmptyFormatting
const CustomNumericFormat = (props) => {
  const { prefix = '', suffix = '', allowEmptyFormatting } = props;
  const { format, ...numberFormatBaseProps } = useNumericFormat(props);
  const customFormat = (numStr) => {
    const formattedValue = format(numStr);
    return allowEmptyFormatting && formattedValue === '' ? prefix + suffix : formattedValue;
  };
  // useNumericFormat has bug, it returns allowEmptyFormatting prop.. must remove it or React throws warning
  const { allowEmptyFormatting: aef, ...safeProps } = numberFormatBaseProps as NumberFormatBaseProps & {
    allowEmptyFormatting: boolean;
  };
  return <NumberFormatBase {...safeProps} format={customFormat} />;
};

class InputNumber extends React.Component<Props> {
  onValueChange = ({ floatValue }) => {
    if (this.props.onChange) {
      this.props.onChange(floatValue);
    }
  };

  render() {
    const {
      name,
      label,
      suffix,
      decimalScale,
      fixedDecimalScale,
      allowEmptyFormatting,
      min,
      max,
      disabled,
      readOnly,
      allowNegative,
      value,
    } = this.props;

    const onValueChange = this.onValueChange;
    return (
      <div className="input-number form-control">
        <label htmlFor={name}>{label}</label>
        <div className="input-container">
          <CustomNumericFormat
            decimalSeparator=","
            value={value || ''}
            name={name}
            onValueChange={onValueChange}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            allowEmptyFormatting={allowEmptyFormatting}
            min={min}
            max={max}
            readOnly={readOnly}
            disabled={disabled}
            allowNegative={allowNegative}
          />
          {suffix && <span className="input-suffix">{suffix}</span>}
        </div>
      </div>
    );
  }
}

export default InputNumber;
