/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises	*/
import React, { Fragment, useEffect } from 'react';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import TemplateBox from '../template/templateBox';
import Spinner from 'components/common/next/spinner';
import { Link, Outlet, useParams } from 'react-router-dom';
import DeliveryBox from '../delivery/deliveryBox';
import ConceptStore from 'stores/next-retailer/conceptStore';
import StoreStore from 'stores/next-retailer/storeStore';
import TemplateStore, { DeliveryTemplateWithMeta } from 'stores/next-retailer/templateStore';
import './conceptView.scss';
import DeliveryStore, { DeliveryWithMeta } from 'stores/next-retailer/deliveryStore';
import UserStore from 'stores/userStore';
import AlertStore from 'stores/next/alerts';
import { getChain, isKRautaChain, isOnninenChain, isKRuokaChain } from 'utils/helpers';
import ToggleButton from 'components/common/next/form/toggleButton';
import { ReactComponent as IconNotice } from '@kesko/icons/attention/icon-notice.svg';
import { DeliveryTemplateStatus, DeliveryState, DeliveryStatus, ConceptType } from 'enums/common';

interface Props {
  conceptStore?: ConceptStore;
  templateStore?: TemplateStore;
  deliveryStore?: DeliveryStore;
  userStore?: UserStore;
  alertStore?: AlertStore;
  storeStore?: StoreStore;
}

const ConceptView = ({ conceptStore, templateStore, deliveryStore, userStore, alertStore, storeStore }: Props) => {
  const { conceptId: conceptIdParam } = useParams();

  const concept = conceptStore.concepts.find((concept) => concept.id === conceptIdParam);

  const templates = _.chain(templateStore.templates).filter({ concept: conceptIdParam }).sortBy('deadline').value();

  const deliveries = _.chain(deliveryStore.deliveries)
    .filter(({ deliveryTemplate }) =>
      _.includes(
        templates.map((t) => t.id),
        deliveryTemplate,
      ),
    )
    .orderBy((d) => d.startDate, 'asc')
    .value();

  const isParticipating = _.get(concept, 'conceptStoreAutomaticParticipation', [])
    .map((csap) => csap.store)
    .includes(userStore.me.store);

  const isPrintTemplatesAvailable = templates.some((template) =>
    template.channels.some((channel) => channel.name === 'print'),
  );

  const isAutomaticallyParticipationDisabledForTemplate = (templateId: string): boolean =>
    concept.conceptStoreAutomaticParticipation
      .filter((csap) => csap.store === userStore.me.store)
      .find(
        (csap) => csap.excludedDeliveryTemplateIds && csap.excludedDeliveryTemplateIds.find((id) => id === templateId),
      ) !== undefined;

  const listAvailableTemplates = (isParticipatingAutomatically: boolean) => {
    return isParticipatingAutomatically
      ? []
      : _.chain(templates)
          .filter((t) => {
            if (t.status !== DeliveryTemplateStatus.Open) {
              return false;
            }
            const deliveriesLength = deliveries.filter((d) => d.deliveryTemplate === t.id).length;
            if (t.maxDeliveries !== -1 && deliveriesLength >= t.maxDeliveries) {
              return false;
            }
            return true;
          })
          .orderBy((t) => t.order, 'asc')
          .value();
  };

  const draftDeliveries = deliveries.filter((d) => d.state === DeliveryState.Draft);

  const scheduledDeliveries = deliveries.filter(
    (d) =>
      (d.status === DeliveryStatus.Processed || d.status === DeliveryStatus.Editable) &&
      d.state === DeliveryState.Confirmed,
  );

  const ongoingDeliveries = deliveries.filter(
    (d) => d.status === DeliveryStatus.Ongoing && d.state === DeliveryState.Confirmed,
  );
  const finishedDeliveries = deliveries.filter(
    (d) => d.status === DeliveryStatus.Finished && d.state === DeliveryState.Confirmed,
  );

  const preloadFullDeliveries = async () => {
    const deliverySet = new Set([
      ...draftDeliveries,
      ...scheduledDeliveries,
      ...ongoingDeliveries,
      ...finishedDeliveries,
    ]);
    // Load 10 deliveries at a time, to speed up loading but only 10 to avoid Heroku 30s timeout
    const deliveries = [...deliverySet.values()];
    while (deliveries.length) {
      await Promise.all(deliveries.splice(0, 10).map((delivery) => deliveryStore.getDelivery(delivery.id, false)));
    }
  };

  useEffect(() => {
    const init = async () => {
      await conceptStore.getConcept(conceptIdParam);
      await preloadFullDeliveries();
      if (!storeStore.store) {
        await storeStore.fetchStore();
      }
    };
    init();
  }, []);

  const toggleAutomaticParticipation = async () => {
    if (isParticipating) {
      const confirm = window.confirm('Oletko varma, että haluat perua automaattisen osallistumisen?');
      if (!confirm) {
        return;
      }
    }
    try {
      await conceptStore.storeParticipateAutomatically(conceptIdParam, {
        participate: !isParticipating,
      });
    } catch (err) {
      alertStore.error({
        message: (
          <span>
            Virhe automaattisessa osallistumisessa. Yritä hetken kuluttua uudelleen. Jos virhe toistuu, ota yhteyttä{' '}
            {isKRautaChain(userStore.me.chainId) && (
              <a href="mailto:k-rauta@kesko.fi?subject=K-Rauta K Markkinointi">k-rauta@kesko.fi</a>
            )}
            {isOnninenChain(userStore.me.chainId) && (
              <a href="mailto:tuki.kmarkkinointi@kesko.fi?subject=Onninen K Markkinointi">
                tuki.kmarkkinointi@kesko.fi
              </a>
            )}
            {isKRuokaChain(userStore.me.chainId) && (
              <a href="mailto:tuki.kmarkkinointi@kesko.fi">tuki.kmarkkinointi@kesko.fi</a>
            )}
          </span>
        ),
        ttl: 0,
      });
    }
  };

  const renderConceptHeader = () => {
    return (
      <div className={`concept-header box-nega ${getChain([userStore.me.chainId])}`}>
        <div className="concept-meta">
          <h2>{concept.title.fi}</h2>
          <p>
            {concept.shortDescription.fi} <Link to="info">Lue lisää &gt;</Link>
          </p>
        </div>
      </div>
    );
  };

  const renderDeliveryList = () => {
    const scheduledTemplates = listAvailableTemplates(!isParticipating);
    const availableTemplates = listAvailableTemplates(isParticipating);
    const allScheduledItems = _.sortBy(
      [
        ...scheduledDeliveries.map((d) => ({
          ...d,
          type: 'delivery',
          deliveryTime: d.startDate,
        })),
        ...scheduledTemplates.map((t) => ({
          ...t,
          type: 'template',
          deliveryTime: t.firstStartDate,
        })),
      ],
      ['deliveryTime'],
    );

    const automaticParticipationAllowed = concept.allowAutomaticParticipation && concept.type === ConceptType.Program;
    return (
      <div className="template-list">
        {automaticParticipationAllowed && (
          <Fragment>
            <div className="concept-options">
              <h4 className="concept-options-label">Automaattinen osallistuminen</h4>
              <ToggleButton
                isSelected={isParticipating}
                disabled={isParticipating === undefined}
                value={concept}
                selectedText="Kyllä"
                notSelectedText="Ei"
                toggle={toggleAutomaticParticipation}
              />
            </div>
          </Fragment>
        )}
        {isParticipating && isPrintTemplatesAvailable && (
          <div className="print-notice box">
            <IconNotice />
            <p>Printti on mukana valinnoissa, tee tarvittavat muutokset.</p>
          </div>
        )}
        {draftDeliveries.length > 0 && (
          <Fragment>
            <h2>Kesken</h2>
            {draftDeliveries.map((delivery) => (
              <DeliveryBox
                key={delivery.id}
                delivery={delivery}
                conceptType={concept.type as ConceptType}
                isParticipatingAutomatically={isParticipating}
                isHalvePrintRecipientCount={concept.halvePrintRecipientCount}
              />
            ))}
          </Fragment>
        )}
        {allScheduledItems.length > 0 && (
          <Fragment>
            <h2>Ajastettu</h2>
            {allScheduledItems.map((item) => {
              if (item.type === 'template') {
                return (
                  <TemplateBox
                    key={item.id}
                    template={item as DeliveryTemplateWithMeta}
                    conceptType={concept.type as ConceptType}
                    disabled={true}
                    maxTemplateParticipations={-1}
                    isParticipatingAutomatically={isParticipating}
                    isParticipatingAutomaticallyDisabled={isAutomaticallyParticipationDisabledForTemplate(item.id)}
                    isHalvePrintRecipientCount={concept.halvePrintRecipientCount}
                  />
                );
              } else {
                return (
                  <DeliveryBox
                    key={item.id}
                    delivery={item as DeliveryWithMeta}
                    conceptType={concept.type as ConceptType}
                    isParticipatingAutomatically={isParticipating}
                    isHalvePrintRecipientCount={concept.halvePrintRecipientCount}
                  />
                );
              }
            })}
          </Fragment>
        )}
        {ongoingDeliveries.length > 0 && (
          <Fragment>
            <h2>Käynnissä</h2>
            {ongoingDeliveries.map((delivery) => (
              <DeliveryBox
                key={delivery.id}
                delivery={delivery}
                conceptType={concept.type as ConceptType}
                isParticipatingAutomatically={isParticipating}
                isHalvePrintRecipientCount={concept.halvePrintRecipientCount}
              />
            ))}
          </Fragment>
        )}
        {availableTemplates.length > 0 && (
          <Fragment>
            <h2>Saatavilla</h2>
            {availableTemplates.map((template) => (
              <TemplateBox
                key={template.id}
                template={template}
                disabled={true}
                maxTemplateParticipations={-1}
                isParticipatingAutomatically={isParticipating}
                isParticipatingAutomaticallyDisabled={isAutomaticallyParticipationDisabledForTemplate(template.id)}
                isHalvePrintRecipientCount={concept.halvePrintRecipientCount}
              />
            ))}
          </Fragment>
        )}
        {availableTemplates.length === 0 && (
          <Fragment>
            <span className="no-delivery-available">Lähetyksiä ei tällä hetkellä saatavilla.</span>
          </Fragment>
        )}
        {finishedDeliveries.length > 0 && (
          <Fragment>
            <h2>Valmis</h2>
            {finishedDeliveries.map((delivery) => (
              <DeliveryBox
                key={delivery.id}
                delivery={delivery}
                conceptType={concept.type as ConceptType}
                isParticipatingAutomatically={isParticipating}
                isHalvePrintRecipientCount={concept.halvePrintRecipientCount}
              />
            ))}
          </Fragment>
        )}
      </div>
    );
  };

  if (!concept) {
    return <Spinner />;
  }

  return (
    <>
      <div className="concept-view">
        <div className="main-content">
          <div className="content">
            {renderConceptHeader()}
            {templates && isParticipating !== undefined ? renderDeliveryList() : <Spinner />}
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default inject(
  'conceptStore',
  'templateStore',
  'deliveryStore',
  'userStore',
  'storeStore',
  'alertStore',
  'appStore',
)(observer(ConceptView));
