/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-floating-promises */
import type Api from 'types/next-api';
import type { PushNotification } from 'components/next/types';
import { observable, action, computed } from 'mobx';
import { StoreState } from 'enums/common';

export type PushNotifictionSearchPayload = Api.Paths.SearchPushNotifications.RequestBody;
export type PushNotificationSearchParameters = Api.Paths.SearchPushNotifications.QueryParameters;

export default class PushNotificationStore {
  public client: Api.Client;
  @observable collection: PushNotification[] = [];
  @observable count = 0;
  @observable periodCount = 0;
  @observable uniqueStoreCount = 0;
  @observable status: StoreState = StoreState.ready;

  @computed
  get pushNotifications() {
    return this.collection;
  }

  @action async search(
    payload: PushNotifictionSearchPayload = {},
    parameters: PushNotificationSearchParameters = null,
  ) {
    this.status = StoreState.loading;
    try {
      const result = await this.client.searchPushNotifications(parameters, payload);
      this.collection = result.data.result;
      this.count = result.data.count;
      this.periodCount = result.data.periodCount;
      this.uniqueStoreCount = result.data.uniqueStoreCount;
      this.status = StoreState.ready;
      return result;
    } catch (err) {
      this.status = StoreState.error;
      console.error(err);
    }
  }

  @action
  public resetCollection() {
    this.collection = [];
    this.count = 0;
    this.periodCount = 0;
    this.uniqueStoreCount = 0;
    this.status = StoreState.ready;
    return this.collection;
  }

  @action async get(id: string) {
    this.status = StoreState.loading;
    try {
      const result = await this.client.getPushNotification(id);
      this.status = StoreState.ready;
      return result.data;
    } catch (err) {
      this.status = StoreState.error;
      console.error(err);
    }
  }

  @action async create(payload: PushNotification) {
    try {
      this.status = StoreState.loading;
      const result = await this.client.createPushNotification(null, payload);
      this.status = StoreState.ready;
      return result;
    } catch (err) {
      this.status = StoreState.ready;
      console.error(err);
      return err.response;
    }
  }

  @action async update(payload: PushNotification) {
    try {
      this.status = StoreState.loading;
      const result = await this.client.replacePushNotification(payload.id, payload);
      this.status = StoreState.ready;
      return result;
    } catch (err) {
      this.status = StoreState.ready;
      console.error(err);
      return err.response;
    }
  }

  @action async delete(notificationId: string) {
    try {
      this.status = StoreState.loading;
      const result = await this.client.deletePushNotification(notificationId);
      this.status = StoreState.error;
      this.search({});
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  @action async getRecipientCount(storeId: string) {
    try {
      this.status = StoreState.loading;
      const result = await this.client.getRecipientCount(storeId);
      this.status = StoreState.ready;
      return result.data;
    } catch (err) {
      console.error(err);
      this.status = StoreState.ready;
    }
  }
}
