/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any	*/
import {
  Concept,
  ContentBlock,
  ContentBlockTemplate,
  ContentField,
  ContentItem,
  ContentSlot,
  ContentTemplate,
  DeliveryTemplate,
  PricingComponent,
  PricingItem,
  PricingStep,
} from 'types/next';
import * as date from 'date-fns';
import * as _ from 'lodash';
import { castDate, getAllowedConceptTypeForChainIds, getNewDeliveryTemplateDates } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';
import { payloadDateFormat } from './variables';
import {
  ContentFieldType,
  ConceptType,
  DeliveryTemplateStatus,
  DeliveryChannelName,
  PricingMethod,
} from 'enums/common';

const DEFAULT_ICON =
  'https://k-markkinointi-api-dev-uploads-bucket.s3.eu-west-1.amazonaws.com/public/de1fb77f-01f0-491e-a6a5-97e7313a519e.svg';
const DEFAULT_ICON_LIGHT =
  'https://k-markkinointi-api-dev-uploads-bucket.s3.eu-west-1.amazonaws.com/public/932aa0a1-4273-42b2-a88b-594c683f7398.svg';

const DEFAULT_DATE = date.format(castDate(new Date()), payloadDateFormat);

export const getContentTemplateDefaults = (
  channel: DeliveryChannelName = DeliveryChannelName.Email,
  order?: number,
  id?: string,
): Partial<ContentTemplate> => ({
  id: id || undefined,
  channel: channel as any,
  isDefault: false,
  title: {
    fi: 'New template',
    sv: 'New template',
  },
  html: {
    fi: '',
    sv: '',
  },
  order,
  recipe: {
    fi: {
      elements: [],
      layers: [],
      fonts: [],
    },
    sv: {
      elements: [],
      layers: [],
      fonts: [],
    },
  },
  recipeField: {},
  contentSlots: [],
  chainIds: [],
});

export const getContentSlotDefaults = (identifier: string) => {
  const slot: ContentSlot = {
    identifier,
    title: {
      fi: 'new slot',
      sv: '',
    },
    required: false,
    options: null,
    defaultContentBlock: null,
    contentBlocks: [],
  };
  return slot;
};

export const getContentBlockTemplateDefaults = () => {
  const template: ContentBlockTemplate = {
    title: { fi: 'New content block template', sv: 'New Content block template SV' },
    emailHtml: { fi: '', sv: '' },
    printHtml: { fi: '', sv: '' },
  };
  return template;
};

export const getContentBlockDefaults = (contentBlockTemplateId: string = null, order: number) => {
  const block: ContentBlock = {
    id: uuidv4(),
    contentBlockTemplateId,
    title: { fi: 'New content block' },
    emailHtml: { fi: '' },
    printHtml: { fi: '' },
    contentFieldIdentifiers: [],
    order,
  };

  return block;
};

export const getContentFieldDefaults = (order: number, contentField?: ContentField) => {
  const field: ContentField = {
    id: contentField ? contentField.id : uuidv4(),
    title: contentField ? contentField.title : { fi: `New content field ${order}` },
    description: contentField ? contentField.description : { fi: 'content field description' },
    identifier: contentField ? contentField.identifier : `new-identifier-${order}`,
    order: contentField ? contentField.order : 0,
    props: contentField ? contentField.props : null,
    type: contentField ? contentField.type : ContentFieldType.text,
    defaultValue: contentField && contentField.defaultValue ? contentField.defaultValue : { fi: 'default value' },
    required: contentField ? contentField.required : false,
    readOnly: contentField ? contentField.readOnly : false,
  };

  return field;
};

export const getDeliveryTemplateDefaults = (
  template?: DeliveryTemplate,
  conceptId?: string,
  conceptType: ConceptType = ConceptType.Program,
): DeliveryTemplate => {
  const { publishDate, deadline, firstStartDate, lastStartDate, firstEditDate, lastEditDate, targetGroupLockDate } =
    getNewDeliveryTemplateDates(conceptType);

  const defaultOfferStartDate = conceptType === ConceptType.Program ? firstStartDate : undefined;

  const result: DeliveryTemplate = {
    ...(template || {}),

    id: template ? template.id : '',
    title: {
      fi: template ? template.title.fi : 'New delivery template',
      sv: template ? template.title.sv : '',
    },
    description: {
      fi: _.get(template, ['description', 'fi'], 'Short description'),
      sv: _.get(template, ['description', 'sv'], ''),
    },
    introduction: {
      fi: _.get(template, ['introduction', 'fi'], 'Introduction'),
      sv: _.get(template, ['introduction', 'sv'], ''),
    },
    maxDeliveries: template ? template.maxDeliveries : 1,
    chainDelivery: template ? template.chainDelivery : false,
    languages: template ? template.languages : ['fi', 'sv'],
    icon: template ? template.icon : { dark: DEFAULT_ICON, light: DEFAULT_ICON_LIGHT },
    concept: template ? template.concept : conceptId,
    deliveryTemplateGroup: template ? template.deliveryTemplateGroup : 'lahetysryhma_1',
    status: template ? template.status : DeliveryTemplateStatus.Draft,
    channels: template ? template.channels : [],
    published: template ? template.published : false,
    publishDate: template ? template.publishDate : publishDate,
    deadline: template ? template.deadline : deadline,
    firstStartDate: template ? template.firstStartDate : firstStartDate,
    lastStartDate: template ? template.lastStartDate : lastStartDate,
    firstEditDate: template ? template.firstEditDate : firstEditDate,
    lastEditDate: template ? template.lastEditDate : lastEditDate,
    targetGroupOpts: template
      ? template.targetGroupOpts
      : {
          dimensions: [],
          useAreas: false,
        },
    offerOpts: template
      ? template.offerOpts
      : {
          min: 0,
          max: 5,
          defaultLength: 7,
          startDate: defaultOfferStartDate,
          allowOwn: false,
          requiredRecommendations: 0,
        },
    offerOptions: template && template.offerOptions ? template.offerOptions : [],
    deliveryOpts: template ? template.deliveryOpts : { minimumLength: 7 },
    contentTemplates: template ? template.contentTemplates : [],
    contentFields: template ? template.contentFields : [],
    contentBlocks: template ? template.contentBlocks : [],
    defaultOfferOptions: [], // template ? template.defaultOfferOptions : [],
    pricing: template ? template.pricing : [],
    createdAt: template ? template.createdAt : undefined,
    updatedAt: template ? template.updatedAt : undefined,
    order: 0,
  };
  if (conceptType === ConceptType.Program) {
    result.targetGroupLockDate = template ? template.targetGroupLockDate : targetGroupLockDate;
  } else {
    result.targetGroupLockDate = null;
  }

  return result;
};

export const getConceptDefaults = (chainIds: string[] | undefined, concept?: Concept) => {
  let deliveryTemplates = undefined;
  if (concept) {
    deliveryTemplates = concept.deliveryTemplates;
  }

  const { conceptStoreAutomaticParticipation } = concept ? concept : ({} as Concept);
  const result: Concept = {
    id: concept ? concept.id : undefined,
    type: concept ? concept.type : getAllowedConceptTypeForChainIds(chainIds)[0] || ConceptType.Season,
    title: concept ? concept.title : { fi: 'New Concept', sv: 'New concept' },
    description: concept && concept.description ? concept.description : { fi: '', sv: '' },
    shortDescription: concept && concept.shortDescription ? concept.shortDescription : { fi: '', sv: '' },
    published: concept ? concept.published : false,
    publishDate: concept ? concept.publishDate : DEFAULT_DATE,
    maxTemplateParticipations: concept ? concept.maxTemplateParticipations : -1,
    chainIds: concept ? concept.chainIds : chainIds,
    updatedAt: concept ? concept.updatedAt : undefined,
    deliveryTemplates,
    ...{ conceptStoreAutomaticParticipation }, // no conceptStoreAutomaticParticipation in object, if not specified in parameter concept
    allowAutomaticParticipation: concept ? concept.allowAutomaticParticipation : false,
    halvePrintRecipientCount: concept ? concept.halvePrintRecipientCount : false,
    order: 0,
  };

  return result;
};

export const getContentItemDefaults = (overrides: Partial<ContentItem> = {}) => {
  const result: ContentItem = {
    type: 'ui',
    stores: null,
    slug: `content-item-${overrides.order || 0}`,
    order: 0,
    chainIds: [],
    title: { fi: 'New content item' },
    content: { fi: '' },
    publishDate: DEFAULT_DATE,
    ...overrides,
  };
  return result;
};

export const getPricingItemDefaults = () => {
  const result: PricingItem = {
    title: 'Pricing item',
    channel: DeliveryChannelName.Print,
    pricingMethod: PricingMethod.Normal,
    components: [getPricingItemComponentDefaults()],
  };

  return result;
};

export const getPricingItemComponentDefaults = () => {
  const result: PricingComponent = {
    title: 'paino',
    steps: [getPricingItemStepDefaults()],
  };

  return result;
};

export const getPricingItemStepDefaults = () => {
  const result: PricingStep = {
    from: 0,
    price: 0,
  };

  return result;
};

export const getArchiveDate = () => {
  const fourteenMonthsInMillis = 14 * 31 * 24 * 60 * 60 * 1000;
  return date.format(castDate(new Date(new Date().getTime() - fourteenMonthsInMillis)), payloadDateFormat);
};
