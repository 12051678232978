import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Role } from 'types/user';
import { routes, adminBase, retailerBase } from '../routes';
import Lander from './lander';
import UserStore from 'stores/userStore';
import Spinner from 'components/common/next/spinner';
/* eslint-disable @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
interface Props {
  userStore?: UserStore;
}

const Auth = ({ userStore }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const { me, requestInProgress } = userStore;
    const searchParams = new URLSearchParams(location.search);

    const selectStore = async () => {
      // If the url contains a store parameter (/?store=), try to use that store for the retailer view
      if (searchParams.has('store') && searchParams.get('store') !== me.store && !requestInProgress) {
        const searchParamStoreId = searchParams.get('store').toUpperCase();
        await userStore.selectStore(searchParamStoreId);
      }

      if (!me.aduser && !requestInProgress) {
        userStore.getMe();
      }

      setIsLoading(false);
    };
    selectStore();
  }, []);

  const { me, requestInProgress } = userStore;
  const from = location.state && location.state.from;

  if (!requestInProgress && !isLoading) {
    const redirectToFrom =
      from !== `${retailerBase}${routes.logout}` &&
      from !== `${adminBase}${routes.logout}` &&
      from !== routes.logout &&
      from;
    if (!redirectToFrom) {
      location.state = {};
    }

    if (me && me.role === Role.Admin) {
      return <Navigate to={redirectToFrom || adminBase} replace />;
    } else if (me && me.role === Role.Retailer) {
      return <Navigate to={{ pathname: redirectToFrom || retailerBase, search: location.search }} replace />;
    } else if (me && me.role === Role.Guest) {
      return <Navigate to={redirectToFrom || routes.unathorized} replace />;
    } else {
      // show lander page with login button
      return <Lander />;
    }
  }
  return (
    <div style={{ display: 'flex', flex: 'auto' }}>
      <Spinner />
    </div>
  );
};

export default inject('userStore')(observer(Auth));
