/* eslint-disable @typescript-eslint/no-unused-vars, 	@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-member-access  */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'utils/lodash';
import * as dateFns from 'date-fns';
import UserStore from 'stores/userStore';
import ConceptsStore from 'stores/next/programs';
import DeliveryTemplateStore from 'stores/next/deliveryTemplates';
import ReportsStore, { ReportType } from 'stores/next/reports';
import page from 'components/next/pages/page/page';
import InputSelect from 'components/common/next/form/inputSelect';
import Button from 'components/common/next/form/button';
import type { Concept, DeliveryTemplate, Route } from 'components/next/types';
import Spinner from 'components/common/next/spinner';
import './reports.scss';
import DeliveriesStore from '../../../../stores/next/deliveries';
import { isEmail, isKRuokaOnlyChain } from '../../../../utils/helpers';
import Alerts from 'components/common/next/alert/alerts';
import { FormInput } from 'components/next/components/form/input';
import { Components } from 'types/next-api';

interface InjectedProps {
  conceptsStore?: ConceptsStore;
  deliveryTemplateStore?: DeliveryTemplateStore;
  deliveriesStore?: DeliveriesStore;
  reportsStore?: ReportsStore;
  userStore?: UserStore;
}

interface Props extends InjectedProps {
  chainIds: string[];
  getPageLink(route: Route, id: string): string;
}

interface State {
  reportType: ReportType;
  availableConcepts: Concept[];
  availableDeliveryTemplates: DeliveryTemplate[];
  availableOffers: Components.Schemas.DeliveryTemplateOffer[];
  selectedConcepts: string[];
  selectedDeliveryTemplates: string[];
  selectedOffers: string[];
  isLoadingDeliveryTemplates: boolean;
  isLoadingOffers: boolean;
  isLoadingReport: boolean;
  recipientEmail: string;
  invalidRecipientEmail: boolean;
}

const Reports = ({
  conceptsStore,
  deliveryTemplateStore,
  deliveriesStore,
  reportsStore,
  userStore,
  chainIds,
  getPageLink,
}: Props) => {
  const [reportType, setReportType] = useState(ReportType.OrderAmountEstimates);
  const [availableConcepts, setAvailableConcepts] = useState<Concept[]>([]);
  const [availableDeliveryTemplates, setAvailableDeliveryTemplates] = useState<DeliveryTemplate[]>([]);
  const [availableOffers, setAvailableOffers] = useState<Components.Schemas.DeliveryTemplateOffer[]>([]);
  const [selectedConcepts, setSelectedConcepts] = useState<string[]>([]);
  const [selectedDeliveryTemplates, setSelectedDeliveryTemplates] = useState<string[]>([]);
  const [selectedOffers, setSelectedOffers] = useState<string[]>([]);
  const [isLoadingDeliveryTemplates, setIsLoadingDeliveryTemplates] = useState(false);
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState('');
  const [invalidRecipientEmail, setInvalidRecipientEmail] = useState(false);

  useEffect(() => {
    const init = async () => {
      const { result } = await conceptsStore.search(
        {
          chainId: [chainIds[0], ...chainIds.slice(1)],
          archived: false,
        },
        {
          sort: 'createdAt',
          order: 'desc',
        },
      );
      const recipientEmail = userStore.me.email;
      setAvailableConcepts(result);
      setRecipientEmail(recipientEmail);
    };
    init();
  }, []);

  const deliveryTemplateSearch = async (concepts: string[]) => {
    if (concepts.length === 0) {
      return false;
    }

    const { result } = await deliveryTemplateStore.search({
      concept: [concepts[0], ...concepts.slice(1)],
    });
    setAvailableDeliveryTemplates(result);
    setIsLoadingDeliveryTemplates(false);
  };

  const offerSearch = async (deliveryTemplates: string[]) => {
    if (deliveryTemplates.length === 0) {
      return false;
    }

    const offers = await deliveryTemplateStore.getOffersInDeliveriesByIds([
      deliveryTemplates[0],
      ...deliveryTemplates.slice(1),
    ]);

    setAvailableOffers(offers);
    setIsLoadingOffers(false);
  };

  const debouncedDeliveryTemplateSearch = _.debounce(deliveryTemplateSearch, 1000);
  const debouncedOfferSearch = _.debounce(offerSearch, 1000);

  const handleReportTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const reportType = e.target.value as ReportType;
    setReportType(reportType);
    setSelectedConcepts([]);
    setSelectedDeliveryTemplates([]);
    setSelectedOffers([]);
    setAvailableDeliveryTemplates([]);
    setAvailableOffers([]);
    setIsLoadingDeliveryTemplates(false);
    setIsLoadingOffers(false);
    setIsLoadingReport(false);
  };

  const handleConceptChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedConcepts = _.compact(_.map(e.target.selectedOptions, (item) => item.value));
    setSelectedConcepts(selectedConcepts);
    setSelectedDeliveryTemplates([]);
    setSelectedOffers([]);
    setAvailableDeliveryTemplates([]);
    setAvailableOffers([]);
    setIsLoadingDeliveryTemplates(selectedConcepts.length ? true : false);
    if (selectedConcepts.length) {
      debouncedDeliveryTemplateSearch(selectedConcepts);
    }
  };

  const handleDeliveryTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDeliveryTemplates = _.compact(_.map(e.target.selectedOptions, (item) => item.value));
    setSelectedDeliveryTemplates(selectedDeliveryTemplates);
    setSelectedOffers([]);
    setAvailableOffers([]);
    setIsLoadingOffers(
      selectedDeliveryTemplates.length && reportType === ReportType.OrderAmountEstimates ? true : false,
    );
    if (selectedDeliveryTemplates.length && reportType === ReportType.OrderAmountEstimates) {
      debouncedOfferSearch(selectedDeliveryTemplates);
    }
  };

  const handleOfferChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOffers(_.compact(_.map(e.target.selectedOptions, (item) => item.value)));
  };

  const downloadReport = async () => {
    setIsLoadingReport(true);
    try {
      if (reportType === ReportType.OrderDeliveryReport) {
        const deliveryReportCsv = await deliveriesStore.exportDeliveryReport({
          deliveryTemplate: selectedDeliveryTemplates,
        });
        const element = document.createElement('a');
        const file = new Blob([deliveryReportCsv], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = `${dateFns.format(Date.now(), 'yyyyMMdd')}-delivery-report.csv`;
        document.body.appendChild(element);
        element.click();
      } else if (reportType === ReportType.OrderAmountEstimates) {
        await reportsStore.exportOrderAmountEstimateReport(
          selectedConcepts,
          selectedDeliveryTemplates,
          selectedOffers,
          recipientEmail,
        );
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoadingReport(false);
  };

  const renderConceptSelector = () => {
    return (
      <InputSelect
        multiple
        size={availableConcepts.length}
        label="Select concepts"
        detail="You can select multiple by holding CTRL while selecting"
        value={selectedConcepts}
        onChange={handleConceptChange}
        style={{ height: '400px' }}
      >
        {_.map(availableConcepts, (c) => (
          <option key={`concept-${c.id}`} value={c.id}>
            {_.get(c, ['title', 'fi'])}
          </option>
        ))}
      </InputSelect>
    );
  };

  const renderDeliveryTemplateSelector = () => {
    if (availableDeliveryTemplates.length === 0 || isLoadingDeliveryTemplates) {
      return null;
    }

    const concepts = _.map(selectedConcepts, (id) => _.find(availableConcepts, { id }));

    const deliveryTemplateOptions =
      selectedConcepts.length > 1
        ? _.map(concepts, (concept) => {
            const conceptId = _.get(concept, ['id']);
            const conceptDeliveryTemplates = _.filter(availableDeliveryTemplates, { concept: conceptId });
            return (
              <optgroup key={`group-${conceptId}`} label={_.get(concept, ['title', 'fi'])}>
                {_.map(conceptDeliveryTemplates, (dt) => {
                  const deliveryTemplateId = _.get(dt, ['id']);
                  return (
                    <option key={`group-dt-${deliveryTemplateId}`} value={deliveryTemplateId}>
                      {_.get(dt, ['title', 'fi'])}
                    </option>
                  );
                })}
              </optgroup>
            );
          })
        : _.map(availableDeliveryTemplates, (dt) => (
            <option key={`delivery-template-${dt.id}`} value={dt.id}>
              {_.get(dt, ['title', 'fi'])}
            </option>
          ));

    const selectSize =
      selectedConcepts.length === 1
        ? availableDeliveryTemplates.length
        : availableDeliveryTemplates.length + selectedConcepts.length;

    return (
      <InputSelect
        multiple
        size={selectSize}
        label="Select delivery templates"
        detail="You can select multiple by holding CTRL while selecting"
        value={selectedDeliveryTemplates}
        onChange={handleDeliveryTemplateChange}
      >
        {deliveryTemplateOptions}
      </InputSelect>
    );
  };

  const renderOfferSelector = () => {
    if (
      availableDeliveryTemplates.length === 0 ||
      isLoadingDeliveryTemplates ||
      availableOffers.length === 0 ||
      isLoadingOffers
    ) {
      return null;
    }

    const deliveryTemplates = _.map(selectedDeliveryTemplates, (id) => _.find(availableDeliveryTemplates, { id }));

    const offerOptions =
      selectedDeliveryTemplates.length > 1
        ? _.map(deliveryTemplates, (deliveryTemplate) => {
            const deliveryTemplateId = _.get(deliveryTemplate, ['id']);
            const deliveryTemplateOffers = _.filter(availableOffers, { deliveryTemplate: deliveryTemplateId });
            return (
              <optgroup key={`group-${deliveryTemplateId}`} label={_.get(deliveryTemplate, ['title', 'fi'])}>
                {_.map(deliveryTemplateOffers, (o) => {
                  const val = `${o.deliveryTemplate}_${o.title}`;
                  return (
                    <option key={`group-offer-${val}`} value={val}>
                      {o.title}
                    </option>
                  );
                })}
              </optgroup>
            );
          })
        : _.map(availableOffers, (o) => {
            const val = `${o.deliveryTemplate}_${o.title}`;
            return (
              <option key={`offer-${val}`} value={val}>
                {o.title}
              </option>
            );
          });

    const selectSize =
      selectedDeliveryTemplates.length === 1
        ? availableOffers.length
        : availableOffers.length + selectedDeliveryTemplates.length;

    return (
      <InputSelect
        multiple
        size={selectSize}
        label="Select offers"
        detail="You can select multiple by holding CTRL while selecting"
        value={selectedOffers}
        onChange={handleOfferChange}
      >
        {offerOptions}
      </InputSelect>
    );
  };

  const handleRecipientEmailChange = (e) => {
    const recipientEmail = e.target.value;
    const invalidRecipientEmail = !isEmail(recipientEmail);
    setRecipientEmail(recipientEmail);
    setInvalidRecipientEmail(invalidRecipientEmail);
  };

  const renderReportActions = () => {
    const isOrderAmountEstimates = reportType === ReportType.OrderAmountEstimates;
    const disabled =
      selectedConcepts.length === 0 ||
      selectedDeliveryTemplates.length === 0 ||
      (isOrderAmountEstimates && selectedOffers.length === 0) ||
      isLoadingDeliveryTemplates ||
      (isOrderAmountEstimates && isLoadingOffers) ||
      isLoadingReport ||
      (isOrderAmountEstimates && invalidRecipientEmail);
    return (
      <>
        {reportType === ReportType.OrderAmountEstimates && (
          <div className="form-control">
            <FormInput
              id="recipientEmail"
              label="Recipient email"
              value={recipientEmail}
              handleChange={handleRecipientEmailChange}
              isInvalid={invalidRecipientEmail}
              validationErrorText={'Check that email address is correctly entered'}
            />
          </div>
        )}
        <div className="report-actions">
          <Button disabled={disabled} onClick={downloadReport}>
            {reportType === ReportType.OrderAmountEstimates ? 'Generate as email' : 'Generate as file'}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="content reports">
      <header>
        <h2>Reports</h2>
      </header>
      <InputSelect label="Select report type" value={reportType} onChange={handleReportTypeChange}>
        {isKRuokaOnlyChain(chainIds) && (
          <option key={ReportType.OrderAmountEstimates} value={ReportType.OrderAmountEstimates}>
            Order amount estimates
          </option>
        )}
        <option key={ReportType.OrderDeliveryReport} value={ReportType.OrderDeliveryReport}>
          Order delivery report
        </option>
      </InputSelect>
      {renderConceptSelector()}
      {renderDeliveryTemplateSelector()}
      {reportType === ReportType.OrderAmountEstimates && renderOfferSelector()}
      {(isLoadingDeliveryTemplates || isLoadingOffers || isLoadingReport) && (
        <div>
          <Spinner />
        </div>
      )}
      {renderReportActions()}
      <Alerts />
    </div>
  );
};

export default page(
  inject('conceptsStore', 'deliveryTemplateStore', 'deliveriesStore', 'reportsStore', 'userStore')(observer(Reports)),
);
