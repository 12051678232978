/* eslint-disable @typescript-eslint/no-explicit-any */
import { LanguageStrings, DateString } from './string';

export interface Previews {
  thumbnail: string;
  large: string;
  pdf?: string;
  html?: string;
}

export enum PromotionType {
  Product = 'tuote-etu',
  WebstoreProduct = 'verkkokaupan tuote-etu',
  Discount = 'alennus',
  Other = 'muu nosto',
}

export enum SaleType {
  price = 'Hintaetu',
  percent = 'Prosenttietu',
  free = 'Veloituksetta',
}

export enum RestrictionType {
  person = 'asiakas',
  household = 'talous',
}

export interface Promotion {
  promotionId?: string;
  title: LanguageStrings;
  type: PromotionType;
  order?: number;
  image?: string;
  restrictionValue?: number;
  restrictionType?: RestrictionType;
  salePrice?: number;
  salePercent?: number;
  saleType?: SaleType;
  origPriceMin?: number;
  origPriceMax?: number;
  pricingUnit?: string;
  minimumPurchase?: number;
  eans?: string[];
  description?: string;
  deposit?: number;
  trackingId?: number;
}

export enum CampaignStatus {
  open = 'open',
  closed = 'closed',
}

export enum ParticipationPhase {
  upcoming = 'upcoming',
  ongoing = 'ongoing',
  passed = 'passed',
}

export enum ParticipationState {
  draft = 'draft',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
}

export interface ParticipationSelection {
  print?: number;
  email?: number;
  segments?: string[];
  subsegments?: string[];
  additionalSegments?: string[];
  visited?: boolean;
}

export interface TemplateSelection {
  print?: string;
  email?: string;
}

export interface CampaignParticipationData {
  selection: ParticipationSelection;
  state: ParticipationState;
  content?: {
    print?: LanguageStrings;
    email?: LanguageStrings;
  };
  promotions: Promotion[];
  endDate?: DateString;
  languages?: string[];
  template?: TemplateSelection;
  store?: string;
}

export interface Recipients {
  print?: number;
  email?: number;
  mobile?: number;
  total?: number;
}

export interface CampaignParticipation extends CampaignParticipationData {
  phase: ParticipationPhase;
  recipients: Recipients;
  participationId: string;
}

export interface CampaignContent {
  description?: string;
  defaultPromotions?: Promotion[];
  discountOptions?: Promotion[];
  lengthLimit?: {
    promotionTitle?: number;
  };
  minPromotions?: number;
  maxPromotions?: number;
}

export enum CampaignType {
  season = 'season',
}

export interface CampaignPreviews {
  email?: Previews;
  print?: Previews;
  mobile?: Previews;
}

export interface AvailableWeekDates {
  week: number;
  year: number;
  publishDate: DateString;
  deadline: DateString;
  startDate: DateString;
  endDate: DateString;
  status?: CampaignStatus;
}

export interface TemplateFields {
  id?: string;
  template?: LanguageStrings;
  templateAc?: LanguageStrings;
  templatePromotions?: LanguageStrings;
  templatePromotion?: LanguageStrings;
  templateMerchant?: LanguageStrings;
  pdfData?: any;
  contentDefault?: LanguageStrings;
  contentLengthLimit?: number;
}

export interface CampaignTemplates {
  print?: TemplateFields[];
  email?: TemplateFields[];
}

export interface CampaignFields {
  title: LanguageStrings;
  type: CampaignType;
  chainId: number;
  channels: string[];
  deadline?: DateString;
  published: boolean;
  startDate?: DateString;
  endDate?: DateString;
  publishDate?: DateString;
  content?: CampaignContent;
  icon?: {
    light?: string;
    dark?: string;
  };
  segments?: string[];
  subsegments?: string[];
  additionalSegments?: string[];
  availableSegments?: string[];
  availableSubsegments?: string[];
  availableAdditionalSegments?: string[];
  visitedOnly?: boolean;
  templates: CampaignTemplates;
  languages: string[];
  availableWeeks?: AvailableWeekDates[];
}

export interface Campaign extends CampaignFields {
  campaignId: string;
  status: CampaignStatus;
  participations?: CampaignParticipation[];
  preview?: CampaignPreviews;
}

export interface Product {
  title: LanguageStrings;
  image?: string;
  ean: string;
  price?: number;
  pricingUnit?: string;
  isOnSale?: boolean;
  deposit?: number;
  id?: string;
}

export interface BTTProduct {
  productId: string;
  ean: string;
  title: string;
  description?: string;
  isOnSale: boolean;
  image?: string;
  imageUrls?: string[];
  basePrice?: number;
  omnibus?: number;
  price: number;
  loyaltyPrice?: number;
  src: string;
  pricingUnit: string;
  vatPercentage: number;
  currency: string;
  url?: string;
  textBlock?: string;
  showTextBlock?: boolean;
}

export enum SearchType {
  add = 'add',
  change = 'change',
}
