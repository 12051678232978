/* eslint-disable @typescript-eslint/no-unsafe-argument, 	@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, 	@typescript-eslint/no-unsafe-member-access */
import axios from 'axios';
import { get } from 'lodash';
import type { User, NotificationContact, NotificationType } from 'types/user';
import type { Store } from 'types/store';

const API_URL = process.env.REACT_APP_API_URL;

const meUrl = `${API_URL}/me`;
const logoutUrl = `${API_URL}/sso/logout`;
export const imageUrl = `${API_URL}/me/image`;
const storesUrl = `${API_URL}/stores`;
const loginUrl = (path?: string) => `${API_URL}/sso/login?redirect=${window.location.origin}${path || ''}`;
const retailerTokenUrl = (retailer: string) => `${API_URL}/retailers/${retailer}/token`;
const storeTokenUrl = (storeId: string) => `${API_URL}/stores/${storeId}/token`;
const getNotificationDetailsUrl = `${API_URL}/next/stores/search`;
const patchNotificationDetailsUrl = (storeId: string) => `${API_URL}/next/stores/${storeId}`;

export async function getMe(): Promise<User> {
  try {
    const { data } = await axios.get(meUrl, { withCredentials: true });
    return data;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    console.error(err);
  }
}

export function login(from?: string): void {
  // just throw the user to SAML login
  window.location.href = loginUrl(from);

  // they will come back with a cookie
}

export async function logout(): Promise<void> {
  try {
    await axios.get(logoutUrl, { withCredentials: true });
  } catch (err) {
    // 401 is fine in logout, don't throw here
    if (!err.response || err.response.status !== 401) {
      throw err;
    }
  }
  return;
}

export async function getStores(): Promise<Store[]> {
  try {
    const { data } = await axios.get(storesUrl, { withCredentials: true });
    const { result } = data;
    return result || [];
  } catch (err) {
    if (err.response) {
      throw err;
    }
    // no stores
    return [];
  }
}

export async function getTokenForRetailer(aduser: string): Promise<string> {
  try {
    const { data } = await axios.get(retailerTokenUrl(aduser), {
      withCredentials: true,
    });
    const { token } = data;
    return token;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    console.error(err);
  }
}

export async function getTokenForStore(storeId: string): Promise<string> {
  try {
    const { data } = await axios.get(storeTokenUrl(storeId), {
      withCredentials: true,
    });
    const { token } = data;
    return token;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    console.error(err);
  }
}

export async function getNotificationDetails(
  storeId: string,
): Promise<{ notificationTypes: NotificationType[]; notificationContacts: NotificationContact[] }> {
  try {
    const { data } = await axios.post(
      getNotificationDetailsUrl,
      {
        storeId: [storeId],
      },
      {
        withCredentials: true,
      },
    );
    const { result } = data;

    return {
      notificationTypes: get(result, [0, 'notificationTypes']) || [],
      notificationContacts: get(result, [0, 'notificationContacts']) || [],
    };
  } catch (err) {
    if (err.response) {
      throw err;
    }
    // no content
    return;
  }
}

export async function patchNotificationTypes(
  storeId: string,
  notificationTypes: NotificationType[],
): Promise<NotificationType[]> {
  try {
    const { data } = await axios.patch(
      patchNotificationDetailsUrl(storeId),
      { notificationTypes },
      {
        withCredentials: true,
      },
    );
    const { result } = data;
    return get(result, ['notificationTypes']) || [];
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return;
  }
}

export async function patchNotificationContacts(
  storeId: string,
  notificationContacts: NotificationContact[],
): Promise<NotificationContact[]> {
  try {
    const { data } = await axios.patch(
      patchNotificationDetailsUrl(storeId),
      { notificationContacts },
      {
        withCredentials: true,
      },
    );
    const { result } = data;
    return get(result, ['notificationContacts']) || [];
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return;
  }
}
