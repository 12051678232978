/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument */
import _ from 'utils/lodash';
import { observable, action } from 'mobx';
import type Api from 'types/next-api';
import { marked } from 'marked';
import { ContentType } from 'enums/common';

export type ContentItem = Api.Components.Schemas.ContentItem;
export type ContentSearchParams = Api.Paths.SearchContent.QueryParameters;

interface ContentOpts {
  html?: boolean /* return html */;
}

export default class ContentStore {
  public client: Api.Client;
  limit = 5;
  @observable offset = 0;
  @observable count = 0;

  /**
   * Current entity
   */
  @observable current: ContentItem;
  @action setCurrent(contentItem: ContentItem) {
    this.current = contentItem;
  }
  @action unsetCurrent() {
    this.current = undefined;
  }

  /**
   * Entity collection
   */
  @observable collection: ContentItem[] = [];
  get content() {
    return this.collection;
  }
  @action
  public updateCollection(items: ContentItem[]) {
    for (const item of items) {
      const index = _.findIndex(this.collection, { id: item.id });
      if (index !== -1) {
        // update
        if (!_.isEqual(this.collection[index], item)) {
          this.collection[index] = item;
        } else {
          // no need to update
        }
      } else {
        // add to collection
        this.collection = [...this.collection, item];
      }
    }
    return this.collection;
  }

  @action
  public deleteFromCollection(id: string) {
    const index = _.findIndex(this.collection, { id });
    const items = [...this.collection];
    items.splice(index, 1);
    this.collection = items;
    return this.collection;
  }

  @action
  public resetCollection() {
    this.collection = [];
    this.offset = 0;
    this.count = 0;
    return this.collection;
  }

  /**
   * API methods
   */
  @action async search(params: ContentSearchParams = {}) {
    try {
      // Filtering empty values away from search params
      const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));
      const result = await this.client.searchContent(filteredParams);
      this.updateCollection(result.data.result);
      return result.data.result;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async searchNews(params: ContentSearchParams = {}) {
    try {
      if (params.offset) {
        this.offset = params.offset;
      }

      const result = await this.client.searchContent({ ...{ offset: this.offset, limit: this.limit }, ...params });
      this.count = result.data.count;
      this.updateCollection(result.data.result);
      return result.data.result;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async getContent(id: string, type?: ContentType) {
    try {
      const result = await this.client.getContentItem({ contentId: id, ...(type && { type }) });
      this.updateCollection([result.data]);
      return result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async saveContent(contentItem: ContentItem) {
    try {
      const result = contentItem.id
        ? await this.client.replaceContentItem(contentItem.id, contentItem)
        : await this.client.createContentItem(null, contentItem);
      this.updateCollection([result.data]);
      return result;
    } catch (error) {
      this.handleError(error);
    }
  }

  @action async updateContents(contentItems: ContentItem[]) {
    try {
      const result = await this.client.replaceContentItems(null, {
        result: contentItems,
        count: contentItems.length,
      });
      this.updateCollection(result.data.result);
      return result;
    } catch (error) {
      this.handleError(error);
    }
  }

  @action async deleteContent(id: string) {
    const result = await this.client.deleteContentItem(id);
    this.deleteFromCollection(id);
    return result.data;
  }

  /**
   * Utility methods
   */
  public getContentItemByProps({ id, type }: { id: string; type: ContentType }, opts: ContentOpts = {}) {
    return _.find(this.collection, { id, type });
  }

  public getContentItemBySlug(slug: string, opts: ContentOpts = {}) {
    return _.find(this.collection, { slug });
  }

  public getContentBySlug(slug: string, opts: ContentOpts = {}) {
    let content = `Content slug "${slug}" not found.`;
    const item = this.getContentItemBySlug(slug);
    if (item) {
      content = item.content.fi;
    }
    return opts.html ? marked(content) : content;
  }

  handleError(err: any) {
    console.error(err);
    if (err.response && err.response.data) {
      console.error(err.response.data);
      throw new Error(err.response.data.message);
    }

    throw err;
  }
}
