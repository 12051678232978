/* eslint-disable @typescript-eslint/no-unsafe-argument, 	@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument */
import { observable, action } from 'mobx';
import type { Client } from 'types/next-api';
import type { PricingItem } from 'types/next';

interface SearchProps {
  limit?: number;
  offset?: number;
}

export default class PricingStore {
  client: Client;
  @observable pricingItems: PricingItem[] = [];
  @observable pricingItemsInUse: { [pricingId: string]: boolean } = {};
  @observable pricingItem: PricingItem;

  @action async searchPricingItems({ limit, offset }: SearchProps) {
    try {
      const pricingItemsResult = await this.client.searchPricingItems({ limit, offset }, null);
      this.pricingItems = pricingItemsResult.data.result;
      const pricingIds = this.pricingItems.map((item) => item.id);
      const pricingItemInUseResult = await this.client.getPricingItemInUse(null, pricingIds);
      this.pricingItemsInUse = pricingItemInUseResult.data.reduce(
        (acc, curr) => ({ [curr.pricingId]: curr.inUse, ...acc }),
        {} as { [pricingId: string]: boolean },
      );
      return pricingItemsResult;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async findById(id: string) {
    try {
      const result = await this.client.getPricingItem(id);
      this.pricingItem = result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async createPricingItem(pricingItem: PricingItem) {
    try {
      const result = await this.client.createPricingItem(null, pricingItem);
      await this.searchPricingItems({});
      this.pricingItem = result.data;
      return result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async updatePricingItem(pricingItem) {
    try {
      const result = await this.client.replacePricingItem(pricingItem.id, pricingItem);
      this.pricingItem = result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async deletePricingItem(id: string) {
    try {
      await this.client.deletePricingItem(id);
      await this.searchPricingItems({});
    } catch (err) {
      this.handleError(err);
    }
  }

  handleError(err: any) {
    console.error(err);
    if (err.response && err.response.data) {
      console.error(err.response.data);
      throw new Error(err.response.data.message);
    }
    throw err;
  }
}
