/* eslint-disable @typescript-eslint/no-floating-promises */
import OpenAPIClientAxios from 'openapi-client-axios';
import type { Client } from 'types/next-api';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_NEXT_OPENAPI_SPEC_HOST = process.env.REACT_APP_NEXT_OPENAPI_SPEC_HOST;
const API_URL =
  REACT_APP_NEXT_OPENAPI_SPEC_HOST && REACT_APP_NEXT_OPENAPI_SPEC_HOST.length > 0
    ? REACT_APP_NEXT_OPENAPI_SPEC_HOST
    : REACT_APP_API_URL;

const api = new OpenAPIClientAxios({
  definition: `${API_URL}/next/openapi.json`,
  axiosConfigDefaults: { withCredentials: true },
});

api.init();

export async function getClient<C = Client>() {
  return api.getClient<C>();
}
