/* eslint-disable @typescript-eslint/no-unsafe-argument	*/
import React, { createRef, RefObject, useEffect, useRef } from 'react';
import { ReactComponent as IconInfo } from '@kesko/icons/attention/icon-info.svg';
import './infoPopover.scss';

interface Props {
  className?: string;
  children?: JSX.Element | JSX.Element[] | string;
}

interface State {
  open: boolean;
}

class InfoPopover extends React.Component<Props, State> {
  wrapperRef: RefObject<HTMLDivElement> = createRef();

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggle = () => {
    const open = !this.state.open;
    this.setState({ open });
    if (open) {
      window.addEventListener('click', this.outsideClose);
      document.addEventListener('keyup', this.escapeClose);
    } else {
      window.removeEventListener('click', this.outsideClose);
      document.removeEventListener('keyup', this.escapeClose);
    }
  };

  escapeClose = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      this.toggle();
    }
  };

  outsideClose = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(target)) {
      this.toggle();
    }
  };

  render() {
    return (
      <div className="info-popover left" ref={this.wrapperRef}>
        <div className="popover-pos-wrapper">
          <button className="info-button" onClick={this.toggle}>
            <IconInfo />
          </button>
          {this.state.open && <Popover>{this.props.children}</Popover>}
        </div>
      </div>
    );
  }
}

const Popover = (props: { children: JSX.Element | JSX.Element[] | string }) => {
  const ref = useRef<HTMLDivElement>();
  const handleResize = () => {
    if (ref.current && !ref.current.style.opacity) {
      const { left, right } = ref.current.getBoundingClientRect();
      if (left < 0) {
        ref.current.style.left = `${-left}px`;
      }
      if (right > window.innerWidth) {
        ref.current.style.left = `${window.innerWidth - right}px`;
      }
      ref.current.style.opacity = '1';
    }
  };
  const reset = () => {
    if (ref.current) {
      ref.current.style.opacity = undefined;
      ref.current.style.left = undefined;
    }
  };
  useEffect(() => {
    window.addEventListener('resize', reset);
    return () => {
      window.removeEventListener('resize', reset);
    };
  });
  useEffect(handleResize, []);
  return (
    <div ref={ref} className="popover">
      <div className="popover-content">{props.children}</div>
    </div>
  );
};

export default InfoPopover;
