/* eslint-disable 	@typescript-eslint/no-unsafe-argument,   @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-floating-promises  */
import React, { Fragment, useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import StampCardStore from 'stores/next/stampCard';
import StampCardStoreNew from 'stores/next-retailer/stampCardStore';
import Spinner from 'components/common/next/spinner';
import InputText from 'components/common/next/form/inputText';
import InputRadio from 'components/common/next/form/inputRadio';
import InputSelect from 'components/common/next/form/inputSelect';
import DrawerContent from '../wrappers/drawerContent';
import Button from '../../../../common/next/form/button';
import './stampCardImageEditor.scss';
import { CollectionStampCardImageTextType } from 'enums/stampCard';
import { getCollectionStampCardImageHTML } from 'utils/stampCard';
import { useNavigate } from 'react-router-dom';
import InputImage from 'components/common/next/form/inputImage';

interface Props {
  newStore?: boolean; // TODO: Remove when old stamp card editor is no longer needed
  keskoStampCard?: boolean;
}

interface InjectedProps extends Props {
  stampCardStore?: StampCardStore;
  stampCardStoreNew?: StampCardStoreNew;
}

const getInitialBackgroundValues = (scStore: StampCardStoreNew | StampCardStore) => {
  const initialBackgroundUrl = scStore.current ? scStore.current.imageData.backgroundUrl : null;
  const backgrounds = scStore.currentTemplate
    ? _.get(scStore.currentTemplate, ['imageData', 'backgroundUrls'])
    : _.get(scStore, ['stampCardBackgrounds']);
  const initialUploadedBackgroundUrl = !backgrounds?.find((b: string) => b === initialBackgroundUrl)
    ? initialBackgroundUrl
    : null;
  const initialTextType = scStore.current
    ? (scStore.current.imageData.textType as CollectionStampCardImageTextType)
    : null;
  const initialText = scStore.current ? scStore.current.imageData.text.fi : null;
  const initialTextBackgroundColor = scStore.current ? scStore.current.imageData.textBackgroundColor : null;
  const initialTextForegroundColor = scStore.current ? scStore.current.imageData.textForegroundColor : null;

  return {
    initialBackgroundUrl,
    initialUploadedBackgroundUrl,
    initialTextType,
    initialText,
    initialTextBackgroundColor,
    initialTextForegroundColor,
  };
};

const StampCardImageEditor = ({ stampCardStore, stampCardStoreNew, newStore, keskoStampCard }: InjectedProps) => {
  const scStore = newStore ? stampCardStoreNew : stampCardStore;

  const navigate = useNavigate();

  const {
    initialBackgroundUrl,
    initialUploadedBackgroundUrl,
    initialTextType,
    initialText,
    initialTextBackgroundColor,
    initialTextForegroundColor,
  } = getInitialBackgroundValues(scStore);
  const [backgroundUrl, setBackgroundUrl] = useState<string>(initialBackgroundUrl);
  const [uplodedBackgroundUrl, setUploadedBackgroundUrl] = useState<string>(initialUploadedBackgroundUrl);
  const [textType, setTextType] = useState<CollectionStampCardImageTextType>(initialTextType);
  const [text, setText] = useState<string>(initialText);
  const [textBackgroundColor, setTextBackgroundColor] = useState<string>(initialTextBackgroundColor);
  const [textForegroundColor, setTextForegroundColor] = useState<string>(initialTextForegroundColor);
  const [backgroundFile, setBackgroundFile] = useState<string | ArrayBuffer>(null);

  useEffect(() => {
    if (!scStore.current) {
      navigate(-1);
    } else {
      const {
        initialBackgroundUrl,
        initialUploadedBackgroundUrl,
        initialTextType,
        initialText,
        initialTextBackgroundColor,
        initialTextForegroundColor,
      } = getInitialBackgroundValues(scStore);
      setBackgroundUrl(initialBackgroundUrl);
      setUploadedBackgroundUrl(initialUploadedBackgroundUrl);
      setTextType(initialTextType);
      setText(initialText);
      setTextBackgroundColor(initialTextBackgroundColor);
      setTextForegroundColor(initialTextForegroundColor);
    }
  }, [scStore.currentTemplate]);

  useEffect(() => {
    if (scStore.current) {
      const templateId = _.get(scStore.current, ['templateId']);
      scStore.getCollectionStampCardTemplate(templateId);
    }
  }, [scStore.current]);

  const handleCancel = () => {
    const confirmed = window.confirm('Oletko varma, että haluat poistua tallentamatta muutoksia?');
    if (!confirmed) {
      return false;
    }
    navigate(-1);
  };

  const handleSubmit = async () => {
    const html = getCollectionStampCardImageHTML({
      preview: false,
      backgroundUrl: backgroundUrl === 'uploaded-image' ? uplodedBackgroundUrl : backgroundUrl,
      textType,
      text,
      textBackgroundColor,
      textForegroundColor,
    });

    await scStore.createCollectionStampCardImage(html, {
      text,
      textType,
      backgroundUrl: backgroundUrl === 'uploaded-image' ? uplodedBackgroundUrl : backgroundUrl,
      textBackgroundColor,
      textForegroundColor,
    });
    navigate(-1);
  };

  const onChangeTextType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const textType = e.target.value as CollectionStampCardImageTextType;
    setTextType(textType);
  };

  const onChangeTextForegroundColor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const textForegroundColor = e.target.value as CollectionStampCardImageTextType;
    setTextForegroundColor(textForegroundColor);
  };

  const onChangeTextBackgroundColor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const textBackgroundColor = e.target.value as CollectionStampCardImageTextType;
    setTextBackgroundColor(textBackgroundColor);
  };

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setText(text);
  };

  const onChangeBackground = (e: React.ChangeEvent<HTMLInputElement>) => {
    const backgroundUrl = e.target.value;
    setBackgroundUrl(backgroundUrl);
  };

  const renderPreview = () => {
    return (
      <div
        className="stampcard-image-editor-preview"
        dangerouslySetInnerHTML={{
          __html: getCollectionStampCardImageHTML({
            preview: true,
            backgroundUrl,
            textType,
            text,
            textBackgroundColor,
            textForegroundColor,
            file: backgroundUrl === 'uploaded-image' ? backgroundFile || uplodedBackgroundUrl : undefined,
          }),
        }}
      />
    );
  };

  const renderHeader = () => {
    return <h2>Muokkaa kuvaa</h2>;
  };

  const renderFooter = (isLoading: boolean) => {
    return (
      <Fragment>
        {isLoading && (
          <div>
            <Spinner />
          </div>
        )}
        <Button color="inverse" onClick={handleCancel} disabled={isLoading}>
          Peruuta
        </Button>
        <Button color="default" onClick={handleSubmit} disabled={isLoading}>
          Tallenna
        </Button>
      </Fragment>
    );
  };

  const handleImageUpload = (file: File) => {
    setBackgroundUrl('uploaded-image');
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const imageDataUrl = e.target.result;
        setBackgroundFile(imageDataUrl);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageSave = (src: string) => {
    setUploadedBackgroundUrl(src);
    setBackgroundUrl('uploaded-image');
  };

  const render = () => {
    const currentStampCard = scStore.current;
    if (!currentStampCard) {
      return null;
    }

    const { isLoadingStampCardImage } = scStore;

    const backgrounds = scStore.currentTemplate
      ? _.get(scStore.currentTemplate, ['imageData', 'backgroundUrls'])
      : _.get(scStore, ['stampCardBackgrounds']);

    return (
      <DrawerContent
        className="stampcard-image-editor"
        renderHeader={renderHeader}
        renderFooter={() => renderFooter(isLoadingStampCardImage)}
      >
        <Fragment>
          <div className="stampcard-image-editor-section">
            <h2>Sisältö</h2>
            <div className="stampcard-image-editor-section section-split">
              <div className="left">{renderPreview()}</div>
              <div className="right form-control">
                <InputSelect label="Teksti" value={textType} onChange={onChangeTextType}>
                  <option key={CollectionStampCardImageTextType.Circle} value={CollectionStampCardImageTextType.Circle}>
                    Pallo
                  </option>
                  <option key={CollectionStampCardImageTextType.Banner} value={CollectionStampCardImageTextType.Banner}>
                    Banneri
                  </option>
                  <option key={CollectionStampCardImageTextType.None} value={CollectionStampCardImageTextType.None}>
                    Ei tekstiä
                  </option>
                </InputSelect>
                <InputSelect label="Tekstin väri" value={textForegroundColor} onChange={onChangeTextForegroundColor}>
                  <option key="fg-#ffffff" value="#ffffff">
                    Vaalea
                  </option>
                  <option key="fg-#000000" value="#000000">
                    Tumma
                  </option>
                </InputSelect>
                <InputSelect
                  label="Tekstin taustaväri"
                  value={textBackgroundColor}
                  onChange={onChangeTextBackgroundColor}
                >
                  <option key="bg-#00205b" value="#00205b">
                    Sininen
                  </option>
                  <option key="bg-#ff6900" value="#ff6900">
                    Oranssi
                  </option>
                </InputSelect>
                <br />
                <InputText textarea key="text" name="text" label="Sisältö" value={text} onChange={onChangeText} />
              </div>
            </div>
          </div>
          <div className="stampcard-image-editor-section">
            <h2>Taustakuva</h2>
            <div className="stampcard-image-editor-backgrounds">
              {keskoStampCard && backgrounds && (
                <div key={`card-background-upload`} className="stampcard-image-editor-background">
                  <InputRadio
                    name="backgroundUrl"
                    value={'uploaded-image'}
                    checked={
                      backgroundUrl === 'uploaded-image' ||
                      (backgroundUrl && !backgrounds?.find((b: string) => b === backgroundUrl))
                    }
                    onChange={onChangeBackground}
                  >
                    <InputImage
                      onChange={({ src }) => handleImageSave(src)}
                      value={{ src: uplodedBackgroundUrl ? uplodedBackgroundUrl : '' }}
                      styles={{ maxHeight: 220 }}
                      readOnly={false}
                      uploadDirectory="stamp-card-backgrouds"
                      onUpload={handleImageUpload}
                      disableDelete={true}
                    />
                  </InputRadio>
                </div>
              )}
              {_.map(backgrounds, (background, i) => {
                return (
                  <div key={`card-background-${i}`} className="stampcard-image-editor-background">
                    <InputRadio
                      label={`<img src="${background}" />`}
                      name="backgroundUrl"
                      value={background}
                      defaultChecked={backgroundUrl === background}
                      onChange={onChangeBackground}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      </DrawerContent>
    );
  };

  return render();
};

export default inject('stampCardStore', 'stampCardStoreNew')(observer(StampCardImageEditor));
