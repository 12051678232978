/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-member-access, 	@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, 	@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unused-vars  */
import React from 'react';
import { ReactComponent as ChevronDown } from '@kesko/icons/nav/icon-chevron-down.svg';
import { ReactComponent as ChevronRight } from '@kesko/icons/nav/icon-chevron-right.svg';
import './dropdown.scss';

export interface DropdownItem {
  name: string;
  value: string | number | boolean;
}

interface DropdownProps {
  data: DropdownItem[];
  notSelectedText: string;
  selectedItem?: DropdownItem;
  select(value: string | number | boolean): void;
  disabled?: boolean;
  className?: string;
}

interface DropDownState {
  selectedText: string;
  selectedIndex: number;
  listVisible: boolean;
  data: DropdownItem[];
}

export default class Dropdown extends React.Component<DropdownProps, DropDownState> {
  rList = React.createRef<HTMLUListElement>();
  rContainer = React.createRef<HTMLDivElement>();

  constructor(props: DropdownProps) {
    super(props);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.higlightItem = this.higlightItem.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.state = {
      selectedText: '',
      selectedIndex: undefined,
      data: [],
      listVisible: false,
    };
  }

  componentDidMount() {
    const { data, selectedItem } = this.props;
    if (this.state.data !== data) {
      const selectedText = selectedItem
        ? data.find((item) => item.value === selectedItem.value).name
        : this.props.notSelectedText;
      this.setState({ data, selectedText });
    }
  }

  componentDidUpdate() {
    const { data, selectedItem } = this.props;
    if (this.state.data !== data) {
      const selectedText = selectedItem
        ? data.find((item) => item.value === selectedItem.value).name
        : this.props.notSelectedText;
      this.setState({ data, selectedText });
    }
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('click', this.handleClick);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp(e) {
    const { data } = this.props;
    const { listVisible, selectedIndex } = this.state;
    let indexAfterIncrement;
    if (listVisible) {
      switch (e.key) {
        case 'ArrowDown':
          if (selectedIndex === undefined) {
            this.setState({ selectedIndex: 0 });
            break;
          } else {
            indexAfterIncrement = selectedIndex < data.length - 1 ? selectedIndex + 1 : 0;
            this.setState({ selectedIndex: indexAfterIncrement });
            break;
          }
        case 'ArrowUp':
          if (selectedIndex === undefined) {
            this.setState({ selectedIndex: data.length - 1 });
            break;
          } else {
            indexAfterIncrement = selectedIndex === 0 ? data.length - 1 : selectedIndex - 1;
            this.setState({ selectedIndex: indexAfterIncrement });
            break;
          }
        case 'Enter':
          if (selectedIndex !== undefined) {
            this.selectItem();
          }
      }
      this.higlightItem(this.state.selectedIndex);
    }
  }

  higlightItem(index: number) {
    const children = Array.from(this.rList.current.children);
    children.forEach((c) => c.classList.remove('is-selected'));
    children[index].classList.add('is-selected');
  }

  handleClick(e) {
    if (!this.rContainer.current.contains(e.target)) {
      this.setState({ listVisible: false });
      return;
    }
  }

  handleItemClick(selectedIndex: number) {
    this.setState({ selectedIndex }, () => {
      this.higlightItem(selectedIndex);
      this.selectItem();
    });
  }

  toggle() {
    if (!this.props.disabled) {
      const listVisible = !this.state.listVisible;
      this.setState({ listVisible });
    }
  }

  selectItem() {
    const { data, select } = this.props;
    const { selectedIndex } = this.state;
    this.setState({ selectedText: data[selectedIndex].name });
    select(data[selectedIndex].value);
    this.toggle();
  }

  render() {
    const { data, notSelectedText, disabled, className } = this.props;
    const { selectedText, listVisible } = this.state;
    const dropdownClass = `${disabled ? 'dropdown disabled' : 'dropdown'} ${className || ''}`;
    const listClass = listVisible ? 'dropdown__list is-visible dropdown-list' : 'dropdown__list';
    return (
      <div className={dropdownClass} ref={this.rContainer}>
        <div className="dropdown__selected" onClick={(e) => this.toggle()}>
          {selectedText || notSelectedText}
          {listVisible ? <ChevronRight /> : <ChevronDown />}
        </div>
        <ul className={listClass} ref={this.rList}>
          {data.map((item, i) => (
            <li key={i} onClick={() => this.handleItemClick(i)}>
              <span>{item.name}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
