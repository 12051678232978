/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access, 	@typescript-eslint/no-unused-vars, @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises, 	@typescript-eslint/no-unsafe-assignment, 	@typescript-eslint/no-var-requires, @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-explicit-any, 	react/prop-types, @typescript-eslint/no-unsafe-return   */
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as date from 'date-fns';
import { NavLink } from 'react-router-dom';
import DeliveriesStore from 'stores/next/deliveries';
import StoreStore from 'stores/next/stores';
import ResultsStore, { AggregatedDeliveryResults } from 'stores/next-retailer/resultsStore';
import page from 'components/next/pages/page/page';
import Spinner from 'components/common/next/spinner';
import { DateField } from 'components/next/components/form/input';
import type { Route, Delivery, Store } from 'types/next';
import { Checkbox } from '../../components/checkbox';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import SearchBox from 'components/next/components/sidebar/searchBox';
import ChainSelector from 'components/next/components/chainSelector';
import StoreSelector from 'components/next/components/storeSelector';
import { ReactComponent as IconArrowRight } from '@kesko/icons/nav/icon-arrow-right.svg';
import './deliveries.scss';
import { getAllowedConceptTypeForChainIds, useDebouncedEffect } from 'utils/helpers';
import {
  calculateDivisionSafe,
  castDate,
  getExcludedChains,
  isKRautaOnlyChain,
  isOnninenOnlyChain,
} from 'utils/helpers';
import { dateFormat, payloadDateFormat } from 'components/next/utils';
import * as dateFns from 'date-fns';
import { ChainAbbreviations } from 'constants/common';
import { ConceptType, DeliveryChannelName, DeliveryStatus, DeliveryState } from 'enums/common';
import TableContainer from 'components/next/components/tableContainer';

interface DeliveriesProps {
  deliveriesStore?: DeliveriesStore;
  storeStore?: StoreStore;
  resultsStore?: ResultsStore;
  chainIds: string[];
  getPageLink(route: Route, id: string): string;
}

const Deliveries = ({
  deliveriesStore,
  storeStore,
  resultsStore,
  chainIds: chainIdsProp,
  getPageLink,
}: DeliveriesProps) => {
  const [query, setQuery] = useState('');
  const [chainIds, setChainIds] = useState(chainIdsProp);
  const [excludedChains, setExcludedChains] = useState(getExcludedChains(chainIdsProp));
  const [storeIds, setStoreIds] = useState<string[]>([]);
  const [selectedDeliveries, setSelectedDeliveries] = useState<string[]>([]);
  const [stores, setStores] = useState<{ [id: string]: Store }>({});
  const [sort, setSort] = useState('delivery.id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [message, setMessage] = useState<string>(null);
  const [isExecuting, setIsExecuting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingResults, setIsLoadingResults] = useState(true);
  const [types, setTypes] = useState(
    _.reduce(
      Object.keys(ConceptType),
      (types, key) => {
        const type = ConceptType[key as string];
        types[type] = true;
        return types;
      },
      {},
    ),
  );
  const [channels, setChannels] = useState(
    _.reduce(
      Object.keys(DeliveryChannelName),
      (channels, key) => {
        const channel = DeliveryChannelName[key as string];
        channels[channel] = true;
        return channels;
      },
      {},
    ),
  );
  const [statuses, setStatuses] = useState(
    _.reduce(
      Object.keys(DeliveryStatus),
      (statuses, key) => {
        const status = DeliveryStatus[key as string];
        statuses[status] = true;
        return statuses;
      },
      {},
    ),
  );
  const [states, setStates] = useState(
    _.reduce(
      Object.keys(DeliveryState),
      (states, key) => {
        const state = DeliveryState[key as string];
        states[state] = true;
        return states;
      },
      {},
    ),
  );
  const [startDateFrom, setStartDateFrom] = useState<string>(null);
  const [startDateTo, setStartDateTo] = useState<string>(null);
  const [endDateFrom, setEndDateFrom] = useState<string>(null);
  const [endDateTo, setEndDateTo] = useState<string>(null);
  const [deadlineFrom, setDeadlineFrom] = useState<string>(null);
  const [deadlineTo, setDeadlineTo] = useState<string>(null);
  const [searchResult, setSearchResult] = useState<Delivery[]>([]);
  const [categorizedResults, setCategorizedResults] = useState({
    recipients: 0,
    opens: 0,
    optOuts: 0,
    clicks: 0,
    totalRedeemers: 0,
    avgPull: 0,
  });
  const [archiveStatusChangeDisabled, setArchiveStatusChangeDisabled] = useState(false);
  const [currentSelectionArchiveStatus, setCurrentSelectionArchiveStatus] = useState(false);
  const [archived, setArchived] = useState(false);
  const isMounted = useRef(false);
  const isKRautaChain = isKRautaOnlyChain(chainIdsProp);
  const isOnninenChain = isOnninenOnlyChain(chainIdsProp);

  const getStores = () => {
    const deliveries = selectedDeliveries.length
      ? searchResult.filter((d) => selectedDeliveries.includes(d.id))
      : searchResult;
    return {
      participating: _.uniqBy(deliveries, 'storeId').length,
      total: storeStore.stores.length,
    };
  };

  const isArchiveStatusChangeDisabled = (selection: string[]) => {
    const firstSelectedDelivery = searchResult.find((d: Delivery) => d.id === selection[0]);
    const initialStatus = firstSelectedDelivery ? firstSelectedDelivery.archived : true;

    const archiveStatusChangeDisabled = selection.some((d: string) => {
      const delivery = searchResult.find((delivery: Delivery) => delivery.id === d);
      return delivery.archived !== initialStatus;
    });

    return {
      archiveStatusChangeDisabled,
      currentSelectionArchiveStatus: initialStatus,
    };
  };

  const getFilteredResults = async () => {
    let deliveries = selectedDeliveries.length
      ? searchResult.filter((d) => selectedDeliveries.includes(d.id))
      : searchResult;
    if (storeIds.length) {
      deliveries = deliveries.filter((delivery) => storeIds.includes(delivery.storeId));
    }
    const result = await Promise.all(deliveries.map((d) => resultsStore.getDeliveryResults(d.id)));
    return result.filter((r) => !_.isNil(r));
  };

  const setResultCategories = async () => {
    if (isKRautaChain || isOnninenChain) {
      const results: AggregatedDeliveryResults[] = await getFilteredResults();
      let { recipients, opens, optOuts, clicks, totalRedeemers, avgPull } = categorizedResults;

      if (results.length) {
        recipients = results.reduce(
          (acc, curr) => (acc += curr.emailResults.totalRecipients + curr.printResults.totalRecipients),
          0,
        );
        opens = results.reduce((acc, curr) => (acc += curr.emailResults.opens), 0);
        optOuts = results.reduce((acc, curr) => (acc += curr.emailResults.optouts), 0);
        clicks = results.reduce((acc, curr) => (acc += curr.emailResults.clicks), 0);
        totalRedeemers = results.reduce((acc, curr) => (acc += curr.totalRedeemers), 0);
        let pulls = 0;
        const pullSum = results.reduce((acc, curr) => {
          if (curr.pullPercentage > 0) {
            pulls++;
          }
          return (acc += curr.pullPercentage);
        }, 0);
        avgPull = calculateDivisionSafe(pullSum, pulls);
      }

      setCategorizedResults({ recipients, clicks, opens, optOuts, totalRedeemers, avgPull });
    }
  };

  const searchDeliveries = async (updateStores = false, limit = 0) => {
    const type = _.keys(_.pickBy(types, Boolean));
    const channel = _.keys(_.pickBy(channels, Boolean));
    const state = _.keys(_.pickBy(states, Boolean));
    const status = _.keys(_.pickBy(statuses, Boolean));

    const search = _.omitBy(
      {
        query,
        chainId: chainIds,
        ...(storeIds.length > 0 && { storeId: storeIds }),
        type,
        channel,
        startDateFrom,
        startDateTo,
        endDateFrom,
        endDateTo,
        deadlineFrom,
        deadlineTo,
        state,
        status,
        archived,
      },
      _.isNil,
    );

    try {
      const deliveries = await deliveriesStore.search(search, { limit, sort, order });
      const searchResult = deliveries.data.result as Delivery[];
      const deliveryIds = _.map(searchResult, 'id');
      const updatedSelection = _.filter(selectedDeliveries, (id) => _.includes(deliveryIds, id)) as string[];

      if (updateStores) {
        const stores = await storeStore.searchStores({ chainIds });

        setSearchResult(searchResult);
        setSelectedDeliveries(updatedSelection);
        setStores(_.keyBy(stores, 'storeId'));
        setIsLoading(false);
      } else {
        setSearchResult(searchResult);
        setSelectedDeliveries(updatedSelection);
        setIsLoading(false);
      }
      setResultCategories();
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Fetch only 100 deliveries initially.
    const init = async () => {
      await searchDeliveries(true, 100);
      const stores = await storeStore.searchStores({});
      if (isKRautaChain || isOnninenChain) {
        resultsStore.searchDeliveryResults({ id: searchResult.map((d) => d.id) }).then(() => {
          setResultCategories();
          setIsLoadingResults(false);
          setStores(_.keyBy(stores, 'storeId'));
        });
      } else {
        setStores(_.keyBy(stores, 'storeId'));
      }
    };
    init();
  }, []);

  useDebouncedEffect(
    () => {
      isMounted.current ? searchDeliveries() : (isMounted.current = true);
    },
    [
      query,
      chainIds,
      storeIds,
      types,
      channels,
      statuses,
      states,
      startDateFrom,
      startDateTo,
      endDateFrom,
      endDateTo,
      deadlineFrom,
      deadlineTo,
      archived,
      sort,
      order,
    ],
    500,
  );

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'query') {
      setQuery(value);
      setIsLoading(true);
    }
  };

  const handleChainChange = (chain: string) => {
    const newChainIds = [...chainIds];
    if (newChainIds.includes(chain)) {
      _.pullAt(newChainIds, newChainIds.indexOf(chain));
    } else {
      newChainIds.push(chain);
    }
    setChainIds(newChainIds);
    setIsLoading(true);
  };

  const handleStoresChange = (stores: Store[]) => {
    setStoreIds(_.map(stores, 'storeId'));
    setIsLoading(true);
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTypes = { ...types };
    const type = e.currentTarget.dataset.conceptType;
    newTypes[type] = !newTypes[type];
    setTypes(newTypes);
    setIsLoading(true);
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStates = { ...states };
    const state = e.currentTarget.dataset.deliveryState;
    newStates[state] = !newStates[state];
    setStates(newStates);
    setIsLoading(true);
  };

  const handleArchivedStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArchived(!archived);
    setIsLoading(true);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStatuses = { ...statuses };
    const status = e.currentTarget.dataset.deliveryStatus;
    newStatuses[status] = !newStatuses[status];
    setStatuses(newStatuses);
    setIsLoading(true);
  };

  const handleChannelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChannels = { ...channels };
    const channel = e.currentTarget.dataset.deliveryChannel;
    newChannels[channel] = !newChannels[channel];
    setChannels(newChannels);
    setIsLoading(true);
  };

  const handleDateChange = (date: Date, setter: (val: string) => void) => {
    const value = dateFns.isDate(date) ? dateFns.format(date, payloadDateFormat) : null;
    setter(value);
    setIsLoading(true);
  };

  const handleDeliverySelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const deliveryId = e.currentTarget.dataset.deliveryId;
    const selected = e.currentTarget.checked;
    const updatedSelection = selected
      ? [...selectedDeliveries, deliveryId]
      : _.filter(selectedDeliveries, (id: string) => id !== deliveryId);

    const { archiveStatusChangeDisabled, currentSelectionArchiveStatus } =
      isArchiveStatusChangeDisabled(updatedSelection);

    setSelectedDeliveries(updatedSelection);
    setArchiveStatusChangeDisabled(archiveStatusChangeDisabled);
    setCurrentSelectionArchiveStatus(currentSelectionArchiveStatus);
    setResultCategories();
  };

  const handleSortChange = (e: React.MouseEvent<HTMLTableHeaderCellElement>) => {
    const newSort = e.currentTarget.dataset.sort;
    if (newSort === sort) {
      setOrder(order === 'desc' ? 'asc' : 'desc');
    } else {
      setSort(newSort);
    }
    setIsLoading(true);
  };

  const handleRebuildAction = async () => {
    if (!window.confirm(`Are you sure you wan't to rebuild this delivery?`)) {
      return;
    }

    setIsExecuting(true);
    let message;

    try {
      const { failed } = _.get(await deliveriesStore.rebuildDeliveries(selectedDeliveries), ['data']);
      message = failed > 0 ? 'Rebuild failed!' : 'Rebuild successful!';
    } catch (err) {
      message = 'Rebuild failed!';
    }
    setIsExecuting(false);
    setMessage(message);
    setTimeout(() => setMessage(null), 10000);
  };

  const toggleArchived = async () => {
    setIsLoading(true);
    await deliveriesStore.setArchiveStatusForDeliveries(selectedDeliveries, !currentSelectionArchiveStatus);
    await searchDeliveries();
    setSelectedDeliveries([]);
    setIsLoading(false);
  };

  const formatChannels = (delivery: Delivery) => {
    return _.keys(_.pickBy(_.get(delivery, ['targetGroup', 'channels']), (value) => value !== 0)).join(', ');
  };

  const renderSidebar = () => {
    return (
      <div className="deliveries-sidebar">
        <div className="deliveries-filters">
          <div className="sidebar__title-wrapper">
            <h3 className="sidebar__title">Filters</h3>
            {isLoading && <Spinner addClassName="spinner--unset" />}
          </div>
          <SearchBox
            value={query}
            name="query"
            onChange={handleQueryChange}
            placeholder="Search for deliveries"
            detail="Search for concept or delivery template title or delivery ID"
          />
          <label>Chain</label>
          <ChainSelector
            chainSelection={chainIds}
            excludeChains={excludedChains}
            handleChainChange={handleChainChange}
          />
          <div className="filter-group">
            <label>Stores</label>
            <StoreSelector
              defaultStoreIds={storeIds}
              stores={_.values(stores)}
              onChange={handleStoresChange}
              detail="Search for store name or business unit ID"
            />
          </div>
          <div className="filter-group">
            <Checkbox
              data-delivery-archived-state="archived"
              key={`delivery-archived-state`}
              id={`delivery-archived-state`}
              label="Show archived deliveries"
              checked={archived}
              handleClick={handleArchivedStateChange}
            />
          </div>

          <div className="filter-group">
            <label>State</label>
            {_.map(Object.keys(DeliveryState), (key) => {
              const state: string = DeliveryState[key as string];

              return (
                <Checkbox
                  data-delivery-state={state}
                  key={`delivery-state-${state}`}
                  id={`delivery-state-${state}`}
                  label={_.capitalize(state)}
                  checked={states[state]}
                  handleClick={handleStateChange}
                />
              );
            })}
          </div>

          <div className="filter-group">
            <label>Status</label>
            {_.map(Object.keys(DeliveryStatus), (key) => {
              const status: string = DeliveryStatus[key as string];

              return (
                <Checkbox
                  data-delivery-status={status}
                  key={`delivery-status-${status}`}
                  id={`delivery-status-${status}`}
                  label={_.capitalize(status)}
                  checked={statuses[status]}
                  handleClick={handleStatusChange}
                />
              );
            })}
          </div>
          <div className="filter-group">
            <label>Type</label>
            {_.map(getAllowedConceptTypeForChainIds(chainIds), (type) => {
              return (
                <Checkbox
                  data-concept-type={type}
                  key={`concept-type-${type}`}
                  id={`concept-type-${type}`}
                  label={_.capitalize(type)}
                  checked={types[type]}
                  handleClick={handleTypeChange}
                />
              );
            })}
          </div>
          <div className="filter-group">
            <label>Channels</label>
            {_.map(Object.keys(DeliveryChannelName), (key) => {
              const channel: string = DeliveryChannelName[key as string];

              return (
                <Checkbox
                  data-delivery-channel={channel}
                  key={`delivery-channel-${channel}`}
                  id={`delivery-channel-${channel}`}
                  label={_.capitalize(channel)}
                  checked={channels[channel]}
                  handleClick={handleChannelChange}
                />
              );
            })}
          </div>
          <div className="filter-group">
            <label>Start date</label>
            <DateField
              value={startDateFrom ? new Date(startDateFrom) : null}
              label="From"
              onChange={(e) => handleDateChange(e, setStartDateFrom)}
              clearable
              placeholder={dateFormat}
            />
            <DateField
              value={startDateTo ? new Date(startDateTo) : null}
              label="To"
              onChange={(e) => handleDateChange(e, setStartDateTo)}
              clearable
              placeholder={dateFormat}
            />
          </div>
          <div className="filter-group">
            <label>End date</label>
            <DateField
              value={endDateFrom ? new Date(endDateFrom) : null}
              label="From"
              onChange={(e) => handleDateChange(e, setEndDateFrom)}
              clearable
              placeholder={dateFormat}
            />
            <DateField
              value={endDateTo ? new Date(endDateTo) : null}
              label="To"
              onChange={(e) => handleDateChange(e, setEndDateTo)}
              clearable
              placeholder={dateFormat}
            />
          </div>
          <div className="filter-group">
            <label>Deadline</label>
            <DateField
              value={deadlineFrom ? new Date(deadlineFrom) : null}
              label="From"
              onChange={(e) => handleDateChange(e, setDeadlineFrom)}
              clearable
              placeholder={dateFormat}
            />
            <DateField
              value={deadlineTo ? new Date(deadlineTo) : null}
              label="To"
              onChange={(e) => handleDateChange(e, setDeadlineTo)}
              clearable
              placeholder={dateFormat}
            />
          </div>
        </div>
      </div>
    );
  };

  const getHeaderClass = (field: string) => {
    if (field !== sort) {
      return '';
    }

    return ` sorted ${order}`;
  };

  const renderDeliveryRows = () => {
    const dateFormat = 'd.M.yyyy';

    if (!searchResult) {
      return null;
    }

    return (
      <tbody id="delivery-table-body">
        {searchResult.map((d) => (
          <tr
            key={d.id}
            className={d.archived ? 'delivery-row archived' : 'delivery-row' + (d.hasFailedInAC ? ' failed' : '')}
          >
            <td>
              <Checkbox
                data-delivery-id={d.id}
                key={`delivery-id-${d.id}`}
                id={`delivery-id-${d.id}`}
                checked={_.includes(selectedDeliveries, d.id)}
                handleClick={handleDeliverySelect}
              />
            </td>
            <td>
              <NavLink to={d.id} title="View delivery">
                {d.id}
              </NavLink>
            </td>
            <td>
              {_.get(
                d,
                ['meta', 'titles', 'concept', 'fi'],
                _.get(d, ['targetGroup', 'channels', 'mobile']) === -1 ? 'Mobile offers' : '',
              )}
            </td>
            {/* <td>{_.capitalize(_.get(d, ['meta', 'type']))}</td>*/}
            <td>
              {_.get(
                d,
                ['meta', 'titles', 'deliveryTemplate', 'fi'],
                _.get(d, ['targetGroup', 'channels', 'mobile']) === -1 ? _.get(d, 'description') : '',
              )}
            </td>
            <td>
              <span className={`chain-name ${ChainAbbreviations[d.chainId]}`}>{ChainAbbreviations[d.chainId]}</span>
              {_.get(stores, [d.storeId, 'shortName'])}, {d.storeId}
            </td>
            {/* <td>{this.formatChannels(d)}</td>*/}
            <td>{d.deliveryIdentifiers.join(', ')}</td>
            <td>{date.format(castDate(d.startDate), dateFormat)}</td>
            {/* <td>{date.format(castDate(d.endDate), dateFormat)}</td>*/}
            <td>{date.format(castDate(d.deadline), dateFormat)}</td>
            <td>{_.capitalize(d.state)}</td>
            <td>{_.capitalize(d.status)}</td>
            <td>{d.archived ? 'Yes' : 'No'}</td>
            <td>{d.hasFailedInAC ? 'Yes ' : 'No'}</td>
            <td>
              <NavLink to={d.id} title="View delivery">
                <IconArrowRight />
              </NavLink>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const getCheckboxes = () => {
    const tableBody = document.getElementById('delivery-table-body');
    if (tableBody) {
      const result = Array.from(tableBody.querySelectorAll('.checkbox input[type=checkbox]'));
      return result;
    }
    return [];
  };

  const selectAll = (selectAll: boolean) => {
    if (!selectAll) {
      setSelectedDeliveries([]);
    } else {
      const selectedDeliveries = searchResult.map((d) => d.id);
      const { archiveStatusChangeDisabled, currentSelectionArchiveStatus } =
        isArchiveStatusChangeDisabled(selectedDeliveries);
      setSelectedDeliveries(selectedDeliveries);
      setArchiveStatusChangeDisabled(archiveStatusChangeDisabled);
      setCurrentSelectionArchiveStatus(currentSelectionArchiveStatus);
    }
  };

  const disableRebuild = selectedDeliveries.length > 1 || isExecuting;
  const allChecked = getCheckboxes().length && getCheckboxes().length === selectedDeliveries.length;

  return (
    <SidebarWrapper renderSidebar={renderSidebar}>
      <div className="results-view">
        {(isKRautaChain || isOnninenChain) && (
          // These should be removed once BTT migrates from legacy statistics
          <div className="results-container">
            <div className="result">
              <span className="result__value">
                {searchResult
                  .map((d) => (d.recipientEstimates && d.recipientEstimates.email ? d.recipientEstimates.email : 0))
                  .reduce((acc, curr) => acc + curr, 0)}
              </span>
              <span className="result_detail">Estimated e-mail deliveries</span>
            </div>
            <div className="result">
              <span className="result__value">
                {searchResult
                  .map((d) => (d.recipientEstimates && d.recipientEstimates.print ? d.recipientEstimates.print : 0))
                  .reduce((acc, curr) => acc + curr, 0)}
              </span>
              <span className="result_detail">Estimated print deliveries</span>
            </div>
          </div>
        )}
        {(isKRautaChain || isOnninenChain) && (
          <div className="results-container">
            <div className="result">
              <span className="result__value">{getStores().participating || 0}</span>
              <span className="result_detail">kauppaa osallistuu</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.recipients || 0}</span>
              <span className="result_detail">viestiä lähetetty</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.opens || 0}</span>
              <span className="result_detail">viestiä avattu</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.clicks || 0}</span>
              <span className="result_detail">viestin linkkiä klikattu</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.optOuts || 0}</span>
              <span className="result_detail">vastaanottajaa perunut</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.totalRedeemers || 0}</span>
              <span className="result_detail">lunastettua etua</span>
            </div>
            <div className="result">
              <span className="result__value">
                {_.isFinite(categorizedResults.avgPull) ? categorizedResults.avgPull.toFixed(2) : '-'}
              </span>
              <span className="result_detail">keskim. pull</span>
            </div>
          </div>
        )}
        <div className="deliveries">
          <TableContainer loading={isLoading}>
            <table className="styled">
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      id="select-all-attributes"
                      checked={allChecked}
                      handleClick={() => selectAll(!allChecked)}
                    />
                  </th>
                  <th
                    className={`sortable${getHeaderClass('delivery.id')}`}
                    data-sort="delivery.id"
                    onClick={handleSortChange}
                  >
                    Id
                  </th>
                  <th
                    className={`sortable${getHeaderClass('concept.title')}`}
                    data-sort="concept.title"
                    onClick={handleSortChange}
                  >
                    Concept
                  </th>
                  <th
                    className={`sortable${getHeaderClass('deliveryTemplate.title')}`}
                    data-sort="deliveryTemplate.title"
                    onClick={handleSortChange}
                  >
                    Delivery template
                  </th>
                  <th
                    className={`sortable${getHeaderClass('delivery.storeId')}`}
                    data-sort="delivery.storeId"
                    onClick={handleSortChange}
                  >
                    Store
                  </th>
                  <th>Identifiers</th>
                  <th
                    className={`sortable${getHeaderClass('delivery.startDate')}`}
                    data-sort="delivery.startDate"
                    onClick={handleSortChange}
                  >
                    Start
                  </th>
                  <th
                    className={`sortable${getHeaderClass('deadline')}`}
                    data-sort="deadline"
                    onClick={handleSortChange}
                  >
                    Deadline
                  </th>
                  <th
                    className={`sortable${getHeaderClass('delivery.state')}`}
                    data-sort="delivery.state"
                    onClick={handleSortChange}
                  >
                    State
                  </th>
                  <th className={`sortable${getHeaderClass('status')}`} data-sort="status" onClick={handleSortChange}>
                    Status
                  </th>
                  <th
                    className={`sortable${getHeaderClass('archived')}`}
                    data-sort="archived"
                    onClick={handleSortChange}
                  >
                    Archived
                  </th>
                  <th
                    className={`sortable${getHeaderClass('hasFailedInAC')}`}
                    data-sort="hasFailedInAC"
                    onClick={handleSortChange}
                  >
                    Failed in AC
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              {renderDeliveryRows()}
            </table>
          </TableContainer>
          <div style={{ display: selectedDeliveries.length > 0 ? 'flex' : 'none' }} className="actions">
            <span className="summary">
              <b>{selectedDeliveries.length}</b> of {searchResult.length} deliveries selected
            </span>
            {/*
             * Rebuilding multiple deliveries disabled for now as rebuilding is quite resource heavy process.
             * Rebuilding multiple deliveries could go over 30s timeout limit of Heroku which should not matter as
             * process should execute till the end but UI won't get notified after that.
             */}
            <button
              className={disableRebuild ? 'btn btn-disabled' : 'btn'}
              onClick={handleRebuildAction}
              disabled={disableRebuild}
            >
              Rebuild
            </button>
            <button
              className={archiveStatusChangeDisabled ? 'btn btn-disabled' : 'btn'}
              onClick={toggleArchived}
              disabled={archiveStatusChangeDisabled}
            >
              {currentSelectionArchiveStatus ? 'Unarchive' : 'Archive'}
            </button>
            {isExecuting && (
              <div>
                <Spinner addClassName="spinner--margin-right" />
              </div>
            )}
            {message}
          </div>
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default page(inject('deliveriesStore', 'storeStore', 'resultsStore')(observer(Deliveries)));
