/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-floating-promises	*/
import React, { useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import Spinner from 'components/common/next/spinner';
import DrawerContent from '../wrappers/drawerContent';
import ConceptStore from 'stores/next-retailer/conceptStore';
import type { Concept } from 'stores/next-retailer/conceptStore';
import { marked } from 'marked';
import { useParams } from 'react-router-dom';

interface Props {
  conceptStore?: ConceptStore;
}

const ConceptInfo = ({ conceptStore }: Props) => {
  const { conceptId: conceptIdParam } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [concept, setConcept] = useState<Concept>();

  useEffect(() => {
    const init = async () => {
      const concept = await conceptStore.getConcept(conceptIdParam, false);
      if (concept) {
        setConcept(concept);
      }
      setIsLoading(false);
    };
    init();
  }, []);

  if (isLoading) {
    return (
      <DrawerContent>
        <Spinner />
      </DrawerContent>
    );
  }

  if (!concept) {
    return (
      <DrawerContent>
        <h2>Not found</h2>
      </DrawerContent>
    );
  }

  return (
    <DrawerContent renderHeader={() => <h2>{concept.title.fi}</h2>}>
      <div className="concept-info" dangerouslySetInnerHTML={{ __html: marked(concept.description.fi) }} />
    </DrawerContent>
  );
};

export default inject('conceptStore')(observer(ConceptInfo));
