/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import type { WebstoreOffer } from 'types/next';
import InputRadio from '../form/inputRadio';
import InputImage from '../form/inputImage';
import './webstoreForm.scss';
import InputNumber from '../form/inputNumber';
import InputSelect from '../form/inputSelect';
import InputText from '../form/inputText';
import { shouldLockForm } from './offerEditor';

interface Props {
  offer?: WebstoreOffer;
  setOffer: ({ offer, isValid }: { offer: WebstoreOffer; isValid?: boolean }) => void;
  isEditable: boolean;
}

const WebstoreForm = ({ offer, setOffer, isEditable }: Props) => {
  const initialWebstoreOffer: WebstoreOffer = {
    id: uuidv4(),
    type: 'webstoreProduct',
    title: {
      fi: '',
    },
    description: {
      fi: '',
    },
    webstoreDiscount: 'freeDelivery',
    eans: [],
    itemLimit: 1,
    itemLimitPer: 'person',
  };

  useEffect(() => {
    if (!offer) {
      setOffer({ offer: { ...initialWebstoreOffer } });
    }
  }, []);

  const onChange = (path?: string) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const value = e.target.value;
      const name = path || e.target.name;
      handleChange(name, value);
    };
  };

  const handleChange = (name: string, value: any) => {
    const updatedOffer = { ...offer };
    const attr = name.replace(/^offer\./, '');
    _.set(updatedOffer, attr, value);
    setOffer({ offer: updatedOffer });
  };

  const render = () => {
    if (!offer) {
      return null;
    }

    const form = (
      <div className="offer-form-webstore">
        <h3>Tarjoustiedot</h3>
        <div className="form-section first">
          <div className="offer-image">
            <InputImage
              label="Tarjouskuva"
              onChange={({ src }) => handleChange('offer.image', src)}
              value={{ src: offer.image }}
              readOnly
              styles={{ maxHeight: 220 }}
            />
          </div>
          <div className="select-type form-control">
            <label>Edun tyyppi</label>
            <InputRadio
              label="Verkkokaupan ilmainen kotiinkuljetus"
              name="offer.webstoreDiscount"
              value="freeDelivery"
              defaultChecked={offer.webstoreDiscount === 'freeDelivery'}
              onChange={onChange()}
            />
            <InputRadio
              label="Verkkokaupan ilmainen nouto"
              name="offer.webstoreDiscount"
              value="freePickup"
              defaultChecked={offer.webstoreDiscount === 'freePickup'}
              onChange={onChange()}
            />
            <InputRadio
              label="Verkkokaupan ilmainen nouto tai kotiinkuljetus"
              name="offer.webstoreDiscount"
              value="freePickupOrDelivery"
              defaultChecked={offer.webstoreDiscount === 'freePickupOrDelivery'}
              onChange={onChange()}
            />
          </div>
        </div>
        <div className="form-section">
          <div className="input-group item-limit">
            <InputNumber label="Rajoitus" name="offer.itemLimit" value={offer.itemLimit || 1} suffix={'kpl'} readOnly />
            <InputSelect name="offer.itemLimitPer" value={offer.itemLimitPer} onChange={onChange('offer.itemLimitPer')}>
              <option value="person">/ asiakas</option>
              <option value="household">/ talous</option>
            </InputSelect>
          </div>
          <InputText
            label="Muut rajoitukset"
            name="offer.additionalRestrictions"
            value={offer.additionalRestrictions || ''}
            onChange={onChange()}
          />
        </div>
      </div>
    );

    return shouldLockForm(form, isEditable);
  };

  return render();
};

export default WebstoreForm;
