/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import axios from 'axios';
import generatedGitInfo from '../../../../utils/generatedGitInfo.json';
import './gitHash.scss';

const API_URL = process.env.REACT_APP_API_URL;
const versionUrl = `${API_URL}/version`;

interface Props {}
interface State {
  apiHash?: string;
}

async function getGitHash() {
  try {
    const { data } = await axios.get(versionUrl);
    const { result } = data;
    return result.apiVersion;
  } catch (err) {
    if (err.response) {
      throw err;
    }
    return;
  }
}
class GitHash extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      apiHash: '',
    };
  }

  componentDidMount = async () => {
    const currentHash = await getGitHash();
    this.setState({ apiHash: currentHash });
  };

  render() {
    return (
      <div className="git-hash">
        <div className="git-hash-text">
          <p className="version">web: {generatedGitInfo.gitCommitHash.substr(0, 8)}</p>
          <p className="version">api: {this.state.apiHash.substr(0, 8)}</p>
        </div>
        <span className="date">{new Date().toISOString()}</span>
      </div>
    );
  }
}

export default GitHash;
