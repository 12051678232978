/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { Link } from 'react-router-dom';
import { home } from '../next/routes';
import { getLink } from '../retailer/next/routes';

interface Props {
  admin?: boolean;
  toPath?: string;
}

const NameLogo = ({ admin, toPath }: Props) => (
  <Link to={toPath ? toPath : admin ? home.link : getLink('front')} className="name-logo-container">
    <div className="name-logo">
      <img className="logo" src={require('images/k_logo.png')} alt="K" />
      Markkinointi
    </div>
  </Link>
);

export default NameLogo;
