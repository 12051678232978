import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../routes';

class Unauthorized extends React.Component {
  public render() {
    return (
      <div className="lander unauthorized">
        <div className="lander-container">
          <div className="lander-content">
            <div className="lander-box">
              <h1>Sinulla ei ole pääsyä K&nbsp;Markkinointiin</h1>
              <div className="long-left">
                <p>Sinulta puuttuu tarvittava käyttöoikeusrooli. Toimi seuraavasti:</p>
                <p>
                  <span className="emph">Päivittäistavarakauppa:</span> Tilaa K Netin Työsuhteet ja käyttöoikeudet
                  –osiosta K Markkinointi –rooli.
                </p>
                <p>
                  <span className="emph">K-Rauta:</span> Tilaa oikeudet Know-järjestelmästä valitsemalla tuotteeksi K
                  Markkinointi. Tämä rooli avaa kaupan työntekijälle K Markkinoinnin kirjautumiseen vaadittavat
                  oikeudet.
                </p>
                <p>
                  <span className="emph">Onninen:</span> Tilaa KAR:ista AD –rooli. Common Access Rights/Common Tools/K
                  Markkinointi/Onninen Express/oma kauppa.
                </p>
              </div>
              <div className="buttons-container">
                <Link to={routes.info.introduction} className="button button-default">
                  Esittely
                </Link>
                <Link to={routes.logout} className="button button-inverse">
                  Takaisin
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Unauthorized;
