/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access, 	@typescript-eslint/no-unused-vars, @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises, 	@typescript-eslint/no-unsafe-assignment, 	@typescript-eslint/no-var-requires, @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-explicit-any   */
import React, { useEffect, useState } from 'react';
import page from 'components/next/pages/page/page';
import { inject, observer } from 'mobx-react';
import LanguageSelector from 'components/common/next/languageSelector';
import ContentBlockTemplateStore from 'stores/next/contentBlockTemplates';
import { FormInput, FormTextArea } from 'components/next/components/form/input';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import './contentBlock.scss';
import * as _ from 'lodash';
import type {
  ContentBlockTemplate as TemplateEntity,
  DeliveryLanguage,
  Route,
  ContentBlockTemplate,
} from 'components/next/types';
import * as date from 'date-fns';
import { timestampFormat } from 'components/next/utils';
import type { ContentField } from 'components/next/types';
import { castDate } from 'utils/helpers';
import { ReactComponent as SurprisedIcon } from '@kesko/icons/mood/icon-mood_surprise.svg';
import { ReactComponent as HappyIcon } from '@kesko/icons/mood/icon-mood_happy.svg';
import { ReactComponent as UnhappyIcon } from '@kesko/icons/mood/icon-mood_unhappy.svg';
import { StoreState } from 'enums/common';
import { contentBlock, contentBlocks } from 'components/next/routes';

interface ContentBlockProps {
  contentBlockTemplateStore?: ContentBlockTemplateStore;
  getPageLink(route: Route, id: string): string;
}

const ContentBlockTemplate = ({ contentBlockTemplateStore, getPageLink }: ContentBlockProps) => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const [changeSet, setChangeSet] = useState<TemplateEntity>(null);
  const [fields, setFields] = useState<ContentField[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [language, setLanguage] = useState<DeliveryLanguage>('fi');

  useEffect(() => {
    const init = async () => {
      await contentBlockTemplateStore.findById(parseInt(idParam, 10));
      const { contentBlockTemplate } = contentBlockTemplateStore;
      if (!changeSet || contentBlockTemplate.createdAt !== changeSet.createdAt) {
        setChangeSet(getChangeSetFrom(contentBlockTemplate));
      }
      setUnsavedChanges(false);
    };
    init();
  }, [idParam]);

  const getChangeSetFrom = (contentBlockTemplate: ContentBlockTemplate) => {
    return {
      emailHtml: { ...contentBlockTemplate.emailHtml },
      printHtml: { ...contentBlockTemplate.printHtml },
      title: { ...contentBlockTemplate.title },
    };
  };

  const handleSave = async () => {
    await contentBlockTemplateStore.updateContentBlock({
      ...contentBlockTemplateStore.contentBlockTemplate,
      ...changeSet,
    });
    setUnsavedChanges(false);
  };

  const handleDuplicate = async () => {
    const newFinnishTitle =
      contentBlockTemplateStore.contentBlockTemplate.title.fi == changeSet.title.fi
        ? changeSet.title.fi + ' copy'
        : changeSet.title.fi;
    const title = { ...changeSet.title, fi: newFinnishTitle };
    const newContentBlockTemplate = await contentBlockTemplateStore.createContentBlockTemplate({
      ...contentBlockTemplateStore.contentBlockTemplate,
      ...changeSet,
      title,
    });
    const newContentBlockTemplateId = newContentBlockTemplate.id;
    if (newContentBlockTemplateId) {
      navigate(getPageLink(contentBlock, newContentBlockTemplateId));
    }
  };

  const handleDelete = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this content block template? This action cannot be undone.',
    );
    if (confirmed) {
      await contentBlockTemplateStore.deleteContentBlockTemplate(idParam);
      if (contentBlockTemplateStore.status == StoreState.ready) {
        navigate(getPageLink(contentBlocks, null));
      }
    }
  };

  const edit = (fieldName: string, value: string) => {
    const newChangeSet = { ...changeSet };
    _.set(newChangeSet, fieldName, value);
    setChangeSet(newChangeSet);
    setUnsavedChanges(true);
  };

  const renderEditorStatus = () => {
    const storeStatus = contentBlockTemplateStore.status;
    let message = 'All changes saved!';
    let icon = <HappyIcon />;
    let statusClass = 'ok';

    if (unsavedChanges) {
      message = 'There are unsaved changes!';
      statusClass = 'warning';
      icon = <SurprisedIcon />;
    }

    if (storeStatus === StoreState.error) {
      message = 'Error saving changes';
      statusClass = 'error';
      icon = <UnhappyIcon />;
    }

    return (
      <div className={`editor-status ${statusClass}`}>
        <span>
          {message} {icon}
        </span>
      </div>
    );
  };

  const renderHeader = (contentBlockTemplate: TemplateEntity) => {
    return (
      <header className="editor-header">
        <div className="editor-header__controls">
          <NavLink className="go-back" to="">
            <button className="go-back" onClick={() => navigate(-1)}>
              <img src={require('images/arrow-back.svg').default} alt="arrow-back" />
            </button>
          </NavLink>
          <div className="editor-header__details">
            <h3>{contentBlockTemplate.title.fi}</h3>
            <h4>
              <span>ID: {contentBlockTemplate.id}</span>
              <span>Last updated: {date.format(castDate(contentBlockTemplate.updatedAt), timestampFormat)}</span>
            </h4>
          </div>
          <div className="editor-header__actions">
            {renderEditorStatus()}
            <button className="save" onClick={(e) => handleSave()}>
              Save
            </button>
            <button className="duplicate" onClick={(e) => handleDuplicate()}>
              Duplicate
            </button>
            <button className="delete" onClick={(e) => handleDelete()}>
              <img src={require('images/delete.svg').default} alt="delete" />
              Delete
            </button>
          </div>
        </div>
      </header>
    );
  };

  const { contentBlockTemplate } = contentBlockTemplateStore;

  return (
    <div className="content-block">
      {contentBlockTemplate && renderHeader(contentBlockTemplate)}
      {changeSet && (
        <div className="content">
          <section className="editor-section">
            <header className="editor-section__header">
              <div className="title-row">
                <h3 className="section-title">{changeSet.title.fi}</h3>
                <div className="title-row__buttons">
                  <LanguageSelector
                    language={language}
                    languages={['fi', 'sv']}
                    toggleLanguage={(language: DeliveryLanguage) => setLanguage(language)}
                  />
                </div>
              </div>
            </header>
            <FormInput
              label={`Title - ${language}`}
              value={changeSet.title[language]}
              handleChange={(e) => edit(`title.${language}`, e.target.value)}
            />
            <FormTextArea
              label={`Email HTML - ${language}`}
              value={changeSet.emailHtml[language]}
              handleChange={(e) => edit(`emailHtml.${language}`, e.target.value)}
            />
            <FormTextArea
              label={`Print HTML - ${language}`}
              value={changeSet.printHtml[language]}
              handleChange={(e) => edit(`printHtml.${language}`, e.target.value)}
            />
          </section>
        </div>
      )}
    </div>
  );
};

export default page(inject('contentBlockTemplateStore')(observer(ContentBlockTemplate)));
