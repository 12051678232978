/* eslint-disable @typescript-eslint/no-unsafe-argument, react/no-unescaped-entities, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-floating-promises  */
import React from 'react';
import { observer } from 'mobx-react';
import _ from 'utils/lodash';
import InputSelect from 'components/common/next/form/inputSelect';
import { FormInput } from 'components/next/components/form/input';
import Button from 'components/common/next/form/button';
import { parseNumber } from '../stampCardEditor';
import { getLink } from 'components/retailer/next/routes';
import { PRODUCT_IMAGE_PLACEHOLDER } from 'constants/stampCard';
import { CollectionStampCardType, CollectionStampCardStrategy } from 'enums/stampCard';
import type { StampCardEditorTabProps } from '../stampCardEditor';
import { Link } from 'react-router-dom';

interface State {}

@observer
export default class StampCardTypeTab extends React.Component<StampCardEditorTabProps, State> {
  constructor(props: StampCardEditorTabProps) {
    super(props);
  }

  get currentStampCard() {
    return this.props.stampCardStoreNew.current;
  }

  get originalStampCard() {
    return this.props.stampCardStoreNew.original;
  }

  get validationError() {
    return this.props.stampCardStoreNew.validationError;
  }

  get invalidField() {
    return this.props.stampCardStoreNew.invalidField;
  }

  changeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { stampCardStoreNew } = this.props;
    const type = e.target.value as CollectionStampCardType;
    const strategy =
      type === CollectionStampCardType.Basket
        ? CollectionStampCardStrategy.OneTimePurchase
        : CollectionStampCardStrategy.SimpleProductCollecting;
    const oneTimePurchase = strategy === CollectionStampCardStrategy.OneTimePurchase ? 50 : '';

    stampCardStoreNew.updateValue(['products'], []);
    stampCardStoreNew.updateValue(['type'], type);
    stampCardStoreNew.updateValue(['strategy'], strategy);
    stampCardStoreNew.updateValue(['oneTimePurchase'], oneTimePurchase);
  };

  changeNumberValue = (path: string[]) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { stampCardStoreNew } = this.props;
    const numberValue = parseNumber(e.target.value);
    stampCardStoreNew.updateValue(path, numberValue);
  };

  renderProductPicker = () => {
    const products = _.get(this.currentStampCard, 'products', []);
    const { mode, id, currentTab } = this.props;

    return (
      <>
        <div style={{ height: '10px' }} />
        <div className="product-picker form-control">
          <label>Tuotteet</label>
          {products.length === 0
            ? 'Ei tuotteita'
            : _.map(products, (product) => (
                <div key={`product-${_.get(product, ['ean'])}`} className="product-item">
                  <div className="image-container">
                    <img
                      src={
                        _.get(product, ['image'])
                          ? `${_.get(product, ['image'])}?h=50&fm=png`
                          : PRODUCT_IMAGE_PLACEHOLDER
                      }
                      alt={_.get(product, ['title', 'fi'])}
                    />
                  </div>
                  <div className="item-details">
                    <div className="title">{_.get(product, ['title', 'fi'])}</div>
                    <div className="label">
                      <span className="emph">EAN </span>
                      {_.get(product, ['ean'])}
                    </div>
                  </div>
                  <div className="price-container">
                    <div className="title">{product?.price ? parseFloat(product.price).toFixed(2) : '-'}</div>
                    <div className="label">{product?.pricingUnit ? `€ / ${product.pricingUnit}` : '€ / kpl'}</div>
                  </div>
                </div>
              ))}
          <div className="button-container">
            <Button color="default" link="picker/product">
              Muokkaa tuotteita
            </Button>
          </div>
        </div>
      </>
    );
  };

  renderSimpleProductCollectionConfiguration = () => {
    const { isStampCardEditable } = this.props;
    const type = _.get(this.currentStampCard, ['type']);
    if (type === CollectionStampCardType.Basket) {
      return null;
    }

    return (
      <div className="product-configuration form-control">
        <FormInput
          type="number"
          label="Ostettavien tuotteiden lukumäärä"
          min={1}
          step={1}
          handleChange={this.changeNumberValue(['stampConfiguration'])}
          value={_.get(this.currentStampCard, ['stampConfiguration'])}
          readonly={!isStampCardEditable}
          isInvalid={this.invalidField === 'stampConfiguration'}
          validationErrorText={this.validationError}
          detail={!isStampCardEditable && 'Kerättävien tuotteiden määrää ei voi muuttaa leimojen keräysajan alettua.'}
        />
        {this.renderProductPicker()}
      </div>
    );
  };

  renderOneTimePurchaseConfiguration = () => {
    const { isStampCardEditable } = this.props;
    const type = _.get(this.currentStampCard, ['type']);
    if (type !== CollectionStampCardType.Basket) {
      return null;
    }

    return (
      <>
        <div className="form-control">
          <FormInput
            additionalClasses={`${this.invalidField === 'stampConfiguration' ? ' invalid' : ''}`}
            type="number"
            label="Kertaostoksien lukumäärä"
            min={1}
            step={1}
            handleChange={this.changeNumberValue(['stampConfiguration'])}
            value={_.get(this.currentStampCard, ['stampConfiguration'])}
            readonly={!isStampCardEditable}
          >
            {!isStampCardEditable && (
              <div className="form-control detail-text">
                Kertaostoksien määrää ei voi muuttaa leimojen keräysajan alettua.
              </div>
            )}
            {this.invalidField === 'stampConfiguration' && (
              <div className="input-validation-error">{this.validationError}</div>
            )}
          </FormInput>
        </div>
        <div className="form-control">
          <FormInput
            additionalClasses={`${this.invalidField === 'oneTimePurchase' ? ' invalid' : ''}`}
            type="number"
            label="Kertaostoksen suuruus"
            min={1}
            handleChange={this.changeNumberValue(['oneTimePurchase'])}
            value={_.get(this.currentStampCard, ['oneTimePurchase'])}
            readonly={!isStampCardEditable}
          >
            {!isStampCardEditable && (
              <div className="form-control detail-text">
                Kertaostoksen suuruutta ei voi muuttaa leimojen keräysajan alettua.
              </div>
            )}
            {this.invalidField === 'oneTimePurchase' && (
              <div className="input-validation-error">{this.validationError}</div>
            )}
          </FormInput>
        </div>
      </>
    );
  };

  render() {
    const stampCardType = _.get(this.currentStampCard, ['type']);
    return (
      <div>
        <div className="section-header">
          <h2>Keräilypassin tyyppi</h2>
        </div>
        <div className="section-split">
          <div className="left">
            <div className="box">
              <InputSelect label="Keräilyn tyyppi" value={stampCardType} onChange={this.changeType}>
                <option key={CollectionStampCardType.Product} value={CollectionStampCardType.Product}>
                  Tuotteet
                </option>
                <option key={CollectionStampCardType.Basket} value={CollectionStampCardType.Basket}>
                  Kertaostot
                </option>
              </InputSelect>
              {this.renderSimpleProductCollectionConfiguration()}
              {this.renderOneTimePurchaseConfiguration()}
            </div>
          </div>
          <div className="right">
            <div className="box">
              <h3>Ohjeet</h3>
              {stampCardType === CollectionStampCardType.Product && (
                <>
                  <p>
                    Valise keräilyn tyypiksi tuotteet tai ostoskorin loppusumma. Tuotteiden osalta voit luoda
                    keräilypasseja vain kpl- hintaisista tuotteista.
                  </p>
                  <p>Tuotekeräilyssä valitse ostettavien tuotteiden lukumäärä sekä keräiltävät tuotteet.</p>
                  <p>
                    Keräilypasseihin voit lisätä kauppasi valikoimasta löytyviä kpl-hintaisia tuotteita tuotenimen tai
                    EAN-koodin perusteella. Huomioithan, että EAN-koodin täytyy sisältää 13-merkkiä. Jos koodissa on
                    niitä vähemmän, lisää etupuolelle nollia (0) niin monta, että 13-merkin vaatimus täyttyy.
                  </p>
                  <p>
                    Huomioithan, että keräilypassissa ja sen palkinnossa käytettävien ean- ja sisäisten koodien täytyy
                    olla ns. hinnattomia, kassasta löytyviä koodeja.
                  </p>
                  <p>
                    <strong>
                      Älä siis ota esimerkiksi lounaan tai patongin koodia vaa'asta saatavasta hintalapusta, vaan
                      kassasta.
                    </strong>
                  </p>
                  <p>Vaakakoodit eivät kerrytä leimoja asiakkaillesi.</p>
                  <p>
                    Lue tarkempi ohje{' '}
                    <Link target="_blank" to={getLink('help', 'info-stampcard-reward')}>
                      tästä linkistä
                    </Link>
                  </p>
                </>
              )}
              {stampCardType === CollectionStampCardType.Basket && (
                <>
                  <p>Valise keräilyn tyypiksi tuotteet tai ostoskorin loppusumma.</p>
                  <p>
                    Ostoskorialennuksessa valise kerättävien kertaostosten lukumäärä, sekä kertaostoksen minimisuuruus,
                    joka kerryttää leiman keräilypassiin.
                  </p>
                  <p>
                    Ostorajan täyttymisessä ei huomioida lahjakortteja, pullopantteja, äidinmaidonkorvikkeita, tupakka-,
                    alkoholi-, nikotiinikorvaus-, Postin-, Veikkauksen- tai muita välitystuotteita. Etu ei koske
                    tarjoustuotteita, eikä sitä voi yhdistää muihin alennuksiin.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
