/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-floating-promises  */
import React, { Fragment, useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import StampCardStore from 'stores/next/stampCard';
import StampCardStoreNew from 'stores/next-retailer/stampCardStore';
import ProductSelect from 'components/common/next/offerEditor/productSelect';
import DrawerContent from '../wrappers/drawerContent';
import Button from '../../../../common/next/form/button';
import InputImage from '../../../../common/next/form/inputImage';
import InputText from 'components/common/next/form/inputText';
import './stampCardProductPicker.scss';
import {
  WEBSTORE_PRODUCT_FREE_DELIVERY_1,
  WEBSTORE_PRODUCT_FREE_DELIVERY_2,
  WEBSTORE_PRODUCT_FREE_PICKUP,
} from 'utils/constants';
import { SearchType } from 'types/campaign';
import type { Product, CollectionStampCardProduct } from 'types/next';
import { validateEan13 } from 'utils/helpers';
import { PRODUCT_IMAGE_PLACEHOLDER } from 'constants/stampCard';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  newStore?: boolean; // TODO: Remove when old stamp card editor is no longer needed
}

interface InjectedProps extends Props {
  stampCardStore?: StampCardStore;
  stampCardStoreNew?: StampCardStoreNew;
}

interface State {
  products: CollectionStampCardProduct[];
  rewardProducts: CollectionStampCardProduct[];
  productTitle: string;
  productImage: string;
  productEan: string;
}

const StampCardProductPicker = ({ stampCardStore, stampCardStoreNew, newStore }: InjectedProps) => {
  const scStore = newStore ? stampCardStoreNew : stampCardStore;

  const navigate = useNavigate();
  const { type: typeParam } = useParams();

  const initialProducts = scStore.current ? _.get(scStore.current, ['products'], []) : [];
  const initialRewardProducts = scStore.current ? _.get(scStore.current, ['rewardProducts'], []) : [];
  const [products, setProducts] = useState(initialProducts);
  const [rewardProducts, setRewardProducts] = useState(initialRewardProducts);
  const [productTitle, setProductTitle] = useState('');
  const [productImage, setProductImage] = useState('');
  const [productEan, setProductEan] = useState('');

  useEffect(() => {
    if (!scStore.current) {
      navigate(-1);
    }
  }, []);

  const handleCancel = () => {
    const confirmed = window.confirm('Oletko varma, että haluat poistua tallentamatta muutoksia?');
    if (!confirmed) {
      return false;
    }

    navigate(-1);
  };

  const handleSubmit = () => {
    if (productEan.length > 0 || productTitle.length > 0) {
      const confirm = window.confirm(
        'Et ole vielä lisännyt muokkaamaasi tuotetta keräilypassille. Oletko varma, että haluat tallentaa?',
      );
      if (!confirm) {
        return;
      }
    }

    if (typeParam === 'product') {
      scStore.updateValue(['products'], products);
    } else {
      scStore.updateValue(['rewardProducts'], rewardProducts);
    }

    navigate(-1);
  };

  const addProduct = () => {
    const product = {
      title: {
        fi: productTitle,
      },
      ean: productEan,
      image: productImage,
    };

    if (product.image === '') {
      product.image = null;
    }

    selectProduct(product);
    setProductTitle('');
    setProductEan('');
    setProductImage('');
  };

  const selectProduct = (product: Product) => {
    const { ean, title, price, pricingUnit, image } = product;
    const stampCardProduct: CollectionStampCardProduct = { ean, title, price, pricingUnit, image };

    if (typeParam === 'product') {
      setProducts([...products, stampCardProduct]);
    } else {
      setRewardProducts([...rewardProducts, stampCardProduct]);
    }
  };

  const removeProduct = (ean: string) => {
    const {
      reward: { image },
    } = scStore.current;

    if (typeParam === 'product') {
      setProducts(_.filter(products, (product) => product.ean !== ean));
    } else {
      const product = _.find(rewardProducts, { ean });
      if (product && product.image === image) {
        scStore.updateValue(['reward', 'image'], PRODUCT_IMAGE_PLACEHOLDER);
      }

      setRewardProducts(_.filter(rewardProducts, (product) => product.ean !== ean));
    }
  };

  const renderProducts = () => {
    if ((typeParam === 'product' ? products : rewardProducts).length === 0) {
      return <div>Ei tuotteita.</div>;
    }

    return _.map(typeParam === 'product' ? products : rewardProducts, (product) => {
      return (
        <div className="product-item" key={`product-${_.get(product, ['ean'])}`}>
          <div className="image-container">
            <img
              src={_.get(product, ['image']) ? `${_.get(product, ['image'])}?h=50&fm=png` : PRODUCT_IMAGE_PLACEHOLDER}
              alt={_.get(product, ['title', 'fi'])}
            />
          </div>
          <div className="item-details">
            <div className="title">{_.get(product, ['title', 'fi'])}</div>
            <div className="label">
              <span className="emph">EAN </span>
              {_.get(product, ['ean'])}
            </div>
          </div>
          <div className="price-container">
            <div className="title">{product?.price ? parseFloat(String(product.price)).toFixed(2) : '-'}</div>
            <div className="label">{product?.pricingUnit ? `€ / ${product.pricingUnit}` : ''}</div>
          </div>
          <div className="remove-item" onClick={() => removeProduct(_.get(product, ['ean']))}>
            <IconDelete />
          </div>
        </div>
      );
    });
  };

  // Adding products is currenlty disabled
  const renderAddProductForm = () => {
    return (
      <React.Fragment>
        <div className="stampcard-product-picker-add-product-inputs">
          <div>
            <InputText
              key="title"
              name="title"
              label="Tuotenimi"
              value={productTitle}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProductTitle(e.target.value)}
            />

            <InputText
              key="ean"
              name="ean"
              label="EAN-koodi"
              value={productEan}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProductEan(e.target.value)}
            />
          </div>
          <InputImage
            label="Tuotekuva"
            acceptTypes="image/png,image/jpg,image/jpeg"
            onChange={({ src }) => setProductImage(src)}
            value={{ src: productImage }}
          />
        </div>
        <div className="stampcard-product-picker-add-product-actions">
          <span className="detail-text">
            Mikäli tuotetta ei löydy haulla, voit lisätä sen keräilypassille tästä, mikäli tiedät tuotteen EAN-koodin.
          </span>
          <Button color="default" onClick={addProduct} disabled={!validateEan13(productEan)}>
            Lisää
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const renderHeader = () => {
    return <h2>Muokkaa tuotteita</h2>;
  };

  const renderFooter = () => {
    return (
      <Fragment>
        <Button color="inverse" onClick={handleCancel}>
          Peruuta
        </Button>
        <Button color="default" onClick={handleSubmit}>
          Tallenna
        </Button>
      </Fragment>
    );
  };

  if (!scStore.current) {
    return null;
  }

  return (
    <DrawerContent className="stampcard-product-picker" renderHeader={renderHeader} renderFooter={renderFooter}>
      <Fragment>
        <h2>Tuotteet</h2>
        <div className="stampcard-product-picker-products">{renderProducts()}</div>
        <h2>
          Hae tuotetta <small>(Voit valita vain kpl-hintaisia tuotteita)</small>
        </h2>
        <ProductSelect
          filterEans={_.map(typeParam === 'product' ? products : rewardProducts, 'ean')}
          allowByPricingUnit="kpl"
          searchType={SearchType.add}
          onSelect={selectProduct}
          extraProducts={[
            WEBSTORE_PRODUCT_FREE_DELIVERY_1,
            WEBSTORE_PRODUCT_FREE_DELIVERY_2,
            WEBSTORE_PRODUCT_FREE_PICKUP,
          ]}
          closeSearchOnSelect
          placeholder="Hae tuotetta nimellä tai EAN-koodilla"
        />
        {/* Adding products is currently disabled
        <h2>Lisää oma tuote</h2>
        <div className="stampcard-product-picker-add-product">{this.renderAddProductForm()}</div>
        */}
      </Fragment>
    </DrawerContent>
  );
};

export default inject('stampCardStore', 'stampCardStoreNew')(observer(StampCardProductPicker));
