/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props {
  name: string;
  label: string;
  checked: boolean;
  disabled: boolean;
  className?: string;
  onChange?: (e: React.SyntheticEvent) => any;
}

const InputCheckbox = ({ label, className, ...inputProps }: Props) => (
  <div className={`checkbox ${className || ''}`}>
    <input type="checkbox" id={`checkbox-${inputProps.name}`} {...inputProps} />
    {label && <label htmlFor={`checkbox-${inputProps.name}`}>{label}</label>}
  </div>
);

export default InputCheckbox;
