/* eslint-disable @typescript-eslint/no-unsafe-assignment  */
import React from 'react';
import { observer } from 'mobx-react';
import page from 'components/next/pages/page/page';
import Spinner from 'components/common/next/spinner';
import './statistics.scss';
import DeliveryTemplates from './tabs/deliveryTemplates';
import Deliveries from './tabs/deliveries';
import StampCards from './tabs/stampCards';
import PushNotifications from './tabs/pushNotifications';
import BestPerforming from './tabs/bestPerforming';
import AutomaticParticipation from './tabs/automaticParticipation';

interface StatisticsProps {
  chainIds: string[];
}

interface StatisticsState {
  currentTab: number;
  isLoading: boolean;
}

@observer
class Statistics extends React.Component<StatisticsProps, StatisticsState> {
  constructor(props: StatisticsProps) {
    super(props);
    this.state = {
      currentTab: 0,
      isLoading: true,
    };
  }

  get tabs() {
    return [
      {
        name: 'Delivery Templates',
        tab: 0,
      },
      {
        name: 'Deliveries',
        tab: 1,
      },
      {
        name: 'Stamp Cards',
        tab: 2,
      },
      {
        name: 'Push Notifications',
        tab: 3,
      },
      {
        name: 'Best Performing',
        tab: 4,
      },
      {
        name: 'Automatic Participation',
        tab: 5,
      },
    ];
  }

  // eslint-disable-next-line
  componentDidMount = async () => {
    this.setState({ isLoading: false });
  };

  renderHeader() {
    return (
      <header className="editor-header">
        <nav className="editor-header__tabs">
          {this.tabs.map(({ name, tab }) => (
            <button
              key={`tab-${tab}`}
              className={`tab-selector ${this.state.currentTab === tab ? 'is-selected' : ''}`}
              onClick={this.changeTab.bind(this, tab)}
            >
              {name}
            </button>
          ))}
        </nav>
      </header>
    );
  }

  renderDeliveryTemplates() {
    return <DeliveryTemplates chainIds={this.props.chainIds}>{this.renderHeader()}</DeliveryTemplates>;
  }

  renderDeliveries() {
    return <Deliveries chainIds={this.props.chainIds}>{this.renderHeader()}</Deliveries>;
  }

  renderStampCards() {
    return <StampCards>{this.renderHeader()}</StampCards>;
  }

  renderPushNotifications() {
    return <PushNotifications>{this.renderHeader()}</PushNotifications>;
  }

  renderBestPerforming() {
    return <BestPerforming>{this.renderHeader()}</BestPerforming>;
  }

  renderAutomaticParticipation() {
    return <AutomaticParticipation chainIds={this.props.chainIds}>{this.renderHeader()}</AutomaticParticipation>;
  }

  renderTab() {
    const { currentTab } = this.state;
    return (
      <>
        {currentTab === 0 && this.renderDeliveryTemplates()}
        {currentTab === 1 && this.renderDeliveries()}
        {currentTab === 2 && this.renderStampCards()}
        {currentTab === 3 && this.renderPushNotifications()}
        {currentTab === 4 && this.renderBestPerforming()}
        {currentTab === 5 && this.renderAutomaticParticipation()}
      </>
    );
  }

  changeTab(tab: number) {
    this.setState({ isLoading: true });
    this.setState({ currentTab: tab });
    this.setState({ isLoading: false });
  }

  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return this.renderTab();
  }
}

export default page(Statistics);
