/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access */
import React, { ChangeEvent } from 'react';
import DeliveryStore, { DeliveryWithMeta } from 'stores/next-retailer/deliveryStore';
import { toJS } from 'mobx';
import InputText from 'components/common/next/form/inputText';
import { inject } from 'mobx-react';

interface State {
  description: string;
}

interface Props {
  delivery: DeliveryWithMeta;
}

interface InjectedProps extends Props {
  deliveryStore: DeliveryStore;
}

@inject('deliveryStore')
class DescriptionBox extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      description: props.delivery.description || '',
    };
  }

  get injected() {
    return this.props as InjectedProps;
  }

  get delivery() {
    return this.props.delivery;
  }

  get deliveryStore() {
    return this.injected.deliveryStore;
  }

  descriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const delivery = toJS(this.delivery);
    const eventValue = e.target.value;
    const description = eventValue === '' ? null : eventValue.trim();
    delivery.description = description;
    this.deliveryStore.setCurrent(delivery);
    this.setState({ description: eventValue });
  };

  public render() {
    return (
      <div className="box">
        <InputText
          name="deliveryDescription"
          label="Kirjoita halutessasi tähän lyhyesti lähetyksen kuvaus"
          value={this.state.description}
          onChange={this.descriptionChange}
        />
      </div>
    );
  }
}

export default DescriptionBox;
