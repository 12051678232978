/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access  */
import React from 'react';
import type { ContentSlot, ContentBlock, DeliveryLanguage } from 'components/next/types';
import { Checkbox } from 'components/next/components/checkbox';
import { FormInput } from 'components/next/components/form/input';
import Dropdown, { DropdownItem } from 'components/next/components/dropdown';
import ToggleButton from 'components/common/next/form/toggleButton';
import { ReactComponent as IconClear } from '@kesko/icons/action/icon-clear.svg';
import * as _ from 'lodash';

export interface SlotEditorProps {
  index: number;
  language: DeliveryLanguage;
  parentIndex: number;
  slot: ContentSlot;
  contentBlocks: ContentBlock[];
  update(i: number, s: ContentSlot): void;
}

const SlotEditor = (props: SlotEditorProps) => {
  const { slot, language = 'fi' } = props;
  const contentBlocks: DropdownItem[] = props.contentBlocks.map((b) => ({
    name: b.title[language] || b.title.fi,
    value: b.id,
  }));
  const selectedContentBlocks = props.contentBlocks.filter((b) => props.slot.contentBlocks.includes(b.id));

  const selectContentBlock = (value: string) => {
    const { contentBlocks } = props;
    const contentBlock = contentBlocks.find((b) => b.id === value);
    if (contentBlock) {
      const slot = { ...props.slot };
      slot.contentBlocks.push(contentBlock.id);
      props.update(props.index, slot);
    }
  };

  const handleUpdate = (fieldName: string, value: string) => {
    const slot = { ...props.slot };
    _.set(slot, fieldName, value);
    props.update(props.index, slot);
  };

  const removeContentBlockSelection = (id: string) => {
    const slot = { ...props.slot };
    slot.contentBlocks = slot.contentBlocks.filter((block) => block !== id);
    props.update(props.index, slot);
  };

  const setDefault = (id: string) => {
    const { defaultContentBlock } = slot;
    handleUpdate('defaultContentBlock', id === defaultContentBlock ? null : id);
  };

  const contentBlockList = (
    <div className="selected-blocks">
      <h3>Selected content blocks</h3>
      <table className="styled">
        <thead>
          <tr>
            <th>Title</th>
            <th>Set as default</th>
            <th>Remove from selection</th>
          </tr>
        </thead>
        <tbody>
          {selectedContentBlocks.map((contentBlock, i) => {
            return (
              <tr key={i}>
                <td>{contentBlock.title[language] || contentBlock.title.fi}</td>
                <td className="toggle-container">
                  <ToggleButton
                    notSelectedText="select"
                    selectedText="selected"
                    isSelected={props.slot.defaultContentBlock === contentBlock.id}
                    toggle={setDefault}
                    value={contentBlock.id}
                  />
                </td>
                <td className="delete-container">
                  <button className="delete-button" onClick={() => removeContentBlockSelection(contentBlock.id)}>
                    <IconClear />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="slot-editor">
      <h3>{slot.title[language]}</h3>
      <div className="slot-details">
        <span>HTML identifier: {slot.identifier || 'unknown'}</span>
      </div>
      <Checkbox
        id={`${props.parentIndex}-required-${props.index}`}
        label="Required"
        checked={slot.required}
        handleClick={(e) => handleUpdate('required', e.target.checked)}
      />
      <FormInput
        label={`Slot title - ${language}`}
        required={true}
        value={slot.title[language]}
        handleChange={(e) => handleUpdate(`title.${language}`, e.target.value)}
      />
      <Dropdown notSelectedText="Add a Content Block" data={contentBlocks} select={selectContentBlock} />
      {selectedContentBlocks.length > 0 ? (
        <h4 className="content-block-name">{contentBlockList}</h4>
      ) : (
        <h4 className="content-block-name required-missing">No Content Block selected</h4>
      )}
    </div>
  );
};

export default SlotEditor;
