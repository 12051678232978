/* eslint-disable 	@typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-argument, 	@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/unbound-method, react/prop-types, @typescript-eslint/no-misused-promises  */
import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as dateFns from 'date-fns';
import CollectionStampCardStore from 'stores/next/stampCard';
import page from 'components/next/pages/page/page';
import Spinner from 'components/common/next/spinner';
import type { CollectionStampCard } from 'components/next/types';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import ChainSelector from 'components/next/components/chainSelector';
import { castDate } from 'utils/helpers';
import { DateField } from 'components/next/components/form/input';
import { dateFormat, payloadDateFormat } from 'components/next/utils';
import { kRautaChainIds, ChainAbbreviations, onninenChainIds } from 'constants/common';
import { CollectionStampCardType } from 'enums/stampCard';

interface State {
  chainIds: string[];
  isLoading: boolean;
  message?: string;
  order: 'asc' | 'desc';
  sort?: string;
  searchResult: CollectionStampCard[];
  stampCardIds: string[];
  statistics: string[];
  activeFrom: string;
  activeTo: string;
}

interface Props {
  stampCardStore: CollectionStampCardStore;
}

@inject('stampCardStore')
@observer
class StampCards extends React.Component<Props, State> {
  debouncedSearchCollectionStampCards: () => void;

  constructor(props) {
    super(props);
    this.state = {
      chainIds: ['3', '4', '5'],
      sort: 'activeFrom',
      order: 'desc',
      message: null,
      isLoading: true,
      searchResult: [],
      statistics: [],
      stampCardIds: [],
      activeFrom: '',
      activeTo: '',
    };
    this.debouncedSearchCollectionStampCards = _.debounce(this.searchCollectionStampCards, 500);
  }

  get collectionStampCards() {
    return this.state.searchResult;
  }

  get stampCardStore() {
    return this.props.stampCardStore;
  }

  get statistics() {
    return this.state.statistics;
  }

  componentDidMount = async () => {
    await this.searchCollectionStampCards();
    await this.searchStatistics();
  };

  searchCollectionStampCards = async (limit = 0) => {
    this.setState({ isLoading: true });
    const { stampCardStore } = this.props;
    const { chainIds, order, sort, activeFrom, activeTo } = this.state;
    const search = _.omitBy(
      {
        chainId: chainIds,
        activeFrom,
        activeTo,
      },
      _.isNil,
    );

    try {
      const collectionStampCards = await stampCardStore.searchCollectionStampCards({
        payload: search,
        params: {
          limit,
          sort,
          order,
        },
      });
      const searchResult = collectionStampCards.data.result as CollectionStampCard[];
      const stampCardIds = _.map(searchResult, 'id');
      this.setState({
        searchResult,
        stampCardIds,
        isLoading: false,
      });
      this.handleStatisticsMapping(this.state.searchResult);
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  searchStatistics = async () => {
    this.setState({ isLoading: true });
    // This call can be rather slow
    await this.stampCardStore.getCollectionStampCardStatistics({ id: this.state.stampCardIds });
    this.handleStatisticsMapping(this.state.searchResult);
    this.setState({ isLoading: false });
  };

  // eslint-disable-next-line
  handleStatisticsMapping = async (stampCards: CollectionStampCard[]) => {
    this.setState({ isLoading: true });
    const { stampCardStatistics } = this.stampCardStore;

    const result = [];

    stampCards.forEach((stampCard) => {
      const stampCardChain = _.uniq(stampCard.stores.map((store) => store.chainId));
      // Get rid of stamp cards that have multiple chains set since we don't want to display them
      if (stampCardChain.length > 1) {
        return;
      }

      const stats = _.get(stampCardStatistics, [stampCard.id], {});
      // Registered households
      const stampcardRegistrationsQty = _.get(stats, ['stampcardRegistrationsQty'], 0);
      // total stamp QTY per stamp card
      const stampQty = _.get(stats, ['stampQty'], 0);
      const date = castDate(stampCard.activeFrom);
      const reformattedStampCard = {
        id: stampCard.id,
        storeIds: stampCard.stores.map((s) => {
          return s.storeId;
        }),
        chainId: stampCardChain[0], // stampCardChain always has only one element
        createdAt: stampCard.createdAt,
        activeFrom: stampCard.activeFrom,
        activeTo: stampCard.activeTo,
        registrationQty: stampcardRegistrationsQty,
        stampQty: stampQty,
        date: `${date.getFullYear()}-${date.getMonth() + 1}`,
      };

      // If the result array contains an object with the same date and chainId, push reformattedStampCard to the existing object
      const existingObject = _.find(result, { date: reformattedStampCard.date, chainId: reformattedStampCard.chainId });
      if (existingObject) {
        existingObject.stampCards.push(reformattedStampCard);
      } else {
        const newObject = {
          date: reformattedStampCard.date,
          chainId: reformattedStampCard.chainId,
          stampCards: [],
        };
        newObject.stampCards.push(reformattedStampCard);
        result.push(newObject);
      }
    });
    this.setState({ statistics: result, isLoading: false });
  };

  handleChainChange = (chain: string) => {
    const chainIds = [...this.state.chainIds];
    if (chainIds.includes(chain)) {
      _.pullAt(chainIds, chainIds.indexOf(chain));
    } else {
      chainIds.push(chain);
    }
    this.setState({ chainIds, isLoading: true });
    this.debouncedSearchCollectionStampCards();
  };

  handleDateChange = (date: Date, field: string) => {
    const value = dateFns.isDate(date) ? dateFns.format(date, payloadDateFormat) : undefined;
    this.setState<never>({ [field]: value || null, isLoading: true });
    this.debouncedSearchCollectionStampCards();
  };

  renderSidebar = () => {
    const { chainIds, isLoading } = this.state;
    return (
      <div className="collectionStampCards-sidebar">
        <div className="collectionStampCards-filters">
          <div className="sidebar__title-wrapper">
            <h3 className="sidebar__title">Filters</h3>
            {isLoading && <Spinner addClassName="spinner--unset" />}
          </div>
          <div className="filter-group">
            <label>Chain</label>
            <ChainSelector
              chainSelection={chainIds}
              handleChainChange={this.handleChainChange}
              excludeChains={[...kRautaChainIds, ...onninenChainIds]}
            />
          </div>
          <div className="filter-group">
            <label>Start date</label>
            <DateField
              value={this.state.activeFrom ? new Date(this.state.activeFrom) : null}
              label="From"
              onChange={(e) => this.handleDateChange(e, 'activeFrom')}
              clearable
              placeholder={dateFormat}
            />
            <DateField
              value={this.state.activeTo ? new Date(this.state.activeTo) : null}
              label="To"
              onChange={(e) => this.handleDateChange(e, 'activeTo')}
              clearable
              placeholder={dateFormat}
            />
          </div>
        </div>
      </div>
    );
  };

  getHeaderClass = (field: string) => {
    const { sort, order } = this.state;

    if (field !== sort) {
      return '';
    }

    return ` sorted ${order}`;
  };

  getCollectionStampCardType = (type: CollectionStampCardType) => {
    switch (type) {
      default:
      case CollectionStampCardType.Product:
        return 'Product collecting';
      case CollectionStampCardType.Basket:
        return 'One time purchase';
    }
  };

  delete = async (cardId: string) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this collection stamp card? This action cannot be undone.',
    );

    if (confirmed) {
      await this.stampCardStore.deleteCollectionStampCard(cardId, false);
      this.searchCollectionStampCards();
    }
  };

  renderCollectionStampCardRows = () => {
    if (this.state.isLoading) {
      return null;
    }
    const dateFormat = 'MMMM yyyy';
    return (
      <tbody id="collectionStampCard-table-body">
        {this.statistics &&
          this.statistics.map((statistic, index) => {
            const date = dateFns.parse(_.get(statistic, ['date']), 'yyyy-M', new Date());
            const chainId = _.get(statistic, ['chainId']);
            const stampCards = _.get(statistic, ['stampCards']);
            // Get storeIds from each period stampcards and filter only unique storeIds
            const periodStoreIds: string[] = stampCards.reduce((acc, curr) => [...acc, ...curr.storeIds], []);
            const periodUniqueStores = [...new Set(periodStoreIds)];

            return (
              <tr key={index}>
                <td>
                  {ChainAbbreviations[chainId] ? (
                    <span className={`chain-name ${ChainAbbreviations[chainId]}`}>{ChainAbbreviations[chainId]}</span>
                  ) : null}
                </td>
                <td>{dateFns.format(date, dateFormat)}</td>
                <td>{stampCards.length}</td>
                <td>{periodUniqueStores.length}</td>
              </tr>
            );
          })}
      </tbody>
    );
  };

  render() {
    const { isLoading } = this.state;

    return (
      <SidebarWrapper renderSidebar={this.renderSidebar}>
        <div className="collectionStampCards">
          {this.props.children}
          <div className="results-container">
            <div className="result">
              <span className="result__value">
                {this.collectionStampCards
                  .map((stampCard) => {
                    const stats = _.get(this.stampCardStore.stampCardStatistics, [stampCard.id], {});
                    return _.get(stats, ['stampcardRegistrationsQty'], 0);
                  })
                  .reduce((acc, curr) => acc + curr, 0)}
              </span>
              <span className="result_detail">stamp cards created</span>
            </div>
          </div>
          <div className="table-container">
            <table className="styled">
              <thead>
                <tr>
                  <th className={this.getHeaderClass('id')}>Chain</th>
                  <th className={this.getHeaderClass('uiData.title.fi')}>Month / Year</th>
                  <th className={this.getHeaderClass('storeId')}>Active stamp cards</th>
                  <th className={this.getHeaderClass('type')}>Stores with active stamp cards</th>
                </tr>
              </thead>
              {this.renderCollectionStampCardRows()}
            </table>
          </div>
          {isLoading && <Spinner />}
        </div>
      </SidebarWrapper>
    );
  }
}
export default page(StampCards);
