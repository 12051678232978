/* eslint-disable react/no-unescaped-entities, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-floating-promises  */
import React from 'react';
import { observer } from 'mobx-react';
import _ from 'utils/lodash';
import Button from 'components/common/next/form/button';
import { getLink } from 'components/retailer/next/routes';
import { format } from 'date-fns';
import { CHAIN_LOGOS, STAMP_COLLECTION_INSTRUCTIONS } from 'constants/stampCard';
import type { StampCardEditorTabProps } from '../stampCardEditor';
import { FormInput, FormTextArea } from 'components/next/components/form/input';

interface State {}

@observer
export default class StampCardAppearanceTab extends React.Component<StampCardEditorTabProps, State> {
  constructor(props: StampCardEditorTabProps) {
    super(props);
  }

  get currentStampCard() {
    return this.props.stampCardStoreNew.current;
  }

  get originalStampCard() {
    return this.props.stampCardStoreNew.original;
  }

  get validationError() {
    return this.props.stampCardStoreNew.validationError;
  }

  get invalidField() {
    return this.props.stampCardStoreNew.invalidField;
  }

  renderCardStores = () => {
    const { stores, chains } = this.currentStampCard;
    const { storesByStoreId } = this.props.userStore;

    if (this.props.keskoStampCard) {
      return (
        <div className="stampcard-details-stores">
          {_.map(chains, (chain: string) => {
            const image = <img src={CHAIN_LOGOS[chain]} alt={chain} />;

            return (
              <div key={`card-chain-${chain}`} className="stampcard-details-store">
                {image}
              </div>
            );
          })}
        </div>
      );
    }

    if (stores.length === 0) {
      return null;
    }

    return (
      <div className="stampcard-details-stores">
        {_.map(stores, ({ storeId }) => {
          const storeData = _.get(storesByStoreId, [storeId]);
          const { chainId, name, shortName } = storeData;

          const image =
            chainId && _.includes(['3', '4', '5'], chainId) ? <img src={CHAIN_LOGOS[chainId]} alt={name} /> : null;

          return (
            <div key={`card-store-${storeId}`} className="stampcard-details-store">
              {image}
              <span>{shortName}</span>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { mode, id, currentTab, stampCardStoreNew } = this.props;
    const currentStampCard = this.currentStampCard;
    const activeFrom = format(new Date(currentStampCard.activeFrom), 'd.M.yyyy');
    const activeTo = format(new Date(currentStampCard.activeTo), 'd.M.yyyy');
    return (
      <div>
        <div className="section-header">
          <h2>Keräilypassin ulkoasu</h2>
        </div>
        <div className="section-split">
          <div className="left">
            <div className="box stampcard-appearance">
              <div className="stampcard-preview-container">
                <div className="stampcard-preview">
                  <img className="stampcard-preview-image" src={currentStampCard.uiData.imageUrl.fi} alt="stamp-card" />
                  <div className="stampcard-details-container">
                    <div className="stampcard-details-name">{currentStampCard.uiData.uiName.fi}</div>
                    <div className="stampcard-details-active">{`Keräilyaika: ${activeFrom} - ${activeTo}`}</div>
                    {this.renderCardStores()}
                  </div>
                </div>
              </div>
              <div className="button-container">
                <Button color="bordered" link="image">
                  Muokkaa kuvaa
                </Button>
              </div>
              <div className="form-control">
                <FormInput
                  id="title"
                  label="Otsikko"
                  placeholder="Otsikko..."
                  value={currentStampCard.uiData.uiName.fi}
                  handleChange={(e) => stampCardStoreNew.updateValue(['uiData', 'uiName', 'fi'], e.target.value)}
                  isInvalid={this.invalidField === 'uiData.uiName.fi'}
                  validationErrorText={this.validationError}
                />
              </div>
              <div className="form-control">
                <FormTextArea
                  id="description"
                  label="Asiakkaalle näkyvä kuvaus"
                  detail="Lisää tähän kuvaus, mistä tuotteista/ostoista saa leiman ja mikä on palkintoetuna."
                  value={currentStampCard.uiData.description.fi}
                  rows={4}
                  handleChange={(e) => stampCardStoreNew.updateValue(['uiData', 'description', 'fi'], e.target.value)}
                  isInvalid={this.invalidField === 'uiData.description.fi'}
                  validationErrorText={this.validationError}
                />
              </div>
              <div className="form-control">
                <FormTextArea
                  id="info"
                  label="Keräilyn tiedot"
                  value={STAMP_COLLECTION_INSTRUCTIONS[currentStampCard.type]}
                  rows={3}
                  readonly
                />
              </div>
            </div>
          </div>
          <div className="right">
            <div className="box">
              <h3>Ohjeet</h3>
              <p>
                Kun luot keräilypassia, näet muutoksia tehdessäsi esikatselunäkymän K-Ruoka sovelluksen passien
                listausnäkymästä. Voit muokata kuvaa ja valita eri taustakuvista juuri sinun passiisi sopivimman. Kuvaa
                muokatessasi pidä mielessä erottuvuus. Mitä tietoja laitat passin kuvaan, jotta asiakkaat ymmärtävät sen
                koskevan juuri sinun kauppasi passia sekä saavat olennaiset tiedot passista vain kuvaa vilkaisemalla.
                Voit saada passiisi erottuvuutta hyödyntämallä passin kuvamuokkauksen "pallo" ja "banner'" osioita ja
                lisätä kuvaan oman tekstin.
              </p>
              <p>
                Anna myös keräilypassillesi kuvaava asiakkaalle näkyvä otsikko, sekä lisää kuvaustekstiin kerailyssä
                olevientuotteiden / leimojen tiedot, eli mistä tuotteista / ostoista saa leiman, ja minkä palkinnon saa
                täydestä passista. Lisää myös mahdolliset keräilyyn tai palkintoetuun littyvät rajoitukset
                kuvaustekstiin. Huomioi että palkintoetu on talouskohtainen.
              </p>
              <p>Keräilyn tiedot -osio lisätään passiin automaattisesti, eikä se ole muokattavissa.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
