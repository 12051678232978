/* eslint-disable @typescript-eslint/no-floating-promises, @typescript-eslint/no-unnecessary-type-assertion */
import React, { Fragment, useEffect, useState } from 'react';
import '../concept/conceptView.scss';
import { inject, observer } from 'mobx-react';
import DeliveryStore, { DeliveryWithMeta } from 'stores/next-retailer/deliveryStore';
import Spinner from 'components/common/next/spinner';
import { DeliveryState, DeliveryStatus } from 'enums/common';
import _ from 'utils/lodash';
import MobileDeliveryBox from './mobileDeliveryBox';
import Button from 'components/common/next/form/button';

interface Props {
  deliveryStore?: DeliveryStore;
}

const INITIAL_VISIBLE_COUNT = 5;
const INCREMENT = 5;
const MobileDeliveriesView = ({ deliveryStore }: Props) => {
  const [loading, setLoading] = useState(false);
  const [visibleCount, setVisibleCount] = useState(INITIAL_VISIBLE_COUNT);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await deliveryStore.search();
      setLoading(false);
    };
    init();
  }, []);
  const getMobileDeliveries = () => {
    return _.chain(deliveryStore.deliveries)
      .filter((d) => d.deliveryTemplate === '' && d.targetGroup.channels.mobile === -1)
      .orderBy((d) => d.startDate, 'asc')
      .value();
  };

  const deliveries = getMobileDeliveries();

  const getScheduledDeliveries = () => {
    const scheduledDeliveries = deliveries.filter(
      (d) => d.status === DeliveryStatus.Editable && d.state === DeliveryState.Confirmed,
    );
    return scheduledDeliveries;
  };
  const getNotEditableDeliveries = () => {
    const nonEditableDeliveries = deliveries.filter(
      (d) => d.status === DeliveryStatus.Processed && d.state === DeliveryState.Confirmed,
    );
    return nonEditableDeliveries;
  };
  const getDraftDeliveries = () => {
    return deliveries.filter((d) => d.state === DeliveryState.Draft);
  };
  const getOngoingDeliveries = () => {
    return deliveries.filter(
      (d) => d.status === DeliveryStatus.Ongoing && d.state === DeliveryState.Confirmed,
    );
  };
  const getFinishedDeliveries = () => {
    return deliveries.filter(
      (d) => d.status === DeliveryStatus.Finished && d.state === DeliveryState.Confirmed,
    )
    .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
  };

  const loadMore = () => {
    setVisibleCount((prev) => prev + INCREMENT);
 }

 const showLess = () => {
   setVisibleCount(INITIAL_VISIBLE_COUNT);
 }

  const renderDeliveryList = () => {
    const scheduledDeliveries = [...getScheduledDeliveries(), ...getNotEditableDeliveries()];
    const draftDeliveries = getDraftDeliveries();
    const ongoingDeliveries = getOngoingDeliveries();
    const finishedDeliveries = getFinishedDeliveries();

    return (
      <div className="template-list">
        {draftDeliveries.length > 0 && (
          <Fragment>
            <h2>Kesken</h2>
            {draftDeliveries.map((delivery) => (
              <MobileDeliveryBox key={delivery.id} delivery={delivery} />
            ))}
          </Fragment>
        )}
        {scheduledDeliveries.length > 0 && (
          <Fragment>
            <h2>Ajastettu</h2>
            {scheduledDeliveries.map((item) => {
              return <MobileDeliveryBox key={item.id} delivery={item as DeliveryWithMeta} />;
            })}
          </Fragment>
        )}
        {ongoingDeliveries.length > 0 && (
          <Fragment>
            <h2>Käynnissä</h2>
            {ongoingDeliveries.map((delivery) => (
              <MobileDeliveryBox key={delivery.id} delivery={delivery} />
            ))}
          </Fragment>
        )}
        {finishedDeliveries.length > 0 && (
          <Fragment>
            <h2>Valmis</h2>
            {finishedDeliveries
            .slice(0, visibleCount)
            .map((delivery) => (
              <MobileDeliveryBox key={delivery.id} delivery={delivery} />
            ))}
            {visibleCount < finishedDeliveries.length && (
              <Button onClick={loadMore}>Näytä lisää...</Button>
            )}
            {visibleCount > INITIAL_VISIBLE_COUNT && (
              <Button onClick={showLess}>Näytä vähemmän...</Button>
            )}
          </Fragment>
        )}
      </div>
    );
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="concept-view">
      <div className="main-content">
        <div className="content">{renderDeliveryList()}</div>
      </div>
    </div>
  );
};

export default inject('deliveryStore')(observer(MobileDeliveriesView));
