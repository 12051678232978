/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-misused-promises, @typescript-eslint/no-unsafe-member-access   */
import LanguageSelector from 'components/common/next/languageSelector';
import { Checkbox } from 'components/next/components/checkbox';
import { CustomField, DateField, FormInput, FormTextArea } from 'components/next/components/form/input';
import WeekdaySelect from 'components/next/components/weekdaySelect';
import type { DeliveryLanguage, DeliveryTemplate, Store } from 'components/next/types';
import { payloadDateFormat } from 'components/next/utils';
import * as dateFns from 'date-fns';
import React, { useEffect, useState } from 'react';
import { isKRuokaOnlyChain } from 'utils/helpers';
import ConceptsStore from 'stores/next/programs';
import { inject, observer } from 'mobx-react';
import _ from 'utils/lodash';
import { GT_LEAD_TIME_DAYS, BTT_LEAD_TIME_DAYS } from 'utils/constants';
import StoreSelector from 'components/next/components/storeSelector';
import StoreStore from 'stores/next/stores';
import { kRautaChainIds } from 'constants/common';
import { ConceptType, DeliveryChannelName } from 'enums/common';
import PricingGroupSelector from 'components/next/components/pricingGroupSelector/pricingGroupSelector';

interface GeneralProps {
  template: DeliveryTemplate;
  create: boolean;
  handleSimpleValueChange(value, fieldName: string): void;
  chainIds: string[];
  conceptsStore?: ConceptsStore;
  storeStore?: StoreStore;
}

const General = ({ template, chainIds, handleSimpleValueChange, conceptsStore, storeStore }: GeneralProps) => {
  const [language, setLanguage] = useState<DeliveryLanguage>(template.languages[0]);
  const [stores, setStores] = useState<Store[]>([]);

  const allowedWeekdays = template.deliveryWeekdays || [0, 1, 2, 3, 4, 5, 6];
  const isProgram = conceptsStore.concept.type === 'program';
  const hasPrintTemplate = !!template.channels.find((channel) => channel.name === DeliveryChannelName.Print);
  const isKrautaProgramDelivery =
    _.intersection(conceptsStore.concept.chainIds, kRautaChainIds).length > 0 &&
    conceptsStore.concept.type === ConceptType.Program;

  const parseNumber = (value: any) => {
    const parsed = Number(value);
    if (!isNaN(parsed)) {
      return parsed;
    }
    return '';
  };

  useEffect(() => {
    const init = async () => {
      const stores = await storeStore.searchStores({ includeTestStores: true });
      setStores(stores);
    };
    init();
  }, []);

  const toggleAllowedWeekday = (weekday: number) => {
    let deliveryWeekdays = [...allowedWeekdays];
    if (deliveryWeekdays.includes(weekday)) {
      deliveryWeekdays = deliveryWeekdays.filter((day) => day !== weekday);
    } else {
      deliveryWeekdays = [...deliveryWeekdays, weekday];
    }
    handleSimpleValueChange(deliveryWeekdays, 'deliveryWeekdays');
  };

  const changeClearableDate = (date: Date | null, fieldName: string) => {
    let payload = null;
    if (date) {
      payload = dateFns.format(date, payloadDateFormat);
    }
    handleSimpleValueChange(payload, fieldName);
  };

  const renderDeliveryTemplateSchedules = () => {
    // How many days there are between target group lock date and delivery date
    // For BTT it will be possible to edit delivery still one day before delivery
    const days_from_tgld_dd = isKRuokaOnlyChain(chainIds) ? GT_LEAD_TIME_DAYS : BTT_LEAD_TIME_DAYS;
    const seasonPrintBufferDays = 14;

    return (
      <React.Fragment>
        <DateField
          value={new Date(template.publishDate)}
          additionalClasses="half-wide"
          label="Signup starts"
          minDate={new Date()}
          maxDate={new Date(template.deadline)}
          onChange={(date: Date) => handleSimpleValueChange(dateFns.format(date, payloadDateFormat), 'publishDate')}
        />
        <DateField
          value={new Date(template.deadline)}
          additionalClasses="half-wide"
          label="Signup ends"
          minDate={new Date(template.publishDate)}
          onChange={(date: Date) => handleSimpleValueChange(dateFns.format(date, payloadDateFormat), 'deadline')}
        />
        <DateField
          value={template.firstEditDate ? new Date(template.firstEditDate) : null}
          additionalClasses="half-wide"
          label="First edit date"
          minDate={new Date(template.publishDate)}
          maxDate={new Date(template.lastEditDate)}
          onChange={(date: any) => changeClearableDate(date, 'firstEditDate')}
        />
        <DateField
          value={template.lastEditDate ? new Date(template.lastEditDate) : null}
          additionalClasses="half-wide"
          minDate={new Date(template.deadline)}
          label="Last edit date"
          onChange={async (date: any) => {
            // Clear pricing group. If async and await are removed, this call won't work for some reason
            // eslint-disable-next-line
            await handleSimpleValueChange(null, 'pricingGroup');
            changeClearableDate(date, 'lastEditDate');
          }}
        />
        {isProgram && (
          <DateField
            value={template.targetGroupLockDate ? new Date(template.targetGroupLockDate) : null}
            additionalClasses="half-wide"
            minDate={dateFns.addDays(new Date(template.lastEditDate), 1)}
            label={'Target group lock date'}
            onChange={(date: any) => changeClearableDate(date, 'targetGroupLockDate')}
          />
        )}
        {!isProgram && (
          <DateField
            value={new Date(template.firstStartDate)}
            additionalClasses="half-wide"
            disableWeekends
            label="First allowed delivery date"
            minDate={new Date(template.publishDate)}
            maxDate={new Date(template.lastStartDate)}
            onChange={(date: Date) =>
              handleSimpleValueChange(dateFns.format(date, payloadDateFormat), 'firstStartDate')
            }
          />
        )}
        <DateField
          value={new Date(template.lastStartDate)}
          additionalClasses="half-wide"
          disableWeekends
          label={isProgram ? 'Delivery date' : 'Last allowed delivery date'}
          minDate={
            isProgram
              ? dateFns.addDays(new Date(template.targetGroupLockDate), days_from_tgld_dd)
              : hasPrintTemplate
                ? dateFns.addBusinessDays(new Date(template.deadline), seasonPrintBufferDays)
                : new Date(template.firstStartDate) > new Date(template.lastEditDate)
                  ? new Date(template.firstStartDate)
                  : new Date(template.lastEditDate)
          }
          onChange={
            isProgram
              ? async (date: Date) => {
                  // eslint-disable-next-line
                  await handleSimpleValueChange(dateFns.format(date, payloadDateFormat), 'firstStartDate');
                  // eslint-disable-next-line
                  await handleSimpleValueChange(dateFns.format(date, payloadDateFormat), 'lastStartDate');
                }
              : (date: Date) => handleSimpleValueChange(dateFns.format(date, payloadDateFormat), 'lastStartDate')
          }
        />
        <div>
          <div className="input-field half-wide">
            <WeekdaySelect
              label="Allowed weekdays for delivery"
              selected={allowedWeekdays}
              toggleDay={toggleAllowedWeekday}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getContent = (propertyName: string) => {
    return _.get(template, `${propertyName}.${language}`, '');
  };

  return (
    <div className="editor-tab general">
      <section className="editor-section details">
        <header className="editor-section__header">
          <div className="title-row">
            <h3 className="section-title">Name & details</h3>
            {template.languages.length > 1 && (
              <LanguageSelector
                language={language}
                languages={['fi', 'sv']}
                toggleLanguage={(language: DeliveryLanguage) => setLanguage(language)}
              />
            )}
          </div>
        </header>
        <FormInput
          id="deliveryTemplateTitle"
          type="text"
          label={`Title - ${language}`}
          required={true}
          value={getContent('title')}
          handleChange={(e) => handleSimpleValueChange(e.target.value, `title.${language}`)}
        />
        <FormInput
          type="text"
          label={`Short description - ${language}`}
          detail="Short description for the template"
          required={true}
          value={getContent('description')}
          handleChange={(e) => handleSimpleValueChange(e.target.value, `description.${language}`)}
        />
        <FormTextArea
          label={`Introduction - ${language}`}
          required={true}
          value={getContent('introduction')}
          handleChange={(e) => handleSimpleValueChange(e.target.value, `introduction.${language}`)}
        />
        <FormInput
          type="number"
          min={-1}
          max={1000}
          label="Maximum deliveries"
          isInvalid={_.isNaN(template.maxDeliveries) || template.maxDeliveries < -1 ? true : false}
          handleChange={(e) => {
            handleSimpleValueChange(parseNumber(e.target.value), 'maxDeliveries');
          }}
          value={template.maxDeliveries}
          detail="Maximum number of deliveries/participations allowed, set to -1 for no maximum"
          readonly={false}
        />
        {hasPrintTemplate && (
          <FormInput
            type="number"
            label="Manual print price estimate (x.x € / print)"
            step={0.01}
            min={0}
            required={false}
            value={template.deliveryOpts.printPriceEstimateOverride}
            handleChange={(e) => {
              // allow empty input
              if (e.target.value === '') {
                handleSimpleValueChange(undefined, 'deliveryOpts.printPriceEstimateOverride');
                return;
              }
              handleSimpleValueChange(parseNumber(e.target.value), 'deliveryOpts.printPriceEstimateOverride');
            }}
            detail="Price estimate for a single print delivery. Leave empty to use the default price estimate."
          />
        )}
        {isKrautaProgramDelivery && (
          <CustomField>
            <Checkbox
              id="is-chain-delivery"
              label="Chain delivery? (Send chain email to customers whose main business unit does not participate)"
              handleClick={(e) => handleSimpleValueChange(e.target.checked, 'chainDelivery')}
              checked={template.chainDelivery}
            />
          </CustomField>
        )}
        <StoreSelector
          stores={stores || []}
          defaultStoreIds={template.stores}
          onChange={(stores) =>
            handleSimpleValueChange(
              stores.map((s) => s.storeId),
              'stores',
            )
          }
          label="Store(s)"
          detail="Search for store name or business unit ID"
        />
        <Checkbox
          id="notifications-off"
          label="Do not send notifications"
          handleClick={(e) => handleSimpleValueChange(e.target.checked, 'notificationsOff')}
          checked={template.notificationsOff}
        />
        {isProgram && hasPrintTemplate && (
          <PricingGroupSelector
            deliveryTemplate={template}
            changePricingGroup={async (pricingGroupId) => {
              // eslint-disable-next-line
              await handleSimpleValueChange(pricingGroupId, 'pricingGroup');
            }}
          />
        )}
      </section>
      <section className="editor-section schedules">
        <h3 className="section-title">Schedules</h3>
        {renderDeliveryTemplateSchedules()}
      </section>
    </div>
  );
};

export default inject('conceptsStore', 'storeStore')(observer(General));
