/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access, 	@typescript-eslint/no-unused-vars, @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises, 	@typescript-eslint/no-unsafe-assignment, 	@typescript-eslint/no-var-requires, @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-explicit-any   */
import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import page from 'components/next/pages/page/page';
import { NavLink } from 'react-router-dom';
import ContentBlockTemplateStore from 'stores/next/contentBlockTemplates';
import { contentBlock } from 'components/next/routes';
import type { Route } from 'components/next/types';
import { getContentBlockTemplateDefaults } from 'components/next/utils';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as IconArrowRight } from '@kesko/icons/nav/icon-arrow-right.svg';

import './contentBlocks.scss';

interface ContentBlocksProps {
  contentBlockTemplateStore?: ContentBlockTemplateStore;
  getPageLink(route: Route, id: string): string;
}

const ContentBlockTemplates = ({ contentBlockTemplateStore, getPageLink }: ContentBlocksProps) => {
  useEffect(() => {
    contentBlockTemplateStore.search({});
  }, []);

  const handleCreate = () => {
    const template = getContentBlockTemplateDefaults();
    contentBlockTemplateStore.createContentBlockTemplate(template);
  };

  const handleDelete = (id: string) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this content block template? This action cannot be undone.',
    );
    if (confirmed) {
      contentBlockTemplateStore.deleteContentBlockTemplate(id);
    }
  };

  const { contentBlockTemplates } = contentBlockTemplateStore;
  return (
    <div className="content content-blocks">
      <header>
        <h2>Content block templates</h2>
        <button className="add-content-template" onClick={() => handleCreate()}>
          <span>Add block template</span>
          <img src={require('images/add.svg').default} alt="add" />
        </button>
      </header>

      <table className="styled entity-grid">
        <thead>
          <tr>
            <th> Title </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {contentBlockTemplates.map((c, i) => (
            <tr key={i}>
              <td>{c.title.fi}</td>
              <td>
                <button onClick={() => handleDelete(c.id)}>
                  <IconDelete />
                </button>
                <NavLink to={c.id}>
                  <IconArrowRight />
                </NavLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default page(inject('contentBlockTemplateStore')(observer(ContentBlockTemplates)));
