/* eslint-disable @typescript-eslint/no-misused-promises  */
import React, { PropsWithChildren, useEffect, useState } from 'react';
import Spinner from 'components/common/next/spinner';
import { ReactComponent as ChevronRight } from '@kesko/icons/nav/icon-chevron-right.svg';
import { ReactComponent as ChevronLeft } from '@kesko/icons/nav/icon-chevron-left.svg';
import './tableContainer.scss';

interface Props {
  loading: boolean;
  pagination?: { pageSize: number; totalItems: number; onClick: (limit: number, offset: number) => Promise<void> };
}

const TableContainer = ({ loading, pagination, children }: PropsWithChildren<Props>) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(0);
  }, [pagination?.totalItems]);

  const onPaginationClick = async (offset: number) => {
    await pagination.onClick(pagination.pageSize, offset);
    setOffset(offset);
  };

  const renderPagination = () => {
    const { pageSize, totalItems } = pagination ? pagination : { pageSize: 0, totalItems: 0 };
    const activeBack = offset !== 0;
    const activeNext = offset + pageSize < totalItems;
    return (
      <div style={{ width: 200, flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            width: '30%',
            display: 'flex',
            justifyContent: 'center',
            cursor: activeBack ? 'pointer' : 'default',
          }}
          onClick={activeBack ? () => onPaginationClick(offset - pageSize < 0 ? 0 : offset - pageSize) : null}
        >
          <ChevronLeft className={activeBack ? '' : 'arrow disabled'} />
        </div>
        <div className="not-selectable" style={{ width: '40%', display: 'flex', justifyContent: 'center' }}>{`Page: ${
          pageSize === 0 ? 1 : offset / pageSize + 1
        }`}</div>
        <div
          style={{
            width: '30%',
            display: 'flex',
            justifyContent: 'center',
            cursor: activeNext ? 'pointer' : 'default',
          }}
          onClick={activeNext ? () => onPaginationClick(offset + pageSize) : null}
        >
          <ChevronRight className={activeNext ? '' : 'arrow disabled'} />
        </div>
      </div>
    );
  };

  return (
    <div className="table-container">
      {children}
      <div className="table-container__extras">
        {loading && <Spinner />}
        {pagination?.totalItems ? renderPagination() : null}
      </div>
    </div>
  );
};

export default TableContainer;
