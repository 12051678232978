/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { v4 as uuidv4 } from 'uuid';
import type { ProductOffer, DeliveryLanguage } from 'types/next';
import InputText from '../form/inputText';
import InputNumber from '../form/inputNumber';
import InputImage from '../form/inputImage';
import InputRadio from '../form/inputRadio';
import ProductSelect from './productSelect';
import { SearchType } from 'types/campaign';
import LanguageSelector from '../../next/languageSelector';
import type * as API from 'types/next-api';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import './productForm.scss';
import InputSelect from '../form/inputSelect';
import { CustomField } from 'components/next/components/form/input';
import { shouldLockForm } from './offerEditor';
// import { Checkbox } from 'components/next/components/checkbox';

type Product = API.Components.Schemas.Product;

interface Props {
  offer?: ProductOffer;
  setOffer: ({ offer, isValid }: { offer: ProductOffer; isValid?: boolean }) => void;
  isEditable: boolean;
  forcedItemLimitPer?: 'person' | 'household';
  mobileOffer?: boolean;
  disabled?: boolean;
}

const ProductForm = ({ offer, setOffer, isEditable, forcedItemLimitPer, mobileOffer, disabled }: Props) => {
  const [language, setLanguage] = useState<DeliveryLanguage>('fi');

  const initialProductOffer: ProductOffer = {
    id: uuidv4(),
    type: 'product',
    title: {
      fi: '',
      sv: '',
    },
    description: {
      fi: '',
      sv: '',
    },
    productOfferType: 'price',
    products: [],
    createTosOffer: true,
    regularPriceMin: 0,
    pricingUnit: 'kpl', // 'st' på svenska
    deposit: null,
    itemLimit: 1,
    itemLimitPer: forcedItemLimitPer || 'person',
  };

  useEffect(() => {
    if (!offer) {
      setOffer({ offer: { ...initialProductOffer } });
    }
  }, []);

  const getDefaultDiscountsForProduct = (product: Product) => {
    if (!product) {
      return {};
    }
    const discountPrice = Number((0.75 * product.price).toFixed(1));
    const discountPercentage = 25;
    return { discountPrice, discountPercentage };
  };

  const addProduct = (product: Product, isNewProduct: boolean) => {
    const updatedOffer = { ...offer };
    const products = updatedOffer.products || [];
    products.push(product);

    if (!isNewProduct) {
      const prices = products.map((p) => p.price);
      updatedOffer.regularPriceMin = _.min(prices);
      updatedOffer.regularPriceMax = _.max(prices);

      if (!updatedOffer.discountPrice) {
        const { discountPrice } = getDefaultDiscountsForProduct(product);
        updatedOffer.discountPrice = discountPrice;
      }
      if (product.pricingUnit) {
        updatedOffer.pricingUnit = product.pricingUnit;
      }
      if (product.deposit) {
        updatedOffer.deposit = product.deposit;
      }
      if (!updatedOffer.image && product.image) {
        updatedOffer.image = product.image;
      }
      if (!updatedOffer.title || !updatedOffer.title.fi) {
        updatedOffer.title = {
          fi: product.title.fi,
          sv: product.title.sv,
        };
      }
    }
    setOffer({ offer: updatedOffer });
  };

  const handleChange = (name: string, value: any) => {
    const updatedOffer = { ...offer };
    const attr = name.replace(/^offer\./, '');

    if (attr === 'productOfferType') {
      updatedOffer.discountPrice = undefined;
      updatedOffer.discountPercentage = undefined;
      if (updatedOffer.products && updatedOffer.products.length > 0) {
        const { discountPrice, discountPercentage } = getDefaultDiscountsForProduct(
          updatedOffer.products[updatedOffer.products.length - 1],
        );
        if (value === 'price') {
          updatedOffer.discountPrice = discountPrice;
        }
        if (value === 'percentage') {
          updatedOffer.discountPercentage = discountPercentage;
        }
      }
    }
    _.set(updatedOffer, attr, value);
    setOffer({ offer: updatedOffer });
  };

  const removeProduct = (ean: string) => {
    return () => {
      const products = _.filter(offer.products, (p) => p.ean !== ean);
      handleChange('offer.products', products);
      if (!products.length) {
        setOffer({ offer: initialProductOffer });
      }
    };
  };

  const onChange = (path?: string) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const value = e.target.value;
      const name = path || e.target.name;
      handleChange(name, value);
    };
  };

  const renderOfferBasics = () => {
    return (
      <div className="form-section offer-basics">
        <div className="offer-image">
          <InputImage
            label="Tarjouskuva"
            onChange={({ src }) => handleChange('offer.image', src)}
            value={{ src: offer.image }}
            styles={{ maxHeight: 220 }}
            readOnly={disabled}
          />
        </div>
        <div className="product-basics">
          <div className="product-basics">
            <InputText
              label={
                mobileOffer ? 'Edun nimi (näkyy asiakkaalle mobiilisovelluksessa)' : `Tarjouksen otsikko - ${language}`
              }
              name={`offer.title.${language}`}
              value={offer.title[language]}
              onChange={onChange()}
              placeholder="Kirjoita tarjoukselle otsikko"
            />
            <div className="product-offer-type form-control">
              <label>Edun tyyppi</label>
              <InputRadio
                label="Hintaetu"
                name="offer.productOfferType"
                value="price"
                defaultChecked={offer.productOfferType === 'price'}
                onChange={onChange()}
              />
              <InputRadio
                label="Prosenttietu"
                name="offer.productOfferType"
                value="percentage"
                defaultChecked={offer.productOfferType === 'percentage'}
                onChange={onChange()}
              />
              <InputRadio
                label="Veloituksetta"
                name="offer.productOfferType"
                value="gratis"
                defaultChecked={offer.productOfferType === 'gratis'}
                onChange={onChange()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderOfferPricing = () => {
    return (
      <div className="form-section">
        <div>
          {offer.productOfferType === 'price' && (
            <InputNumber
              label="Alennettu hinta (sis. mahdolliset pantit)"
              name="offer.discountPrice"
              value={offer.discountPrice}
              onChange={(val) => handleChange('offer.discountPrice', val)}
              decimalScale={2}
              fixedDecimalScale
              min={0}
              suffix={`€ / ${offer.pricingUnit}`}
            />
          )}
          {offer.productOfferType === 'percentage' && (
            <InputNumber
              label="Alennusprosentti"
              name="offer.discountPercentage"
              value={offer.discountPercentage}
              onChange={(val) => handleChange('offer.discountPercentage', val)}
              decimalScale={0}
              fixedDecimalScale
              min={0}
              max={100}
              suffix="%"
            />
          )}
          {offer.products.length > 1 ? (
            <div className="regular-price-range form-control">
              <label>Normaali hinta</label>
              <div className="input-group">
                <InputNumber
                  name="offer.regularPriceMin"
                  value={offer.regularPriceMin}
                  onChange={(val) => handleChange('offer.regularPriceMin', val)}
                  decimalScale={2}
                  max={offer.regularPriceMax}
                  fixedDecimalScale
                />
                <div className="range-identifier">–</div>
                <InputNumber
                  name="offer.regularPriceMax"
                  value={offer.regularPriceMax}
                  onChange={(val) => handleChange('offer.regularPriceMax', val)}
                  decimalScale={2}
                  fixedDecimalScale
                  min={offer.regularPriceMin}
                  suffix={`€ / ${offer.pricingUnit}`}
                />
              </div>
            </div>
          ) : (
            <InputNumber
              label="Normaali hinta"
              name="offer.regularPriceMin"
              value={offer.regularPriceMin}
              onChange={(val) => handleChange('offer.regularPriceMin', val)}
              decimalScale={2}
              fixedDecimalScale
              suffix={`€ / ${offer.pricingUnit}`}
            />
          )}

          <CustomField label="Myyntiyksikkö" additionalClasses="form-control">
            <InputSelect name="offer.pricingUnit" value={offer.pricingUnit} onChange={onChange('offer.pricingUnit')}>
              <option value="kpl">kpl</option>
              <option value="kg">kg</option>
            </InputSelect>
          </CustomField>

          <InputNumber
            label="Pantti"
            name="offer.deposit"
            value={offer.deposit}
            onChange={(val) => handleChange('offer.deposit', val)}
            min={0}
            suffix={`€ / ${offer.pricingUnit}`}
          />
          <div className="input-group item-limit">
            <InputNumber
              label="Rajoitus"
              name="offer.itemLimit"
              value={offer.itemLimit}
              onChange={(val) => handleChange('offer.itemLimit', val)}
              suffix={`${offer.pricingUnit}`}
            />
            <InputSelect
              name="offer.itemLimitPer"
              value={offer.itemLimitPer}
              onChange={onChange('offer.itemLimitPer')}
              disabled={Boolean(forcedItemLimitPer)}
            >
              <option value="person">/ asiakas</option>
              <option value="household">/ talous</option>
            </InputSelect>
          </div>
          {offer.pricingUnit !== 'kpl' && (
            <span className="limitation-notice">{`Tuotteen myyntiyksikkö on ${offer.pricingUnit}. Huomioithan tämän myyntirajoituksessa`}</span>
          )}
          <InputText
            label="Muut rajoitukset"
            name="offer.additionalRestrictions"
            value={offer.additionalRestrictions || ''}
            onChange={onChange()}
          />
        </div>
      </div>
    );
  };

  const renderProduct = (product: Product) => {
    const { ean, image, title, price, pricingUnit } = product;
    return (
      <div className="product-item" key={ean}>
        <div className="image-container">
          <img
            src={image ? `${image}?h=50&fm=png` : 'https://www.k-ruoka.fi/assets/6335/img/ei-tuotekuvaa.svg'}
            alt="tuotekuva"
          />
        </div>
        <div className="item-details">
          <div className="title">{title[language]}</div>
          <div className="label">
            EAN {ean}
            <div className="price">
              {price} / {pricingUnit}
            </div>
          </div>
        </div>
        <div className="remove-item" onClick={removeProduct(ean)}>
          {isEditable && <IconDelete />}
        </div>
      </div>
    );
  };

  const render = () => {
    if (!offer) {
      return null;
    }
    const eans = offer.products.map((p) => p.ean);

    const form = (
      <div className="offer-form-product">
        <div className="form-section">{offer.products.length > 0 && renderOfferBasics()}</div>
        <div className="form-section">
          <h3>Hintatiedot</h3>
          {offer.products.length > 0 && renderOfferPricing()}
        </div>
        <div className="form-section">
          <h3>Tuotteet</h3>
          {offer.products.map(renderProduct)}
          <ProductSelect filterEans={eans} searchType={SearchType.add} onSelect={addProduct} />
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div className="title-row">
          <h3>Tarjoustiedot</h3>
          {offer.products.length > 0 && (
            <LanguageSelector language={language} languages={['fi', 'sv']} toggleLanguage={setLanguage} />
          )}
        </div>
        {shouldLockForm(form, isEditable)}
      </React.Fragment>
    );
  };

  return render();
};

export default ProductForm;
