/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access, 	@typescript-eslint/no-unused-vars, @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises, 	@typescript-eslint/no-unsafe-assignment, 	@typescript-eslint/no-var-requires, @typescript-eslint/unbound-method   */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'utils/lodash';
import page from 'components/next/pages/page/page';
import { NavLink, useNavigate } from 'react-router-dom';
import StoreStore from 'stores/next/stores';
import StampCardStore from 'stores/next/stampCard';
import type { Route, Store } from 'components/next/types';
import { createCollectionStampCardTemplate, editCollectionStampCardTemplate } from 'components/next/routes';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import SearchBox from 'components/next/components/sidebar/searchBox';
import ChainSelector from 'components/next/components/chainSelector';
import StoreSelector from 'components/next/components/storeSelector';
import Spinner from 'components/common/next/spinner';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as IconArrowRight } from '@kesko/icons/nav/icon-arrow-right.svg';
import { kRautaChainIds, ChainAbbreviations, onninenChainIds } from 'constants/common';
import './collectionStampCardTemplates.scss';
import { useDebouncedEffect } from 'utils/helpers';

interface Props {
  getPageLink(route: Route, id: string): string;
}

interface InjectedProps extends Props {
  stampCardStore?: StampCardStore;
  storeStore?: StoreStore;
}

const CollectionStampCardTemplates = ({ stampCardStore, storeStore, getPageLink }: InjectedProps) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [chainId, setChainId] = useState(['3', '4', '5']);
  const [storeIds, setStoreIds] = useState<string[]>([]);

  useEffect(() => {
    storeStore.searchStores({});
    searchCollectionCardTemplates();
  }, []);

  const searchCollectionCardTemplates = () => {
    const search = _.omitBy(
      {
        query,
        ...(storeIds.length > 0 && { storeId: storeIds }),
        ...(chainId.length > 0 && { chainId }),
      },
      _.isNil,
    );

    stampCardStore.searchCollectionStampCardTemplates({
      payload: search,
    });
  };

  useDebouncedEffect(searchCollectionCardTemplates, [query, chainId, storeIds], 500);

  const del = async (id: string) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this collection stamp card template? This action cannot be undone.',
    );

    if (confirmed) {
      await stampCardStore.deleteCollectionStampCardTemplate(id);
      searchCollectionCardTemplates();
    }
  };

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'query') {
      setQuery(value);
    }
  };

  const handleChainChange = (chain: string) => {
    const chainIdCopy = [...chainId];
    if (chainIdCopy.includes(chain)) {
      _.pullAt(chainIdCopy, chainIdCopy.indexOf(chain));
    } else {
      chainIdCopy.push(chain);
    }
    setChainId(chainIdCopy);
  };

  const handleStoresChange = (stores: Store[]) => {
    setStoreIds(_.map(stores, 'storeId'));
  };

  const renderSidebar = () => {
    return (
      <div className="collection-stamp-card-templates-sidebar">
        <div className="collection-stamp-card-templates-filters">
          <button
            className="add-button sidebar-button"
            onClick={() => navigate(getPageLink(createCollectionStampCardTemplate, null))}
          >
            <span>Add template</span>
            <img src={require('images/add-nega.svg').default} alt="add" />
          </button>
          <div className="sidebar__title-wrapper">
            <h3 className="sidebar__title">Filters</h3>
            {isLoadingStampCardTemplates && <Spinner addClassName="spinner--unset" />}
          </div>
          <SearchBox
            value={query}
            name="query"
            onChange={handleQueryChange}
            placeholder="Search for templates"
            detail="Search for template title"
          />
          <label>Chain</label>
          <ChainSelector
            chainSelection={chainId}
            handleChainChange={handleChainChange}
            excludeChains={[...kRautaChainIds, ...onninenChainIds]}
          />
          <div className="filter-group">
            <label>Stores</label>
            <StoreSelector
              defaultStoreIds={storeIds}
              stores={storeStore.stores}
              onChange={handleStoresChange}
              detail="Search for store name or business unit ID"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderTemplateList = () => {
    const { stampCardTemplates } = stampCardStore;
    return (
      <div className="table-container">
        <table className="styled concept-grid">
          <thead>
            <tr>
              <th>Title</th>
              <th>Chains</th>
              <th>Stores</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.map(stampCardTemplates, (template, i) => {
              let storeList;
              const stores = _.get(template, ['stores']);
              if (_.isNil(stores)) {
                storeList = 'All';
              } else {
                storeList = stores.length === 0 ? '-' : stores.join(', ');
              }

              return (
                <tr key={i}>
                  <td>{_.get(template, ['uiData', 'uiName', 'fi'])}</td>
                  <td>
                    {_.get(template, ['chains'], [])
                      .sort()
                      .map((c, i) =>
                        ChainAbbreviations[c] ? (
                          <span className={`chain-name ${ChainAbbreviations[c]}`} key={`chain-${i}`}>
                            {ChainAbbreviations[c]}
                          </span>
                        ) : null,
                      )}
                  </td>
                  <td>{storeList}</td>
                  <td>{_.get(template, ['published'], false) ? 'Published' : 'Draft'}</td>
                  <td>
                    <div className="buttons">
                      <button onClick={() => del(_.get(template, ['id']))}>
                        <IconDelete />
                      </button>

                      <NavLink to={getPageLink(editCollectionStampCardTemplate, _.get(template, ['id']))}>
                        <IconArrowRight />
                      </NavLink>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const { isLoadingStampCardTemplates } = stampCardStore;
  return (
    <SidebarWrapper renderSidebar={renderSidebar}>
      <div className="collection-stamp-card-templates">
        <div className="collection-stamp-card-template-list">{renderTemplateList()}</div>
        {isLoadingStampCardTemplates && <Spinner />}
      </div>
    </SidebarWrapper>
  );
};

export default page(inject('stampCardStore', 'storeStore')(observer(CollectionStampCardTemplates)));
