/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-member-access,    */
import React, { useEffect, useState } from 'react';
import page from '../page/page';
import { inject, observer } from 'mobx-react';
import type { DimensionOption, Route as NextRoute } from 'types/next';
import DimensionsStore from '../../../../stores/next/dimensions';
import { FormInput } from '../../components/form/input';
import { Checkbox } from '../../components/checkbox/index';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spinner from 'components/common/next/spinner';

interface InjectedProps {
  dimensionsStore?: DimensionsStore;
}

interface DimensionOptionProps extends InjectedProps {
  unsavedChanges: boolean;
  informParentPage?(data: any): void;
  getPageLink(route: NextRoute, id: string): string;
}

interface DimensionOptionState {
  dimensionOption: DimensionOption;
}

const DimensionOptionPage = ({
  dimensionsStore,
  unsavedChanges,
  informParentPage,
  getPageLink,
}: DimensionOptionProps) => {
  const { id: idParam, oid: oidParam } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [dimensionOption, setDimensionOption] = useState<DimensionOption>(undefined);

  useEffect(() => {
    const dimension = dimensionsStore.dimensions.find((d) => String(d.id) === idParam);
    const b2bDimension = dimensionsStore.b2bDimensions.find((d) => String(d.id) === idParam);
    if (dimension) {
      const dimensionOption = dimension.options.find((o) => String(o.id) === oidParam);
      setDimensionOption(dimensionOption);
    } else if (b2bDimension) {
      const dimensionOption = b2bDimension.options.find((o) => String(o.id) === oidParam);
      setDimensionOption(dimensionOption);
    }
  }, []);

  const updateDimensionOption = (dimensionOption: DimensionOption) => {
    setDimensionOption(dimensionOption);
    informParentPage(dimensionOption);
  };

  const handleBack = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (
      !unsavedChanges ||
      window.confirm(
        'Some changes you made to this dimension option have not been saved. If you move away from this page, ' +
          'these changes will be lost. Are you sure you want to continue?',
      )
    ) {
      // discard earlier changes
      updateDimensionOption(undefined);
      const dimensionPage = location.pathname.replace(/\/dimension-options.*/g, '');
      navigate(dimensionPage);
    }
  };

  const renderHeader = () => {
    if (!dimensionOption) {
      return null;
    }

    return (
      <header className="editor-header">
        <div className="editor-header__controls">
          <button className="go-back" onClick={handleBack}>
            <img src={require('images/cross.svg').default} alt="go-back" />
          </button>
          <div className="editor-header__details">
            <h3>{dimensionOption.title}</h3>
            <h4>
              <span>ID: {dimensionOption.id}</span>
            </h4>
          </div>
        </div>
      </header>
    );
  };

  const shouldRenderEditor = !!dimensionOption;
  const dimOpt = dimensionOption;
  const isInUse =
    dimOpt &&
    dimensionsStore.dimensionOptionInUse.some((opt) => opt.dimensionOption.name === dimOpt.name && opt.inUse === true);

  return (
    <div>
      {shouldRenderEditor ? (
        <React.Fragment>
          {renderHeader()}
          <div className="content">
            <FormInput
              type="text"
              label="Name"
              value={dimOpt.name || ''}
              required={true}
              readonly={isInUse}
              handleChange={(e) => updateDimensionOption({ ...dimOpt, name: e.target.value })}
            />
            <FormInput
              type="text"
              label="Title"
              value={dimOpt.title || ''}
              required={true}
              handleChange={(e) => updateDimensionOption({ ...dimOpt, title: e.target.value })}
            />
            <FormInput
              type="text"
              label="Condition"
              value={dimOpt.condition || ''}
              required={true}
              readonly={isInUse}
              handleChange={(e) => updateDimensionOption({ ...dimOpt, condition: e.target.value })}
            />
            <FormInput
              type="text"
              label="Attribute"
              value={dimOpt.attribute || ''}
              readonly={isInUse}
              handleChange={(e) => updateDimensionOption({ ...dimOpt, attribute: e.target.value })}
            />
            <FormInput
              type="number"
              label="Order"
              value={dimOpt.order}
              min={0}
              handleChange={(e) => updateDimensionOption({ ...dimOpt, order: e.target.value })}
            />
            <FormInput
              type="text"
              label="Reach"
              value={dimOpt.reach || ''}
              readonly={isInUse}
              handleChange={(e) => updateDimensionOption({ ...dimOpt, reach: e.target.value })}
            />
            <Checkbox
              id={'checkbox' + dimOpt.id}
              label="Exclusive"
              checked={dimOpt.exclusive}
              disabled={isInUse}
              handleClick={(e) => updateDimensionOption({ ...dimOpt, exclusive: e.target.checked })}
            />
          </div>
        </React.Fragment>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default page(inject('dimensionsStore')(observer(DimensionOptionPage)));
