/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-member-access   */
import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'utils/lodash';
import General from './tabs/general';
import TargetAudience from './tabs/targetGroups';
import ContentTemplates from './tabs/contentTemplates';
import ContentBlocks from './tabs/contentBlocks';
import ContentFields from './tabs/contentFields';
import OfferOptions from './tabs/offerOptions';
import './templateEditor.scss';
import type {
  DeliveryTemplate,
  TargetGroup,
  ContentTemplate,
  ContentBlock,
  Dimension,
  ContentField,
  OfferOption,
  ContentBlockTemplate,
  PricingItem,
} from 'components/next/types';
import ConceptsStore from 'stores/next/programs';
import { DeliveryChannelName, ConceptType, BusinessType } from 'enums/common';

interface TemplateEditorProps {
  template: DeliveryTemplate;
  pricingItems: PricingItem[];
  dimensions: Dimension[];
  contentBlockTemplates: ContentBlockTemplate[];
  tab: number;
  create: boolean;
  updateChangeSet(value, fieldName, forceUpdate?): void;
  chainIds: string[];
}

interface InjectedProps extends TemplateEditorProps {
  conceptsStore: ConceptsStore;
}

interface TemplateEditorState {
  title: string;
}

@inject('conceptsStore')
@observer
class TemplateEditor extends React.Component<TemplateEditorProps, TemplateEditorState> {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
    };

    this.renderGeneral = this.renderGeneral.bind(this);
    this.renderTargetGroups = this.renderTargetGroups.bind(this);
    this.renderTab = this.renderTab.bind(this);
    this.renderContentTemplates = this.renderContentTemplates.bind(this);
    this.renderContentBlocks = this.renderContentBlocks.bind(this);
    this.renderContentFields = this.renderContentFields.bind(this);

    this.handleSimpleValueChange = this.handleSimpleValueChange.bind(this);
    this.handleChannelChange = this.handleChannelChange.bind(this);
    this.handleTargetGroupOptionChange = this.handleTargetGroupOptionChange.bind(this);
    this.handleContentTemplateUpdate = this.handleContentTemplateUpdate.bind(this);
    this.handleContentBlockUpdate = this.handleContentBlockUpdate.bind(this);
    this.handleContentFieldUpdate = this.handleContentFieldUpdate.bind(this);
    this.handleOfferOptionUpdate = this.handleOfferOptionUpdate.bind(this);
    this.handlePricingChange = this.handlePricingChange.bind(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  get concept() {
    return this.injected.conceptsStore.concept;
  }

  handleSimpleValueChange(value: string | number | boolean, fieldName: string) {
    const { updateChangeSet } = this.props;
    updateChangeSet(value, fieldName);
  }

  handleChannelChange(channels) {
    const { updateChangeSet } = this.props;
    updateChangeSet(channels, 'channels');
  }

  handleTargetGroupOptionChange(opts: TargetGroup[] | boolean) {
    const { updateChangeSet } = this.props;
    if (typeof opts === 'boolean') {
      updateChangeSet(opts, 'targetGroupOpts.useAreas');
      return;
    }
    updateChangeSet(opts, 'targetGroupOpts.dimensions');
  }

  handleContentTemplateUpdate(templates: ContentTemplate[]) {
    const { updateChangeSet } = this.props;
    updateChangeSet(_.filter(templates), 'contentTemplates');
  }

  handleContentBlockUpdate(contentBlocks: ContentBlock[]) {
    const { updateChangeSet } = this.props;
    updateChangeSet(_.filter(contentBlocks), 'contentBlocks');
  }

  handleContentFieldUpdate(contentFields: ContentField[]) {
    const { updateChangeSet } = this.props;
    updateChangeSet(_.filter(contentFields), 'contentFields');
  }

  handleOfferOptionUpdate(offerOptions: OfferOption[]) {
    const { updateChangeSet } = this.props;
    updateChangeSet(_.filter(offerOptions), 'offerOptions');
  }

  handlePricingChange(value: string, channel: DeliveryChannelName) {
    const { updateChangeSet, template, pricingItems } = this.props;

    // Get currently selected pricing items from delivery template.
    const currentPricingItems = _.reduce(
      template.pricing,
      (result, pricingItem: PricingItem) => {
        result[pricingItem.channel] = pricingItem;
        return result;
      },
      {},
    );

    const newPricing = _.find(pricingItems, (pricingItem: PricingItem) => pricingItem.id === value) || null;
    currentPricingItems[channel] = newPricing;
    updateChangeSet(_.compact(_.values(currentPricingItems)), 'pricing');
  }

  renderGeneral() {
    const { template, create, chainIds } = this.props;
    return (
      template && (
        <General
          template={template}
          create={create}
          handleSimpleValueChange={this.handleSimpleValueChange}
          chainIds={chainIds}
        />
      )
    );
  }

  renderTargetGroups() {
    const { template, dimensions } = this.props;
    const targetGroupType = this.concept.type === ConceptType.B2b ? BusinessType.B2b : BusinessType.B2c;
    return (
      template && (
        <TargetAudience
          dimensions={dimensions}
          template={template}
          targetGroupType={targetGroupType}
          handleSimpleValueChange={this.handleSimpleValueChange}
          handleTargetGroupOptionChange={this.handleTargetGroupOptionChange}
        />
      )
    );
  }

  renderContentTemplates() {
    const { template, pricingItems } = this.props;
    const { contentTemplates, contentBlocks, contentFields } = template;
    return (
      template && (
        <ContentTemplates
          deliveryTemplate={template}
          contentTemplates={contentTemplates}
          contentBlocks={contentBlocks}
          contentFields={contentFields}
          pricingItems={pricingItems}
          handlePricingChange={this.handlePricingChange}
          handleContentTemplateUpdate={this.handleContentTemplateUpdate}
          handleChannelChange={this.handleChannelChange}
          conceptType={this.concept.type as ConceptType}
        />
      )
    );
  }

  renderContentBlocks() {
    const { template, contentBlockTemplates } = this.props;
    const { contentBlocks, contentFields } = template;
    return (
      template && (
        <ContentBlocks
          deliveryTemplate={template}
          contentBlocks={contentBlocks}
          contentFields={contentFields}
          update={this.handleContentBlockUpdate}
          contentBlockTemplates={contentBlockTemplates}
        />
      )
    );
  }

  renderContentFields() {
    const { template } = this.props;
    const { contentFields } = template;
    return template && <ContentFields contentFields={contentFields} update={this.handleContentFieldUpdate} />;
  }

  renderOfferOptions() {
    const { template } = this.props;
    const offerOptions = [...template.offerOptions];
    return (
      template && (
        <OfferOptions
          firstStartDate={template.firstStartDate}
          conceptType={this.concept.type as ConceptType}
          offerOptions={offerOptions}
          offerOpts={template.offerOpts}
          update={this.handleOfferOptionUpdate}
          handleSimpleValueChange={this.handleSimpleValueChange}
        />
      )
    );
  }

  renderTab() {
    const { tab } = this.props;
    switch (tab) {
      case 0:
        return this.renderGeneral();
      case 1:
        return this.renderTargetGroups();
      case 2:
        return this.renderContentTemplates();
      case 3:
        return this.renderContentBlocks();
      case 4:
        return this.renderContentFields();
      case 5:
        return this.renderOfferOptions();
      default:
        console.error('Attempting to render an unavailable tab...');
        return this.renderGeneral();
    }
  }

  render() {
    return <div className="template-editor">{this.renderTab()}</div>;
  }
}

export default TemplateEditor;
