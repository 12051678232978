/* eslint-disable 	@typescript-eslint/no-explicit-any  */
import React, { useEffect } from 'react';
import { ReactComponent as IconClose } from '@kesko/icons/action/icon-close.svg';
import './drawer.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: JSX.Element | JSX.Element[];
  closeTo?: string;
  onClose?: () => any;
  confirmClose?: string;
  open?: boolean;
  className?: string;
  navigateBack?: boolean;
}

const Drawer = ({ children, closeTo, onClose, confirmClose, open, className, navigateBack = false }: Props) => {
  const navigate = useNavigate();

  const getClassName = () => {
    const classes = ['drawer'];
    if (open) {
      classes.push('open');
    }
    if (className) {
      classes.push(className);
    }
    return classes.join(' ');
  };

  const handleClose = () => {
    if (confirmClose && !window.confirm(confirmClose)) {
      return false;
    }
    if (closeTo) {
      navigate(closeTo);
    }
    if (navigateBack) {
      navigate(-1);
    }
    if (onClose) {
      onClose();
    }
  };

  function escapeClose(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      handleClose();
    }
  }

  function outsideClose(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.className === className) {
      handleClose();
    }
  }

  useEffect(() => {
    window.addEventListener('click', outsideClose);
    document.addEventListener('keyup', escapeClose);
    return () => {
      window.removeEventListener('click', outsideClose);
      document.removeEventListener('keyup', escapeClose);
    };
  }, []);

  return (
    <div className={getClassName()}>
      <div className="drawer-content">
        {children}
        {(onClose || closeTo || navigateBack) && (
          <div className="drawer-close">
            <button onClick={handleClose}>
              <IconClose />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Drawer;
