/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, 	@typescript-eslint/no-unsafe-assignment, 	@typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, 	@typescript-eslint/no-unsafe-return	*/
import _ from 'utils/lodash';
import { v4 as uuidv4 } from 'uuid';
import type {
  ContentField,
  ContentItem,
  ContentTemplate,
  ContentBlock,
  DeliveryTemplate,
  Concept,
} from '../types/entities';
import { getNewDeliveryTemplateDates } from 'utils/helpers';
import { ConceptType } from 'enums/common';

export const mapHTMLContentToFields = (
  html: string,
  contentFields: ContentField[],
): { matchingFields: ContentField[]; nonMatchingFields: string[] } => {
  const fieldIdentifiers = parseEntitiesByName(html, 'field');
  if (!fieldIdentifiers) {
    return { nonMatchingFields: null, matchingFields: null };
  }
  const matchingFields = _.chain(fieldIdentifiers)
    .map((identifier) => contentFields.find((field) => field.identifier === identifier))
    .filter((item) => !_.isNil(item))
    .uniq()
    .value();
  const nonMatchingFields = _.chain(fieldIdentifiers)
    .filter((identifier) => !matchingFields.find((field) => field.identifier === identifier))
    .uniq()
    .value();
  return { matchingFields, nonMatchingFields };
};

export const parseEntitiesByName = (html: string, entity: string, forceUnique = true) => {
  const fieldIdentifiers: string[] = [];
  // eslint-disable-next-line
  const regex = new RegExp(`{{${entity}\.([A-z0-9]+)}}`, 'gm');
  let m;

  do {
    m = regex.exec(html);
    if (m) {
      fieldIdentifiers.push(m[1]);
    }
  } while (m);

  return _.uniq(fieldIdentifiers);
};

export function changeOrder<T extends ContentItem | ContentField | ContentTemplate | ContentBlock>(
  collection: T[],
  currentIndex: number,
  shiftUp: boolean,
) {
  // normalize collection to zero-based order indices in case items have been removed
  const normalizedCollection = collection.map((item, index) => _.set(item, 'order', index));
  const currentItem = normalizedCollection[currentIndex];
  const adjacentIndex = shiftUp ? currentIndex - 1 : currentIndex + 1;
  const adjacentItem = normalizedCollection[adjacentIndex];

  if (!adjacentItem) {
    return collection;
  }
  _.set(currentItem, 'order', adjacentIndex);
  _.set(adjacentItem, 'order', currentIndex);
  normalizedCollection[currentIndex] = currentItem;
  normalizedCollection[adjacentIndex] = adjacentItem;

  return _.orderBy(normalizedCollection, 'order');
}

export const duplicateDeliveryTemplate = (original: DeliveryTemplate, conceptType: ConceptType, concept?: Concept) => {
  const oldBlockIds: string[] = original.contentBlocks.map((cb) => cb.id);
  const { publishDate, deadline, firstStartDate, lastStartDate, firstEditDate, lastEditDate, targetGroupLockDate } =
    getNewDeliveryTemplateDates(conceptType);

  const result = _.chain(original)
    .set('concept', `${concept ? concept.id : original.concept}`)
    .set('title.fi', `${original.title.fi} copy`)
    .set('title.sv', original.title.sv ? `${original.title.sv} copy` : '')
    .set(
      'contentBlocks',
      original.contentBlocks.map((contentBlock) => _.set(contentBlock, 'id', uuidv4())),
    )
    .set(
      'contentFields',
      original.contentFields.map((contentField) => _.set(contentField, 'id', uuidv4())),
    )
    .set(
      'contentTemplates',
      original.contentTemplates.map((contentTemplate) => _.omit(contentTemplate, 'id')),
    )
    .set(
      'offerOptions',
      original.offerOptions.map((offerOption) => _.set(offerOption, 'id', uuidv4())),
    )
    .set('publishDate', publishDate)
    .set('deadline', deadline)
    .set('firstStartDate', firstStartDate)
    .set('lastStartDate', lastStartDate)
    .set('firstEditDate', firstEditDate)
    .set('lastEditDate', lastEditDate)
    .set('targetGroupLockDate', targetGroupLockDate)
    .set('published', false)
    .set('pricingGroup', original.pricingGroup)
    .omit('id')
    .value();

  // Map old block ids to new ids
  const oldBlockIdsToNewBlockIds = oldBlockIds.reduce((acc: any, curr: string, index: number) => {
    acc[curr] = result.contentBlocks[index].id;
    return acc;
  }, {});

  // Update each attached content template with default block and available block ids
  result.contentTemplates = result.contentTemplates
    ? result.contentTemplates.map((ct) => {
        ct.contentSlots = ct.contentSlots
          ? ct.contentSlots.map((cs) => {
              cs.defaultContentBlock =
                cs.defaultContentBlock !== null ? oldBlockIdsToNewBlockIds[cs.defaultContentBlock] : null;
              cs.contentBlocks = cs.contentBlocks ? cs.contentBlocks.map((cb) => oldBlockIdsToNewBlockIds[cb]) : [];
              return cs;
            })
          : [];
        return ct;
      })
    : [];

  return result;
};

export * from './variables';
export * from './defaults';
