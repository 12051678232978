/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { scrollToTop } from 'utils/helpers';

interface Props {
  to?: string;
  title: string;
  icon?: any;
  disabled?: boolean;
  exact?: boolean;
}

const NavigationLink = ({ to, title, disabled, exact, icon: Icon }: Props) => {
  const location = useLocation();

  const isActive = () => {
    if (exact) {
      return location.pathname === to;
    }
    return location.pathname.startsWith(to);
  };

  const classes = ['navigation-link'];
  if (disabled) {
    classes.push('disabled');
  }
  if (isActive()) {
    classes.push('active');
  }

  return (
    <NavLink to={to} onClick={scrollToTop} className={classes.join(' ')}>
      <div className="link-content">
        <div className="icons">
          {Icon && [
            <Icon key="" className="link-icon" color="#fff" width={24} height={24} />,
            <Icon key="active" className="link-icon active" width={24} height={24} />,
          ]}
        </div>
        <span className="link-text">{title}</span>
      </div>
    </NavLink>
  );
};

export default NavigationLink;
