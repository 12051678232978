/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import ContentStore from 'stores/next-retailer/contentStore';
import { ContentType } from 'enums/common';
import { marked } from 'marked';
import './helpPage.scss';

interface Props {}

interface InjectedProps extends Props {
  contentStore: ContentStore;
}

@inject('contentStore')
@observer
class HelpPage extends React.Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  get contentStore() {
    return this.injected.contentStore;
  }

  get content() {
    return _.filter(this.contentStore.content, { type: ContentType.Help });
  }

  get contentSections() {
    return _.filter(this.contentStore.collection, { type: ContentType.Help });
  }

  public componentDidMount() {
    const { contentStore } = this.injected;
    contentStore.search({ type: ContentType.Help });
    const { pathname } = window.location;

    // Check if the url contains a slug, then scroll to it
    // This allows calls like <Link target='_blank' to={getLink('help', 'info-stampcard-reward')} /> to be made from elsewhere.
    const foundSlug = this.contentSections.find((s) => pathname.includes(s.slug));
    if (foundSlug) {
      const elem = document.getElementById(foundSlug.slug); // Scroll to element that has ID equal to hash
      if (typeof elem !== 'undefined' && elem !== null) {
        elem.scrollIntoView(true);
      }
    }

    if (window.location.hash) {
      // Check if the current URL contains a hash
      const hash = window.location.hash.substring(1); // Store hash in variable, remove the # character
      const elem = document.getElementById(hash); // Scroll to element that has ID equal to hash
      if (typeof elem !== 'undefined' && elem !== null) {
        elem.scrollIntoView(true);
      }
    }
  }

  public renderHelpSections = () =>
    _.orderBy(this.contentSections, 'order').map((section) => {
      return (
        <section id={section.slug} key={section.slug}>
          {section.title && <h2 className="section-title">{section.title.fi}</h2>}
          <div className="section-content" dangerouslySetInnerHTML={{ __html: marked(section.content.fi) }} />
        </section>
      );
    });

  public renderHelpTableOfContents = () =>
    _.orderBy(this.contentSections, 'order').map((section) => {
      const { title } = section;
      return (
        title &&
        title.fi.trim().length > 0 && (
          <li key={section.slug}>
            <a href={`#${section.slug}`}>{title.fi}</a>
          </li>
        )
      );
    });

  public render() {
    return (
      <div className="help-page">
        <div className="main-content">
          <div className="content">
            <h1 className="main-title">K Markkinointi NEXT ohjeet</h1>
            <section className="table-of-contents" key="table-of-contents">
              {this.renderHelpTableOfContents()}
            </section>
            {this.renderHelpSections()}
          </div>
        </div>
      </div>
    );
  }
}

export default HelpPage;
